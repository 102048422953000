import React, { useState, useEffect, Component } from "react";

//import {AgGridReact} from 'ag-grid-react';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MiniDrawer from "./../MiniDrawer";
import "./../App.css";


import { useRecoilState } from 'recoil'
import { userAtom} from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import {getTimestamp} from '../components/timeStamp.js'


const ManualSubmit =(props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)


    const [the_xml, setTheXML] = useState("") 
    const [hmrc_userid, setHMRCUserId] = useState("") 
    const [hmrc_password, setHMRCPassword] = useState("")
    const [test_file, setTestFile] = useState('Y')
    const [amendment, setAmendment] = useState("N")
    const [success, setSuccess] = useState("Successful Submission")
    const [message, setMessage] = useState("")
    const [report_token, setReportToken]= useState("")
    const [submitting, setSubmitting] = useState(false)
    const [test_service, setTestService] = useState("N")
    const [admin, setAdmin] = useState("N")

    useEffect(() => {
        if (!recoilFetched) {
          if (!user_atom.token || user_atom.agent !== ""){
          let userState = getLocalStorage()

            setUserAtom({
            ...userState,
            agent: user_atom.agent
            })
            setRecoilFetched(true)
            }

        else{
          setRecoilFetched(true)
        }
      }
      }, [])

      useEffect(()=> {
          if(recoilFetched){
            let hmrc_userid = localStorage.getItem("hmrc_userid");
            let admin = localStorage.getItem("admin");
            setHMRCUserId(hmrc_userid)
            setAdmin(admin)
          }
      }, [recoilFetched])

    const showFile = (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = (e) => {
            const text = (e.target.result)
            console.log(text)
            setTheXML(text)
            let textArea = document.getElementById("the_xml");
            textArea.value = e.target.result;
        };
        reader.readAsText(e.target.files[0])
    }

    const submitXML = () => {
        localStorage.setItem("hmrc_userid", hmrc_userid);

        let body = {
            xml: the_xml,
            hmrc_username: hmrc_userid,
            hmrc_password,
            test_file,
            amendment,
            test_service
        }

        console.log(payload)

        let payload = JSON.stringify(body);

        setSubmitting(true)

            fetch(
                `https://${global.api_url}/tax/manualXMLSubmit.php?ts=${getTimestamp()}`,
                {
                    method: "POST",
                    headers: { token: user_atom.token, agentId: user_atom.agent },
                    body: payload
                }
            )
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    let _success = json.success === "Y" ? "Successful Submission" : "Submission Failed";
                    let _message = json.data;
                    let _report_token = json.report_token;
                    //Mixpanel.track("bulk_untag_trans");
                    setSuccess(_success)
                    setMessage(_message)
                    setReportToken(_report_token)
                    setSubmitting(false)
                    //now trigger send email

                    if (json.success === "Y" && test_file === 'N') {

                        fetch(`https://${global.api_url}/tax/sendPDFEmail.php`, {
                            method: "POST",
                            headers: { token: user_atom.token, agentId: user_atom.agent },
                            body: JSON.stringify({
                                report_token: json.report_token,
                                tax_year_id: json.tax_year_id
                            })
                        })
                            .then(res => res.json())
                            .then(json => {
                                console.log("email done");
                            });
                    }
                });

    }


    const renderProgress = () => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        backgroundColor: "#FAFAFC"
                    }}
                >
                    <MiniDrawer />
                    <Container
                        style={{
                            width: "100%",
                            marginTop: 120,
                            height: "80vh",
                            overflow: "auto",
                            textAlign: 'left',
                            marginLeft: 0, marginRight: 0
                        }}
                    >

                        <h2>Communicating with HMRC. Please wait ... </h2>
                        <CircularProgress />
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    const renderResponse = () => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        backgroundColor: "#FAFAFC"
                    }}
                >
                    <MiniDrawer />
                    <Container
                        style={{
                            width: "100%",
                            marginTop: 120,
                            height: "80vh",
                            overflow: "auto",
                            textAlign: 'left',
                            marginLeft: 0, marginRight: 0
                        }}
                    >

                        <h2>{success}</h2>

                        <p>{message}</p>

                        <p><a href={`https://pdfe1.untied.io/render_pdf.php?u=https://${global.api_url}/tax/viewReturnReport.php?t=${report_token}`}>Download PDF Report</a></p>

                    </Container>
                </div>
            </React.Fragment>
        )
    }

    const renderForm = () => {
        return (
            <React.Fragment>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        backgroundColor: "#FAFAFC"
                    }}
                >
                    <MiniDrawer />
                    <Container
                        style={{
                            width: "100%",
                            marginTop: 120,
                            height: "80vh",
                            overflow: "auto",
                            textAlign: 'left',
                            marginLeft: 0, marginRight: 0
                        }}
                    >

                        <h2>XML Submission</h2>
                        <p>Use this if you have been given an XML file by an untied adviser.</p>

                        <div style={{ width: '75%', float: 'left' }}>

                            <input type="file" onChange={(e) => showFile(e)} />

                            <textarea
                                id="the_xml"
                                style={{ height: 500, width: '95%', marginTop: 5 }}
                                onChange={(event) => setTheXML(event.target.value)}
                            >{the_xml}</textarea>
                        </div>

                        <div style={{ marginLeft: '40px', paddingLeft: 30, paddingTop: 10 }}>

                            <TextField
                                //style={styles.input}
                                variant="outlined"
                                label="HMRC Username"
                                style={{ marginLeft: 10, width: 200 }}
                                autoCapitalize="none"
                                value={hmrc_userid}
                                onChange={(event) => setHMRCUserId(event.target.value)}
                                autoCorrect={false}
                                // keyboardType="email-address"
                                // returnKeyType="next"
                                placeholder="Username"
                                placeholdertextcolor="#000000"
                                InputProps={{ disableUnderline: true }}
                            />


                            <TextField
                                //style={styles.input}
                                variant="outlined"
                                label="HMRC Password"
                                style={{ marginLeft: 10, width: 200, marginTop: 20 }}
                                autoCapitalize="none"
                                value={hmrc_password}
                                onChange={(event) => setHMRCPassword(event.target.value)}
                                type={"password"}
                                // autoCorrect={false}
                                // keyboardType="email-address"
                                // returnKeyType="next"
                                placeholder="Password"
                                placeholdertextcolor="#000000"
                                InputProps={{ disableUnderline: true }}
                            />

                            <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

                                <Select
                                    value={test_file}
                                    variant="outlined"
                                    label={"Test Submission"}
                                    onChange={(event) => setTestFile(event.target.value)}
                                    disableUnderline={false}
                                >

                                    <MenuItem key={1} value={"Y"}>Test-In-Live</MenuItem>
                                    <MenuItem key={2} value={"N"}>Live Submission</MenuItem>

                                </Select>
                            </div>


                            <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

                                <Select
                                    value={amendment}
                                    variant="outlined"
                                    label={"Test Submission"}
                                    onChange={(event) => setAmendment(event.target.value)}
                                    disableUnderline={false}
                                >

                                    <MenuItem key={1} value={"N"}>First Submission</MenuItem>
                                    <MenuItem key={2} value={"Y"}>Amendment</MenuItem>

                                </Select>
                            </div>


                            {admin === "Y" && (
                                <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

                                    <Select
                                        value={test_service}
                                        variant="outlined"
                                        label={"Test Submission"}
                                        onChange={(event) => setTestService(event.target.value)}
                                        disableUnderline={false}
                                    >

                                        <MenuItem key={1} value={"Y"}>Test Service</MenuItem>
                                        <MenuItem key={2} value={"N"}>Live Service</MenuItem>

                                    </Select>
                                </div>
                            )}


                            <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    style={{
                                        paddingVertical: 12,

                                        height: 56,
                                        borderRadius: 2,
                                        color: "white",
                                        backgroundColor: "#14A7CC",
                                        textTransform: "none"
                                    }}
                                    onClick={()=>submitXML()}
                                >Submit to HMRC</Button></div>

                        </div>

                    </Container>
                </div>
            </React.Fragment >
        );
    }

    const displayMain = () => {
        if(recoilFetched){
            if (submitting) {
                return renderProgress();
            } else {
                if (message === "") {
                    return renderForm();
                } else {
                    return renderResponse();
                }
            }
        }
        else {
            return <div/>
        }
    }

    return(
        displayMain()
    )


}

export default withRouter(ManualSubmit)



// class ManualSubmit extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             the_xml: "", hmrc_userid: "", hmrc_password: "", test_file: 'Y', amendment: "N",
//             success: "Successful Submission", message: "", report_token: "", submitting: false,
//             test_service: "N", admin: "N"
//         };
//     }

//     componentDidMount() {
//         var hmrc_userid = localStorage.getItem("hmrc_userid");
//         var admin = localStorage.getItem("admin");
//         this.setState({ hmrc_userid: hmrc_userid, admin: admin })
//     }

//     showFile = (e) => {
//         e.preventDefault()
//         const reader = new FileReader()
//         reader.onload = (e) => {
//             const text = (e.target.result)
//             console.log(text)
//             this.setState({ the_xml: text })
//             var textArea = document.getElementById("the_xml");
//             textArea.value = e.target.result;
//         };
//         reader.readAsText(e.target.files[0])
//     }

//     submitXML = () => {
//         var timeStamp = Math.floor(Date.now() / 1000);

//         localStorage.setItem("hmrc_userid", this.state.hmrc_userid);

//         let body = {
//             xml: this.state.the_xml,
//             hmrc_username: this.state.hmrc_userid,
//             hmrc_password: this.state.hmrc_password,
//             test_file: this.state.test_file,
//             amendment: this.state.amendment,
//             test_service: this.state.test_service
//         }

//         var payload = JSON.stringify(body);

//         this.setState({ submitting: true }, () => {

//             fetch(
//                 `https://${global.api_url}/tax/manualXMLSubmit.php?ts=${timeStamp}`,
//                 {
//                     method: "POST",
//                     headers: { token: this.props.auth.auth.token, agentId: this.props.auth.agent },
//                     body: payload
//                 }
//             )
//                 .then(res => res.json())
//                 .then(json => {
//                     console.log(json);
//                     var success = json.success === "Y" ? "Successful Submission" : "Submission Failed";
//                     var message = json.data;
//                     var report_token = json.report_token;
//                     //Mixpanel.track("bulk_untag_trans");
//                     this.setState({ success, message, report_token, submitting: false })

//                     //now trigger send email

//                     if (json.success === "Y" && this.state.test_file === 'N') {

//                         fetch(`https://${global.api_url}/tax/sendPDFEmail.php`, {
//                             method: "POST",
//                             headers: { token: this.props.auth.auth.token, agentId: this.props.auth.agent },
//                             body: JSON.stringify({
//                                 report_token: json.report_token,
//                                 tax_year_id: json.tax_year_id
//                             })
//                         })
//                             .then(res => res.json())
//                             .then(json => {
//                                 console.log("email done");
//                             });
//                     }
//                 });

//         });
//     }

//     render() {
//         if (this.state.submitting) {
//             return this.renderProgress();
//         } else {
//             if (this.state.message === "") {
//                 return this.renderForm();
//             } else {
//                 return this.renderResponse();
//             }
//         }

//     }

//     renderProgress() {
//         return (
//             <React.Fragment>
//                 <div
//                     style={{
//                         display: "flex",
//                         width: "100%",
//                         backgroundColor: "#FAFAFC"
//                     }}
//                 >
//                     <MiniDrawer />
//                     <Container
//                         style={{
//                             width: "100%",
//                             marginTop: 120,
//                             height: "80vh",
//                             overflow: "auto",
//                             textAlign: 'left',
//                             marginLeft: 0, marginRight: 0
//                         }}
//                     >

//                         <h2>Communicating with HMRC. Please wait ... </h2>
//                         <CircularProgress />
//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }

//     renderResponse() {
//         return (
//             <React.Fragment>
//                 <div
//                     style={{
//                         display: "flex",
//                         width: "100%",
//                         backgroundColor: "#FAFAFC"
//                     }}
//                 >
//                     <MiniDrawer />
//                     <Container
//                         style={{
//                             width: "100%",
//                             marginTop: 120,
//                             height: "80vh",
//                             overflow: "auto",
//                             textAlign: 'left',
//                             marginLeft: 0, marginRight: 0
//                         }}
//                     >

//                         <h2>{this.state.success}</h2>

//                         <p>{this.state.message}</p>

//                         <p><a href={`https://pdfe1.untied.io/render_pdf.php?u=https://${global.api_url}/tax/viewReturnReport.php?t=${this.state.report_token}`}>Download PDF Report</a></p>

//                     </Container>
//                 </div>
//             </React.Fragment>
//         )
//     }

//     renderForm() {
//         return (
//             <React.Fragment>
//                 <div
//                     style={{
//                         display: "flex",
//                         width: "100%",
//                         backgroundColor: "#FAFAFC"
//                     }}
//                 >
//                     <MiniDrawer />
//                     <Container
//                         style={{
//                             width: "100%",
//                             marginTop: 120,
//                             height: "80vh",
//                             overflow: "auto",
//                             textAlign: 'left',
//                             marginLeft: 0, marginRight: 0
//                         }}
//                     >

//                         <h2>XML Submission</h2>
//                         <p>Use this if you have been given an XML file by an untied adviser.</p>

//                         <div style={{ width: '75%', float: 'left' }}>

//                             <input type="file" onChange={(e) => this.showFile(e)} />

//                             <textarea
//                                 id="the_xml"
//                                 style={{ height: 500, width: '95%', marginTop: 5 }}
//                                 onChange={(event) => this.setState({ the_xml: event.target.value })}
//                             >{this.state.the_xml}</textarea>
//                         </div>

//                         <div style={{ marginLeft: '40px', paddingLeft: 30, paddingTop: 10 }}>

//                             <TextField
//                                 //style={styles.input}
//                                 variant="outlined"
//                                 label="HMRC Username"
//                                 style={{ marginLeft: 10, width: 200 }}
//                                 autoCapitalize="none"
//                                 value={this.state.hmrc_userid}
//                                 onChange={(event) => this.setState({ hmrc_userid: event.target.value })}
//                                 autoCorrect={false}
//                                 // keyboardType="email-address"
//                                 // returnKeyType="next"
//                                 placeholder="Username"
//                                 placeholdertextcolor="#000000"
//                                 InputProps={{ disableUnderline: true }}
//                             />


//                             <TextField
//                                 //style={styles.input}
//                                 variant="outlined"
//                                 label="HMRC Password"
//                                 style={{ marginLeft: 10, width: 200, marginTop: 20 }}
//                                 autoCapitalize="none"
//                                 value={this.state.hmrc_password}
//                                 onChange={(event) => this.setState({ hmrc_password: event.target.value })}
//                                 type={"password"}
//                                 // autoCorrect={false}
//                                 // keyboardType="email-address"
//                                 // returnKeyType="next"
//                                 placeholder="Password"
//                                 placeholdertextcolor="#000000"
//                                 InputProps={{ disableUnderline: true }}
//                             />

//                             <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

//                                 <Select
//                                     value={this.state.test_file}
//                                     variant="outlined"
//                                     label={"Test Submission"}
//                                     onChange={(event) => this.setState({ test_file: event.target.value })}
//                                     disableUnderline={false}
//                                 >

//                                     <MenuItem key={1} value={"Y"}>Test-In-Live</MenuItem>
//                                     <MenuItem key={2} value={"N"}>Live Submission</MenuItem>

//                                 </Select>
//                             </div>


//                             <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

//                                 <Select
//                                     value={this.state.amendment}
//                                     variant="outlined"
//                                     label={"Test Submission"}
//                                     onChange={(event) => this.setState({ amendment: event.target.value })}
//                                     disableUnderline={false}
//                                 >

//                                     <MenuItem key={1} value={"N"}>First Submission</MenuItem>
//                                     <MenuItem key={2} value={"Y"}>Amendment</MenuItem>

//                                 </Select>
//                             </div>


//                             {this.state.admin === "Y" && (
//                                 <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>

//                                     <Select
//                                         value={this.state.test_service}
//                                         variant="outlined"
//                                         label={"Test Submission"}
//                                         onChange={(event) => this.setState({ test_service: event.target.value })}
//                                         disableUnderline={false}
//                                     >

//                                         <MenuItem key={1} value={"Y"}>Test Service</MenuItem>
//                                         <MenuItem key={2} value={"N"}>Live Service</MenuItem>

//                                     </Select>
//                                 </div>
//                             )}


//                             <div class="MuiFormControl-root MuiTextField-root" style={{ marginLeft: 10, marginTop: 20, width: '200px' }}>
//                                 <Button
//                                     variant="outlined"
//                                     type="submit"
//                                     style={{
//                                         paddingVertical: 12,

//                                         height: 56,
//                                         borderRadius: 2,
//                                         color: "white",
//                                         backgroundColor: "#14A7CC",
//                                         textTransform: "none"
//                                     }}
//                                     onClick={this.submitXML}
//                                 >Submit to HMRC</Button></div>

//                         </div>

//                     </Container>
//                 </div>
//             </React.Fragment >
//         );
//     }
// }

// // function scale(val) {
// //     return val;
// // }

// // const classes = {
// //     card: {
// //         minWidth: 175,
// //         maxWidth: 500
// //     },
// //     bullet: {
// //         display: "inline-block",
// //         margin: "0 2px",
// //         transform: "scale(0.8)"
// //     },
// //     title: {
// //         fontSize: 16,
// //         //color: "#e0f7fa"
// //         color: "white"
// //     },
// //     titleMain: {
// //         fontSize: 20,
// //         //color: "#e0f7fa"
// //         color: "white"
// //     },
// //     pos: {
// //         marginBottom: 12
// //     }
// // };

// // const styles = {
// //     buttonContainer: {
// //         paddingVertical: 12,
// //         width: 200,
// //         marginBottom: 15,
// //         height: 48,
// //         borderRadius: 4,
// //         backgroundColor: "#c7ff00",
// //         color: "#14A7CC",

// //         textTransform: "none",
// //         marginTop: 30
// //     },

// //     buttonText: {
// //         color: "#000000",
// //         textAlign: "center",
// //         fontWeight: "700"
// //     },

// //     input: {
// //         width: "200px",
// //         borderRadius: scale(4),
// //         borderColor: "#c7ff00",
// //         borderStyle: "solid",
// //         borderBottomWidth: scale(2),
// //         backgroundColor: "#ffffff",
// //         _paddingLeft: scale(12),
// //         get paddingLeft() {
// //             return this._paddingLeft;
// //         },
// //         set paddingLeft(value) {
// //             this._paddingLeft = value;
// //         },
// //         fontSize: scale(14),
// //         height: 36,
// //         marginTop: 10,
// //         marginBottom: 20
// //         //paddingBottom: 20
// //     },
// //     itIs: {
// //         color: "#c7ff00",
// //         fontSize: 18,
// //         fontWeight: "500",
// //         letterSpacing: 0.54,
// //         textAlign: "center",
// //         alignSelf: "center"
// //     },
// //     footer: {
// //         flex: 1,
// //         alignSelf: "center",
// //         justifyContent: "center",
// //         paddingBottom: 5,
// //         borderRadius: scale(4),
// //         borderTopColor: "#14A7CC",
// //         borderTopStyle: "solid",
// //         borderTopWidth: scale(2),

// //         alignItems: "center"
// //     },
// //     title: {
// //         fontSize: 16,
// //         //color: "#e0f7fa"
// //         color: "white"
// //     },
// //     titleMain: {
// //         fontSize: 20,
// //         //color: "#e0f7fa"
// //         color: "white"
// //     }
// // };

// // const mapStateToProps = (state) => {
// //     return { ...state, }
// //   }
  
  
// //   export default connect(mapStateToProps)(withRouter(ManualSubmit))
//   export default withRouter(ManualSubmit)
