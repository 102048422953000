import React, { useState, useEffect, useRef } from "react";

import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { Mixpanel } from "../mixpanel";
import { Redirect } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser'; 

import { Container, MenuItem} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";

import { IonButton, IonAlert, IonLoading, IonPopover, IonGrid, IonRow, IonCol } from '@ionic/react';
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import Link from '@material-ui/icons/Link';
import Refresh from '@material-ui/icons/Refresh';
import moment from "moment";
import empty_state from '../images/empty_state.png'

import MiniDrawer from "../MiniDrawer";
import Footer from "../components/Footer";
import "./../App.css";
import "./../modal_css.css";
import './Page.css';
import { getTimestamp } from '../components/timeStamp.js'
import { getLocalStorage } from '../components/localstorage.js'
import { testPayments } from '../components/fakeBankDetails.js'

const Payments = (props) => {
  const [user_atom, setUserAtom] = useRecoilState(userAtom)
  // const [token, setToken] = useState(user_atom.token);
  // const [agentId, setAgentId] = useState(user_atom.agent);
  const [recoilFetched, setRecoilFetched] = useState(false)
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  // const [requestsRowData, setRequestsRowData] = useState([]);
  const [requests, setRequests] = useState([])
  const [resent, setResent] = useState(false)
  const [mCopied, setMCopied] = useState(false)
  const [lCopied, setLCopied] = useState(false)
  const [first, setFirst] = useState(true)
  const [discardedRequests, setDiscardedRequests] = useState([])
  const [paymentData, setPaymentData] = useState({})
  const [popUpMessage, setPopUpMessage] = useState({})
  const [delpopUpMessage, setDelpopUpMessage] = useState({})
  const [redirect, setRedirect] = useState(false)

  const requestsRef = useRef()
  const discardedRef = useRef()
  const [discarded, setDiscarded] = useState(false)
  const [hamburgerMenu, setHamburgerMenu] = useState({open: false, target:""})
  const constants = {
    defaultColDef: {
      resizable: true,
      editable: false,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      flex: 1,
      onCellDoubleClicked: (params) => {
        // console.log(params.data) 
          Mixpanel.track("opened_payment_info");
          let payUrl = `https://pay.untied.io/?g=${params.data.guid}|${params.data.id}`
          let payMessage = 'Please pay me £' + formatAmount(params.data.amount) + ' for ' + params.data.description + ' ' + payUrl
          setPaymentData({
            show: true,
            id: params.data.id,
            data: params.data,
            message: payMessage,
            url: payUrl
          })
          
      }
    },
    columnDefs: [
      {
        headerName: "Submitted Time",
        field: "timestamp",
        cellClass: "cell-style",
        valueFormatter: (params) => prettyDateTime(params.value),
        maxWidth: 200,
        sort: "desc",
        filter: "agDateColumnFilter",
      },
        {
        headerName: "Description",
        field: "description",
        cellClass: "cell-style",
        maxWidth: 420,
        //editable: true,
        resizable: true
      },
      {
        headerName: "Amount",
        field: "amount",
        cellClass: "cell-style",
        maxWidth: 120,
        //editable: true,
        resizable: false,
        valueFormatter: params => {
          // console.log(params.data)
          const cSymbol = params.data.currency_symbol ? params.data.currency_symbol : '£';
          return ( 
          cSymbol +
          Number(params.value)
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          )
        }
      },
      {
        headerName: "Request made to",
        cellClass: "cell-style",
        field: "email",
        filter: true,
        resizable: false,
        sortable: false,
        maxWidth: 220,
        valueFormatter: params => {
          return ( 
            params.data.email.toLowerCase()
          )
        }
      },
      {
        headerName: "Account",
        cellClass: "cell-style",
        field: "name",
        resizable: false,
        maxWidth: 120,
      },
      {
        headerName: "Status",
        cellClass: "cell-style",
        field: "paid",
        maxWidth: 150,
        resizable: false,
        valueFormatter: params => {
          if(params.value === "Y"){
            return "Auto-tagged"
          }
          else if (params.value === "N"){
            return "Request sent" 
          } else {
            return "Paid"
          }
        }
      },
      {
        headerName: "Invoice",
        cellClass: "cell-style",
        field: "invoice",
        maxWidth: 100,
        resizable: false,
        valueFormatter: params => {
          if(params.value === "Y"){
            return "Y"
          }
          else {
            return "N" 
          }
          
        }
      },
      {
        headerName: "Payment Link",
        cellClass: "cell-style",
        resizable: false,
        filter: false,
        maxWidth: 150,
        // cellStyle: { textAlign: "center" },
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          const eSpan = document.createElement('span');
          eDiv.appendChild(eSpan)
          eDiv.className = "click-span"
          const eButton = document.createElement('button')
          eButton.className = "message"
         
          let payUrl = `https://pay.untied.io/?g=${params.data.guid}|${params.data.id}`
          let payMessage = 'Please pay me £' + formatAmount(params.data.amount) + ' for ' + params.data.description + ' ' + payUrl
          eButton.addEventListener('click', () => {
            Mixpanel.track("clicked_view_link_icon");
              setPaymentData({
                show: true,
                id: params.data.id,
                data: params.data,
                message: payMessage,
                url: payUrl
              })
              // setPopUpMessage({
              //     show: true,
              //     message: "Payment Link",
              //     longMessage: payMessage,
              //     buttonText: "Copy message",
              //     buttonHandler: () => { Mixpanel.track("clicked_copy_message_action"); navigator.clipboard.writeText('Please pay me £' + formatAmount(params.data.amount) + ' for ' + params.data.description + ' ' + payUrl) },
              //     urlButtonText: "Copy link",
              //     urlButtonHandler: () => { Mixpanel.track("clicked_copy_link_action"); navigator.clipboard.writeText(payUrl) }, 
              //     emailButtonText: "Resend payment request",
              //     emailButtonHandler: () => {
              //       Mixpanel.track("clicked_copy_link_action")
                   
              //       doSend(params.data.id)},
              //     cancelButton:true
              //   })
              });

                eSpan.appendChild(eButton)
                return eDiv
            }
      },
      !discarded ? {  
        headerName: "Discard request",
        cellClass: "cell-style",
        resizable: false,
        filter: false,
        maxWidth: 150,
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          const eSpan = document.createElement('span');
          eDiv.appendChild(eSpan)
          eDiv.className = "click-span"
          const eButton = document.createElement('button')
          eButton.className = "delete"
          eButton.addEventListener('click', () => {
            Mixpanel.track("delete_pay_request_clicked");
              setDelpopUpMessage({
                  show: true,
                  message: "Discard pay request",
                  longMessage: "Are you sure you want to discard this pay request?",
                  noButtonText: "No",
                  noButtonHandler: () => { console.log("not deleting", params.data.id); setDelpopUpMessage({show:false}) },
                  yesButtonText: "Yes",
                  yesButtonHandler: () => { Mixpanel.track("deleted_pay_request_confirmation"); discardRequest(params.data.id) }, 
                  cancelButton:false
                })
              });

                eSpan.appendChild(eButton)
                return eDiv
            }
      } :  
      {headerName: "Restore request",
      cellClass: "cell-style",
      resizable: false,
      filter: false,
      maxWidth: 150,
      cellRenderer: (params) => {
        const rDiv = document.createElement('div');
        const rSpan = document.createElement('span');
        rDiv.appendChild(rSpan)
        rDiv.className = "click-span"
        const rButton = document.createElement('button')
        rButton.className = "restore"
        rButton.addEventListener('click', () => {
          Mixpanel.track("restore_pay_request_clicked");
            setDelpopUpMessage({
                show: true,
                message: "Restore pay request",
                longMessage: "Are you sure you want to restore this pay request?",
                noButtonText: "No",
                noButtonHandler: () => { console.log("not restoring", params.data.id); setDelpopUpMessage({show:false}) },
                yesButtonText: "Yes",
                yesButtonHandler: () => { Mixpanel.track("restored_pay_request_confirmation"); discardRequest(params.data.id, "N") }, 
                cancelButton:false
              })
            });

              rSpan.appendChild(rButton)
              return rDiv
          }
        }
    ]
  };


  useEffect(() => {
    if (!user_atom.token) {
      let userState = getLocalStorage()
      setUserAtom(userState)
    }
  }, [])

  useEffect(() => {
    if (user_atom.token) {
      console.log("user ato checker")
      fetchRequests();
      fetchDiscardedRequests()
    }
  }, [user_atom])

  const formatAmount = (amount) => {
    return parseFloat(Math.abs(amount))
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  const displayTransitionsModal = (props) => {
    return (
      <div
        style={{

          borderColor: "black",
          float: "right",
          textAlign: "right",
          paddingRight: 2
        }}
      >

      <IconButton
        style={{padding:6}}

        aria-haspopup="true"
        onClick={(event) => {
          setHamburgerMenu({open: true, target: event.currentTarget})
          Mixpanel.track("transaction menu clicked");
        }}
        color="inherit"
      >
        <svg xmlns="http://www.w3.org/2000/svg"  style={{fill: '#00338d'}}  height="32" viewBox="0 0 24 24" width="32"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
      </IconButton>

      <Menu
        anchorEl={hamburgerMenu.target}
        open={Boolean(hamburgerMenu.target)}
        onClose={()=>setHamburgerMenu({open: false, target: null})}
        disableScrollLock={ true }
      >
        <MenuItem onClick={() => {
            setHamburgerMenu({open: false, target: null});

            setRedirect(true)
          }}>              
          <svg xmlns="http://www.w3.org/2000/svg"  
                style={{fill: '#00338d'}} 
                height="24" 
                viewBox="0 0 24 24"
                width="24">                  
                <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
          </svg>&nbsp;Send a payment request or invoice
      </MenuItem>
      {discarded ? <MenuItem onClick={() => {
            setHamburgerMenu({open: false, target: null});
            setDiscarded(false)
       
          }}>              
          <svg 
          xmlns="http://www.w3.org/2000/svg" 
          height="24" 
          viewBox="0 0 24 24" 
          width="24">
          <path fill= '#00338d' d="M6 21v-2c4-2 3.5-6 3.5-6H7v-2h2.5c-1-4.5.5-8 4.5-8c2 0 3 .5 3 .5v2c-6-2-6 2.5-5.5 5.5H16v2h-4.5s.5 4-2 6H18v2H6z"/>
          </svg>&nbsp;View requests
      </MenuItem> : <MenuItem onClick={() => {
            setHamburgerMenu({open: false, target: null});
            setDiscarded(true)
          
          }}>              
          <svg 
          xmlns="http://www.w3.org/2000/svg" 
          height="24px" 
          viewBox="0 0 24 24" 
          width="24px" 
          fill="#000000">
          <path d="M0 0h24v24H0V0z" fill="none"/><path fill= '#00338d' d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
          </svg>&nbsp;View discarded requests
      </MenuItem>}
      </Menu>
      </div>
    );
  }

 
    // const prettyDateTime = (dateToProcess) => {
    //     let date1 = new Date(dateToProcess);
    //     let longDate = date1.toDateString();
    //     let timePart = date1.toLocaleTimeString();
    //     return longDate + " " + timePart;
    //   }


    const  prettyDateTime = (dateToProcess) => {
      let date1 = new Date(dateToProcess);
      let longDate = moment.utc(date1).format('DD/MM/YYYY HH:MM')
      return longDate

    }

      const doSend = (payId) => {
        let payload = {"pay_id": payId}

        fetch(
            `https://${global.api_url}/sendPaymentRequest.php`,
        {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
          })
          .then(res => res.json())
          .then(json => {

            if (json != null) {
                setResent(true)
                Mixpanel.track('resent_payment_request')
            } else {
                setPopUpMessage({show:false, ...popUpMessage})
                
                Mixpanel.track('error_sending_payment_request')
            }
        })

    }


    const discardRequest = (requestId, discard = "Y") => {
      fetch(`https://${global.api_url}/discardPaymentRequest.php?id=${requestId}&discard=${discard}&ts=${getTimestamp()}`,
        {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        }
      )
      .then(res => res.json())
      .then(json => { 
        if (discard == "N"){
          let newReqs = discardedRef.current.filter(req => {return req.id !== requestId })
          let req = discardedRef.current.filter(req => {return req.id == requestId })
          requestsRef.current = [...requestsRef.current, ...req]
          discardedRef.current = newReqs
        }
        else{
          let newReqs = requestsRef.current.filter(req => {return req.id !== requestId })
          let req = requestsRef.current.filter(req => {return req.id == requestId })
          discardedRef.current  = [...discardedRef.current, ...req]
          requestsRef.current = newReqs
        }

          setDelpopUpMessage({show:false})
        });
}


    const fetchRequests = () => {
        fetch(
            `https://${global.api_url}/getPaymentRequests.php?ts=${getTimestamp()}`,
            {
              method: "GET",
              headers: { token: user_atom.token, agentId: user_atom.agent }
            }
          )
          .then(res => res.json())
          .then(json => { 
              requestsRef.current = json
              // requestsRef.current = testPayments
              // console.log(json)
              setRequests(json)
              // setRequests(testPayments)
              setDiscarded(false) 
            });
    }

    const fetchDiscardedRequests = () => {
      console.log("fetching discarded reqests")
      fetch(
        `https://${global.api_url}/getPaymentRequests.php?discarded=Y&ts=${getTimestamp()}`,
        {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        }
      )
      // .then(res => res.text())
      // .then(t => console.log(t))
      .then(res => res.json())
      .then(json => {
        if (first){
          setDiscardedRequests(json)
          discardedRef.current = json
          setFirst(false)
        }
        setRecoilFetched(true) 
        });
  }
  
    

    const displayMainScreen = () => {

      if ( requests && requests.length > 0 || discardedRequests.length > 0 || discarded){
        return (<>
          <div
            style={{
              height: 48,
           
              paddingBottom: 7,
              width: "100%"
            }}
          >
          <IonButton 
              className="ion-button-money"
              style ={{float: "left"}}
              onClick={() => {
                setRedirect(true)
                // props.history.push('/payment/form')
            }}>Send a payment request or invoice</IonButton>
          {displayTransitionsModal(props)}
          </div>


          <div className={`ag-theme-alpine grid-container ${1 === 2 ? "hide-grid" : "show-grid"}`}>
            <AgGridReact
              modules={AllCommunityModules}
              defaultColDef={constants.defaultColDef}
              columnDefs={constants.columnDefs}
              animateRows
              rowHeight={30}
              rowSelection={"multiple"}
              rowData={discarded ? discardedRef.current : requestsRef.current}
              suppressRowClickSelection={false}
              suppressDragLeaveHidesColumns={true}
              rowClassRules={constants.rowClassRules}
              onFilterChanged={() => filterChanged()}
              onRowSelected={(node) => onRowSelected(node)}
              onGridReady={onGridReady}
            />
          </div>
        </>)
      }
      else{
        return(
        <>
        {/* pay your tax bill */}
         <Container  style={{
                            width: "75%",
                            marginTop: 120,
                            height: "80vh",
                            overflow: "auto"
                        }}>
          <div>
          <img style ={{width: "40%", height: "40%"}} src ={empty_state} alt = {"send new payment request"}/>

          </div>
          <div
          style= {{
            marginTop: 20,
            marginBottom: "10px"
          }}

          >
          Looks like you haven't sent any payments or invoices yet. Why not try this new feature?</div>
               <IonButton className="ion-button-money"
                  onClick={() => {
                    setRedirect(true)
                    // props.history.push('/payment/form')
                  }}
               >
                   Send a payment request or invoice
               </IonButton>

             {/* <IonButton className="ion-button-money"
                        onClick={() => {
                            props.history.push('/hmrcpay')
                        }}
             >
                 Pay your tax bill
             </IonButton> */}

          </Container>
          </>)
      }
    }

    const displayPopover = () => {
      if(paymentData.data){
        {console.log(paymentData)}
      
      // replace line breaks in description with <br/>
      const descriptionWithBreaks = paymentData.data.description.replace(/(?:\r\n|\r|\n)/g, '<br />');
      

      return(
          <IonPopover
          cssClass = {"my-payment-popover-wide"}
          isOpen={paymentData.show}
          onDidDismiss={() => setPaymentData({})} 
          >
                  <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "rgba(132, 200, 217, 0.1)", "--ion-grid-padding": "0px", borderRadius: "5px 5px 0px 0px"}}>
                    <IonRow style = {{backgroundColor : "#00338d", borderRadius: "5px 5px 0px 0px", }}>
                    <div style ={{width: "100%", display: "flex", flexDirection: "row", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}> 
                      <h2 style = {{marginLeft: "10px", color: "white"}}>Your payment request has been {paymentData.data.paid == "P" ? "paid" : "sent"}</h2> 
                      <div style={{alignSelf:"center", alignItem: "flex-end", paddingRight: "10px", marginLeft: "auto"}}>  
                      <CloseIcon
                            style={{height: "40px", width: "auto", cursor: "pointer", color: "white"}}
                            onClick={() => {
                              setPaymentData({})
                            }}
                        />
                      </div>
                    </div>
                    
                    </IonRow>
                    <IonRow ><div style ={{ padding: "5px"}} >The following message and payment link has been sent {paymentData.data.paid == "P" ? "to and paid by" : "to"} <strong>{paymentData.data.email}</strong></div></IonRow>
                    {paymentData.data.invoice == "Y" &&
                    <IonRow>
                     <IonCol>
                      <IonButton
                        className="ion-button-inverse"
                            onClick = {() => {
                              window.open(
                                `https://${global.api_url}/public/PDFViewer.php?id=${paymentData.data.guid}`
                              );
                              }}
                            >
                             View invoice
                            </IonButton>
                      </IonCol>
                    </IonRow>
                     }
                     {paymentData.data.invoice == "Y" ? 
                     <IonRow >
                      <div style ={{margin: "10px", padding: "5px", backgroundColor: "rgb(255,255,255, 1)", borderRadius:"3px", width: "100%", paddingBottom: "10px"}}>
                        <p>Hi</p>
                        <p>I'm emailing you to ask for the payment of £{parseFloat(paymentData.data.amount).toFixed(2)} for {paymentData.data.description}.</p>
                        
                        <p>When you click the pay now button below you'll be taken to a secure open banking payment portal where you can pay directly from your bank account. You will be able to edit the amount and view my account name for verification. For your records, the transaction will carry the reference {paymentData.data.guid}.</p>
                    
                    <div style ={{margin: "auto", backgroundColor: "none", width: "120px"}}>
                    </div>
                    </div>
                    </IonRow>
                     :
                    <IonRow >
                      <div style ={{margin: "10px", padding: "5px", backgroundColor: "rgb(255,255,255, 1)", borderRadius:"3px", width: "100%", paddingBottom: "10px"}}>
                        <p>Hi</p>
                        <p>I'm using untied to manage my payment requests.</p>
                        <p>This email is to ask you to pay me £{parseFloat(paymentData.data.amount).toFixed(2)} for:</p>
                        <p>{ReactHtmlParser(descriptionWithBreaks)}</p>
                        <p>When you click the link below you'll be taken to a secure open banking payment portal where you can pay directly from your bank account. You will be able to edit the amount and view my account name for verification. And for your records, the transaction will carry the reference {paymentData.data.guid}.</p>
                    
                    <div style ={{margin: "auto", backgroundColor: "none", width: "120px"}}>
                    <img style= {{height: "120px", width: "120px"}} src ={`https://quickchart-pdfv22ipta-nw.a.run.app/qr?text=${paymentData.url}`} alt = {"QR code"} title = {paymentData.url}/>
                    </div>
                    </div>
                    </IonRow>
                     }
                    {/* <IonRow style = {{backgroundColor: "rgba(132, 200, 217, 0.5)"}}>
                    <div style ={{margin: "auto", backgroundColor: "white"}}>
                    <img style= {{height: "120px", width: "120px"}} src ={`https://quickchart-pdfv22ipta-nw.a.run.app/qr?text=${paymentData.url}`} alt = {"QR code"} title = {paymentData.url}/>
                    </div>
                    </IonRow> */}

                    <IonRow style = {{padding: "10px", paddingTop: "0px"}}>
                      <div>
                        You can also copy the following message, just the payment link or resend the email using the buttons below 
                      </div>
                      <div style ={{padding: "3px", backgroundColor: "rgba(255,255,255, 1)", borderRadius:"3px", width: "100%"}}>
                      <em>{paymentData.message}</em>
                      </div>
                    </IonRow>

                    <IonRow style = {{paddingBottom: "10px"}}>
                          <IonCol size ="3"> 
                          <IonButton
                            className="ion-button-inverse"
                            onClick = {()=>{ Mixpanel.track("clicked_copy_message_action"); navigator.clipboard.writeText(paymentData.message); setMCopied(true) }}
                            style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            size = "small">

                            <FileCopy/>
                                Copy message
                              </IonButton>
                          </IonCol>
                          <IonCol size ="4"> 
                          <IonButton
                            className="ion-button-inverse"
                            onClick = {()=>{ Mixpanel.track("clicked_copy_link_action"); navigator.clipboard.writeText(paymentData.url); setLCopied(true) }}
                            style = {{width: "100%", height: "95%", fontSize: "12px", textAlign: "right", }}
                            size = "small">
                            <Link/>
                              Copy payment link
                            </IonButton>
                          </IonCol>
                          <IonCol size ="4"> 
                            <IonButton
                            className="ion-button-inverse"
                            onClick={()=>{
                              Mixpanel.track("clicked_resend_payment_email_action")
                              doSend(paymentData.id)
                            }}
                            style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            size = "small">
                            <Refresh/>
                              Resend payment email
                            </IonButton>
                          </IonCol>
                    </IonRow>
                    <IonRow>
                     {/* {paymentData.data.invoice == "Y" && <IonCol>
                      <IonButton
                        className="ion-button-inverse"
                            onClick = {() => {
                              window.open(
                                `https://${global.api_url}/public/PDFViewer.php?id=${paymentData.data.guid}`
                              );
                              }}
                            >
                             View invoice
                            </IonButton>
                      </IonCol>
                     } */}
                    <IonCol>
                      <IonButton
                        className="ion-button-inverse"
                            // style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            // size = "small">
                            onClick = {() => {
                              // props.history.push('/payment/form')
                              setRedirect(true)
                              }}
                            >
                              Send a new payment request
                            </IonButton>
                      </IonCol>
                      <IonCol>
                      <IonButton
                      className="ion-button-money"
                            // style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            // size = "small">
                            style ={{float: "right", marginRight: "16px"}}
                            onClick={() => setPaymentData({})} 
                            >
                              Done
                            </IonButton>
                      </IonCol>
                  
                     
                    </IonRow>
                  </IonGrid>
      
          </IonPopover>
      )
                          }
    }

    const sendEmail = (params) => {

    }



    const displayFooter = () => {
        return (
          <Footer />
        );
      }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        // fetchRequests();
      }
    const onRowSelected = (node) => {
        node.data.checkbox = node.node.selected;
        node.data.isSelected = node.node.selected ? "Y" : "N";
        gridApi.updateRowData({ node });
      }
    const filterChanged = () => {
        let total = 0;
        gridApi.forEachNodeAfterFilter(node => {
          if (node.data.type === "CREDIT") {
            total = total + Math.abs(node.data.amount);
          } else {
            total = total - Math.abs(node.data.amount);
          }
        });
      }

  return (
    <>
      {redirect &&  <Redirect
            to={{pathname: "/payment/form" }}
            />}
    <React.Fragment>
      <div className="tax-returns-page-container">
        <MiniDrawer />
        <div className="tax-returns-page-content">
          {displayPopover()}
          {recoilFetched ? displayMainScreen() : <div>Loading...</div>}
          </div>
      </div>
      
    </React.Fragment>
    <IonAlert
      isOpen={resent}
      header = "Email sent successfully"
      onDidDismiss={() => {setResent(false)}}
      buttons = {["OK"]}
    />
    <IonAlert
      isOpen={mCopied}
      header = "Message copied to clipboard"
      onDidDismiss={() => {setMCopied(false)}}
      buttons = {["OK"]}
    />
    <IonAlert
      isOpen={lCopied}
      header = "Payment link copied to clipboard"
      onDidDismiss={() => {setLCopied(false)}}
      buttons = {["OK"]}
    />
 

<IonAlert
                isOpen={delpopUpMessage.show}
                onDidDismiss={() => setPopUpMessage({
                  show: false,
                  message: "",
                  longMessage:"",
                  buttonText:"OK",
                  buttonHandler: undefined,
                  cancelButton:false
                 })}
                cssClass = "payment-popup"
                header={delpopUpMessage.message}
                message={delpopUpMessage.longMessage}
                buttons={[
                    {
                      cssClass: 'pointer',
                      text: delpopUpMessage.noButtonText,
                      handler: delpopUpMessage.noButtonHandler
                    },
                    {
                      cssClass: 'pointer',
                      text: delpopUpMessage.yesButtonText,
                      handler: delpopUpMessage.yesButtonHandler
                    },
                    ]
                }
              />

    <IonAlert
                isOpen={popUpMessage.show}
                onDidDismiss={() => setPopUpMessage({
                  show: false,
                  message: "",
                  longMessage:"",
                  buttonText:"OK",
                  buttonHandler: undefined,
                  cancelButton:false
                 })}
                cssClass = "payment-popup"
                header={popUpMessage.message}
                message={popUpMessage.longMessage}
                buttons={popUpMessage.cancelButton ?
                  [
                    {
                      cssClass: 'pointer',
                      text: popUpMessage.emailButtonText,
                      handler: popUpMessage.emailButtonHandler
                    },
                    {
                      cssClass: 'pointer',
                      text: popUpMessage.buttonText,
                      handler: popUpMessage.buttonHandler
                    },
                    {
                      cssClass: 'pointer',
                      text: popUpMessage.urlButtonText,
                      handler: popUpMessage.urlButtonHandler
                    },
                    {
                      cssClass: 'pointer',
                      text:'Cancel',
                      handler: ()=>{setPopUpMessage({show: false, ...popUpMessage})}
                    },] : [{
                        cssClass: 'pointer',
                        text: popUpMessage.buttonText,
                        handler: popUpMessage.buttonHandler
                    }]
                }
              />
    </>
  );
};

function scale(val) {
  return val;
}

const styles = {
  card: {
    minWidth: 675
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  buttonContainer: {
    paddingVertical: 12,
    width: "100%",
    marginBottom: 15,
    height: 36,
    borderRadius: 2,
    color: "#14A7CC",
    backgroundColor: "#c7ff00",
    textTransform: "none",
    paddingLeft: 5
  },
  buttonContainerSubtle: {
    paddingVertical: 5,
    marginBottom: 15,
    height: 36,
    borderRadius: 4,
    backgroundColor: "#FAFAFC"
  },
  buttonText: {
    color: "#000000",
    textAlign: "center",
    fontWeight: "700"
  },
  input: {
    width: "100%",
    borderRadius: scale(4),
    borderColor: "#14A7CC",
    borderStyle: "solid",
    borderBottomWidth: scale(2),
    backgroundColor: "#ffffff",
    _paddingLeft: scale(12),
    get paddingLeft() {
      return this._paddingLeft;
    },
    set paddingLeft(value) {
      this._paddingLeft = value;
    },
    fontSize: scale(14),
    height: 36
  },
  itIs: {
    color: "#c7ff00",
    fontSize: 18,
    fontWeight: "500",
    letterSpacing: 0.54,
    textAlign: "center",
    alignSelf: "center"
  },
  tagSelect: {
    borderRadius: scale(4),
    borderColor: "#14A7CC",
    borderBottomStyle: "solid",
    borderBottomWidth: scale(2),
    backgroundColor: "#ffffff",
    paddingBottom: 2,
    width: "100%"

  },
  footer: {
    alignSelf: "center",
    justifyContent: "center",
    paddingBottom: 5

  }
};

export default Payments
