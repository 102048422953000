import React, { useState, useRef, useEffect } from "react";

import { Container } from "@material-ui/core";
import { withRouter, Redirect } from "react-router-dom";
import MiniDrawer from "../MiniDrawer";
import {IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonAlert, IonCheckbox, IonGrid, IonRow, IonCol, IonModal} from '@ionic/react';
import { getTimestamp } from '../components/timeStamp.js'
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";


import { useRecoilState, waitForAllSettled } from 'recoil'
import { userAtom, taxYearsAtom, profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { SelectionBar } from '../components/selectionBar.js';
import { Mixpanel } from "../mixpanel";


import QuickLink from './../images/quick_link.png'
import LightBulb from './../images/light_bulb_hint.png'




import "./../App.css";

const HubPage = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    const [profile_atom, setProfileAtom] = useRecoilState(profileAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)
    const [location, setLocation] = useState("Home")
    const [update, setUpdate] = useState(false)
    const firstTimeRender = useRef(true)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [redirectForms, setRedirectForms] = useState("")
    const [goToTutorial, setGoToTutorial] = useState("")
    const [transactionsRedirect, setTransactionsRedirect] = useState("")
    const [accountRedirect, setAccountRedirect] = useState(false)
    const [taxFormRedirect, setTaxFormRedirect] = useState(false)
    const [addManual, setAddManual] = useState(false)
    const [addRule, setAddRule] = useState(null)
    const [addJourney, setAddJourney] = useState(false)
    const [covidRedirect, setCovidRedirect] = useState(false)
    const [moneyFilter, setMoneyFilter] = useState("")
    const [showVideo, setShowVideo] = useState(false)



    const [showFaq, setShowFaq] = useState(false)
    const [redirectTutorials, setRedirectTutorials] = useState(false)
    const [redirectReturn, setRedirectReturn] = useState(false)

    const [hubState, setHubState] = useState({
        loaded: false,
        change: false,
        show: true,
        how_you_earn: [
            {text: "Self-employed",
            checkbox:false,
            link: "SA103S"
            },
            {text: "Employed",
            checkbox:false,
            link: "SA102"
            },
            {text: "UK Property",
            checkbox:false,
            link: "SA105"
            },
            {text: "Pension, benefits & student loans",
            checkbox:false,
            link: "SA100"
            },
            {text: "Interest",
            checkbox:false,
            },
            {text: "Dividends",
            checkbox:false,
            },
            {text: "Investments",
            checkbox:false,
            link: "SA108"
            },
            {text: "Capital gains",
            checkbox:false,
            link: "SA108"
            },
            {text: "COVID support",
            checkbox:false,
            },
            {text: "Universal credit",
            checkbox:false,
            },
        ]
    })

    const [slide, setSlide] = useState(0)
    const faqs = [
        {
            text: `Need more help getting started? `, 
            link: <a className="red-link" onClick={ () => {mixPanelTrack(`video_tutorials`)}} href='/tutorials/menu'>Check our video tutorials.</a>
        },
        {
            text: "What's a UTR?", 
            link: <a href = "https://help.untied.io/article/5-what-is-a-utr" onClick={ () => {mixPanelTrack(`what_is_a_utr_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "How do I register with HMRC to get a UTR?", 
            link: <a className = "red-link" onClick={ () => {mixPanelTrack(`register_with_hmrc_article`)}} href = "https://help.untied.io/article/79-how-do-i-register-as-self-employed-or-a-property-landlord-with-hmrc" target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "Which banks do you support?", 
            link: <a href = "https://help.untied.io/article/16-which-banks-does-untied-work-with" onClick={ () => {mixPanelTrack(`what_banks_do_you_support_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "Can I upload transactions from a statement spreadsheet?", 
            link: <a href = "https://help.untied.io/article/66-my-bank-isnt-supported-can-i-upload-transactions-to-untied" onClick={ () => {mixPanelTrack(`transactions_from_spreadsheet_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },

        {
            text: "What expenses can I claim?", 
            link: <a href = "https://help.untied.io/article/89-im-self-employed-what-expenses-can-i-claim" onClick={ () => {mixPanelTrack(`what_expenses_can_i_claim_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "Can I claim the cost of untied? ", 
            link: <a href = "https://www.youtube.com/watch?v=zD-Y6DSDqlg&t=1s" onClick={ () => {mixPanelTrack(`claiming_cost_of_untied_youtube`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "How do I add mileage for the whole year?", 
            link: <a href = "https://help.untied.io/article/57-adding-mileage-for-the-year" onClick={ () => {mixPanelTrack(`year_mileage_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "How can I log my journeys automatically?", 
            link: <a href = "https://help.untied.io/article/117-automatically-logging-journeys-with-untied" onClick={ () => {mixPanelTrack(`journey_logging_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "Why do I need to add my employment if I already paid tax?", 
            link: <a href = "https://help.untied.io/article/33-add-multiple-p60-employers" onClick={ () => {mixPanelTrack(`adding_employment_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },
        {
            text: "What happens after I submit?", 
            link: <a href = "https://help.untied.io/article/42-what-happens-after-i-submit" onClick={ () => {mixPanelTrack(`after_submitting_article`)}} target ="_blank"><img className = "small-link" src = {QuickLink}/></a>
        },

    ]
    const slides = [
        {
            image: LightBulb,
            title: "Are you in the right tax year?",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>Always make sure you're in the right tax year! Feeling confused about the tax year? <a className = "red-link" href = "https://www.youtube.com/watch?v=qDx0gtbYT6c" onClick={ () => {mixPanelTrack(`tax_year_youtube`)}} target = "_blank">Check our quick video on how the tax year works!</a> [4 min video]</div> 
        },
        {
            image: LightBulb,
            title: "Filing with untied",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>You'll need to be on a paid subscription plan to file your tax return with untied. If you’re using untied wholly and exclusively for your business, you can save money by <a className = "red-link" href = "https://youtu.be/E7gZo22zbAw" onClick={ () => {mixPanelTrack(`claiming_untied_as_expense_youtube`)}} target = "_blank">claiming the cost of untied as an expense!</a> [1 min video] </div>
        },
        {
            image: LightBulb,
            title: "What if you don’t have an online HMRC/Government Gateway account? ",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>You don’t need one with untied, as you can file your taxes using <a className = "red-link" href = "https://help.untied.io/article/59-submitting-your-tax-returnuntied" onClick={ () => {mixPanelTrack(`express_submit_article`)}} target = "_blank">untied Express submit</a> that’s all included in your untied subscription! </div>
        },
        {
            image: LightBulb,
            title: "Working from home? You may be eligible to claim tax relief ",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>Get a flat rate of reliefs - no need to work out separate gas, electricity, and water costs. <span className =  "hub-text-link" onClick = {() => {setRedirectForms("HW1"); mixPanelTrack(`home_working_redirect`) }}>Answer a few questions to claim</span>. Don’t forget, you can still claim the business share of telecoms costs.</div>
        },
        {
            image: LightBulb,
            title: "Did you get COVID support?",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>untied’s <a className = "red-link" onClick={ () => {mixPanelTrack(`covid_support_wizard`)}} href = "/cov19" target = "_blank">COVID-support wizard</a> helps you understand what, and how, to include covid support like SEISS on your tax return</div>
        },
        {
            image: LightBulb,
            title: "Did you get Universal Credit? ",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>This does not appear on your tax return! untied records income and expenses the way you need it for Universal Credit reporting.</div>
        },
        {
            image: LightBulb,
            title: "Want to see untied in action?",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}>Choose <a className = "red-link" href = "/tutorials/menu" onClick={ () => {mixPanelTrack(`tutorial_menu_link`)}} target = "_blank">Tutorials</a> in the menu for a video introduction and tips.</div>
        },
        // {
        //     image: LightBulb,
        //     title: "Claiming the cost of untied as a business expense ",
        //     text: <div className style = {{textAlign: "left", paddingRight: 10}}>If you’re self-employed or earning by renting out property, you can claim untied as an expense. Check out this <a href = "https://www.youtube.com/watch?v=zD-Y6DSDqlg" className = "red-link" target = "_blank"> 1 min video</a> where Kevin, untied’s CEO, explains how!</div>
        // },
        {
            image: LightBulb,
            title: "Claim the cost of untied as a business expense ",
            text: <div className style = {{textAlign: "left", paddingRight: 10}}> If you’re self-employed or earning by renting out property, set up a rule for <span className =  "hub-text-link" onClick = {() => {setAddRule({desc_op: "contains", description:"PADDLE.NET* UNTIED", tag: "business"}); mixPanelTrack(`adding_business_expense_rule`)}} >business</span> or <span className =  "hub-text-link" onClick = {() => {setAddRule({desc_op: "contains", description:"PADDLE.NET* UNTIED", tag: "property"}); mixPanelTrack(`adding_property_expense_rule`)}} >property</span> to claim untied as an expense (Apple AppStore and Google Play Store purchases should be tagged manually).</div>
        },

    ]

    useEffect(()=> {
        if(recoilFetched){
            if(hubState.loaded && hubState.change){
                saveData()
            }
            else if (!hubState.loaded){
                getData()
            }
        }   
    }, [hubState, recoilFetched])


    const getData = () => {

        fetch(`https://${global.api_url}/getObjectData.php?type=hubpage&ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
        .then(res => res.json())
        .then(json => {

            if(props.match && props.match.params.data == 'reset'){
                setHubState({...hubState, loaded: true})
                return;
            }

            if (JSON.stringify(json.data)!== "[]" && JSON.stringify(json.data) !== JSON.stringify(hubState)){
                setHubState({...json.data, loaded: true})
            }
            else{
                setHubState({...hubState, loaded: true})}
        })
    }

    const mixPanelTrack = (clicked) => {
        Mixpanel.track("homepage_clicked", {clicked: clicked});
    }
    
    const saveData = () =>{

        let hubData = {...hubState}
        hubData.loaded = false
        hubData.change = false

        const body = JSON.stringify({ 
            type: "hubpage",
            data: {...hubData}
        });

        const showHubPage = hubData.show ? 'Y' : 'N'
        let profile = {...profile_atom, show_hub_page: showHubPage}      
        setProfileAtom({...profile_atom, show_hub_page: showHubPage})
        
        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${getTimestamp()}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(profile)
        })
            .then(res => res.json())
            .then(json => { })

        
        fetch(`https://${global.api_url}/saveObjectData.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: body,
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
        .then(res => res.json())
        .then(json => {
            setHubState({...hubState, change: false})
            Mixpanel.track("homepage_save_state", {state: hubState});
        })
        
        
    }

    const getYear = () => {
        let year = tax_years_atom.taxYears.filter(y => { if (y.id == tax_years_atom.dateFilter) { return y } })
        let tax_year = year[0].tax_year
        console.log(tax_year)
        return tax_year
    }



    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        const start = new Date(element.start_date);
                        const end = new Date(element.end_date);

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });
                    setTaxYearsAtom(
                        {
                            taxYears: json.years,
                            dateFilterStart: dateFilterStart,
                            dateFilterEnd: dateFilterEnd,
                            dateFilter: currentYearID
                        });
                    if (!recoilFetched) {
                        setRecoilFetched(true)
                    }
                }
            });
    }


    useEffect(() => {
        if (recoilFetched) {
            setPageLoaded(true)
        }
    }, [recoilFetched])



    useEffect(() => {
        firstTimeRender.current = false;
        if (!user_atom.token) {
            let userState = getLocalStorage()
            setUserAtom(userState)
            getRecoilTaxYears(userState.token, userState.agent)
        }else if (JSON.stringify(tax_years_atom) === "{}") {
            getRecoilTaxYears()
        }else {
            setRecoilFetched(true)
        }
    }, [])

    const taxChecklist = () => {
        return(
            <div 
                className = "hub-page-content"
                style = {{
                    width: "49%", 
         
                    marginRight: 5,
                    marginBottom: 5
                    }}
            >
            <div className="hub-page-header" style = {{marginBottom: 0}}>untied guide</div>
                {checkListContents()}
            </div>
        )
    }


    const checkListContents = () => {
        return(
        <IonGrid className = 'hub-flex-grid'>
        <ul style = {{marginTop: 0}}>
        <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div className = "hub-text"> <span style={{textDecorationLine:'underline', cursor:'pointer'}} onClick={() => setShowVideo(true)} className="red-link" >Watch a getting started video</span>.</div>
                    </IonCol>
                </IonRow>
            </li>  
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div className = "hub-text"> <a className="red-link" href="/profileMain">Complete your profile</a>.</div>
                    </IonCol>
                </IonRow>
            </li>  
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div className = "hub-text"> We’ll need your UTR (Unique Taxpayer Reference). No UTR? <a className="red-link" href="https://join.untied.io/start" onClick={ () => {mixPanelTrack(`complete_profile_link`)}} target = "_blank">Register with HMRC</a> via untied.</div>
                    </IonCol>
                </IonRow>
            </li>              
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div style = {{display: 'flex', flexDirection: "column"}}>
                            <div className = "hub-text"> Add your transactions:</div>
                            <div className = "hub-text">- <span onClick = {()=> {setAccountRedirect(true); mixPanelTrack("link_account");}} className = "hub-text-link">Link your bank account</span> to see all your transactions in one place. See <a target ="_blank" href = "https://help.untied.io/article/72-adding-a-bank-or-card-account-to-untied" onClick={ () => {mixPanelTrack(`adding_bank_account_or_card_article`)}} className = "red-link">how</a>.</div>
                            <div className = "hub-text">- Know your income and expense figures already? <span onClick = {()=> {setAddManual(true); mixPanelTrack("add_manual_link");}} className = "hub-text-link">Add those values</span> manually. See <a target ="_blank" href = "https://help.untied.io/article/27-adding-manual-transaction-to-untied" onClick={ () => {mixPanelTrack(`adding_manual_transactions_article`)}} className = "red-link">how</a>.</div>
                        </div>
                    </IonCol>
                </IonRow>
            </li>
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div className = "hub-text"><span onClick = {()=> {setMoneyFilter("Money in"); mixPanelTrack(`money_in_filter`)}} className = "hub-text-link">Tag your income</span>. Find tagging tips in <span onClick = {()=> {setGoToTutorial('Transactions'); mixPanelTrack("tutorial_transactions_link");}} className = "hub-text-link">Tutorials</span> on the left-hand menu.  </div>
                    </IonCol>
                </IonRow>
            </li>
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div className = "hub-text"><span onClick = {()=> setMoneyFilter("Money out")} className = "hub-text-link">Tag your expenses</span>. See what counts as an expense <a target ="_blank" href = "https://help.untied.io/article/89-im-self-employed-what-expenses-can-i-claim" className = "red-link">here</a>.</div>
                    </IonCol>
                </IonRow>
            </li>
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                        <div style = {{display: 'flex', flexDirection: "column"}}>
                            <div className = "hub-text"><a href = '/journeys' onClick={ () => {mixPanelTrack(`journeys_link`)}} className = "red-link">Claim your mileage</a>. Use the untied mobile App to log journeys, or <span onClick = {()=> {setAddJourney(true); mixPanelTrack("add_journey_link");}} className = "hub-text-link">enter individual journeys</span> or total mileage manually — untied claims the right rate.</div> 
                            <div className = "hub-text">Important – <a className = "red-link" onClick={ () => {mixPanelTrack(`double_claim_youtube`)}} href = "https://www.youtube.com/watch?v=2Ls7-Dy6gIk" target ="_blank" >you CANNOT double claim</a> BOTH mileage and other vehicle costs like fuel, maintenance, insurance, road tax, etc.</div>
                        </div>
                    </IonCol>
                </IonRow>
            </li>
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                            <div className = "hub-text">Set up <a href = "https://help.untied.io/article/59-submitting-your-tax-return" onClick={ () => {mixPanelTrack(`submitting_tax_return_article`)}} target = "_blank" className = "red-link">Express Submit</a> and file without needing an HMRC online/Government Gateway account.</div>
                    </IonCol>
                </IonRow>
            </li>
            <li>
                <IonRow className = "wide-row">
                    <IonCol>
                            <div className = "hub-text">
                                <span 
                                    onClick= {() => {
                                        let today = new Date()
                                        let year = tax_years_atom.taxYears.find(y => {if (today <= new Date(y.end_date) && today >= new Date(y.start_date)){return y}})
                                        let years = year.tax_year.split('-')
                                        years = years.map(y => y-1).join('-')
                                        year = tax_years_atom.taxYears.find(y=> y.tax_year == years)
                                        setTaxYearsAtom({
                                            ...tax_years_atom, 
                                            dateFilterStart: year.start_date,
                                            dateFilterEnd: year.end_date,
                                            dateFilter: year.id, });
                                        mixPanelTrack(`submitting_tax_return_link`)
                                        setTaxFormRedirect(true)
                                        }} 
                                    className = "hub-text-link"
                                    >Submit your tax return</span> directly from the app! See <span onClick = {()=> {setGoToTutorial('Doing your tax return'); mixPanelTrack("tax_return_tutorial_link");}} className = "hub-text-link">how</span>.</div>
                    </IonCol>

                </IonRow>
            </li>
            </ul>
        </IonGrid>
        )

    }

    const howYouEarnCheckbox = (info, index) => {
        let linkDiv = <div onClick = {() => {
            setLocation('taxforms')
            mixPanelTrack(`${info.link}`)
            setRedirectForms(info.link)
            }} className = "hub-text-link">{info.text} </div>
        if(info.text == "Interest" || info.text == "Dividend" || info.text == "Dividends"){
            linkDiv = <div onClick = {() => {
                let text = info.text
                if(text == 'Dividends'){
                    text = 'Dividend'
                }
                setLocation('transactions')
                mixPanelTrack(`${text}`)
                setTransactionsRedirect(text)
                }} className = "hub-text-link">{info.text} </div>
        }
        if(info.text == "COVID support"){
            linkDiv = <div onClick = {() => {
                setLocation('cov19')
                mixPanelTrack(`covid_redirect`)
                setCovidRedirect(true)
                }} className = "hub-text-link">{info.text} </div>
        }
        if(info.text == "Universal credit"){
            linkDiv = <div className = "hub-text"><a href="https://help.untied.io/article/131-untied-and-universal-credit" onClick={() => mixPanelTrack(`universal_credit_article_link`)} target= "_blank" className = "red-link">{info.text}</a></div>
        }

        return(
            <IonRow style = {{width: "49%"}}>
                <IonCol size = "1">
                    <IonCheckbox 
                        className = 'hub-checkbox-no-margin' 
                        checked = {info.checkbox}
                        onIonChange={() => {
                            let newState = [...hubState.how_you_earn]
                            newState[index].checkbox = ! newState[index].checkbox 
                            setHubState({...hubState, how_you_earn: newState, change: true})
                        }}

                    />
                </IonCol>
                <IonCol size = "10">
                    {info.checkbox ? linkDiv : <div className = "hub-text">{info.text}</div>}
                </IonCol>
                
            </IonRow>
        )
    }

    const howYouEarnChecklist = () => {
        return(
            <div 
            className = "hub-page-content"
            style = {{
                width: "100%", 
                // height:" 49%", 
                paddingBottom: 10,
                marginBottom: 5}}
        >
            <div className="hub-page-header">Tell us how you earn</div>
            <div className="grid-scroll">
                <IonGrid 
                className = 'hub-flex-grid'
                style = {{paddingLeft: 22}}
                >
                    {hubState.how_you_earn && hubState.how_you_earn.map( (i, index) => howYouEarnCheckbox(i, index))}
                    {/* <IonRow className = "wide-row">
                        <IonCol >
                            <div style = {{textAlign: "left", color: "#d10049"}}>To return to this page, use the home button in the menu on the left</div>
                        </IonCol>

                    </IonRow> */}
                </IonGrid>
            </div>
        </div>
        )
    }

    const FAQS = () => {
        return(
        <div style = {{width: "100%"}}>
            <div  style = {{display: "flex", }}>
                <div className="hub-page-header">FAQS</div>
                <div onClick = {() => {setShowFaq(!showFaq); mixPanelTrack(`FAQ_${showFaq? "collapsed" : "expanded"}`)}} className = {showFaq? "chevronUp" : "chevronDown"}/>
            </div>
            <div style = {{display: showFaq ? "" : "none"}} className="grid-scroll">
            <IonGrid className = 'hub-flex-grid' style = {{marginLeft: 10}}>
            {faqs.map(f => {
                return(
                    <IonRow style = {{marginRight: 5, width: "49%", textAlign: "left"}}>
                        <IonCol style = {{display: "flex", flexDirection: "row"}}>
                            <div style = {{marginRight: 5}}>{f.text}</div>
                            <div>{f.link}</div>
                        </IonCol>
                    </IonRow>
                )
            })}
           
        </IonGrid>
        </div>
        </div>
        )
    }

    const hintsAndTips = () => {
        return(
            <div 
            className = "hub-page-content"
            style = {{
                width: "100%", 
                height:" 50%", 
                marginBottom: 5}}
        >
         <div className="hub-page-header" style = {{paddingLeft: 8}}>Hints and tips</div>

         <div style = {{display: "flex", flexDirection: "row", height: "70%", margin: "auto",}}>
            <div className = "arrow-left" >
                <ChevronLeftIcon 
                    className = "blue-arrow" 
                    onClick = {() => {
                        if (slide-1 == -1){
                            setSlide(slides.length -1)
                        }
                        else{
                        setSlide(slide-1)
                        }
                        }}/></div>
            <div className = "hub-hints-info">
                <div style = {{minWidth: "25%", marginTop: "auto", marginBottom: "auto", padding: "0px 10px"}}>
                    <img src ={slides[slide].image} style = {{width: "100%", maxWidth: 100}} />
                </div>
                <div style = {{marginTop: "auto", marginBottom: "auto", minWidth: "70%"}}>
                    <div className style = {{fontWeight: 900, textAlign: "left", marginBottom: 10}}>{slides[slide].title}</div>
                    {slides[slide].text}
                </div>

            </div>
            <div className = "arrow-right">
                <ChevronRightIcon 
                className = "blue-arrow" 
                    onClick = {() => {
                        if(slide+ 1 == slides.length){
                            setSlide(0)
                        }
                        else{
                        setSlide(slide+1)
                        }
                        }}/></div>
         </div>
         <div className="hub-hints-dots"></div>


        </div>
        )
    }

    const hubContent = () => {
        return(
        <div style = {{width: "100%", display: "flex", flexDirection: "column", paddingBottom: 30}}>
            <div style = {{width: "100%", display: "flex",}}>
                {taxChecklist()}

                <div style = {{
                    width: "50%", 
                    display: "flex", 
                    flexDirection: "column",
                    marginLeft: 5,
                    justifyContent: 'space-between'
                    }}
                >
                    {howYouEarnChecklist()}
                    {hintsAndTips()}



                </div>
            </div>
            <div className = "hub-page-content" style = {{width: "99.8%", display: "flex", paddingBottom: showFaq ? 20 : 5 }}>
                    {FAQS()}
            </div>
        </div>
        )
    }

    const topBar = () => {
        return(
        <div className = "hub-page-content" style = {{width: "99.8%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div style = {{display: "flex", flexDirection: "column"}}>
            <div style = {{display: "flex",}}>
                <div style ={{margin: "16px 5px 0px 15px" }}>
                    <svg 
                        style = {{fontSize: "35px"}}
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" 
                        focusable="false" 
                        aria-hidden="true" 
                        viewBox="0 0 24 24" 
                        data-testid="HomeIcon">
                        <path fill = "#00338d" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path></svg>
                </div>
                <div className="hub-page-header" style = {{marginLeft: 0, marginBottom: 0}}>Home</div>
            </div>
                <div className = "hub-text" style = {{paddingLeft: 20, paddingBottom: 5}}>Some helpful steps, links, tips, and FAQs — all to help you get started and on track to submitting your tax return.</div>
                <div style = {{display: "flex", flexDirection: "row", marginLeft: 20, paddingBottom: 20}}>
                    <div>
                        <IonCheckbox 
                            className = 'hub-checkbox-no-margin' 
                            style = {{"--size": "12px"}}
                            checked = {hubState.show}
                            onIonChange={() => {
                                setHubState({...hubState, show: !hubState.show, change: true})
                            }}

                        />
                    </div>
                    <div className = "hub-text" style = {{paddingLeft: 5, paddingTop: 3, fontSize: 10, color: "grey", fontStyle: 'italic'}}>Show this page every time I log in</div>
                </div>
            </div>    

        </div>
        )
    }

    const vidModal = () => {
        return(
            <IonModal
                isOpen={showVideo}
                onDidDismiss={() => setShowVideo(false)}    
               
                className="video-modal"         
                >

                <div 
                    style={{
                      height: 480, width:'100%'
                      }}>
                      <iframe 
                      src="https://player.vimeo.com/video/641733898?h=5f38a05eeb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;texttrack=en"
                      frameborder="0" 
                      allow="autoplay; fullscreen; picture-in-picture" 
                      allowfullscreen 
                      style={{
                        width:"100%",
                        height:"100%",}}
                      title="Welcome to untied Pro in the browser">

                       </iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

                
            </IonModal>
        )
    }


    const redirects = () => {
        return(<>
         {redirectForms && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: redirectForms, year: getYear() }
                }}
            />}

            {goToTutorial && <Redirect
                        to={{
                            pathname: "/tutorials",
                            state: {tutorial: goToTutorial}
                        }}
            />}

            {accountRedirect && <Redirect
                to={{
                    pathname: "/accounts",
                    state: { addAccount: true }
                }}
                />}
            {taxFormRedirect && <Redirect
                to={{
                    pathname: "taxreturn/form",
                    state: {}
                }}
                />}
            {transactionsRedirect &&<Redirect
                    to={{
                        pathname: "/transactions",
                        state: { hubFilter: transactionsRedirect, yearData: {id:tax_years_atom.dateFilter} }
                    }}
                />}
            {addManual &&<Redirect
                    to={{
                        pathname: "/transactions",
                        state: { addManual: addManual, yearData: {id:tax_years_atom.dateFilter} }
                    }}
                />}
            {moneyFilter &&<Redirect
                    to={{
                        pathname: "/transactions",
                        state: { moneyFilter: moneyFilter, yearData: {id:tax_years_atom.dateFilter} }
                    }}
                />}
            {addJourney &&<Redirect
                    to={{
                        pathname: "/journeys",
                        state: { addJourney: addJourney, yearData: {id:tax_years_atom.dateFilter} }
                    }}
                />}
            {addRule &&<Redirect
                    to={{
                        pathname: "/rules",
                        state: { addRule: addRule, yearData: {id:tax_years_atom.dateFilter} }
                    }}
                />}

            {covidRedirect &&<Redirect to={{pathname: "/cov19"}} />}

        </>

        )
    }


    return (
            <React.Fragment>
        <div className="tax-forms-page-container">
            <MiniDrawer locationFromHub = {location}/>
            {hubState.loaded && 
            <Container style = {{ maxWidth: "100vw"}}>
                {vidModal()}
                {topBar()}
                {redirects()}
                {/* <div style = {{display: "none"}}>
                {SelectionBar(pageLoaded, update)}
                </div> */}
                <div style = {{margin: "10px 0px"}}>
                </div>
                {hubContent()}
                    {/* {goToForm && <Redirect
                        to={{
                            pathname: "/taxForms",
                            state: {
                                form: chosenForm
                            }
                        }}
                    />} */}
            </Container>}
        </div>
    </React.Fragment>
    )
};

export default withRouter(HubPage)
