import React, { useState, useRef, useEffect } from "react";

import { Mixpanel } from "../mixpanel";
import MiniDrawer from "../MiniDrawer";
import { Container } from "@material-ui/core";
import { withRouter, Link, Redirect } from "react-router-dom";
import { getTimestamp } from '../components/timeStamp.js'

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom, importAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { checkUTR } from '../components/UtrChecker.js';
import { Buffer } from 'buffer';
import jwt_decode from "jwt-decode";

import moment from "moment";
import {
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    IonLoading,
    IonText,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonTextarea,
    IonSelect,
    IonSelectOption
} from '@ionic/react';


import './Page.css';

const Partner_import = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    //const [importData, setImportData] = useRecoilState(importAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [profileFetched, setProfileFetched] = useState(false);
    const [dataReady, setDataReady] = useState(false)
    const [dataFromLandlord, setDataFromLandlord] = useState(false)

    const firstTimeRender = useRef(true);

    // const [years, setYears] = useState(tax_years_atom.taxYears);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [postcode, setPostcode] = useState('')
    const [dob, setDOB] = useState('')
    const [employmentStatus, setEmploymentStatus] = useState('')
    const [annualPension, setAnnualPension] = useState("")
    const [utr, setUTR] = useState("")
    const [nin, setNIN] = useState("")
    const [propNo, setPropNo] = useState(0)
    const [UKbased, setUKBased] = useState("Yes")
    const [incomeTotal, setIncomeTotal] = useState("")
    const [expenseTotal, setExpenseTotal] = useState("")
    const [loanInterest, setLoanInterest] = useState("")
    const [showLoading, setShowLoading] = useState(false);

    const [transactions, setTransactions] = useState([])
    const [taxYears, setTaxYears] = useState()
    const [endDate, setEndDate] = useState()
    const [yearData, setYearData] = useState()

    const [replaceIncome, setReplaceIncome] = useState()
    const [replaceExpense, setReplaceExpense] = useState()

    const [error, setError] = useState(false)
    const [parsedError, setParsedError] = useState(false)
    const [UKError, setUKError] = useState(false)
    const [UTRError, setUTRError] = useState(false)
    const [confirmAlert, setConfirmAlert] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [help, setHelp] = useState({ helpMainTitle: "", helpTitle: "", helpText: "" })

    const [jointLet, setJointLet] = useState(false)

    const [redirect, setRedirect] = useState(false)
    const [data, setData] = useState();
    const divStyles = {
        display: "flex",
        marginTop: "20px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        minWidth: "300px",
        flexBasis: "300px",
        flexShrink: "0",
        marginBottom: "50px"
    };

    const ionGridStyle = {
        backgroundColor: '#f5f7fa',
        fontSize: '14px',
        borderRadius: '5px',
        width: "70%",
        marginBottom: 5,
        marginTop: 5
    }


    useEffect(() => {
        if (recoilFetched) {
            firstTimeRender.current = false;
            if (!user_atom.token) {
                let userState = getLocalStorage()
                setUserAtom(userState)
                // getProfile(userState.token)
                getTransactions(userState.token)
                getTaxYears(userState.token)
            }
            else {
                // getProfile()
                getTransactions()
                getTaxYears()
            }
        }
    }, [recoilFetched])

    useEffect(()=>{
        if(yearData == undefined && endDate){
            let userState = getLocalStorage()
            getTaxYears(userState.token)
        }
    }, [yearData, endDate])

    useEffect(() => {
    
        if (yearData && transactions.length > 0) {
            let available = transactions.filter(t => { if (t.datetime < yearData.end_date && t.datetime > yearData.start_date) return t })
            let replaceable = available.filter(t => { if (t.description.includes(`Imported from ${data.importedFrom}`)) return t })
            let incomes = []
            let expenses = []
            replaceable.forEach(t => {
                if (t.category == "INCOME") {
                    incomes.push({ id: t.id })
                }
                else {
                    expenses.push({ id: t.id })
                }
            })

            setReplaceIncome(incomes)
            setReplaceExpense(expenses)
        }
    }, [yearData, transactions])

    const getTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {

        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                // console.log("recoil tax years success")
               
                if (json.error) {
                    //alert('redirect to login page');
                    props.history.push("/login?target=partner_import");
                } else {
                    let year = json.years.filter(y => {
                        if (endDate < new Date(y.end_date) && endDate > new Date(y.start_date)) { return y }
                    })
            
                    setYearData(...year)
                    setTaxYears(json.years);
                }
            })
    }

    useEffect(() => {
        if (!recoilFetched) {
            if (props.match && props.match.params) {
                let params = props.match.params
                if (params.data) {
                            
                    //setImportData({data: params.data})
                    localStorage.setItem('import_data', params.data)

                    //do we also have a tokoen 
                    if (params.token) {  
                        
                        const decoded_jwt = jwt_decode(params.token);                        
                        const user_email = decoded_jwt.user_email

                        props.history.push(`/login?email=${user_email}&target=partner_import&ml=1`);
                        
//                        recoilAuth(params.token)                        
                    } else {  

                        if (localStorage.getItem('token')) {
                            setDataReady(true)
                            setRecoilFetched(true)
                        } else {                        
                            props.history.push("/login?target=partner_import");
                        }
                    }
                }
                
            }
        }
    }
    , [props])
 
    useEffect(() => {      
        
        if (user_atom.token) {
            const idata = localStorage.getItem('import_data')

            if (idata) {
                let p = Buffer.from(idata,'base64')
                let parsed          

                try {
                    parsed = JSON.parse(p)
                    
                    let end = new Date(parsed.period.end_date)
                    setEndDate(end)
                    if (!parsed) {
                        setError(true)
                    }

                    setData(parsed)
                    setDataReady(true)                    
                    
                    setRecoilFetched(true)
                    setParsedError(false)                

                    localStorage.removeItem('import_data')

                }
                catch (e) {
                    setParsedError(true)
                }

            }
        }
    },[user_atom])

    useEffect(() => {

        if (dataReady) {
            if  ( props &&  props.match && props.match.params.data) {
                let p = Buffer.from(props.match.params.data,'base64')
                let parsed
            
                try {
                    parsed = JSON.parse(p)
                    let end = new Date(parsed.period.end_date)
                    setEndDate(end)
                    if (!parsed) {
                        setError(true)
                    }
                    setData(parsed)
                }
                catch (e) {                    
                    setParsedError(true)
                }
            } else{                
            //    setParsedError(true)
            }     
        }
    }, [dataReady])

    useEffect(() => {
    
        if (data && data.propertiesData) {
            let expense = 0
            let income = 0
            let loan_interest = 0
            data.propertiesData.forEach(e => {
                let totalIncome = parseFloat(e.totalIncome)
                let totalExpense = parseFloat(e.totalExpenses)
                let loan = parseFloat(e.loanInterest)
                if (e.ownershipPercentage) {
                    let percentage = parseFloat(e.ownershipPercentage)
                    percentage = percentage / 100
                    totalIncome = totalIncome * percentage
                    totalExpense = totalExpense * percentage
                    loan = loan * percentage
                }
                income += totalIncome
                expense += totalExpense
                loan_interest +=loan
                if (e.jointLet) {
                    setJointLet(true)
                }
            })
            setPropNo(data.propertiesData.length)
            setLoanInterest(parseAmount(loan_interest.toString()))
            setIncomeTotal(parseAmount(income.toString()))
            setExpenseTotal(parseAmount(expense.toString()))
        }
        if (data && data.ownerData) {
            let od = data.ownerData
            let employ = od.employmentStatus.toLowerCase() === "employed" ? "Yes" : "No"
            setEmploymentStatus(employ)
            setAnnualPension(od.annualPension)
          
            if (!email) {
                setEmail(od.email)
            }

            if (!dob) {
                setDOB(od.DOB)
            }

            if (!name) {
                setName(od.name)
            }
            if (!postcode) {
                setPostcode(od.postcode)
            }

            if (!address) {
                setAddress(od.address)
            }
            setDataFromLandlord(true)
        }
    }, [data])

    useEffect(() => {
        if (dataFromLandlord && user_atom.token) {
            getProfile()
        }

    }, [dataFromLandlord, user_atom])

    const parseAmount = (value) => {
        if (value && value.length > 0) {
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if (value.indexOf(".") > -1) {
                const integers = value.split(".")[0] + ".";
                let digits = value.split(".")[1];
                if (digits.length > 2) digits = digits.substring(0, 2);
                if (digits.length < 2) digits = digits + "0"

                return "£" + integers + digits;
            } else {
                return "£" + value + ".00";
            }
        } else {
            return "";
        }
    };

    const prettyDate = (dateToProcess) => {

        let date1 = new Date(dateToProcess);

        return date1.toDateString();

    };

    const createTrans = (trans_type, amount) => {
        let total = cleanNumbers(amount)
        const trans_date = moment(new Date(endDate)).format("DD/MM/YYYY");
        //income =  {description: 'test', amount: '1234.00', trans_type: 'In', trans_date: '29/11/2021', category: '2'}
        //expense = {description: 'test', amount: '1234.00', trans_type: 'Out', trans_date: '29/11/2021', category: '12'}
        let payload = { description: `Imported from ${data.importedFrom}`, amount: total, category: trans_type == "In" ? "2" : "12", trans_type: trans_type, trans_date: trans_date }
      
        fetch(`https://${global.api_url}/createTransaction.php?ts=${getTimestamp()}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                Mixpanel.track("Partner_import_transaction_added", {id: json.trans_id});
            })


    }

    const untagAndDiscard = (ids) => {
        let payload = JSON.stringify({ transactions: ids });
        fetch(
            `https://${global.api_url}/claimTransactions.php?ts=${getTimestamp()}&operation=unclaimOnly`,
            {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: payload
            }
        )
            .then(res => res.json())
            .then(json => {
             
                ids.forEach(element => {
                    fetch(
                        `https://${global.api_url}/discardTransaction.php?ts=${getTimestamp()}&id=${element.id}`,
                        {
                            method: "GET",
                            headers: { token: user_atom.token, agentId: user_atom.agent }
                        }
                    )
                        .then(res => res.json())
                        .then(json => {                                              
                            Mixpanel.track(`discarded_imported_transaction`, {id: element.id});
                        });
                });
            })
    }

    const getProfile = (passed_token = user_atom.token) => {
        fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/login?target=partner_import");
                    }
                } else {
                 
                    if (json.name && name == "") {
                        setName(json.name);
                    }
                    if (json.address && address == "") {
                        setAddress(json.address);
                    }
                    if (json.postcode && postcode == "") {
                        setPostcode(json.postcode);
                    }

                    var date
                    if (json.dob == null) {
                        date = moment(new Date()).subtract(18, "years").format('YYYY-MM-DD')
                    } else {
                        date = json.dob
                        if (date.includes('/')) {
                            date = date.split("/").reverse().join("-");
                        }
                        date = moment(new Date(date)).format('YYYY-MM-DD')
                    }
                    if (date && !dob) {
                        setDOB(date);
                    }
                    setNIN(json.nin);
                    setUTR(json.utr);
                    setProfileFetched(true)

                }
            });

    }

    const getTransactions = (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {
        fetch(`https://${global.api_url}/getTransactions.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(res => res.json())
            .then(json => {
        
                if (json.error) {
                    if (json.error === "unauthorized") {
                        props.history.push("/login?target=partner_import");
                    }
                }
                else {                 
                    setTransactions([...json.transactions]);
                }
            }
            )
    }

    const saveProfile = () => {

        if (name === "" || name == null) {
            setShowHelp(true)
            setHelp({
                ...help,
                helpTitle: 'Name missing',
                helpText: 'Please enter your name.',
            })
            setShowLoading(false)
            return;
        }

        if (postcode === "" || postcode == null) {
            setShowHelp(true)
            setShowLoading(false)
            setHelp({
                ...help,
                helpTitle: 'Postcode missing',
                helpText: 'Please enter your postcode.',
            })
            return;
        }

        var payload = {
            name,
            address,
            postcode,
            nin,
            utr: utr ? utr.replace(" ", "") : "",
            // dob: dob ? moment(dob).format("DD/MM/YYYY") : "",
            dob: dob ? dob : "",
        }

        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${getTimestamp()}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {

                if (propNo !== 0) {                   
                    saveData()
                }

                Mixpanel.track("saved_profile_for", {name: name});
           
                setConfirmAlert(true)
            })
    }

    const saveData = () => {

        let tax_column = "property"
        let dataPackage = { "include": true, "box_1": propNo, "box_3": jointLet, "box_26": cleanNumbers(loanInterest) };
        console.log(dataPackage)
        dataPackage = JSON.stringify(dataPackage)
     

        fetch(`https://${global.api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${yearData.id}&type=${tax_column}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: dataPackage
        })
            .then(res => res.json())
            .then(json => {              
                if (json.status === "success") {
                    Mixpanel.track("property_data_saved")
                } else {
                }

            });

    }

    const recoilAuth = (session_token) => {

        const body = JSON.stringify({ session_token })

        fetch(`https://${global.api_url}/getToken.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: body,
            headers: { partnerId: global.partner_id }
        })
            .then(res => res.json())
            .then(json => {

                if (json.status === "success") {
                  
                    const has_sub = (!json.in_trial && json.plan != 3) ? "Y" : "N"
                    let userForStorage = {
                        ...json,
                        has_sub,
                        logged_in: true
                    }
                    setUserAtom(userForStorage)
                    localStorage.setItem('in_trial', json.in_trial)
                    localStorage.setItem('logged_in', true)
                    localStorage.setItem('plan', json.plan)
                    localStorage.setItem('subscription_active', json.subscription_active)
                    localStorage.setItem('agent', null)
                    localStorage.setItem('guid', json.guid);
                    localStorage.setItem("token", json.token);
                    localStorage.setItem("admin_token", json.token);
                    localStorage.setItem("admin", json.admin);
                    localStorage.setItem("username", json.email);
                    localStorage.setItem("logged_in_as", "");
                    localStorage.setItem("mtd", json.mtd);
                    localStorage.setItem("has_sub", !json.in_trial && json.plan != 3 ? "Y" : "N")
                    localStorage.setItem("has_password", json.has_password ? "Y" : "N")
                    localStorage.setItem('web_login_timeout', json.web_login_timeout)
                    Mixpanel.identify(json.guid);
                    Mixpanel.track("log_in");

                    Mixpanel.people.set({
                        $email: email,
                        dev: process.env.NODE_ENV != "production"
                    });

                    setRecoilFetched(true)
                    setDataReady(true)

                } else {
                 
                    props.history.push("/login?target=partner_import");

                    Mixpanel.track("Unsuccessful login");

                }
            });

    }


    const alerts = () => {
        return (<>
            <IonAlert
                isOpen={error}
                onDidDismiss={() => setError(false)}
                header={"State error"}
                message={"The passed state contains errors, please check before resubmitting"}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={UKError}
                onDidDismiss={() => setUKError(false)}
                header={"Confirm resident status"}
                message={"Please confirm if you a resident of the uk"}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={confirmAlert}
                onDidDismiss={() => setRedirect(true)}
                header={"Confirmed"}
                message={`${incomeTotal || expenseTotal ? "Your imported " : ""}${incomeTotal ? "Property incomes" : ""}${incomeTotal && expenseTotal ? " and " : ""}${expenseTotal ? "Property expenses" : ""} ${incomeTotal || expenseTotal ? " have been added to your transactions. " : ""} Your Profile has${incomeTotal || expenseTotal ? " also": ""} been saved`}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={showHelp}
                onDidDismiss={() => setShowHelp(false)}
                header={help.helpMainTitle || 'Help'}
                subHeader={help.helpTitle}
                message={help.helpText}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            var text = help.helpText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');
                        }
                    },
                    'OK'
                ]}
            />
            <IonAlert
                isOpen={UTRError}
                onDidDismiss={() => setUTRError(false)}
                header={'UTR error'}

                message={"The unique tax reference you have provided does not match HMRC's format, please cancel and double check or save profile anyway"}
                buttons={[
                    'Cancel',

                    {
                        text: 'Save anyway',
                        handler: blah => {
                            if (incomeTotal) {
                                createTrans("In", incomeTotal)
                            }
                            if (expenseTotal) {
                                createTrans("Out", expenseTotal)
                            }
                            saveProfile()
                        }
                    },

                ]}
            />


        </>)
    }

    const cleanNumbers = (num) => {
        let newValue = num
        newValue = newValue.replace(/[^0-9\.]/g, '');
        newValue = parseFloat(newValue).toString()
        return newValue
    }

    const mainContent = () => {

        if (parsedError) {
            return (<>
                <IonGrid style={ionGridStyle}>
                    <IonRow>
                        <IonCol>
                            <h1 className="tax-forms-card-title">Error with imported information</h1>

                            <p style={{ textAlign: 'left' }}>We were unable to parse the imported data, please contact the referring partner</p>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </>)
        }
        if (recoilFetched && data) {
            return (<>
                <IonGrid style={ionGridStyle}>


                    <IonRow>
                        <IonCol>
                            <h1 className="tax-forms-card-title" style ={{marginBottom: 5}}>{data.importedFrom} import</h1>
{/* 
                            <p style={{ textAlign: 'left' }}>Please review the information you are importing below.</p> */}
                        </IonCol>
                    </IonRow>

                    {data && data.period &&
                        <IonRow>
                            <IonCol style={{ textAlign: 'left', fontWeight: 700 }}>
                                <IonText >Please review the information being imported for the period of {prettyDate(data.period.start_date)} to {prettyDate(data.period.end_date)}</IonText>
                            </IonCol>
                        </IonRow>
                    }

                    <IonRow>

                        <IonCol style={{ textAlign: 'left' }}>

                            <h3 className="tax-forms-card-title" style={{ margin: 0 }}>Personal information</h3>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Name:</IonLabel>
                                <IonInput
                                    value={name}
                                    onIonChange={e => {
                                        setName(e.detail.value)
                                    }}
                                    placeholder=""
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Email:</IonLabel>
                                <IonInput value={email}
                                    onIonChange={e => {
                                        setEmail(e.detail.value)
                                    }}
                                    placeholder=""
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Address:</IonLabel>
                                <IonTextarea
                                    rows="4"
                                    value={address}
                                    onIonChange={e => {
                                        setAddress(e.detail.value)
                                    }}
                                    placeholder=""
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Postcode:</IonLabel>
                                <IonInput
                                    rows="4"
                                    value={postcode}
                                    onIonChange={e => {
                                        setPostcode(e.detail.value)
                                    }}
                                    placeholder=""
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>



                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <div tabindex="0"></div>
                                <IonLabel position="stacked" style={{ marginBottom: 15 }} >Date of birth</IonLabel>
                                <IonInput>
                                    <input
                                        type="date"
                                        value={dob}
                                        style={{ border: "none", width: "100%", height: "fit-content", font: "400 14px Arial", backgroundColor: 'white', paddingLeft: 0, fontSize: 16 }}
                                        onChange={e => {                                        
                                            
                                            setDOB(e.target.value)
                                        }} />
                                </IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </IonGrid>

                <IonGrid style={ionGridStyle}>


                    <IonRow>

                        <IonCol style={{ textAlign: 'left' }}>

                            <h3 className="tax-forms-card-title" style={{ margin: 0 }}>Property information</h3>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Number of properties:</IonLabel>
                                <IonInput value={propNo}
                                    inputmode="number" type="number"
                                    onIonChange={e => {
                                        setPropNo(e.detail.value)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Property income total:</IonLabel>
                                <IonInput
                                    // value={parseAmount(incomeTotal)}
                                    // onIonChange={e => {
                                    //     let newValue = e.detail.value
                                    //     newValue = newValue.replace(/[^0-9\.]/g,'');
                                    //     setIncomeTotal(newValue)
                                    //     }}
                                    value={incomeTotal}
                                    onIonChange={e => {
                                        setIncomeTotal(e.detail.value)
                                    }}
                                    onFocus={e => {
                                        let newValue = cleanNumbers(incomeTotal)
                                        setIncomeTotal(newValue)
                                    }}
                                    onBlur={e => {
                                        let newValue = cleanNumbers(incomeTotal)
                                        setIncomeTotal(parseAmount(newValue))
                                    }}
                                    placeholder=""
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Property expense total:</IonLabel>
                                <IonInput
                                    // value={parseAmount(expenseTotal)}
                                    // onIonChange={e => {
                                    //     let newValue = e.detail.value
                                    //     newValue = newValue.replace(/[^0-9\.]/g,'');
                                    //     setExpenseTotal(newValue)
                                    // }}
                                    placeholder=""
                                    value={expenseTotal}
                                    onIonChange={e => {
                                        setExpenseTotal(e.detail.value)
                                    }}
                                    onFocus={e => {
                                        let newValue = cleanNumbers(expenseTotal)
                                        setExpenseTotal(newValue)
                                    }}
                                    onBlur={e => {
                                        let newValue = cleanNumbers(expenseTotal)
                                        setExpenseTotal(parseAmount(newValue))
                                    }}

                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Loan interest total:</IonLabel>
                                <IonInput
                                    // value={parseAmount(expenseTotal)}
                                    // onIonChange={e => {
                                    //     let newValue = e.detail.value
                                    //     newValue = newValue.replace(/[^0-9\.]/g,'');
                                    //     setExpenseTotal(newValue)
                                    // }}
                                    placeholder=""
                                    value={loanInterest}
                                    onIonChange={e => {
                                        setLoanInterest(e.detail.value)
                                    }}
                                    onFocus={e => {
                                        let newValue = cleanNumbers(loanInterest)
                                        setLoanInterest(newValue)
                                    }}
                                    onBlur={e => {
                                        let newValue = cleanNumbers(loanInterest)
                                        setLoanInterest(parseAmount(newValue))
                                    }}

                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </IonGrid>

                <IonGrid style={ionGridStyle}>

                    <IonRow>

                        <IonCol style={{ textAlign: 'left' }}>

                            <h3 className="tax-forms-card-title" style={{ margin: 0 }}>Tax information</h3>

                            <p>In order to submit your tax return we need your{" "}
                                <span className="fake-anchor" onClick={() => {
                                    setShowHelp(true)
                                    setHelp({
                                        helpTitle: 'National Insurance Number',
                                        helpText: 'Your National Insurance Number or NINO is made up of letters and numbers and never changes. You can find it on your payslip; ' +
                                            'on your P60; on letters about your tax, pension or benefits; or ' +
                                            'in the National Insurance section of your <a href="https://www.gov.uk/personal-tax-account">personal tax account</a>.'
                                    })
                                }}>National Insurance Number</span>{" "}

                                and <span className="fake-anchor" onClick={() => {
                                    setShowHelp(true)
                                    setHelp({
                                        ...help,
                                        helpTitle: 'Unique Tax Reference',
                                        helpText: 'UTR stands for Unique Taxpayer Reference. It is assigned automatically by HMRC when you register for self assessment. ' +
                                            'If you are not yet registered for self assessment you will need to do so if you need to submit a tax return. ' +
                                            'You can do that <a href="https://www.gov.uk/log-in-file-self-assessment-tax-return/register-if-youre-not-self-employed">here on the HMRC website</a>.'
                                    })
                                }}>Unique Taxpayer Reference (UTR)</span>. If you don’t have them now - don't worry - you can still import your data and add them to your profile later.</p>

                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="stacked ">National Insurance Number: </IonLabel>
                                <IonInput value={nin}
                                    onIonChange={e => setNIN(e.detail.value)}
                                    placeholder="e.g AB 12 34 56 A" inputmode="text" type="text"
                                    className="main-input" />

                            </IonItem>

                        </IonCol>



                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="floating">Unique Taxpayer Reference:</IonLabel>
                                <IonInput value={utr}
                                    onIonChange={e => setUTR(e.detail.value)}
                                    placeholder=""
                                    inputmode="text"
                                    type="text" />
                            </IonItem >

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem className="ion-no-padding">
                                <IonLabel position="stacked ">Do you receive a salary from employment: </IonLabel>
                                <IonSelect value={employmentStatus}
                                    multiple={false}
                                    onIonChange={(event) => {
                                        setEmploymentStatus(event.target.value)
                                    }}
                                    interface="popover"
                                    placeholder="Select"
                                    style={{ "--padding-start": 0, maxWidth: '100%', width: '100%' }}

                                    interfaceOptions={{
                                        header: 'Select...',
                                    }}
                                    className="main-input"
                                >
                                    <IonSelectOption value="Yes">Yes</IonSelectOption>
                                    <IonSelectOption value="No">No</IonSelectOption>
                                </IonSelect>
                            </IonItem>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-no-padding" style={{ textAlign: 'left' }}>
                            <IonText>Please check that the information above is correct and complete. You will be able to submit your tax return from the next screen, or come back and do that later.</IonText>


                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol>

                        <p style={{ fontStyle: 'italic', textAlign: 'left' }}>Important: This importer will update your profile and overwrite any existing property schedule data you may already have entered into untied. Any existing bank transactions you have already
                                tagged as property will remain intact but income and expenses previously imported from {data.importedFrom} for the same year will be overwritten.</p>

                            <IonButton
                                className="ion-button-money"
                                onClick={() => {
                                    if (UKbased == "") {
                                        setUKError(true)
                                        return
                                    }
                                    let utrError = true
                                    if (utr) {
                                        utrError = checkUTR(utr)
                                    }
                                    if (!utrError) {
                                        setUTRError(true)
                                    }
                                    else {

                                        if (incomeTotal) {
                                            if (replaceIncome && replaceIncome.length > 0) {
                                                untagAndDiscard(replaceIncome)
                                                setReplaceIncome([])
                                            }
                                            createTrans("In", incomeTotal)
                                        }
                                        if (expenseTotal) {
                                            if (replaceExpense && replaceExpense.length > 0) {
                                                untagAndDiscard(replaceExpense)
                                                setReplaceExpense([])
                                            }
                                            createTrans("Out", expenseTotal)
                                        }

                                        saveProfile()
                                    }
                                }}
                            >
                                Import &amp; continue
                            </IonButton>

                        </IonCol>
                    </IonRow>



                </IonGrid>

            </>)
        }
    }

    if (redirect && employmentStatus == "Yes") {
        return <Redirect to={{
            pathname: "/Partner_import_employed",
            state: {
                name,
                email,
                address,
                dob,
                employmentStatus,
                annualPension,
                utr,
                nin,
                UKbased,
                incomeTotal,
                expenseTotal,
                propNo,
                yearData,
                importedFrom: data.importedFrom
            }
        }} />;
    }
    if (redirect && employmentStatus !== "Yes") {
        return <Redirect to={{
            pathname: "/Partner_import2",
            state: {
                name,
                email,
                address,
                dob,
                employmentStatus,
                annualPension,
                utr,
                nin,
                UKbased,
                incomeTotal,
                expenseTotal,
                propNo,
                yearData,
                importedFrom: data.importedFrom
            }
        }} />;
    }

    return (<React.Fragment>
        <div style={divStyles}>
            <MiniDrawer />
            {/* {recoilFetched && */}
            <Container className="tax-breakdown-page-content">

                <div style={{ width: "80%", margin: "auto" }}>
                    {alerts()}
                    {mainContent()}
                </div>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={30000}
                />
            </Container>
            {/* } */}
        </div>
    </React.Fragment>)
};


export default withRouter(Partner_import)
