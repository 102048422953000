import React, {  useState, useRef, useEffect} from "react";

import MiniDrawer from "./../MiniDrawer";
import { Container } from "@material-ui/core";
import { withRouter, Redirect } from "react-router-dom";
import {getTimestamp} from '../components/timeStamp.js'
import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'


import './Page.css';

const TXYearCheck = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [tx_ids, setTxIds] = useState([])
  
    useEffect(() => {

        console.log(user_atom)
        if (user_atom.token) {
     
        fetch(`https://${global.api_url}/getClaimsWithMisMatchedYears.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: user_atom.token }
        })
          .then(response => response.json())
          .then(json => {

                console.log('here')
                console.log(json.tx)
                const incoming_ids = json.tx
                var ids = []
                incoming_ids.forEach(e => ids.push(Number(e.id)))
                console.log(ids)
                setTxIds(ids)

           })

        }

    }, [user_atom])


    useEffect(() => {
       
          if (!user_atom.token || user_atom.agent !== "") {
            let userState = getLocalStorage()
            setUserAtom({
              ...userState,
              agent: user_atom.agent
            })
          }
       
      }, [])

    return (<>
    
        {tx_ids.length > 0 && <Redirect
            to={{
              pathname: "/transactions",
              state: { fromCsvImporter: tx_ids, showClaimDate:true }
            }}
        />}
    
    <React.Fragment>
        <div>
            <MiniDrawer />
            {/* {recoilFetched && */}
            <Container className="tax-breakdown-page-content">
                <div style={{paddingTop:200}}>
                    Please wait ... 
                </div>

            </Container>
            {/* } */}
        </div>
    </React.Fragment>
    </>)
};

  
export default withRouter(TXYearCheck)
