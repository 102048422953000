import React, { useState, useEffect } from "react";
import "./index.css";
import Login from "./Login";
import AgentLogin from "./AgentLogin";
import Transactions from "./views/Transactions";
import TaxReturns from "./views/TaxReturns";
import TaxReturn from "./views/TaxReturn";
import Accounts from "./views/Accounts";
// import Profile from "./views/Profile";
// import MainProfile from "./views/profileMain";
import FullProfile from "./views/ProfileFull";
import Tutorials from './views/Tutorials'
import TutorialsMenu from './views/TutorialsMenu'
import ManualSubmit from "./views/ManualSubmitReturn";
import Journeys from "./views/Journeys";
import { Route, BrowserRouter as Router, withRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { ProtectedRoutes } from "./protected.routes";
import MixPanelScreenChange from "./routelistener.js";
import ManualTransactionsImporter from "./views/ManualTransactionsImporter";
import TaxForms from "./views/TaxForms";
import TaxFormsMenu from "./views/TaxFormsMenu";
import MTD from "./views/MTD";
import MTDReport from "./views/MTDReport";
import Insights from "./views/Insights";
import Rules from "./views/Rules";
import TaxBreakdown from "./views/TaxBreakdown";
import Partner_import from "./views/Partner_import";
import Partner_import2 from "./views/Partner_import2";
import Partner_import_employed from "./views/Partner_import_employed";
import HMRCPayment from "./views/HMRCPayment/HMRCPayment";
import Covid19Support from "./views/covid19Support";
import Payment from "./views/Payment";
import Payments from "./views/Payments";
import TXYearCheck from "./views/Txyearcheck";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { userAtom } from "./atom.js";
import { useRecoilState } from "recoil";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { getTimestamp } from "./components/timeStamp";
import LoginPopup from "./components/LoginPopup";
import invoiceSettings from "./views/invoiceSettings"
import HubPage from './views/HubPage'

require("dotenv").config();
if (process.env.NODE_ENV !== "development") {
  if (window.location.hostname==='app-sandbox.untied.io') {
    global.api_url = "api-sandbox.untied.io";
  } else {
    global.api_url = "api.untied.io";
  }
} else {
  global.api_url = process.env.REACT_APP_API_URL;
}
global.partner_id = "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a";
global.admin_ips = ["3.8.213.59", "89.243.255.61", "35.246.107.246"];



//heartbeat intervals 
const pingInterval = 1800
const pingTimeoutThreshold = 2700

const Routes = (props) => {
  const [showTimeout, setShowTimeout] = useState(false);
  const [user, setUser] = useRecoilState(userAtom);
  const [update, setUpdate] = useState(0)
  const [userToken, setUserToken] = useState("")

  let LoginTimeout
  if(localStorage.getItem('agent')!==""){
    LoginTimeout = 7200000
    // LoginTimeout = 7200 
  }
  else{
    LoginTimeout = localStorage.getItem("web_login_timeout") * 1000
  }

  /*
    Heartbeat function. Used to check if app has been running and allows us to force a log out when restarted and
    the app/browser was previously closed. When heartbeat starts again we check if more than pingTimeoutThreshold
    seconds has elapsed since last beat, and if so force a log out. Heartbeat runs every pingThreshold seconds.
  */
  useEffect(() => {
    setInterval(()=> {
      const token = localStorage.getItem("token");
      const lastEvent = localStorage.getItem("pingTime")
      if (token && lastEvent && lastEvent!=="") {
        //how many seconds elapsed from now 
        const nowTime = new Date();
        const thenTime = Date.parse(lastEvent);
        const dif = nowTime - thenTime;
        const secondsElapsed = dif/1000;
        console.log("Seconds elapsed :", secondsElapsed, "pingThreshold", pingTimeoutThreshold )
        if (secondsElapsed>pingTimeoutThreshold) {
          //browser must have been closed ... clear localStorage and force a re-render - this will log us out
          localStorage.clear()
          setUpdate(update+1)
        }
      } 
      localStorage.setItem("pingTime", new Date())
    // },pingInterval*1000)
    },pingInterval*100)
  },[])

  const startTimer = () => {
    setTimeout(() => {
      const token = localStorage.getItem("token");
      const agent = localStorage.getItem("agent")
      if (token) {
        if (agent){
          //store token elsewhere to allow agent refresh
          setUserToken(token)
          localStorage.removeItem("token");
        }
        else{
        //remove the token
        console.log("in token", agent, token)
        localStorage.removeItem("token");
        setShowTimeout(true);
        }
      }
    }, LoginTimeout );
  };

  useEffect(()=> {
    if(userToken){
      setShowTimeout(true)
    }

  },[userToken])

  useEffect(()=> {
    if(!showTimeout){
      setUserToken("")
    }
  },
  [showTimeout])

  useEffect(() => {
    const token = localStorage.getItem("token");
    const hasPassword = localStorage.getItem("has_password");
    const agent = localStorage.getItem("agent")
    if ((hasPassword === 'Y' && token) || agent) {      
      startTimer();
    }
  }, [user]);

  const isloggedIn = () => {
    return localStorage.getItem("token");
  };

  const transactionsRoute = () => {
    props.history.push("/transactions");
  };

  if (showTimeout) {

    return (
      <LoginPopup
        routerProps={props}
        setShowTimeout={setShowTimeout}
        startTimer={startTimer}
        userToken={userToken}
      />
    );
  }

  return (
    <HttpsRedirect>
        <Router>
          <div>
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/:user" component={Login} />
            <Route exact path="/logout" component={Login} />
            <Route path="/logout/:logout" component={Login} />
            <Route exact path="/agent" component={AgentLogin} />

            <Route path="/magiclink/:token/:email" component={Login} />

            <Route path="/mtd_report/:token" component={MTDReport} />

            <Route
              exact
              path="/"
              render={(routerProps) =>
                !isloggedIn() ? (
                  <Login />
                ) : (
                  routerProps.history.push("/transactions")
                )
              }
            />

            <Route
              path="/admin/:token/:email"
              render={(routerProps) => {
                fetch("https://api.ipify.org?format=json")
                  .then((res) => res.json())
                  .then((json) => {
                    if (global.admin_ips.includes(json.ip)) {
                      localStorage.setItem(
                        "token",
                        routerProps.match.params.token
                      );
                      localStorage.setItem(
                        "username",
                        routerProps.match.params.email
                      );
                      localStorage.setItem("IP_ADDRESS", json.ip);
                      routerProps.history.push("/transactions");
                    } else {
                      routerProps.history.push("/login");
                    }
                  });
              }}
            />

            <Route
              exact
              path="/hmrcpay/form"
              render={() => {
                return <Route component={HMRCPayment} />;
              }}
            />
            <ProtectedRoutes exact path="/cov19" component={Covid19Support} />
            <ProtectedRoutes exact path="/payment/form" component={Payment} />
            <ProtectedRoutes exact path="/payment" component={Payments} />
            <ProtectedRoutes
              exact
              path="/transactions"
              component={Transactions}
            />
            <ProtectedRoutes
              exact
              path="/transactions/:data"
              component={Transactions}
            />
            <ProtectedRoutes exact path="/taxReturns" component={TaxReturns} />
            <ProtectedRoutes exact path="/accounts" component={Accounts} />
            {/* <ProtectedRoutes exact path="/profile" component={Profile} /> */}
            <ProtectedRoutes
              exact
              path="/ManualSubmit"
              component={ManualSubmit}
            />
            <ProtectedRoutes exact path="/journeys" component={Journeys} />
            <ProtectedRoutes
              exact
              path="/ManualTransactionsImporter"
              component={ManualTransactionsImporter}
            />
            <ProtectedRoutes
              exact
              path="/taxForms/:name"
              component={TaxFormsMenu}
            />
            <ProtectedRoutes exact path="/taxForms" component={TaxForms} />
            <ProtectedRoutes
              exact
              path="/taxReturn/:name"
              component={TaxReturn}
            />
            <ProtectedRoutes exact path="/home" component={HubPage} />
            <ProtectedRoutes exact path="/home/:data" component={HubPage} />
            <ProtectedRoutes exact path="/profile" component={FullProfile} />
            <ProtectedRoutes exact path="/profileMain" component={FullProfile} />
            <ProtectedRoutes exact path="/tutorials" component={Tutorials} />
            <ProtectedRoutes exact path="/tutorials/menu" component={TutorialsMenu} />
            <ProtectedRoutes exact path="/plan" component={FullProfile}/>
            <ProtectedRoutes exact path="/MTD" component={MTD} />
            <ProtectedRoutes exact path="/Partner_import" component={Partner_import} />
            <ProtectedRoutes exact path="/Partner_import/:data" component={Partner_import} />
            <ProtectedRoutes exact path="/Partner_import/:data/:token" component={Partner_import} />
            <ProtectedRoutes exact path="/Partner_import2" component={Partner_import2} />
            <ProtectedRoutes exact path="/Partner_import_employed" component={Partner_import_employed} />
            {/* <ProtectedRoutes
            exact
            path="/MTDReport"
            component={MTDReport}
          /> */}
            <ProtectedRoutes exact path="/insights" component={Insights} />
            <ProtectedRoutes exact path="/rules" component={Rules} />
            <ProtectedRoutes
              exact
              path="/taxBreakdown"
              component={TaxBreakdown}
            />
            <ProtectedRoutes exact path="/hmrcpay" component={HMRCPayment} />
            <ProtectedRoutes exact path="/txYearCheck" component={TXYearCheck} />
            <ProtectedRoutes exact path="/invoiceSettings" component={invoiceSettings} />
          </div>
          <MixPanelScreenChange />
        </Router>
     </HttpsRedirect>
  );
};

export default withRouter(Routes);
