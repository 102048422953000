import React, { useState, useEffect } from "react";

const CountDwnr = ({ minutes = 0 }) => {
    const [over, setOver] = useState(false);
    const [mins, setTime] = useState(minutes);
    const tick = () => {
        if (over) return;
        if (mins <= 0) {
            setOver(true);
            if(localStorage.getItem('support_token_start') !== ""){
                localStorage.removeItem('support_token_start')
                window.location.reload(false)
            }
        }        
        setTime(mins - (1/60))
    };

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    const m = Math.trunc(mins)
    const s = Math.round((mins-m) * 60);    

    return (
        <div>
            {!over && <h4 style={{textAlign: "left"}}>{`${m} minutes and ${s} seconds left`}</h4>}
            <h4>{over ? "Time's up!" : ''}</h4>
        </div>
    );
};

export default CountDwnr;
