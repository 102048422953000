import { IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import moment from "moment";
import { languageOutline } from 'ionicons/icons';

export const TaxBrkdown = props => {
    const taxObj = props.taxCalc;
    const yearData = props.yearData;

    const yearTotals = {
        summary_income: taxObj.summary_income || 0,
        summary_expense: taxObj.summary_expense || 0,
        tax_to_pay: taxObj.tax_to_pay || 0
    };

    const renderValue = (amount, data) => {
        const a = amount || 0;
        const target = data? data.target : "";
        const label = "£" + Number(a)
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

        return(<>
            {(a === 0 || target === "") && label}
            {a !== 0 && target !== "" && <Link
                to={{pathname: `/${target}`, state: {code: data ? data.code : "", yearData: yearData, formName: data ? data.formName : "", filters: true}}}
            >
                {label}
            </Link>}
        </>)
    };


    const renderMe = [
        {
            label: "Memo",
            value: [
                { name: "Personal allowance", finance: taxObj.personal_allowance, data: {code: "", target: "taxForms", formName: "SA102"} },
                { name: "Unused allowance for dividends", finance: taxObj.div_deduction, data: {code: "", target: ""} }
            ]
        },
        {
            label: "Employment income",
            value: [
                { name: "Employment salary", finance: taxObj.employment_salary, data: {code: "", target: "taxForms", formName: "SA102"} }
            ]
        },
        {
            label: "Trading information",
            value: [
                { name: "Trading income", finance: taxObj.trading_income || 0, data: {code: "TRAI", target: "transactions"} },
                { name: "Trading expenses (excluding mileage)", finance: taxObj.unadjusted_trading_expenses || 0, data: {code: "TRAE", target: "transactions"} },
                { name: "Trading allowance", finance: taxObj.trading_allowance || 0, data: {code: "", target: ""} },
                { name: "Mileage claimed", finance: taxObj.apply_miles_to_property === false ? taxObj.mileage_claimed : 0 || 0, data: {code: "", target: "journeys"} },
                { name: "Home working", finance: taxObj.wfh_claim || 0, data: {code: "", target: "taxForms", formName: "HW1"} },
                { name: "Net trading", finance: taxObj.trading_profit || 0, styles: { fontWeight: 700 }, data: {code: "", target: ""} }
            ]
        },
        {
            label: "Property information",
            value: [
                { name: "Rental income", finance: taxObj.property_gross_income || 0, data: {code: "PROI", target: "transactions"} },
                { name: "Rental expenses", finance: taxObj.property_gross_expenses || 0, data: {code: "PROE", target: "transactions"} },
                { name: "FHL income", finance: taxObj.fhl_gross_income || 0, data: {code: "FHLI", target: "transactions"} },
                { name: "FHL expenses", finance: taxObj.fhl_gross_expenses || 0, data: {code: "FHLE", target: "transactions"} },
                { name: "Gross finance costs (from SA105)", finance: taxObj.property_residential_interest || 0, data: {code: "", target: "taxForms", formName: "SA105"} },
                { name: "Room relief", finance: taxObj.property_room_relief || 0, data: {code: "", target: ""} },
                { name: "Income allowance", finance: taxObj.property_allowance + taxObj.fhl_allowance || 0, data: {code: "", target: ""} },
                { name: "Allowable finance costs", finance: taxObj.property_residential_finance_relief || 0, data: {code: "", target: ""} },
                { name: "Total allowances & reliefs", finance: taxObj.property_room_relief + taxObj.property_allowance + taxObj.fhl_allowance + taxObj.property_residential_finance_relief || 0, data: {code: "", target: ""} },
                { name: "Allowable expenses", finance: taxObj.property_expenses + taxObj.fhl_expenses - (taxObj.apply_miles_to_property === true ? taxObj.mileage_claimed : 0) || 0, data: {code: "", target: ""} },
                { name: "Mileage claimed", finance: taxObj.apply_miles_to_property === true ? taxObj.mileage_claimed : 0 || 0, data: {code: "", target: "journeys"} },
                { name: "Net property", finance: (taxObj.property_profit + taxObj.fhl_profit), styles: { fontWeight: 700 }, data: {code: "", target: ""} }
            ]
        },
        {
            label: "Other",
            value: [
                { name: "Other income", finance: taxObj.other_income, data: {code: "OTHI", target: "transactions"} },
                { name: "Other expenses", finance: taxObj.other_expenses, data: {code: "OTHE", target: "transactions"} }
            ]
        },
        {
            label: "Dividends",
            value: [
                { name: "Dividends", finance: taxObj.dividends, data: {code: "DIVI", target: "transactions"} },
                { name: "Dividend allowance", finance: taxObj.div_allowance, data: {code: "", target: ""} },
                { name: "Taxable dividends", finance: Math.max(taxObj.dividends_adjusted - taxObj.div_allowance,0), data: {code: "", target: ""} }
            ]
        },
        {
            label: "Capital gains",
            value: [
                { name: "Net capital gains", finance: taxObj.capital_gains_taxable, data: {code: "", target: "taxForms", formName: "SA108"} },
                { name: "Gross capital gains", finance: taxObj.capital_gains_total, data: {code: "", target: "taxForms", formName: "SA108"} },
            ]
        },
        {
            label: "Misc",
            value: [
                { name: "Bank interest", finance: taxObj.savings_income, data: {code: "BANI", target: "transactions"} },
                { name: "Taxed interest", finance: taxObj.taxed_interest, data: {code: "TBNI", target: "transactions"} },
                { name: "Gift aid", finance: taxObj.gift_aid_paid, data: {code: "GAPE", target: "transactions"} },
                { name: "Other reliefs (e.g. EIS/SEIS)", finance: taxObj.other_reliefs, data: {code: "", target: ""} },
            ]
        },

        {
            label: "Taxes",
            value: [
                { name: "Income tax due", finance: (taxObj.tax_to_pay - taxObj.StudentLoanRepaymentDue), data: {code: "", target: ""} },               
                { name: "Class 2 national insurance due", finance: taxObj.class2_nic,  data: {code: "", target: ""} },
                { name: "Class 4 national insurance due", finance: taxObj.class4_nic,  data: {code: "", target: ""} },
                { name: "Total tax", finance: taxObj.tax_to_pay + taxObj.class2_nic + taxObj.class4_nic, styles: { fontWeight: 700 }, data: {code: "", target: ""} },
                { name: "Student loan repayment", finance: taxObj.StudentLoanRepaymentDue,  data: {code: "", target: ""} },
                { name: "Total due (to be confirmed by HMRC)", finance: taxObj.tax_to_pay + taxObj.class2_nic + taxObj.class4_nic + taxObj.StudentLoanRepaymentDue , styles: { fontWeight: 700 }, data: {code: "", target: ""} }
            ]
        }
    ];

    const styles = {
        ionGridStyle: { backgroundColor: '#f5f7fa', fontSize: '14px', borderRadius: '5px' },
        topColStyle: { textAlign: 'left', lineHeight: '20px', fontWeight: 700 },
        innerColStyleTitle: { textAlign: 'left', lineHeight: '20px', borderBottom: "1px solid rgb(212, 212, 212)" },
        innerColStyleValue: { textAlign: 'right', lineHeight: '20px', borderBottom: "1px solid rgb(212, 212, 212)" },
        emptyRowStyle: { height: 20 },
        totalAmountColor: {
            income: { color: 'green' },
            expenses: { color: '#7213EA' }
        }
    };

    return (
        <IonGrid style={styles.ionGridStyle}>
            {/* <IonRow>
                <IonCol>Income
                    <div style={styles.totalAmountColor.income}>{renderValue(yearTotals.summary_income)}</div>
                </IonCol>
                <IonCol>Expenses
                    <div style={styles.totalAmountColor.expenses}>{renderValue(yearTotals.summary_expense + taxObj.mileage_claimed || 0)}</div>
                </IonCol>
                <IonCol>Tax
                    <div>{renderValue(yearTotals.tax_to_pay)}</div>
                </IonCol>
            </IonRow> */}

            {renderMe.map((item, outerIndex) => (<>
                    <IonRow/><IonCol/>
                    <IonRow key={outerIndex}>
                        <IonCol>
                            <div style={styles.topColStyle}>{item.label}</div>
                            {item.value.map((info, innerIndex) => (<>
                                {Object.keys(info).length > 0 && <IonRow key={innerIndex}>
                                    <IonCol style={styles.innerColStyleTitle}><span style={info.styles}>{info.name}</span></IonCol>
                                    <IonCol style={styles.innerColStyleValue}><span style={info.styles}>{renderValue(info.finance, info.data)}</span></IonCol>
                                </IonRow>}
                                {Object.keys(info).length === 0 && <IonRow key={innerIndex}>
                                    <IonCol style={styles.emptyRowStyle} />
                                </IonRow>}
                            </>))}
                        </IonCol>
                    </IonRow>
                </>))
            }
        </IonGrid>
    )
};

// export const TaxSummary = (props) => {
//     const summary_income = props.taxCalc.summary_income || 0;
//     const summary_expense = props.taxCalc.summary_expense || 0;
//     const mileage_claimed = props.taxCalc.mileage_claimed || 0;
//     const tax_to_pay = props.taxCalc.tax_to_pay || 0;

//     const styles = {
//         ionGridStyle: { backgroundColor: '#f5f7fa', fontSize: '14px', borderRadius: '5px', margin: '10px 0px' },
//         totalAmountColor: {
//             income: { color: 'green' },
//             expenses: { color: '#7213EA' }
//         }
//     };

//     function renderValue(amount) {
//         const a = amount || 0;
//         return "£" + Number(a)
//             .toFixed(2)
//             .toString()
//             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
//     }

//     return (
//         <IonGrid style={styles.ionGridStyle}>
//             <IonRow>
//                 <IonCol>Income
//                     <div style={styles.totalAmountColor.income}>{renderValue(summary_income)}</div>
//                 </IonCol>
//                 <IonCol>Expenses
//                     <div style={styles.totalAmountColor.expenses}>{renderValue(summary_expense + mileage_claimed)}</div>
//                 </IonCol>
//                 <IonCol>Tax
//                     <div>{renderValue(tax_to_pay)}</div>
//                 </IonCol>
//             </IonRow>
//         </IonGrid>
//     )
// };

export const TranslateIcon = (props) => {
    function doTranslateLink() {
        var text = document.body.innerText;
        text = text.replace('untied', 'untied®')
        text = text.replace('untied®®', 'untied®')
        text = text.replace('gig', 'GIG')
        var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
        window.open(url, '_untiedTranslater');
    }
    return (

        <IonButtons slot="end">
            <IonButton data-tip="Translate page" onClick={() => {
                doTranslateLink()
            }} >

                <IonIcon slot="start" icon={languageOutline} style={{ color: 'white' }} /><br />
                <span style={{ fontSize: '10px', color: 'white' }}>
                    <img
                        style={{
                            height: "20px",
                        }}
                        alt=""
                        src={require("./images/flags.png").default}
                    />
                </span>
            </IonButton>
        </IonButtons>

    )

}

// export const getTaxYears = function (callback) {
//     const timeStamp = Math.floor(Date.now() / 1000);
//     const token = localStorage.getItem("token");
//     const agentLogin = localStorage.getItem("agentId");
//     if (token == null) {
//         return;
//     }

//     fetch(`https://${global.api_url}/getTaxYears.php?ts=${timeStamp}`, {
//         method: "GET",
//         headers: { token: token, agentId: agentLogin }
//     })
//         .then(res => res.json())
//         .then(json => {

//             if (json.error) {
//                 if (json.error === "unauthorized") {
//                     //alert('redirect to login page');
//                     window.location = '/logout';
//                 }
//             } else {
//                 const localYear = localStorage.getItem("selectedYear");
//                 var element = {}
//                 if (localYear) {
//                     element = json.years.find(item => {
//                         return (Number(item.id) === Number(localYear));
//                     })
//                 } else {
//                     const last_year_date = moment(new Date()).subtract(1, "years");
//                     element = json.years.find(element => {
//                         return (last_year_date >= moment(element.start_date) && last_year_date <= moment(element.end_date));
//                     })
//                 }
//                 callback(json.years, element)
//             }

//         });
// };

export const getTaxCalc = function (taxYearId, callback) {
    const timeStamp = Math.floor(Date.now() / 1000);
    const agentLogin = localStorage.getItem("agentId");
    const token = localStorage.getItem("token");
    // const taxYearId = this.state.selectedYear;
    fetch(`https://${global.api_url}/getTaxCalculation.php?ts=${timeStamp}&tax_year_id=${taxYearId}`, {
        method: "GET",
        headers: { token: token, agentId: agentLogin }
    })
        .then(res => res.json())
        .then(json => {

            if (json.error) {
                if (json.error === "unauthorized") {
                    //alert('redirect to login page');
                    window.location = '/logout';
                }
            } else {
                // console.log("taxCalc", json)
                callback(json)
            }

        });
}

export const CustomButton = (props) => {
    const {linkTo, label, onClick, hidden, color, small, big} = props;

    return(
        <IonButton onClick={onClick || null} className={`ion-button-money ${big ? 'subscription-button' : ''} ${small ? 'small-text' : ''} ${hidden ? 'd-none' : ''}`}>
            <IonItem color={color} className={`employment-pages-nav-button ion-activatable ${big ? 'd-flex h-100' : ''}`} href={linkTo}>
                <IonLabel className={`text-center ${big ? 'ion-text-wrap' : ''}`}>{label}</IonLabel>
            </IonItem>
        </IonButton>
    )
};
