import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { ToastProvider } from 'react-toast-notifications'

import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import "./mixpanel";

import { RecoilRoot } from 'recoil'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";

Bugsnag.start({
  apiKey: '7dff2e2424c97f969b7a66fdd7f8bf95',
  enabledReleaseStages: [ 'production' ],
  plugins: [new BugsnagPluginReact()]
})

function noop() { }

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

function App() {

  return (
    <ToastProvider>
      <div className="App">
        <Routes />
      </div>
    </ToastProvider>
  );
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </ErrorBoundary>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
