import React, { useState, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MiniDrawer from "./../MiniDrawer";
import moment from "moment";
import {
  IonAlert,
  IonCheckbox,
  IonRow,
  IonCol,
  IonLoading,
  IonButton
} from "@ionic/react";
import { getTimestamp } from "../components/timeStamp.js";
import { taxFieldVerify, verifyTaxForm } from "../components/taxFormVerify.js";

import { useRecoilState } from "recoil";
import { userAtom, taxYearsAtom } from "../atom.js";
import { getLocalStorage } from "../components/localstorage.js";
import { SelectionBar } from "../components/selectionBar.js";
import CustomFilter from "../components/CustomFilter";
import ReactHtmlParser from 'react-html-parser'; 

import "./../App.css";

const TaxForms = (props) => {
  const [user_atom, setUserAtom] = useRecoilState(userAtom);
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
  const [recoilFetched, setRecoilFetched] = useState(false);

  const [propertyMin, setPropertyMin] = useState(false);

  const [covidState, setCovidState] = useState({})

  const [update, setUpdate] = useState(false);
  const firstTimeRender = useRef(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [checkboxRender, setCheckboxRender] = useState("");
  const [forms, setForms] = useState([]);
  const [data, setData] = useState([{}]);
  const [selectedForm, setSelectedForm] = useState("1");

  const [focusId, setFocusId] = useState("0");
  const [showLoading, setShowLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [saved, setSaved] = useState(false);

  const [includeData, setIncludeData] = useState(null)
  const [includeError, setIncludeError] = useState(false)
  const [userChanged, setUserChanged] = useState(false)

  const [postToggleSave, setPostToggleSave]= useState({save: false, data: null})

  const [connectAlert, setConnectAlert] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    alertTitle: "",
    alertMessage: "",
  });
  const [landedFromBreakdown, setLandedFromBreakdown] = useState(
    props.location.state !== undefined
  );
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (recoilFetched) {
      getForms();
      getCovidData()
    }
  }, [recoilFetched]);

  useEffect(() => {
    firstTimeRender.current = false;
    if (!user_atom.token) {
      let userState = getLocalStorage();
      setUserAtom(userState);
      getRecoilTaxYears(userState.token, userState.agent);

    } else if (JSON.stringify(tax_years_atom) === "{}") {
      getRecoilTaxYears();
    } else {
      setRecoilFetched(true);
    }
  }, []);

  useEffect(() => {
    handleFormSelect({ target: { value: selectedForm } });
  }, [pageLoaded]);


  const getRecoilTaxYears = (
    passed_token = user_atom.token,
    passed_agentId = user_atom.agentId
  ) => {
    fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: passed_token, agentId: passed_agentId },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          if (json.error === "unauthorized") {
            //alert('redirect to login page');
            props.history.push("/logout/logout");
          }
        } else {
          let today = new Date();
          let currentYearID = 0;
          let dateFilterStart = "";
          let dateFilterEnd = "";
          json.years.forEach((element) => {
            const start = new Date(element.start_date);
            const end = new Date(element.end_date);

            if (today >= start && today <= end) {
              currentYearID = element.id;
              dateFilterStart = element.start_date;
              dateFilterEnd = element.end_date;
            }
          });
          setTaxYearsAtom({
            taxYears: json.years,
            dateFilterStart: dateFilterStart,
            dateFilterEnd: dateFilterEnd,
            dateFilter: currentYearID,
          });
          if (!recoilFetched) {
            setRecoilFetched(true);
          }
        }
      });
  };

  useEffect(() => {
    if (forms.length > 0) {
      getTaxYears();
      setPageLoaded(true);
    }
  }, [forms]);

  const getForms = () => {
    fetch(`https://${global.api_url}/getTaxForms.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then((res) => res.json())
      .then((json) => {
        setForms([...json.forms]);
      });
  };

  const getTaxYears = () => {
    if (props.location.state === undefined) {
      setReady(true);
    } else {
      if (
        props.location.state.year ||
        (props.location.state.yearData &&
          props.location.state.yearData.tax_year)
      ) {
        const year_name =
          props.location.state.year || props.location.state.yearData.tax_year;

        const tax_year_element = tax_years_atom.taxYears.find((element) => {
          return year_name === element.tax_year;
        });

        setTaxYearsAtom({
          taxYears: tax_years_atom.taxYears,
          dateFilterStart: tax_year_element.start_date,
          dateFilterEnd: tax_year_element.end_date,
          dateFilter: tax_year_element.id,
        });
      }
      const form_name =
        props.location.state.form || props.location.state.formName;
      const form_element = forms.find((element) => {
        return form_name === element.code;
      });

      setSelectedForm(form_element.id);
      handleFormSelect({ target: { value: form_element.id } });
      setReady(true);
    }
  };

  const handleFormSelect = (event) => {
    if (forms.length === 0) {
      return false;
    }
    const form_id = event.target.value;
    const element = forms.find((form) => form.id === form_id);
    const tax_column = element.tax_column;
    setReady(false);
    setShowLoading(true);

    fetch(
      `https://${
        global.api_url
      }/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${
        tax_years_atom.dateFilter
      }&type=${tax_column}`,
      {
        method: "GET",
        headers: { token: user_atom.token, agentId: user_atom.agent },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.data) {

          const json_data = JSON.parse(json.data);
        
          console.log(json_data)

          if (tax_column === "employment") {
            setData(json_data);
            setSelectedForm(form_id);
          } else {
            setData([json_data]);

            setSelectedForm(form_id);
          }
        } else {
          setData([{}]);
          setSelectedForm(form_id);
        }
        setReady(true);
        setShowLoading(false);
      });
  };

  const getValue = (name, ndx, def = "") => {
    if (data.length === 0) {
      return "";
    }

    if (data[ndx][name]) {
      return data[ndx][name];
    } else {
      return def;
    }
  };

  const setValue = (
    name,
    newValue,
    format,
    length,
    ndx,
    min = undefined,
    allowNegative = false
  ) => {

    if (newValue === " ") {
      newValue = newValue.trim();
    }

    let retValue = newValue;
    let validData;

    if (retValue != null) {
      if (format.toLowerCase() === "string") {
        retValue = retValue.substr(0, length);
      }

      if (format.toLowerCase() === "number") {
        if (newValue !== "") {
          const addNegative =
            allowNegative && newValue.length > 0 && newValue.charAt(0) === "-";
          newValue =
            (addNegative ? "-" : "") + newValue.replace(/[^0-9\.]/g, "");
          if (newValue.length > length)
            newValue = newValue.substring(0, length);
          if (min && newValue < min) newValue = min;
          retValue = newValue;
        }
      }

      if (format.toLowerCase() === "date") {
        retValue = "";
        let cChar = "";
        const dateMask = [
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
        for (let i = 0; i < newValue.length; i++) {
          cChar = newValue.charAt(i).match(dateMask[i]);
          if (cChar != null) {
            retValue = retValue + cChar;
          }
        }

        let curValue = data[ndx][name];

        if (curValue === undefined) {
          curValue = "";
        }

        if (
          (curValue.length === 1 && retValue.length === 2) ||
          (curValue.length === 4 && retValue.length === 5)
        ) {
          retValue = retValue + "/";
        }

        const element = forms.find((element) => {
            return element.id === selectedForm;
          });
      
        const tax_column = element.tax_column;

        if (retValue.length === 10) {
          let aDate = moment(retValue, "DD/MM/YYYY", true);
          let isValid = aDate.isValid();
          if (
            isValid &&
            (name === "box_5" || name === "box_6" || name === "box_7") &&
            tax_column == "self_employment"
          ) {
            let box_5;
            if (data[0].box_5) {
              box_5 = data[0].box_5.split("/");
              box_5 = `${box_5[2]}-${box_5[1]}-${box_5[0]}`;
            } else {
              box_5 = tax_years_atom.dateFilterEnd;
            }
            let valid = taxFieldVerify(
              "date",
              newValue,
              {
                param1: tax_years_atom.dateFilterStart,
                param2: tax_years_atom.dateFilterEnd,
                param3: box_5,
              },
              name
            );

            if (valid.test) {
              setAlert({
                open: true,
                alertTitle: "Invalid date",
                alertMessage: valid.message,
              });
              setFocusId(name);
            }
          } else {
            if (!isValid) {
              setTimeout(() => {
                validData = [...data];
                validData[ndx][name] = "";
                setAlert({
                  open: true,
                  alertTitle: "Invalid date",
                  alertMessage: "Please enter a valid date.",
                });
                setData(validData);

                setFocusId(name);
              }, 500);
            }
          }
        }
      }
    }
    validData = [...data];
    validData[ndx][name] = retValue;
    setData([...validData]);
  };

  const parseValue = (value, currency) => {
    if (currency && value) {
      value = value.toString();
      value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      if (value.indexOf(".") > -1) {
        const integers = value.split(".")[0] + ".";
        let digits = value.split(".")[1];
        if (digits.length > 2) digits = digits.substring(0, 2);

        return integers + digits;
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  const toggleValue = (name, ndx, extra, save = false) => {

    let dataClone = [...data];
    dataClone[ndx][name] = !data[ndx][name];
    if (
      ready &&
      extra.title &&
      !propertyMin &&
      extra.title == "Include property schedule (SA105)?" &&
      dataClone[ndx][name] == true
    ) {

      setPropertyMin(true);
    }

    setData(dataClone);
    setCheckboxRender(!checkboxRender);
    if(save){
      setPostToggleSave({save: true, data: dataClone})
    }

  };

  const saveData = (include) => {
    let dataPackage;
    let dataClone
    const element = forms.find((element) => {
      return element.id === selectedForm;
    });

    const tax_column = element.tax_column;

    if (tax_column === "employment") {
      dataPackage = JSON.stringify(data);
    } 
    else {
      dataClone = {...data[0]}
      if(include && (forms.find(f => f.id == selectedForm).code !== 'SA100')){
        dataClone.include = true
      }
      dataPackage = JSON.stringify(dataClone);
    }
    const verified = verifyTaxForm(tax_column, data, tax_years_atom)
    if (!verified.pass) {
      setAlert({
        open: true,
        alertTitle: verified.alertTitle,
        alertMessage: verified.alertMessage,
      });
      return;
    }

    setPostToggleSave({save: false, data: null})

    setShowLoading(true);
    setUserChanged(false)
    setIncludeData(null)

    fetch(`https://${global.api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${tax_years_atom.dateFilter}&type=${tax_column}`,
      {
        method: "POST",
        headers: { token: user_atom.token, agentId: user_atom.agent },
        body: dataPackage,
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.status === "success") {
          setAlert({ ...alert, open: false });
          setFocusId(null);
        } else {
          setAlert({
            open: true,
            alertTitle: "Error",
            alertMessage: "There was a problem saving the form.",
          });
          setFocusId(null);
        }
        setShowLoading(false);
        setSaved(true);
        setUpdate(!update);
      });
  };

  const formsSelection = () => {
    return (
      <span
        style={{
          marginLeft: "20px",
          fontSize: "16px",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          paddingTop: "10px",
        }}
      >
        Tax form:{" "}
        <Select
          value={selectedForm}
          style={{ marginBottom: "00px" }}
          onChange={handleFormSelect}
        >
          {forms.map((element) => {
            return (
              <MenuItem
                key={element.id}
                value={element.id}
                selected={element.code === "SA100"}
              >
                {element.code} - {element.title}
              </MenuItem>
            );
          })}
        </Select>
      </span>
    );
  };

  useEffect(() => {
    if (pageLoaded) {
      handleFormSelect({ target: { value: selectedForm } });
      if (landedFromBreakdown) {
        if (props.location.state) {
          setLandedFromBreakdown(false);
          history.replace({ ...location, state: undefined });
        }
      }
    }
  }, [tax_years_atom]);

  useEffect(() => {
    if (propertyMin) {
      setPropertyMin(false);
    }
  }, [propertyMin]);

  useEffect(() => {
    if (includeData) {
      checkInclude();
    }
  }, [includeData]);

  useEffect(() => {
    if (postToggleSave.save && postToggleSave.data == data) {
      saveData();
    }
  }, [postToggleSave, data]);


  const getCovidData = () => {
    fetch(`https://${global.api_url}/getObjectData.php?type=covid1&ts=${getTimestamp()}`, {
        method: "GET",
        headers: { token: user_atom.token, agentId: user_atom.agent }
    })
    .then(res => res.json())
    .then(json => {
        if (JSON.stringify(json.data)!== "[]"){
            setCovidState(json.data)
        }
    })
}

const saveCovidData = (val) =>{
  let body
    if(val){
      body = JSON.stringify({ 
          type: "covid1",
          data: {...covidState, self_employed: true, wfh: true}
      });
    }
    else{
      body = JSON.stringify({ 
        type: "covid1",
        data: {...covidState, self_employed: false}
    });

    }

    fetch(`https://${global.api_url}/saveObjectData.php?ts=${getTimestamp()}`, {
        method: "POST",
        body: body,
        headers: { token: user_atom.token, agentId: user_atom.agent }
    })
    .then(res => res.json())
    .then(json => {
        // Mixpanel.track("covid_support_save_state", {state: covidState});
        
    })
    
    
}

  const checkInclude = () => {
  
    let inc = includeData.fields.find(f => {if(f.name){ return f.name.includes('include')}})

    if(forms.find(f => f.id == selectedForm).code == "HW1"){
      let id = tax_years_atom.taxYears.find(y => y.tax_year == "2020-2021").id
      if(tax_years_atom.dateFilter == id){
        let inc_index = includeData.fields.indexOf(inc)
        let val = getValue(inc.name, inc_index)
        saveCovidData(val)
      }
    }
 
    if(inc && userChanged){
      let inc_index = includeData.fields.indexOf(inc)
      let val = getValue(inc.name, inc_index)
    
      if(val){
        saveData()
      }
      else{
        setIncludeError(true)
      }
     
    }
    else{
      saveData()
    }
  }

  const renderRow = (element, ndx, taxYearInfo, fieldIndex) => {
    if (!ready) {
      return;
    }

    //if this element has a taxYears attribute don't continue if it does not contain the selected year
    if (element.taxYears) {
      if (!element.taxYears.includes(taxYearInfo.tax_year)) {
        return;
      }
    }

    let format =
      element.format === undefined || element.format === "*"
        ? "string"
        : element.format;
    let allowNegative =
      element.allowNegative === undefined ? false : element.allowNegative;

    if (element.depends) {
      if (!getValue(element.depends, ndx) && getValue(element.name, ndx) === "") {
        return;
      }
    }

    function helpi(element) {
      if (element.help_link) {
        return (
          <a href={element.help_link} target="_new">
            Info
          </a>
        );
      }
    }

    if (element.format === "checkbox") {
      var el_checked =
        getValue(element.name, ndx) !== "" ||
        getValue(element.name, ndx) === true;

      if (
        props.location.state !== undefined &&
        ndx === 0 &&
        !landedFromBreakdown
      ) {
        if (element.name === "include") {
          el_checked = true;
        }
      }
    }

    const yearStartDate = moment(tax_years_atom.dateFilterStart).format("D MMM YYYY");
    const yearEndDate = moment(tax_years_atom.dateFilterEnd).format("D MMM YYYY");
    var elTitle = element.title.replace('[yearStartDate]', yearStartDate)
    elTitle = elTitle.replace('[yearEndDate]', yearEndDate)

    return (
      <div key={fieldIndex}>
        <IonRow key={element.name}>
          <IonCol className="tax-forms-input" style={{ padding: 0 }}>
            {format === "checkbox" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && <h3 className="taxFormsHeading">{element.heading}</h3>}
                {element.subheading && <h4 className="taxFormsSubHeading">&nbsp;&nbsp;{element.subheading}</h4>}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer95}>
                    {ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <IonCheckbox
                    checked={el_checked}
                    style={styles.checkboxStyle}
                    onIonChange={(e) => {
                      if (e.detail.checked !== el_checked && (includeData==null)) {
                        toggleValue(element.name, ndx, element);
                      }
                      if (element.name === "more_options") {
                        setCheckboxRender(!checkboxRender);
                      }
                      if(!element.name.includes('include')){
                        setUserChanged(true)
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {format === "select" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && <h3 className="taxFormsHeading">{element.heading}</h3>}
                {element.subheading && <h4 className="taxFormsSubHeading">&nbsp;&nbsp;{element.subheading}</h4>}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer80}>
                    {ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <div style={styles.inputContainer20}>
                    <CustomFilter
                      select
                      label=" "
                      className="custom-filter-input"
                      value={getValue(element.name, ndx, element.default)}
                      onChange={(e) =>{
                        setValue(
                          element.name,
                          e.target.value,
                          format,
                          element.length,
                          ndx
                        )
                        setUserChanged(true)
                      }
                      }
                    >
                      {element.choices.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </CustomFilter>
                  </div>
                </div>
              </div>
            )}

            {format === "number" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && <h3 className="taxFormsHeading">{element.heading}</h3>}
                {element.subheading && <h4 className="taxFormsSubHeading">&nbsp;&nbsp;{element.subheading}</h4>}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer80}>
                    {ReactHtmlParser(element.indent||"")}{ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <div style={styles.inputContainer20}>
                    {element.currency && (
                      <span style={styles.currencyStyle}>£</span>
                    )}
                    <CustomFilter
                      label=" "
                      className="custom-filter-input"
                      value={parseValue(
                        getValue(element.name, ndx, element.default),
                        element.currency
                      )}
                      onChange={(e) =>{
                        setValue(
                          element.name,
                          e.target.value,
                          format,
                          element.length || 11,
                          ndx,
                          element.min,
                          allowNegative
                        )
                        setUserChanged(true)
                      }
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {format !== "select" &&
              format !== "checkbox" &&
              format !== "number" && (
                <div style={styles.inputContainer} id={element.name}>
                  {element.heading && <h3 className="taxFormsHeading">{element.heading}</h3>}
                  {element.subheading && <h4 className="taxFormsSubHeading">&nbsp;&nbsp;{element.subheading}</h4>}
                  <div
                    style={
                      element.multiline
                        ? styles.displayMultiLine
                        : styles.displaySingleLine
                    }
                  >
                    <span
                      style={
                        element.multiline
                          ? styles.titleContainer100
                          : styles.titleContainer80
                      }
                    >
                      {ReactHtmlParser(elTitle)}
                    </span>
                    <div
                      style={
                        element.multiline
                          ? styles.textareaContainer100
                          : styles.textareaContainer20
                      }
                    >
                      <div>
                        <CustomFilter
                          label=" "
                          style={{
                            width: "100%",
                            color: "#000000",
                            height: 70,
                          }}
                          multiline={element.multiline}
                          className={
                            !element.multiline
                              ? "tax-forms-input-textarea"
                              : "tax-forms-input-textarea-multiline"
                          }
                          value={getValue(element.name, ndx)}
                          placeholder={
                            element.multiline
                              ? "Add further info here, e.g. details of computations or attachments provided"
                              : ""
                          }
                          onChange={(e) =>{
                            setValue(
                              element.name,
                              e.target.value,
                              element.format,
                              element.length,
                              ndx
                            )
                            setUserChanged(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </IonCol>
        </IonRow>
      </div>
    );
  };

  const connectToHMRC = () => {
    setConnectAlert(false)
    let theForm = document.getElementById('connect_form');

    //create a pop up window as target for the form
    let w = 600;
    let h = 770;
    let left = (window.screen.width / 2) - (w / 2);
    let top = (window.screen.height / 2) - (h / 2);
    let child = window.open('about:blank', 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);

    //set the window as the target for the form and submit
    theForm.target = 'Popup_Window';
    theForm.submit();

    let leftDomain = false;
    let interval = setInterval(function () {
        try {
            if (child.document.domain === document.domain) {
                if (leftDomain && child.document.readyState === "complete") {

                  console.log("hmrc flow completed")
                    // we're here when the child window returned to our domain
                    clearInterval(interval);
                    //child.postMessage({ message: "requestResult" }, "*");
                }
            }
            else {
                // this code should never be reached,
                // as the x-site security check throws
                // but just in case
                leftDomain = true;
            }
        }
        catch (e) {
            // we're here when the child window has been navigated away or closed
            if (child && child.closed) {
                clearInterval(interval);
                console.log("window closed")
                //refresh this page
                // window.location.reload();

                // now actually GET the data
                console.log('now get prepop')
                var timeStamp = Math.floor(Date.now() / 1000);
                fetch(`https://${global.api_url}/hmrc/getPrePopulation.php?ts=${timeStamp}&tax_year_id=${tax_years_atom.dateFilter}`, {
                  method: "GET",
                  headers: { token: user_atom.token, 'Content-Type': 'application/json' }
                })
                  .then(res => res.json())
                  .then(json => { 
                    // do something - fetch the data again ..
                    console.log(json) 
                    handleFormSelect({ target: { value: selectedForm } });
                  })

                return;
            }
            // navigated to another domain
            leftDomain = true;
        }
    }, 500);

}

  const addItem = () => {
    setData([...data, {}]);
  };

  const delItem = (index) => {
    let filtered = data.filter((datum, i) => {
      if (i !== index) return datum;
    });
    setData([...filtered]);
  };

  const displayForm = () => {
    if (ready === false) {
      return <div />;
    }

    if (data == null) {
      return <div />;
    }

    //get the currently selected year string
    const taxYearInfo = tax_years_atom.taxYears.find((element) => {
      return element.id === tax_years_atom.dateFilter;
    });

    if (forms === []) {
      return <div />;
    }

    const form_container = forms.find((element) => {
      return element.id === selectedForm;
    });
    if (form_container === undefined) {
      return <div />;
    }

    const tax_column = form_container.tax_column;
    const oform = JSON.parse(form_container.form_json);

    return (
      <div>
        {oform.help_link && (
          <div style={{ width: "100%", textAlign: "left" }}>
            <a href={oform.help_link} target="_new">
              Get help on this form
            </a>
          </div>
        )}
        
        {tax_column === "employment" && (
                  <div style={{ width: "100%", textAlign: "left" }}>
                  <span style={styles.buttons.addEmploymentButton} onClick={() => setConnectAlert(true)}>
                    Get my employment information from HMRC
                  </span>
                </div>
        )}
        {data.map((form, index) => {
          return (
            <div key={index}>
              {tax_column === "employment" && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    paddingTop: "10px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3 className="taxFormsHeading">Employment {index + 1}</h3>{" "}
                  {index > 0 && (
                    <span>
                      <span
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          color:'#a00338'
                        }}
                        onClick={() => delItem(index)}
                      >
                        Delete
                      </span>{" "}
                    </span>
                  )}
                </div>
              )}
              {oform.fields.map((element, fieldIndex) => {
                return renderRow(element, index, taxYearInfo, fieldIndex);
              })}
            </div>
          );
        })}

        <div className="tax-forms-buttons-container">
          {/* <Button
            type="submit"
            style={styles.buttons.backButton}
            onClick={() => props.history.push("/taxForms/menu")}
          >
            Back
          </Button> */}

          <IonButton className = "ion-button-inverse"
            onClick={() => props.history.push("/taxForms/menu")}
            style={{width:150}}
          > Cancel </IonButton>

          {tax_column === "employment" && (
            <>
            <Button
              type="submit"
              style={styles.buttons.addEmploymentButton}
              onClick={() => addItem()}
            >
              Add employment
            </Button>

            {/* <form action={`https://${global.api_url}/hmrc/app_connect.php?`} method="POST" id='connect_form'>
                <input type='hidden' name='token' value = {user_atom.token} />
            </form> */}
                    {/* <Button style={styles.buttons.basicButton}
                            onClick={() => setConnectAlert(true)}>
                        Retrieve from HMRC
                    </Button> */}

            </>
          )}

          {/* <Button
            type="submit"
            style={styles.buttons.basicButton}
            onClick={() => setIncludeData(oform)}
          >
            Save
          </Button>  */}

            <IonButton className="ion-button-money" style={{width:150}}
                    onClick={() => setIncludeData(oform)}> Save </IonButton>

        </div>

        <IonAlert
          isOpen={alert.open}
          onDidDismiss={() => {
            setAlert({ ...alert, open: false });
            if (focusId != null) {
              setTimeout(() => {
                const id = focusId;
                document.getElementById(id).focus();
              }, 500);
            }
          }}
          header={alert.alertTitle}
          message={alert.alertMessage}
          buttons={["OK"]}
        />
      </div>
    );
  };

  const styles = {
    buttons: {
      basicButton: {
        width: 200,
        height: 48,
        borderRadius: 4,
        backgroundColor: "#c7ff00",
        fontSize: "16px",
        color: "#00338d",
        textTransform: "none",
      },
      backButton: {
        width: 200,
        height: 48,
        borderRadius: 4,
        backgroundColor: "#00338d",
        fontSize: "16px",
        color: "#ffffff",
        textTransform: "none",
      },
      addEmploymentButton: {
        //width: 200,
        //height: 48,
        //borderRadius: 4,
        //backgroundColor: "#c7ff00",
        fontSize: "16px",
        color: "#D10049",
        textTransform: "none",
        textDecorationLine: "underline",
        cursor:"pointer"
      },
    },
    inputContainer: {
      width: "100%",
      textAlign: "left",
      borderBottom: "2px solid #00338d",
    },
    displayFlex: {
      display: "flex",
      width: "100%",
      height: 70,
      fontSize: 14,
    },
    displayMultiLine: {
      marginTop: 10,
      display: "block",
    },
    displaySingleLine: {
      marginTop: 0,
      display: "flex",
    },
    titleContainer100: {
      width: "100%",
      margin: "auto",
      marginLeft: 0,
    },
    titleContainer80: {
      width: "80%",
      margin: "auto",
      marginLeft: 0,
    },
    titleContainer95: {
      width: "95%",
      margin: "auto",
      marginLeft: 0,
    },
    inputContainer20: {
      width: "20%",
      position: "relative",
    },
    textareaContainer100: {
      position: "relative",
      marginTop: 10,
      width: "100%",
    },
    textareaContainer20: {
      position: "relative",
      marginTop: 0,
      width: "20%",
    },
    checkboxStyle: {
      margin: "auto",
      marginRight: 0,
    },
    currencyStyle: {
      position: "absolute",
      bottom: 25,
    },
  };

  const displayMain = () => {
    if (pageLoaded) {
      return (
        <div style={{ width: "70%", minWidth: 700, margin: "auto" }}>
          <div style={{ margin: "10px 0" }}>{formsSelection()}</div>

          {displayForm()}

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={30000}
          />
        </div>
      );
    }
  };

  return (
    <>
      <IonAlert
      
        isOpen={saved}
        onDidDismiss={() => {
          setSaved(false);
        }}
        cssClass="form-save"
        message="Your changes have been saved"
        buttons={["OK"]}
      />
      <IonAlert
          isOpen={connectAlert}
          onDidDismiss={() => setConnectAlert(false)}
          header={"Connect to your HMRC account"}
          message={"By connecting to your HMRC account we can query any historic employment data and pre-fill your employment form. Note that HMRC data is not always completely up to date and may not be available for the current year."}
          buttons={[
            {
                text: 'Cancel',
                handler: () => setConnectAlert(false)
            },
            {
                text: 'Continue',
                handler: () => connectToHMRC()
            }
          ]}
        />
      <IonAlert
          isOpen={includeError}
          backdropDismiss ={false}
          onDidDismiss={() => setIncludeError(false)}
          header={"Include this information on my tax return "}
          message={`You have made changes but have not checked the 'Include' box to include the form in your tax return`}
          buttons={[
            {
                text: 'Save and include the data on my tax return',
                handler: () => {
                  setIncludeError(false)
                  setUserChanged(false)
                  let inc = includeData.fields.find(f => {if(f.name){ return f.name.includes('include')}})
                  let inc_index=includeData.fields.findIndex(f => f== inc)
                  toggleValue(inc.name, inc_index, inc, true)
                  setIncludeData(null)
                  
                  }
            },
            {
                text: 'Save without including this information on my return',
                handler: () => {
                  setIncludeError(false)
                  setUserChanged(false)
                  setIncludeData(null)
                  saveData()
                  }
            },
            {
                text: 'Cancel - go back to form',
                handler: () => {
                  setIncludeData(null)
                  setIncludeError(false)
                }
            },
          ]}
        />
      <React.Fragment>
        <div className="tax-forms-page-container">
          <MiniDrawer location={props.location} />
          <Container
            className={
              saved
                ? "tax-forms-page-content-no-scroll"
                : "tax-forms-page-content"
            }
          >
            {SelectionBar(pageLoaded, update)}
            {displayMain()}
            <form action={`https://${global.api_url}/hmrc/app_connect.php?`} method="POST" id='connect_form'>
                <input type='hidden' name='token' value = {user_atom.token} />
            </form>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default withRouter(TaxForms);
