import { atom } from 'recoil'

export const userAtom = atom({
    key: "user", 
    default:{
    }
})

export const userErrorAtom = atom({
    key:"userError",
    default:{
        showError: false, 
        errorTitle: "", 
        errorSubTitle: "", 
        errorText: "" }
})

export const userCheckoutAtom = atom ({
    key:"userCheckout",
    default:{
        showCheckout: false, 
        checkoutEmail: null, 
        showMessage: false, 
        messageTitle: '', 
        heading_text: "",
        messageSubTitle: '', 
        messageText: ""
    }
})

export const profileAtom = atom ({
    key: 'profile',
    default:{
        
    }
})

export const taxYearsAtom = atom ({
    key: 'taxYears',
    default:{
        
    }
})


export const importAtom = atom ({
    key: 'importData',
    default:{
        data: ""
    }
})