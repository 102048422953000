import React, { useState, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { IonButton, IonPopover, IonAlert, IonGrid, IonRow, IonCol, IonItem, IonInput, IonLoading, IonLabel,  IonModal, IonTextarea, IonToggle, } from '@ionic/react';
import { Button,  MenuItem, Modal, Backdrop, Fade, Toolbar } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from "react-router-dom";
import MiniDrawer from "../MiniDrawer";
import { Mixpanel } from "../mixpanel";
import moment from "moment";
import Footer from "../components/Footer";
import CustomFilter from "../components/CustomFilter";
import DropFile from "../components/DropFile";
import "react-confirm-alert/src/react-confirm-alert.css";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import "@pathofdev/react-tag-input/build/index.css";
import './Page.css';
import "./../App.css";
import { getTimestamp } from '../components/timeStamp.js';
import { SelectionBar } from '../components/selectionBar.js';
import { TagSelector } from "../components/TagSelector.js";
import { Buffer } from 'buffer';
import jwt_decode from "jwt-decode";


let externalFilters = {
  dateFilterStart: "",
  dateFilterEnd: "",
  tagFilter: "",
  moneyFilter: "",
  imported: "",
  descriptionFilter: ""
};

let clickedId, editMode = false, temp = [], initials = {
  description: [],
  amount: []
};

const TaxCatItem = (props) => {
  return (
    <div className="tax-tag-item">
      {props.item.title}<br /><div className="tax-tag-item-description">{props.item.description}</div>
    </div>)
}


const Transactions = (props) => {
  const [user_atom, setUserAtom] = useRecoilState(userAtom);
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
  const [recoilFetched, setRecoilFetched] = useState(false);

  const [update, setUpdate] = useState(false)
  const [first, setFirst] = useState(false)
  const firstTimeRender = useRef(true);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [transactionsRowData, setTransactionsRowData] = useState([]);
  const [plan, setPlan] = useState(2);
  const [incomeInfo, setIncomeInfo] = useState({
    required: false,
    type: "",
    form: "",
    year: ""
  });
  const [editedData, setEditedData] = useState({
    description: "",
    customTags: [],
    tag: "",
    amount: "",
    file: ""
  })
  const [trialExpired, setTrialExpired] = useState(false)
  const dataclone = useRef()
  const [addNote, setAddNote] = useState(false)
  const [note, setNote] = useState({
    transId: "",
    text: ""
  })

  // const [taggingAlert, setTaggingAlert] = useState({
  //   data: {},
  //   show: false
  // })
  const [claimedError, setClaimedError] = useState(false)

  const [tagConfirm, setTagConfirm] = useState(false)

  const [agentAdded, setAgentAdded] = useState(null)
  const [taggedAlert, setTaggedAlert] = useState(false)

  const [similarTransactionData, setSimilarTransactionData] = useState({})
  const [similarDataTrue, setSimilarDataTrue] = useState(false)
  const [attachmentsPresent, setAttachmentPresent] = useState(false)

  const [selectSimilarRow, setSelectSimilarRow] = useState(null)
  const [clearFilters, setClearFilters] = useState(false)
  const [type, setType] = useState("");
  const [cat, setCat] = useState('');
  const [transaction, setTransaction] = useState({
    type: 'E',
    description: "",
    cat: "",
    date: "",
    fetched: false,
    amount: ""
  });
  const [allocated, setAllocated] = useState("100%")
  const [allocatedArray, setAllocatedArray] = useState([])
  const [toggleArray, setToggleArray] = useState([])
  const [arrayReset, setArrayReset] = useState(false)


  const [toggleChecked, setToggleChecked] = useState(true)
  const taxTypes = [{ type: "Expense", value: 'E' }, { type: "Income", value: "I" }]
  const [taxCats, setTaxCats] = useState([{}]);
  const [errorText, setErrorText] = useState("");
  const [alloc, setAlloc] = useState("100%");
  const [allocAmount, setAllocAmount] = useState(0)
  const [filters, setFilters] = useState({
    textFilter: "",
    dateFilter: "",
    tagFilter: "Tag filter",
    moneyFilter: "Money filter",
    dateFilterStart: "",
    dateFilterEnd: ""
  });
  const [transFilters, setTransFilters] = useState({
    imported: "N",
    trans_ids: [""],
    fromCsvImporter: undefined
  });

  //for data being passed in via /:data
  const [parsedData, setParsedData] = useState(null)
  const [parsedError, setParsedError] = useState(false)
  const [noDataAlert, setNoDataAlert] = useState(false)

  // for transaction attachments/receipts
  const [attachment, setAttachment] = useState([]);
  const [showTagSelector, setShowTagSelector] = useState({ show: false, id: "" })
  const [showConfirmTagSelector, setShowConfirmTagSelector] = useState(false)

  const [attachmentError, setAttachmentError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [landedFromBreakdown, setLandedFromBreakdown] = useState(props.location.state !== undefined && props.location.state.code !== undefined);
  const location = useLocation();
  const history = useHistory();

  const [showIncomeExpense, setShowIncomeExpense] = useState(false)
  const [receipts, setReceipts] = useState([])
  const [attachments, setAttachments] = useState([])
  const [attachmentNames, setAttachmentNames] = useState([])
  const [typeSelected, setTypeSelected] = useState("");
  const [taxYears, setTaxYears] = useState(false);

  const [clearFiles, setClearFiles] = useState(false)
  const [tagReversed, setTagReversed] = useState(false)

  const [show, setShow] = useState({
    errorBox: false,
    loading: false,
    loadingMain: false,
    addTrans: false,
    multiList: false,
    bulkRemoveTagAlert: false,
    discardAlert: false,
    open: false,
    isInfoShown: false,
    isDownloadShown: false,
    isUploadShown: false,
    editDescription: false,
    editAmount: false,
    amountError: false,
    showClassification: false,
    showNotesColumn: false
  });
  const [insightMessage, setInsightMessage] = useState({
    show: false,
    message: "",
    longMessage: "",
    buttonText: "OK",
    buttonHandler: undefined,
    cancelButton: false
  });
  const [similarMessage, setSimilarMessage] = useState({
    show: false,
    message: "",
    longMessage: "",
    buttonText: "OK",
    buttonHandler: undefined,
    cancelButton: false
  });

  const [wfhRedirect, setWFHRedirect] = useState(false);
  const [transactionData, setTransactionData] = useState({
    show: false,
    data: [],
    paramsData: {}
  });

  const [flags, setFlags] = useState({
    redirectForms: false,
    transactionsFetched: false,
    gridIsFiltered: false,
    clearTransIds: false
  });
  const [counts, setCounts] = useState({
    taxCat: 0,
    rowCount: 0,
    accountsConnected: 0,
    totalVisible: 0,
    totalIn: 0,
    totalOut: 0,
    rowTotal: 0
  });
  const [addAccount, setAddAccount] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [filterChanged, setFilterChanged] = useState('')
  const [showToolBarTagSelector, setShowToolBarTagSelector] = useState(false)
  const [tagClickFunction, setTagClickFunction] = useState('')

  const constants = {
    multiLineSelect: [     // THIS LIST IS HARD CODED BASED ON DB VALUES AND MAY NEED TO BE UPDATED!
      { title: "Business", id: 1, idIn: 5, idOut: 11, tagname: "Business", description: "Business", type: 'M' },
      { title: "Rental Property", id: 2, idIn: 2, idOut: 12, tagname: "Rental", description: "Rental property", type: 'M' },
      { title: "Furnished Holiday Let", id: 3, idIn: 22, idOut: 21, tagname: "FHL", description: "Furnished Holiday Let", type: 'M' },
      { title: "Personal", id: 4, idIn: 25, idOut: 26, tagname: "Personal", description: "Personal, non business", type: "M" },
      /* { title: "Other", id: 6, idIn: 8, idOut: 18, tagname: "Other", description:"Other" },*/
      { title: "Transfer", id: 7, idIn: 7, idOut: 17, tagname: "Transfer", description: "Transfer", type: "M" }
    ],
    defaultColDef: {
      resizable: true,
      editable: false,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      flex: 1,
      minWidth: 125,
      onCellDoubleClicked: (params) => {
        let t
        // if(parseFloat(params.data.amount) <0){
        //   t = "E"
        // }
        // else{ t= "I"}
        if (params.data.type == "CREDIT") {
          t = "I"
        }
        else { t = "E" }
        setType(t)

        Mixpanel.track("opened_transaction");
        if (params.data.claimed == "N") {
          let amount = Math.abs(params.data.amount).toFixed(2)
          setAllocAmount(amount)

          setAllocatedArray([{ percent: "100%" }])
          setToggleArray([true])
          let data = {
            amount: `${amount}`,
            category: "0",
            description: params.data.description,
            id: 1,
            receipt: "N",
            receipt_name: null,
            tags: [],
            token: ""
          }
          setTransactionData({ show: false, data: [data], paramsData: params.data })

          getTransaction(params.data.id)
          getReceipts(params.data.id)
        }
        else {
          getTransaction(params.data.id)
          getTaxTrans(params.data)
        }
      }
    },
    columnDefs: [
      {
        maxWidth: 40,
        filter: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        lockPosition: true,
        sortable: false,
        resizable: false,
        cellClass: "cell-style"
      },
      {
        // sortable: false,
        filter: false,
        headerName: "",
        field: "recommendation",
        maxWidth: 48,
        hide: false,
        resizable: false,
        cellStyle: { textAlign: "right" },
        cellRenderer: (params) => {

          const eDiv = document.createElement('div');
          const eSpan = document.createElement('span');
          eDiv.appendChild(eSpan)

          const isWFH = params.data.message.includes("work from home")


          if (params.data.claimed === 'N') {
            // not claimed - is it a candidate or wfh or other insight?

            // if candidate add candidate button
            if (params.data.candidate === "Y") {

              const eButton = document.createElement('button')
              eButton.className = "similar"

              eButton.addEventListener('click', () => {
                Mixpanel.track("clicked_similar_icon");
                setSimilarMessage({
                  show: true,
                  message: "",
                  longMessage: "This transaction is similar to other tagged transactions. Do you want to select a tag?",
                  buttonText: "Select tag",
                  buttonHandler: () => { Mixpanel.track("clicked_similar_action"); params.node.setSelected(true) },
                  similarText: "View similar transactions",
                  similarButtonHandler: () => { Mixpanel.track("clicked_view_similar_action"); services.fetchService.getSimilar(params.data.id); },
                  cancelButton: true
                })
              });

              eSpan.appendChild(eButton)

            } else {
              //it is not a candidte, see if it is an insight ....

              if (params.data.message !== "") {

                // must be an insight ...
                const insightButton = document.createElement('button')
                insightButton.className = isWFH ? "lightbulb" : "message"   // is it work from home or not?

                insightButton.addEventListener('click',
                  () => {
                    Mixpanel.track("clicked_insight_icon");
                    setInsightMessage({
                      show: true,
                      message: params.data.message,
                      longMessage: isWFH ? "Complete the work from home form to apply a deduction to your tax bill" : "",
                      buttonText: isWFH ? "Go to form" : "OK",
                      buttonHandler: isWFH ? () => { Mixpanel.track("clicked_wfh_action"); setWFHRedirect(true) } : undefined,
                      cancelButton: isWFH
                    });
                  })

                eSpan.appendChild(insightButton)

              }
            }

          }

          return eDiv

        }

      },
      {
        headerName: "Tag",
        field: "claimed",
        filter: true,
        valueGetter: params => params.data.claimed === "Y" ? params.data.tagname : " ",
        suppressMovable: true,
        width: 40,
        cellClass: (params) => {
          if (params.data.claimed === "Y") {
            if (params.data.tagname === "Personal") {
              return "cell-style cell-style-personal"
            }
            if (params.data.type === "DEBIT") {
              return "cell-style cell-style-debit"
            } else {
              return "cell-style cell-style-other"
            }
          } else {
            return "cell-style"
          }
        }
      },
      {
        headerName: "Account",
        field: "ac_name",
        width: 50,
        cellClass: "cell-style"
      },
      {
        headerName: "Date",
        field: "datetime",
        valueFormatter: (params) => services.actionsService.prettyDateTime(params.value),
        sortingOrder: ["desc", "asc", "no sort"],
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            let dateAsString = cellValue;
            if (dateAsString == null) return 0;

            let cellDate = new Date(dateAsString);
            cellDate.setHours(0, 0, 0, 0);
            filterLocalDateAtMidnight.setHours(0, 0, 0, 0);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          inRangeInclusive: true
        },
        minWidth: 180,
        // width: 240,
        cellClass: "cell-style"
      },
      {
        headerName: "Description",
        field: "description",
        cellClass: "cell-style",
        minWidth: 180,

      },
      {
        headerName: "",
        // headerComponentParams: {
        //   template: '<div><span ><div id="attachmentHeader" class = "attachment"></div></span></div>'
        // },

        headerClass: "attachmentHeader",
        enableSorting: true,
        sortingOrder: ["desc", "asc", "no sort"],
        field: "has_receipt",
        filter: false,
        // filterParams: attachmentFilterParams,
        cellClass: "cell-style",
        // filter: false,
        maxWidth: 50,
        cellStyle: { textAlign: "center" },
        cellRenderer: params => {
          const reDiv = document.createElement('div');
          const reSpan = document.createElement('span');
          reDiv.appendChild(reSpan)
          if (params.data.has_receipt == "Y") {

            const reButton = document.createElement('button')
            reButton.className = "attachment"

            reButton.addEventListener('click', () => {
              Mixpanel.track("clicked_receipt_icon");
            });

            reSpan.appendChild(reButton)
          }
          return reDiv

        }
      },

      {
        headerName: "Amount",
        field: "amount",
        sortingOrder: ["desc", "asc", "no sort"],
        cellStyle: params => {
          if (params.data.type === "CREDIT" || params.data.type.toLowerCase() === "in" || params.data.type.toLowerCase() === "income") {
            return {
              color: "#00338d",
              fontWeight: "bold",
              textAlign: "right"
            };
          } else {
            return {
              fontWeight: "bold",
              textAlign: "right"
            };
          }
        },
        valueGetter: params => {
          return Math.abs(params.data.amount);
        },
        valueFormatter: params => {
          const cSymbol = params.data.currency_symbol ? params.data.currency_symbol : '';
          if (params.data.type === "CREDIT" || params.data.type.toLowerCase() === "in" || params.data.type.toLowerCase() === "income") {
            return (
              "+ " + cSymbol +
              Number(params.value)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          } else {
            return (
              cSymbol +
              Number(params.value)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          }
        },
        filter: "agNumberColumnFilter",
        cellClass: "cell-style"
      },

      {
        headerName: "Claimed",
        field: "claimed_amount",
        cellStyle: params => {
          if ((params.data.type === "CREDIT" || params.data.type.toLowerCase() === "in" || params.data.type.toLowerCase() === "income")
            && (params.data.claimed === 'Y' && (!params.data.claimed_amount < 0))) {
            return {
              color: "#00338d",
              fontWeight: "bold",
              textAlign: "right"
            };
          } else {
            return {
              fontWeight: "bold",
              textAlign: "right"
            };
          }
        },
        valueGetter: params => {
          //return params.data.claimed_amount
          return Math.abs(params.data.claimed_amount);
        },
        valueFormatter: params => {
          const cSymbol = params.data.currency_symbol ? params.data.currency_symbol : '';
          if ((params.data.type === "CREDIT" || params.data.type.toLowerCase() === "in" || params.data.type.toLowerCase() === "income")
            && (params.data.claimed === 'Y' && (!params.data.claimed_amount < 0))) {
            return (
              "+ " + cSymbol +
              Number(params.value)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          } else {
            return (
              cSymbol +
              Number(params.value)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          }
        },
        filter: "agNumberColumnFilter",
        cellClass: "cell-style"
      },
      {
        headerName: "Type",
        field: "type",
        hide: true,
        width: 0,
        maxWidth: 0,
        valueGetter: (params) => {
          return params.data.type === 'DEBIT' ? "Money out" : "Money In"
        }
      },
      {
        headerName: "Tax date",
        field: "tax_date",
        valueFormatter: (params) => services.actionsService.prettyDateTime(params.value),
        width: 200,
        minWidth: 200,
        cellClass: "cell-style"
      },
      {
        headerName: "Category",
        field: "category",
        width: 150,
        cellClass: "cell-style",

      },
      {
        headerName: "Classification",
        field: "classification",
        resizable: true,
        cellClass: "cell-style",

      },
      {
        headerName: "Note",
        field: "has_note",
        cellClass: "cell-style",
        width: 30,
        maxWidth: 75,
        cellStyle: { textAlign: "center" },

      },
      {
        sortable: false,
        filter: false,
        headerName: "Edit",
        cellStyle: { textAlign: "center" },
        maxWidth: 50,
        resizable: false,
        cellRendererFramework: params => {

          return (
            <button
              className="editJourneyButton"
              style={{ width: 50 }}
              onClick={() => {
                let t
                if (params.data.type == "CREDIT") {
                  t = "I"
                }
                else { t = "E" }
                setType(t)

                Mixpanel.track("opened_transaction");
                if (params.data.claimed == "N") {
                  let amount = Math.abs(params.data.amount).toFixed(2)
                  setAllocAmount(amount)
                  setAllocatedArray([{ percent: "100%" }])
                  setToggleArray([true])
                  let data = {
                    amount: `${amount}`,
                    category: "0",
                    description: params.data.description,
                    id: 1,
                    receipt: "N",
                    receipt_name: null,
                    tags: [],
                    token: ""
                  }
                  setTransactionData({ show: false, data: [data], paramsData: params.data })
                  getTransaction(params.data.id)
                  getReceipts(params.data.id)
                }
                else {
                  getTransaction(params.data.id)
                  getTaxTrans(params.data)
                }
              }}
            >
              <EditIcon className="edit-journey-icon" />
            </button>)
        }
      }

    ],
    rowClassRules: {
      "claimed-row": params => params.data.claimed === "Y"
    },
    filterBy: [
      {
        tagName: "Tag filter"
      },
      {
        tagName: "Not tagged"
      },
      {
        tagName: "All tagged"
      }
    ],
    moneyInOut: [
      { id: 0, tagName: "Money filter" },
      { id: 1, tagName: "Money out" },
      { id: 2, tagName: "Money in" }
    ]
  };

  const untagAndDiscard = () => {
    var id = 0;

    var ids = [];
    gridApi.getSelectedRows().forEach(node => {
      if (node.claimed === "Y") {
        node.tagname = null;
        node.selected = false;
        node.claimed = "N";
        node.claimed_amount = "0"
        node.discarded = "Y";
        id = node.id;
        node.toUnclaim = "Y";
        ids.push({ id });

        gridApi.updateRowData({ node });
      }
      else {
        node.discarded = "Y";
        id = node.id;
        ids.push({ id });

        gridApi.updateRowData({ node });

      }
    });

    gridApi.redrawRows();
    gridApi.deselectAll();

    let payload = JSON.stringify({ transactions: ids });
    fetch(
      `https://${global.api_url}/claimTransactions.php?ts=${getTimestamp()}&operation=unclaimOnly`,
      {
        method: "POST",
        headers: { token: user_atom.token, agentId: user_atom.agent },
        body: payload
      }
    )
      .then(res => res.json())
      .then(json => {

        ids.forEach(element => {
          fetch(
            `https://${global.api_url}/discardTransaction.php?ts=${getTimestamp()}&id=${element.id}`,
            {
              method: "GET",
              headers: { token: user_atom.token, agentId: user_atom.agent }
            }
          )
            .then(res => res.json())
            .then(json => {

            });
        });
        Mixpanel.track("bulk_delete_trans");
        gridApi.onFilterChanged()
        gridApi.redrawRows();
        gridApi.deselectAll();
        setUpdate(!update)
      }
      );

  }

  const updateNewNode = (info, node) => {
    let num = (alloc.split("%")[0] / 100)
    const id = node.id
    let tax_cat = info.tax_cat
    if (info.tax_cat) {
      let tagname = taxCats.filter(item => item.id === info.tax_cat)[0].tagname
      node.tagname = tax_cat === 9999 ? null : tagname;
      node.claimed = tax_cat === 9999 ? "N" : "Y";
      node.selected = false;
      node.claimed_amount = (node.amount * num)
      node.tax_date = node.datetime
    }
    gridApi.updateRowData({ node });
  }

  const tagModal = () => {

    const advisor = localStorage.getItem("agent") 

    return (

      <TagSelector
        taxCats={show.multiList ? constants.multiLineSelect : taxCats}
        type={show.multiList ? 'M' : type}
        show={showTagSelector.show}
        onDidDismiss={() => setShowTagSelector({ show: false, id: "" })}
        enableToggle={ advisor!=="" }
        onClick={(item) => {

          setTagReversed(type !== item.type)

          if (tagClickFunction === "filterbar") {
            setCat(item.id);
            setTransaction({
              ...transaction,
              cat: item.id
            });
          }
          // else if (tagClickFunction === "transactionPopup") {
          //   setCat(item.id)
          //   setTaggingAlert({ ...taggingAlert, taxTag: item.title })
          // }

          else if (tagClickFunction === "editTransaction") {
            setCat(item.id)
            services.actionsService.updateTransactionDataInfo(item.id, showTagSelector.id, "category", transactionData.paramsData.id)
          }
          setShowTagSelector({ show: false, id: "" })
        }} />
    )

  }

  const incomeExpenseModal = () => {
    return (
      <IonModal
        isOpen={showIncomeExpense}
        onDidDismiss={() => setShowIncomeExpense(false)}
        className="income-expense-selector-modal"
      >

        <div style={{ overflow: 'auto', borderRadius: 5, paddingBottom: 5 }}>
          <h4 style={{ paddingLeft: 10, color: "#00338d" }}>Transaction type</h4>
          {taxTypes !== [{}] && taxTypes.map((type, index) => {

            return <div className="income-expense-modal-item" key={index} onClick={() => {
              setCat('');
              setType(type.value)
              setTransaction({
                ...transaction,
                type: type.value,
                cat: ''
              })
              setShowIncomeExpense(false)
            }}>
              <div className="income-expense-tag-item">{type.type}</div>
            </div>
          })}
        </div>
      </IonModal>

    )
  }

  const tagConfirmModal = () => {
    let multipleRows = false
    let trans
    let currency_symbol = "£"
    if (tagConfirm) {
      let rows = gridApi.getSelectedRows()
      trans = rows[0]
      if (rows.length > 1) {
        multipleRows = true
      }
      else {
        if (allocAmount == 0) {
          setAllocAmount(Math.abs(trans.amount).toFixed(2))
        }
      }

      currency_symbol = trans.currency_symbol
    }

    const advisor = localStorage.getItem("agent") 

    return (

      <>

        <TagSelector
          show={showConfirmTagSelector}
          onDidDismiss={() => setShowConfirmTagSelector(false)}
          taxCats={show.multiList ? constants.multiLineSelect : taxCats}
          type={show.multiList ? 'M' : (typeSelected === 'CREDIT' ? 'I' : 'E')}
          toolbar={true}
          enableToggle={ advisor!=="" }
          onClick={(item) => {
            if (!show.multiList) {
              const originalType = typeSelected === 'CREDIT' ? 'I' : 'E'
              const newType = item.type
              setTagReversed(originalType !== newType)
            } else {
              setTagReversed(false)
            }

            setCounts((counts) => ({ ...counts, taxCat: item.id }))
            setShowConfirmTagSelector(false)

          }}
          dockWith="tbConfirmTagSelection"
        />

        <IonModal
          isOpen={tagConfirm}
          backdropDismiss={false}
          onDidDismiss={() => setTagConfirm(false)}
          className="tag-confirm-modal"
        >
          <div style={{ backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "42px", marginBotton: "10px", display: "flex", flexDirection: "row" }}>
            <div style={{ fontWeight: "bold", width: "30%", paddingTop: "10px", color: "white", fontFamily: "Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px" }}>
              Confirm tag
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContents: "center", paddingRight: "10px", marginLeft: "auto" }}>

              <div style={{ alignSelf: "center" }}>

                <CloseIcon
                  style={styles.filterButtons.closeWhite}
                  onClick={() => {
                    setTagConfirm(false);
                    setAllocAmount(0)
                    setToggleChecked(true)
                    setAlloc("100%")
                  }
                  }
                /> </div>
            </div>
          </div>
          <IonGrid>


            <IonRow>
              <IonCol size="6">
                Transactions will be tagged as
              </IonCol>
            </IonRow>

            <IonRow style={{ flexDirection: "row" }}>
              <IonCol size="6" style={{ paddingLeft: 10, "--padding-inline-end": 0 }} >
                <div style={{ fontSize: 12 }}>Tag</div>


                <IonItem
                  id="tbConfirmTagSelection"
                  // className="custom-ion-native" 
                  className='no-padding-select'
                  style={{
                    "--padding-start": 0,
                    "--inner-padding-end": 0,
                    margin: 0,
                    width: "100%"
                  }}>

                  <IonInput
                    style={{ "marginLeft": "5px", textOverflow: "ellipsis", "--padding-start": "5px !important", }}
                    placeholder="Select tag"
                    value={show.multiList 
                      ? constants.multiLineSelect.find(el => el.id === counts.taxCat) ? constants.multiLineSelect.find(el => el.id === counts.taxCat).title : "Select tag"  
                      : taxCats.find(el => el.id === counts.taxCat) ? taxCats.find(el => el.id === counts.taxCat).title : "Select tag"}
                    readonly
                    onClick={() => {
                      setShowConfirmTagSelector(true)
                    }}>
                    <div className="my-caret-dropdown-icon" />
                  </IonInput>
                </IonItem>

              </IonCol>


              {multipleRows &&
                <>
                  <IonCol size="5">
                    <div style={{ fontSize: 12 }}>Allocation amount</div>
                    <IonItem
                      className="grey-input"
                      style={{ "--padding-start": 0 }}
                    >
                      <IonInput
                        className="my-tax-selector-input left-indent"
                        style={{ margin: "8px 0px, 0px" }}
                        autoCapitalize="none"
                        value={alloc}
                        onIonChange={(event) => {
                          const value = event.detail.value;
                          let newVal = value;
                          let lastChar = value[value.length - 1];
                          if (value !== "") {
                            let v = value.replace("%", "");
                            if (!isNaN(Number.parseFloat(v)) && isFinite(Number.parseFloat(v))) {
                              if (Number(v) < 101) {
                                if (lastChar === "%") {
                                  newVal = v + "%";
                                }
                              } else {
                                newVal = "100%";
                              }
                            } else {
                              newVal = "0%";
                            }
                          }
                          setAlloc(newVal);
                        }}
                        placeholder=""
                        placeholdertextcolor="#000000"
                      />
                      {/* </div> */}
                    </IonItem>
                  </IonCol>
                </>
              }

              {!multipleRows && trans &&
                <>
                  <IonCol size="1" style={{ textAlign: "center", marginRight: 3 }}>
                    <IonLabel style={{
                      display: "block",
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: 12,
                      width: "100%",
                      paddingLeft: 12,
                      textAlign: "center"
                    }}>{toggleChecked ? currency_symbol : "%"}</IonLabel>
                    <IonToggle
                      style={{ marginTop: 8, "--background": "#C0C0C0", "--background-checked": "#C0C0C0", "--handle-background-checked": "white" }}
                      checked={toggleChecked}
                      onIonChange={(e) => { setToggleChecked(e.detail.checked) }}

                    />
                  </IonCol>
                  {!toggleChecked && trans &&
                    <IonCol size="4">

                      <IonLabel style={styles.labelLeftBlack}>Partial claim by percentage</IonLabel>
                      <IonItem
                        className="grey-input"
                        style={{
                          "--padding-start": 0,
                          //  "--border-width": "1px",
                          margin: 0,
                          width: "100%"
                        }}
                      >
                        <IonInput
                          className="custom-filter-payments"
                          value={alloc}
                          // placeholder="100%"
                          inputmode="text"
                          type="text"
                          onIonChange={(event) => {
                            const value = event.target.value;
                            let newVal = value;
                            let lastChar = value[value.length - 1];
                            if (value !== "") {
                              let v = value.replace("%", "");
                              if (!isNaN(Number.parseFloat(v)) && isFinite(Number.parseFloat(v))) {
                                let num = (v / 100) * Math.abs(trans.amount)
                                setAllocAmount(num.toFixed(2))
                                if (Number(v) < 101) {
                                  if (lastChar === "%") {
                                    newVal = v + "%";
                                  }
                                } else {
                                  newVal = "100%";
                                }
                              } else {
                                newVal = "100%";
                              }
                            }
                            setAlloc(newVal)
                          }}
                        />
                      </IonItem>
                    </IonCol>
                  }
                  {toggleChecked && trans &&
                    <IonCol size="4">
                      <IonLabel style={styles.labelLeftBlack}>Partial claim by amount</IonLabel>
                      <IonItem
                        className="grey-input"
                        style={{
                          "--padding-start": 0,
                          //  "--border-width": "1px",
                          margin: 0,
                          width: "100%"
                        }}
                      >

                        <IonInput
                          style={{ "marginLeft": "5px" }}
                          // value={services.actionsService.formatValue(trans.amount, true, index+1 !== clickedId, transactionData.paramsData.currency_symbol)}
                          value={`${currency_symbol}${allocAmount}`}
                          onIonChange={(e) => {
                            const value = e.detail.value;
                            let v = value.replace(currency_symbol, "");
                            if (v !== "") {
                              v = parseFloat(v)
                              let total = parseFloat(trans.amount)
                              let percent = (v / trans.amount) * 100
                              if (percent > 100) {
                                v = total
                                percent = 100
                              }
                              setAllocAmount(v)
                              setAlloc(`${percent.toFixed(2)}%`)

                            }
                          }
                          }
                          id="amount_claim"
                        />
                      </IonItem>


                    </IonCol>
                  }

                </>

              }
            </IonRow>
            <IonRow >
              <IonCol style={{ textAlign: 'center' }}>

                <IonButton
                  className="ion-button-inverse"
                  onClick={() => {

                    setTagConfirm(false)
                    setCat('')
                    setCounts((counts) => ({ ...counts, taxCat: 0 }))
                    setAllocAmount(0)
                    setToggleChecked(true)
                    setAlloc("100%")
                  }}
                >
                  cancel
                </IonButton>

              </IonCol>
              <IonCol style={{ textAlign: 'center' }}>

                <IonButton
                  className="ion-button-money"
                  onClick={() => {
                    services.actionsService.submitChangeTag();
                    setTagConfirm(false)
                    setAllocAmount(0)
                    setToggleChecked(true)
                  }}
                >
                  Tag
                </IonButton>

              </IonCol>
            </IonRow>
          </IonGrid>
        </IonModal>
      </>
    )
    // }
  }

  const sanitizeInputAmount = (value) => {
    function countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    }

    let amount = value
    if (value) {
      amount = value.replace(/[^0-9\.,]/g, '');
      amount = amount.replace(/\./g, (i => m => !i++ ? m : '')(0));
      if (amount.includes(".") && countDecimals(amount) > 2) {
        amount = parseFloat(amount).toFixed(2).toString()
      }
    }

    return amount
  }

  const addTransactionAmount = (value) => {

    const amount = sanitizeInputAmount(value);

    setTransaction({
      type: transaction.type,
      description: transaction.description,
      cat: transaction.cat,
      date: transaction.date,
      fetched: transaction.fetched,
      amount: amount
    })
  }

  const getTransaction = (id) => {

    fetch(`https://${global.api_url}/getTransaction.php?ts=${getTimestamp()}&trans_id=${id}`, {
      method: "GET",
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then(res => res.json())
      .then(json => {
        setNote({
          transId: json.transaction.id,
          text: json.transaction.notes
        })
        if (json.transaction.notes) {
          setAddNote(true)
        }
      })

  }

  const saveTransactionInfo = () => {

    let info = { id: note.transId, note: note.text }
    let body = JSON.stringify(info)
    fetch(`https://${global.api_url}/saveTransactionInfo.php?ts=${getTimestamp()}`, {
      method: "POST",
      headers: { token: user_atom.token, agentId: user_atom.agent },
      body: body
    })
      .then(res => res.json())
      .then(json => {
        if (note.text == "") {
          function updateNode(node) {
            node.has_note = "N"
            gridApi.updateRowData({ node });
          }
          gridApi.forEachNode(node => {
            if (note.transId === node.data.id) {
              updateNode(node.data)
            }
          })
        }
        if (note.text !== "") {
          function updateNode(node) {
            node.has_note = "Y"
            gridApi.updateRowData({ node });
          }
          gridApi.forEachNode(node => {
            if (note.transId === node.data.id) {
              updateNode(node.data)
            }
          })
        }

        setAddNote(false)
        setNote({
          transId: "",
          text: ""
        })
      })
    // if (taggingAlert.show) {
    //   setTaggingAlert({ show: false, data: {} })
    // }
  }


  const saveEditData = () => {
    let transactionTotal = Math.abs(transactionData.paramsData.amount)
    let transactions_total = 0

    const transactionDataCopy = JSON.parse(JSON.stringify(transactionData.data))

    transactionDataCopy.forEach(trans => {
      trans.amount = trans.amount.replace(/[^0-9\.]/g, '');
      if (trans.amount === "") trans.amount = "0"
      transactions_total += parseFloat(trans.amount)
    })
    if (transactions_total > transactionTotal) {
      setShow((show) => ({ ...show, amountError: true }));
      return;
    }
    let body = JSON.stringify(transactionDataCopy)

    let id = transactionData.paramsData.id

    const trans_date = moment(new Date(transaction.date)).format("DD/MM/YYYY");

    fetch(`https://${global.api_url}/createTaxTransactions.php?ts=${getTimestamp()}&trans_id=${id}&alloc=1&editing=true&trans_date=${trans_date}&get_matches=Y`, {
      method: "POST",
      headers: { token: user_atom.token, agentId: user_atom.agent },
      body: body
    })
      .then(res => res.json())
      .then(json => {
        Mixpanel.track("update_transaction_details");
        setUpdate(!update)

        function updateNode(node, data) {
          let total = 0
          let tags = []
          data.forEach(datum => taxCats.filter(cat => {
            if (cat.id == datum.category) {
              tags.push(cat.tagname)
            }
          }))
          let tagname
          if (tags.length > 1) {
            tagname = "Multiple"
          }
          if (tags.length == 1) {
            tagname = tags[0]
          }
          if (tags.length > 0) {
            node.claimed = "Y"
            data.forEach(datum => total += parseFloat(datum.amount))
            node.claimed_amount = total
            node.tagname = tagname;
          }

          gridApi.updateRowData({ node });
        }





        gridApi.forEachNode(node => {
          if (id === node.data.id) {
            updateNode(node.data, transactionDataCopy)
          }
        })


      });
    setAlloc("100%");
    if (attachments.length == 0) {
      setTransactionData({
        show: false,
        data: [],
        paramsData: {}
      })
      setArrayReset(true)

    }

  }

  const services = {
    fetchService: {
      addTransaction: () => {

        if (type === "") {
          setShow((show) => ({ ...show, errorBox: true, loading: false }));
          setErrorText("Please select the transaction type.");
          return;
        }

        if (transaction.description === "" || transaction.amount === "") {
          setShow((show) => ({ ...show, errorBox: true, loading: false }));
          setErrorText(transaction.description === "" ? "Please provide a description of the transaction." : "Please enter the amount. Enter numbers and decimal point only.");
          return;
        }

        if (transaction.cat === "") {
          setShow((show) => ({ ...show, errorBox: true, loading: false }));
          setErrorText("Please select a tax tag.");
          return;
        }

        if (transaction.date === "") {
          setShow((show) => ({ ...show, errorBox: true, loading: false }));
          setErrorText("Please select a date.");
          return;
        }

        //verify that transaction.date is a valid date
        if (!moment(transaction.date).isValid()) {
          setShow((show) => ({ ...show, errorBox: true, loading: false }));
          setErrorText("Please enter a valid date.");
          return;
        }

        let description = transaction.description;
        const amount = transaction.amount.replace(/[^0-9\.]/g, '');
        const trans_type = transaction.type === 'I' ? 'In' : 'Out';
        const trans_date = moment(new Date(transaction.date)).format("DD/MM/YYYY");
        const category = transaction.cat;

        const payload = { description, amount, trans_type, trans_date, category };

        fetch(`https://${global.api_url}/createTransaction.php?ts=${getTimestamp()}`, {
          method: "POST",
          headers: { token: user_atom.token, agentId: user_atom.agent },
          body: JSON.stringify(payload)
        })
          .then(res => res.json())
          .then(json => {
            Mixpanel.track("added_manual_transaction");
            // let transaction_id = json.trans_id.toString();
            // services.fetchService.getTransactions(token, agentId, transaction_id)

            if (json.se_info != null) {
              if (json.se_info.num_before === 0 && !json.se_info.have_info) {
                setIncomeInfo({
                  required: true,
                  type: "self-employed business",
                  form: "SA103S",
                  year: json.se_info.tax_year_name
                });
              }
            }

            if (json.prop_info != null) {
              if (json.prop_info.num_before === 0 && !json.prop_info.have_info) {
                setIncomeInfo({
                  required: true,
                  type: 'property business',
                  form: 'SA105',
                  year: json.prop_info.tax_year_name
                });
              }
            }

            if (json.emp_info != null) {
              if (json.emp_info.num_before === 0 && !json.emp_info.have_info) {
                setIncomeInfo({
                  required: true,
                  type: 'employment',
                  form: 'SA102',
                  year: json.emp_info.tax_year_name
                });
              }
            }
            if (attachment !== "") {
              uploadReceipt(json.trans_id)

            }
            if (addNote && note.text !== "" && note.text) {
              setNote({
                ...note,
                transId: `${json.trans_id}`,
                addNewNote: true
              })
            }
            setUpdate(!update)
            setAlloc("100%");
          });
        // props.addTransaction(payload, filters.dateFilter, token, agentId);

        setTimeout(() => {
          setShow((show) => ({ ...show, addTrans: false, loading: false }));
          services.fetchService.getTransactions()
        }, 1500);


        setTransaction({
          type: transaction.type,
          description: "",
          cat: transaction.cat,
          date: transaction.date,
          fetched: transaction.fetched,
          amount: ""
        });
        setAttachment([])
      },
      getTransactions: (passed_token = user_atom.token, passed_agentId = user_atom.agent, id = '', addedNewAccount = false) => {
        if (show.loading !== true) {
          setShow((show) => ({ ...show, loading: true }))
        }

        fetch(`https://${global.api_url}/getTransactions.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: passed_token, agentId: passed_agentId }
        })
          .then(res => res.json())
          .then(json => {

            if (json.error) {
              if (json.error === "unauthorized") {
                //alert('redirect to login page');
                props.history.push("/logout/logout");
              }
            } else {
              let filteredCategory;

              if (props.location.state && props.location.state.code) {
                const taxCode = props.location.state.code;
                filteredCategory = json.tax_categories.find((element) => {
                  return element.code === taxCode;
                });

                const filteredRowsByCategory = json.transactions.filter(item => item.tax_cat_id === filteredCategory.id);
                setTransactionsRowData([...filteredRowsByCategory]);


              } else {

                setTransactionsRowData([...json.transactions]);
              }

              setTaxCats(json.tax_categories);

              setFlags({
                ...flags,
                redirectForms: flags.redirectForms,
                transactionsFetched: true
              });
              setCounts((counts) => ({ ...counts, accountsConnected: json.accounts.length }));
              setPlan(json.plan);
              setShow((show) => ({ ...show, loadingMain: false, loading: false }));

              if (filters.dateFilter === '') {
                services.fetchService.getTaxYears();
              }

              if (gridApi) {
                gridApi.onFilterChanged()
              }

              setRecoilFetched(true)
              let agent = localStorage.getItem("agent")
              let added = localStorage.getItem("agent_code")

              if (added) {
                if (added && added !== "null" && !agent) {
                  //setAgentAdded(true)
                }
              }
              else {
                if (!agent) {
                  //setShowSetPassword(localStorage.getItem("has_password") == "N");
                }
              }


              //asynchronously see if there are any partially claimed tx in dataset and if so enable claimed tx column
              //checkPartialClaims()

            }
          });
      },
      updateTransactionDetails: (data, id) => {
        const trans_date = moment(new Date(transaction.date)).format("DD/MM/YYYY");

        fetch(`https://${global.api_url}/createTaxTransactions.php?ts=${getTimestamp()}&trans_id=${id}&alloc=1&editing=true&trans_date=${trans_date}&get_matches=Y`, {
          method: "POST",
          headers: { token: user_atom.token, agentId: user_atom.agent },
          body: JSON.stringify(data)
        })
          .then(res => res.json())
          .then(json => {
            Mixpanel.track("update_transaction_details");
            setUpdate(!update)

            function updateNode(node, data) {
              let total = 0
              data.forEach(datum => total += parseFloat(datum.amount))
              node.claimed_amount = total
              gridApi.updateRowData({ node });
            }

            gridApi.forEachNode(node => {
              if (id === node.data.id) {
                updateNode(node.data, data)
              }
            })


          });

      },

      getSimilar: (id) => {
        fetch(`https://${global.api_url}/findSimilar.php?tx_id=${id}&tagged=A&current_year=N&ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: user_atom.token }
        })
          .then(resp => resp.json())
          .then(json => {


            let trans_array = []
            json.similar.forEach(transaction => {
              trans_array.push(transactionsRowData.filter(trans => transaction == trans.id))
            })
            trans_array = trans_array.flat()
            let selected = trans_array.filter(trans => trans.id == id)
            setSelectSimilarRow(selected)

            setTransactionsRowData([...trans_array])

            let datesObject = {}
            tax_years_atom.taxYears.forEach(year => datesObject[year.explain] = [])
            tax_years_atom.taxYears.forEach(year => {
              trans_array.forEach(
                trans => {
                  if (trans.datetime < year.end_date && trans.datetime > year.start_date) {
                    datesObject[year.explain].push(trans)
                  }
                }
              )
            }
            )

            setSimilarTransactionData(datesObject)
          })
      },

      getRecoilTaxYears: (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {

        if (JSON.stringify(tax_years_atom) === "{}") {
          fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
          })
            .then(response => response.json())
            .then(json => {

              if (json.error) {
                if (json.error === "unauthorized") {
                  //alert('redirect to login page');
                  props.history.push("/logout/logout");
                }
                if (json.error === "trial_expired" || json.error === "invalidated" || json.error === "subscription_invalid'") {
                  //alert('redirect to login page');
                  setTrialExpired(true)
                }
              } else {
                let today = new Date();
                let currentYearID = 0;
                let dateFilterStart = "";
                let dateFilterEnd = "";
                json.years.forEach(element => {
                  let start = new Date(element.start_date);
                  start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0)

                  let end = new Date(element.end_date);
                  end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

                  if (today >= start && today <= end) {
                    currentYearID = element.id;
                    dateFilterStart = element.start_date;
                    dateFilterEnd = element.end_date;
                  }
                });
                setTaxYears(true)

                setTaxYearsAtom(
                  {
                    taxYears: json.years,
                    dateFilterStart: dateFilterStart,
                    dateFilterEnd: dateFilterEnd,
                    dateFilter: currentYearID
                  });
                if (!first) {
                  setFirst(true)
                }
              }
            })
        }
        else {
          setTaxYears(true)
          if (!first) {
            setFirst(true)
          }
        };
      },
      getTaxYears: () => {
        let today = new Date();
        let currentYearID = 0;
        let dateFilterStart = "";
        let dateFilterEnd = "";
        tax_years_atom.taxYears.forEach(element => {
          const start = new Date(element.start_date);
          const end = new Date(element.end_date);

          if (today >= start && today <= end) {
            currentYearID = element.id;
            dateFilterStart = element.start_date;
            dateFilterEnd = element.end_date;
          }
        });
        setFilters((filters) => ({ ...filters, dateFilter: currentYearID, dateFilterStart: dateFilterStart, dateFilterEnd: dateFilterEnd }));
        services.actionsService.externalFilterChanged(dateFilterStart, "dateFilterStart");
        services.actionsService.externalFilterChanged(dateFilterEnd, "dateFilterEnd");
        services.actionsService.externalFilterChanged(filters.tagFilter, "tagFilter");
        services.actionsService.externalFilterChanged(filters.moneyFilter, "moneyFilter");
        services.actionsService.externalFilterChanged(transFilters.imported, "imported");
      },

      suppressPasswordPrompt: () => {
        fetch(`https://${global.api_url}/suppressPasswordPrompt.php?ts=${getTimestamp()}`, {
          method: "POST",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        })
          .then(res => res.json())
          .then(json => {

          });
      }

    },
    actionsService: {
      claimSelected: (editing, editedTaxCat, editedTxId) => {

        function updateNode(node) {

          const id = node.id
          let tax_cat = editing ? editedTaxCat : counts.taxCat;

          if (show.multiList) {
            const tax_element = constants.multiLineSelect.find(element => {
              return element.id === tax_cat;
            });
            tax_cat =
              node.type === "CREDIT" ? tax_element.idIn : tax_element.idOut;
            tagname = tax_element.tagname;
          } else {
            const tax_element = taxCats.find(element => {
              return element.id === tax_cat;
            });
            tagname = tax_element.tagname;
          }

          let num = (alloc.split("%")[0] / 100)

          node.claimed = tax_cat === 9999 ? "N" : "Y";
          node.selected = false;
          node.claimed_amount = (Number(node.amount) * num) * (tagReversed ? -1 : 1);

          node.tagname = tax_cat === 9999 ? null : tagname;
          node.tax_date = node.datetime

          gridApi.updateRowData({ node });

          return {
            id,
            tax_cat: tax_cat,
            claimed: node.claimed,
            type: node.type
          }

        }

        let tagname = "";
        let ids = [];

        if (editing) {
          gridApi.forEachNode(node => {

            let id = node.data.id;
            if (id === editedTxId) {
              ids.push(updateNode(node.data))
            }
          })
        } else {
          gridApi.getSelectedRows().forEach(node => {
            ids.push(updateNode(node))
          });
        }

        gridApi.redrawRows();
        gridApi.deselectAll();

        //if we are not editing then call claimTransaction 
        if (!editing) {


          let payload = JSON.stringify({ transactions: ids });

          const percentage = alloc;

          fetch(
            `https://${global.api_url}/claimTransactions.php?ts=${getTimestamp()}&operation=both&percentage=${percentage}&reverse=${tagReversed ? 'Y' : 'N'}`,
            {
              method: "POST",
              headers: { token: user_atom.token, agentId: user_atom.agent },
              body: payload
            }
          )
            .then(res => res.json())
            .then(json => {

              Mixpanel.track("tagged_trans");
              if (json.se_info != null) {
                if (Number(json.se_info.num_before) === 0 && !json.se_info.have_info) {

                  setIncomeInfo({
                    required: true,
                    type: "self-employed business",
                    form: "SA103S",
                    year: json.se_info.tax_year_name
                  });
                }
              }
              if (json.prop_info != null) {
                if (Number(json.prop_info.num_before) === 0 && !json.prop_info.have_info) {
                  setIncomeInfo({
                    required: true,
                    type: "property business",
                    form: "SA105",
                    year: json.prop_info.tax_year_name
                  });
                }
              }
              if (json.emp_info != null) {
                if (Number(json.emp_info.num_before) === 0 && !json.emp_info.have_info) {
                  setIncomeInfo({
                    required: true,
                    type: "employment",
                    form: "SA102",
                    year: json.emp_info.tax_year_name
                  });
                }
              }
              setAlloc("100%");
              setUpdate(!update)
            });
        }
      },
      externalFilterChanged: (newValue, field) => {
        if (field !== "dateFilterEnd" && field !== "dateFilterStart") {
          setFilterChanged(newValue)
        }
        externalFilters[field] = newValue;
      },
      prettyDateTime: (dateToProcess) => {
        let date1 = new Date(dateToProcess);
        let longDate = moment.utc(date1).format('DD/MM/YYYY')
        return longDate
      },
      makeYearLabel: (text, start_date, end_date) => {
        const today = new Date();
        const start = new Date(start_date);
        const end = new Date(end_date);
        if (today >= start && today <= end) {
          return text + " (This Tax Year)";
        }
        let dateOffset = 24 * 60 * 60 * 1000 * 365;
        today.setTime(today.getTime() - dateOffset);
        if (today >= start && today <= end) {
          return text + " (Last Tax Year)";
        }
        return text;
      },
      formatValue: (value, edited, currentItem, prefix = "£") => {
        if (value != null) {
          let suffix = "";
          if (value < 0) {
            prefix = "-" + prefix;
            //suffix = " (refund)";
          }
          if (isNaN(value)) {
            value = 0;
          }
          if (edited) {
            if (value) {
              value = value.trim();
            }
            const regex = /^\d*[]?\d*$/;
            const splitString = value.toString().split(".");
            if (splitString[0].length > 7) value = splitString[0].substring(0, 7);
            if (regex.test(value)) {
              if (!editMode) {
                return prefix +
                  Math.abs(value)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

              } else {
                if (currentItem !== true) {
                  return prefix + Number(value);
                } else {
                  return prefix + value + ".00";
                }
              }
            } else {
              if (splitString[1].length > 2) splitString[1] = splitString[1].substring(0, 2);
              if (!editMode) {
                return (
                  prefix +
                  Math.abs(value)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

              } else {
                if (splitString[1].length === 1) splitString[1] = splitString[1]
              }
              return prefix + splitString[0] + "." + splitString[1];

            }
          } else {
            return (
              prefix +
              Math.abs(value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
              suffix
            );
          }
        } else {
          if (edited) {
            return prefix;
          } else {
            return prefix + "0";
          }
        }
      },
      clearTransId: () => {
        setTransFilters({
          imported: "N",
          trans_ids: [""],
          fromCsvImporter: undefined
        });
        setFlags((flags) => ({ ...flags, clearTransIds: true }));
      },
      submitChangeTag: () => {
        if (counts.taxCat === 0) {
          alert("Please select a tag");
          return false;
        }
        let anyTagged = false;
        gridApi.getSelectedRows().forEach(node => {
          if (node.claimed === "Y") {
            anyTagged = true;
          }
        });

        if (anyTagged === true) {
          setTaggedAlert(true)
        } else {
          services.actionsService.claimSelected();
        }
      },
      submitRemoveTag: () => {
        let anyTagged = false;
        gridApi.getSelectedRows().forEach(node => {
          if (node.claimed === "Y") {
            anyTagged = true;
          }
        });

        if (anyTagged === true) {
          setShow((show) => ({ ...show, bulkRemoveTagAlert: true }));
        }
      },
      bulkRemoveTag: () => {
        var id = 0;

        var ids = [];
        gridApi.getSelectedRows().forEach(node => {
          if (node.claimed === "Y") {
            node.tagname = null;
            node.selected = false;
            node.claimed = "N";
            node.claimed_amount = "0"
            id = node.id;
            node.toUnclaim = "Y";
            ids.push({ id });

            gridApi.updateRowData({ node });
          }
        });

        gridApi.redrawRows();
        gridApi.deselectAll();
        let payload = JSON.stringify({ transactions: ids });
        fetch(
          `https://${global.api_url}/claimTransactions.php?ts=${getTimestamp()}&operation=unclaimOnly`,
          {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: payload
          }
        )
          .then(res => res.json())
          .then(json => {
            Mixpanel.track("bulk_untag_trans");
            setUpdate(!update)
          });
      },
      bulkDiscard: () => {
        let id = 0;
        let ids = [];
        gridApi.getSelectedRows().forEach(node => {
          if (node.claimed === "N") {
            node.discarded = "Y";
            id = node.id;
            ids.push({ id });

            gridApi.updateRowData({ node });
          }
        });

        gridApi.redrawRows();
        gridApi.deselectAll();

        ids.forEach(element => {
          fetch(
            `https://${global.api_url}/discardTransaction.php?ts=${getTimestamp()}&id=${element.id}`,
            {
              method: "GET",
              headers: { token: user_atom.token, agentId: user_atom.agent }
            }
          )
            .then(res => res.json())
            .then(json => {

            });
        });
        Mixpanel.track("bulk_delete_trans");
        gridApi.onFilterChanged()
        setUpdate(!update)
      },
      addDemoBankAccount: () => {

        fetch(
          `https://${global.api_url}/addDemoBankAccount.php?ts=${getTimestamp()}&app=web&all=`,
          {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
          }
        )
          .then(res => res.json())
          .then(json => {

            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {
              // services.fetchService.getTransactions('', true);
              services.fetchService.getTransactions(user_atom.token, user_atom.agent, '', true);
            }
          });
      },
      isDateInTaxYear: (yearString, date) => {
        const yearEnds = yearString.split('-');
        const start = yearEnds[0] + '-04-06';
        const end = yearEnds[1] + '-04-05';

        const sd = new Date(start);
        const ed = new Date(end);
        let txDate = new Date(date);
        txDate.setUTCHours(0); txDate.setMinutes(0); txDate.setSeconds(0);

        return (txDate >= sd && txDate <= ed)
      },
      checkSum: (totalAmount, array) => {
        if (array.length > 0) {
          const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
          return Number(totalAmount) < Number(array.reduce(reducer))
        } else {
          return false;
        }
      },

      processChanges: (val, callApi, targetAction, data, clickedId) => {
        setShow((show) => ({ ...show, [targetAction]: false }));
        if (callApi) {
          if (targetAction === "editAmount") {
            const theIndex = temp.findIndex(((item, index) => index + 1 === clickedId));
            const backUpVal = temp[theIndex];
            temp[theIndex] = Number(val[clickedId - 1].amount).toString();

            if (services.actionsService.checkSum(Math.abs(data.amount), temp)) {
              setShow((show) => ({ ...show, amountError: true }));
              initials.amount[theIndex] = backUpVal;
              services.actionsService.processChanges(val[clickedId - 1], false, "editAmount")
            } else {
              services.fetchService.updateTransactionDetails(val, data.id);
            }

          } else {
            if (targetAction === "addCustomTag") {
              services.fetchService.updateTransactionDetails(val, data);
            } else {
              services.fetchService.updateTransactionDetails(val, data.id);
            }
          }
        } else {
          let newData = transactionData.data;
          const theIndex = newData.findIndex(item => item.id === val.id);
          if (targetAction === "editDescription") {
            newData[theIndex].description = initials.description[theIndex];
          }
          if (targetAction === "editAmount") {
            newData[theIndex].amount = initials.amount[theIndex];
          }
          setTransactionData((transactionData) => ({ ...transactionData, data: newData }));
        }
        editMode = false;
      },
      updateTransactionDataInfo: (val, id, field, percentUpdate = true) => {
        if (field === "description" && val.trim().length === 0) { val = val.trim() };
        if (field === "amount") {

          val = sanitizeInputAmount(val)
          if (percentUpdate && toggleChecked) {
            let v = Math.abs(val.replace(/[^0-9\.]/g, ''))
            let t = Math.abs(transactionData.paramsData.amount)
            if (v < 0) {
              v = v * -1
              t = t * -1
            }
            setAllocated(`${(parseFloat(v / t) * 100).toFixed(2)}%`)
          }
        };
        let newData = transactionData.data;
        const theIndex = newData.findIndex((obj => obj.id === id));

        newData[theIndex][field] = val;
        temp = initials.amount;
        let totalData = { ...transactionData, data: newData }
        setTransactionData(totalData);

      },
      resetAddTransactionsPopoverForm: () => {
        setType('');
        setNote({
          transID: "",
          text: ""
        })
        setAddNote(false)
        let currentDate = new Date();
        let newDate = new Date(Date.parse(filters.dateFilterEnd));
        let date;
        date = currentDate < newDate ? currentDate : newDate;
        date = moment(date).format('YYYY-MM-DD')
        setTransaction((transaction) => ({ ...transaction, amount: '', description: '', date: date }));
      }
    },
    externalFiltersService: {
      setTextFilter: (param) => {
        setFilters((filters) => ({ ...filters, textFilter: param }));
        gridApi.setQuickFilter(param);
        Mixpanel.track("search filter used");
      },

      setDateFilter: (param, route) => {
        const element = tax_years_atom.taxYears.find(element => element.id === param);

        let start_date = element.start_date;
        let end_date = element.end_date;

        setFilters((filters) => ({
          ...filters,
          dateFilter: tax_years_atom.taxYears.filter((item) => (item.id === param))[0].id,
          dateFilterStart: start_date,
          dateFilterEnd: end_date
        }));

        services.actionsService.externalFilterChanged(tax_years_atom.taxYears.find(element => element.id === param).start_date, "dateFilterStart");
        services.actionsService.externalFilterChanged(tax_years_atom.taxYears.find(element => element.id === param).end_date, "dateFilterEnd");
        if (gridApi) gridApi.onFilterChanged();
        Mixpanel.track("date filter used");
      },

      setTagFilter: (param) => {
        setFilters((filters) => ({ ...filters, tagFilter: param }));
        services.actionsService.externalFilterChanged(param, "tagFilter");
        gridApi.onFilterChanged();
        Mixpanel.track("tag filter used");
      },
      setMoneyFilter: (param) => {
        setFilters((filters) => ({ ...filters, moneyFilter: param }));
        services.actionsService.externalFilterChanged(param, "moneyFilter");
        gridApi.onFilterChanged();
        Mixpanel.track("money filter used");
      }
    },
    gridService: {
      onGridReady: (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.onFilterChanged();

        params.columnApi.setColumnVisible('claimed_amount', true)
        params.columnApi.setColumnVisible('tax_date', false)
        params.api.sizeColumnsToFit();

        if (props.location.state === undefined) {
          return;
        } else {
          if (props.location.state.fromCsvImporter !== undefined) {

            if (props.location.state.showClaimDate) {
              params.columnApi.setColumnVisible('tax_date', true)
            }

            services.actionsService.externalFilterChanged("", "dateFilterStart");
            services.actionsService.externalFilterChanged("", "dateFilterEnd");
            services.actionsService.externalFilterChanged("Y", "imported");

            setTransFilters({
              imported: "Y",
              trans_ids: [props.location.state.fromCsvImporter],
              fromCsvImporter: props.location.state.fromCsvImporter,
            });

            params.api.onFilterChanged();


          } else {
            setTransFilters({
              imported: "N",
              trans_ids: [""],
              fromCsvImporter: undefined
            });
          }
        }



      },
      doesExternalFilterPass: (node) => {
        let imported_ids = props.location.state ? props.location.state.fromCsvImporter : "";

        if (node.data.discarded === "Y") {
          return false;
        }

        let filterPass = {
          tagFilterPass:
            externalFilters.tagFilter === "Tag filter" ||
            (externalFilters.tagFilter === "Not tagged" && node.data.tagname === null) ||
            (externalFilters.tagFilter === "All tagged" && node.data.tagname !== null) ||
            node.data.tagname === externalFilters.tagFilter,
          dateFilterPass: (externalFilters.dateFilterStart === "" && externalFilters.dateFilterEnd === "") ||
            (node.data.datetime >= externalFilters.dateFilterStart && node.data.datetime <= externalFilters.dateFilterEnd),
          moneyFilterPass: (externalFilters.moneyFilter !== "Money in" && externalFilters.moneyFilter !== "Money out") ||
            (externalFilters.moneyFilter === "Money in" && node.data.type === "CREDIT") ||
            (externalFilters.moneyFilter === "Money out" && node.data.type === "DEBIT"),
          csvFilterPass: externalFilters.imported !== "Y" || (externalFilters.imported === "Y" && imported_ids.indexOf(parseInt(node.data.id)) > -1),
          descFilterPass: externalFilters.descriptionFilter === "" || externalFilters.descriptionFilter.toUpperCase() === node.data.description.toUpperCase()

        };

        return Object.keys(filterPass).filter(item => !filterPass[item]).length === 0;
      },
      filterChanged: () => {
        gridApi.deselectAll();
        setFlags((flags) => ({ ...flags, gridIsFiltered: Object.keys(gridApi.getFilterModel()).length > 0 }));
        let total = 0;
        setFilterChanged(gridApi.getFilterModel())
        gridApi.forEachNodeAfterFilter(node => {
          if (node.data.type === "CREDIT") {
            total = total + Math.abs(node.data.amount);
          } else {
            total = total - Math.abs(node.data.amount);
          }
        });
        let rowData = [];
        gridApi.forEachNodeAfterFilter(node => rowData.push(node.data));
        setCounts((counts) => ({ ...counts, totalVisible: parseFloat(total).toFixed(2), rowTotal: gridApi.getDisplayedRowCount() }));
      },
      onSelectionChanged: () => {

        let rowCount = gridApi.getSelectedRows().length;
        let showMultiList = false;
        let typeSelected = "";
        if (rowCount > 0) {
          const selectedGridRows = gridApi.getSelectedRows();
          typeSelected = selectedGridRows[0].type;
          selectedGridRows.forEach(element => {
            if (!showMultiList) {
              showMultiList = element.type !== typeSelected;
            }
          });
        }
        setShow((show) => ({ ...show, multiList: showMultiList }))
        setCounts((counts) => ({ ...counts, taxCat: 0, rowCount: rowCount }));
        setTypeSelected(typeSelected);
      }
    },
    renderService: {
      displayFilterControls: (props) => {
        return (
          <React.Fragment>
            <Toolbar className="filters-container">
              <div style={styles.filtersContainer}>
                <div style={styles.toolBar}>
                  <CustomFilter
                    label="Search"
                    style={{ minWidth: "150px" }}
                    value={filters.textFilter}
                    onChange={(e) => services.externalFiltersService.setTextFilter(e.target.value)}
                  />
                </div>

                {transFilters.imported === "N" && <div style={styles.toolBar}>
                  <CustomFilter
                    select
                    label=" "
                    value={filters.tagFilter}
                    onChange={(e) => services.externalFiltersService.setTagFilter(e.target.value)}
                  >
                    {constants.filterBy.map(item => (
                      <MenuItem key={item.tagName} value={item.tagName} >
                        {item.tagName}
                      </MenuItem>
                    ))}
                  </CustomFilter>
                </div>}

                {transFilters.imported === "N" && <div style={styles.toolBar}>
                  <CustomFilter
                    select
                    label=" "
                    value={filters.moneyFilter}
                    onChange={(e) => services.externalFiltersService.setMoneyFilter(e.target.value)}
                  >
                    {constants.moneyInOut.map(item => (
                      <MenuItem key={item.id} value={item.tagName}>
                        {item.tagName}
                      </MenuItem>
                    ))}
                  </CustomFilter>
                </div>}

                {clearFilters && JSON.stringify(similarTransactionData) == "{}" ? <div
                  style={{ display: "flex", width: "100%" }}>
                  <div
                    className="clearFilters"
                    // style={{ marginLeft: "15px"}}
                    onClick={() => {
                      services.externalFiltersService.setTextFilter("")
                      services.externalFiltersService.setMoneyFilter("Money filter")
                      services.externalFiltersService.setTagFilter("Tag filter")
                      setFilterChanged({})
                      setClearFilters(false)
                      if (parsedData) {
                        setParsedData(null)
                        props.match.params.data = {}
                      }
                      gridApi.setFilterModel(null)
                      services.fetchService.getTransactions()
                    }
                    }
                  >
                    Clear filters
                  </div>
                </div> : null}


                {JSON.stringify(similarTransactionData) !== "{}" ?
                  <div
                    style={{ display: "flex", width: "100%" }}>
                    <div
                      className="clearFilters"
                      // style={{ marginLeft: "15px"}}
                      onClick={() => {
                        setSimilarTransactionData({})
                        setSelectSimilarRow(null)
                        setTaxYearsAtom({ ...tax_years_atom, dateFilter: tax_years_atom.taxYears[0].id, dateFilterStart: tax_years_atom.taxYears[0].start_date, dateFilterEnd: tax_years_atom.taxYears[0].end_date })
                        services.externalFiltersService.setTextFilter("")
                        services.externalFiltersService.setMoneyFilter("Money filter")
                        services.externalFiltersService.setTagFilter("Tag filter")
                        setFilterChanged({})
                        setClearFilters(false)
                        gridApi.setFilterModel(null)
                        services.fetchService.getTransactions()
                      }
                      }
                    >
                      Clear filters
                    </div>
                    <div
                      // className = "similar-big"
                      onClick={() => { setSimilarDataTrue(true) }}
                      className="similarTrans"

                    // style={{ marginLeft: "15px"}}
                    >Similar transactions</div>
                  </div>
                  : null}



                {transFilters.imported === "N" && <div>
                  {/* <IonButton
                    className="ion-button-money"
                    onClick={() => setShow((show) => ({ ...show, addTrans: true }))}
                >
                  Add Transaction
                </IonButton> */}
                </div>}

                {props.location.state && props.location.state.showClaimDate && transFilters.imported == "Y" &&
                  <div style={{ paddingRight: 20 }}>
                    <h3>Showing transactions claimed in a year different to the source transaction year</h3>
                  </div>}

                {transFilters.imported === "Y" && <div style={styles.toolBar}>
                  <IonButton
                    className="ion-button-money"
                    onClick={() => {
                      services.actionsService.clearTransId();
                    }}
                  >
                    See all transactions
                  </IonButton>
                </div>}

                <div
                  style={{
                    borderWidth: 0,
                    borderColor: "black",
                    borderStyle: "solid",
                    flex: 1,
                    paddingTop: 10
                  }}
                >
                  {services.renderService.displayTransitionsModal({ history: props.history, onDownload: () => gridApi.exportDataAsCsv({ allColumns: true }) })}
                </div>
              </div>
            </Toolbar>
          </React.Fragment>
        );
      },
      displayChangeTagControls: () => {
        const advisor = localStorage.getItem("agent") 
        return (
          <React.Fragment>
            <Toolbar style={{ flexDirection: "row", paddingLeft: 0, paddingRight: 0 }}>
              <div style={styles.tagSelect}>

                <TagSelector
                  show={showToolBarTagSelector}
                  onDidDismiss={() => setShowToolBarTagSelector(false)}
                  taxCats={show.multiList ? constants.multiLineSelect : taxCats}
                  type={show.multiList ? 'M' : (typeSelected === 'CREDIT' ? 'I' : 'E')}
                  toolbar={true}
                  enableToggle={ advisor!=="" }
                  onClick={(item) => {
                    if (!show.multiList) {
                      const originalType = typeSelected === 'CREDIT' ? 'I' : 'E'
                      const newType = item.type
                      setTagReversed(originalType !== newType)
                      setTypeSelected(newType === 'I' ? 'CREDIT' : 'DEBIT')
                    } else {
                      setTagReversed(false)
                    }

                    setCounts((counts) => ({ ...counts, taxCat: item.id }))
                    setShowToolBarTagSelector(false)
                    setTagConfirm(true)

                  }}
                  dockWith="tbTagSelectButton"
                />

                <div id="tbTagSelectButton" className="filter-container-tag" style={{ color: "white", borderRadius: 5, backgroundColor: "#00338d", paddingLeft: 5, paddingRight: 5, cursor: 'pointer' }}
                  onClick={() => {
                    setShowToolBarTagSelector(true)
                  }}>

                  Select Tag

                </div>
            
                <div className="filter-container">
                  <Button
                    type="submit"
                    style={styles.filterButtons.unTag}
                    onClick={() => {
                      services.actionsService.submitRemoveTag();
                    }}
                  >
                    Untag
                  </Button>
                </div>
                <div className="filter-container">
                  <Button
                    type="submit"
                    style={styles.filterButtons.discard}
                    onClick={() => setShow((show) => ({ ...show, discardAlert: true }))}
                  >
                    Discard
                  </Button>
                </div>
                <div className="filter-container">
                  {`${counts.rowCount} ${counts.rowCount === 1 ? "item" : "items"} = ${services.actionsService.formatValue(counts.totalOut)} Out; ${services.actionsService.formatValue(counts.totalIn)} In`}
                </div>
                <div className="filter-container">
                  <CloseIcon
                    style={styles.filterButtons.close}
                    onClick={() => {
                      gridApi.deselectAll();
                    }}
                  />
                </div>
              </div>
            </Toolbar>
          </React.Fragment>
        );
      },
      displayMainScreen: () => {

        const showSectionIf = {
          initialScreen: first && flags.transactionsFetched,
          addYourFirstAccount: flags.transactionsFetched && transactionsRowData.length === 0 && counts.accountsConnected === 1,
          connectToAccount: flags.transactionsFetched && transactionsRowData.length === 0 && counts.accountsConnected === 1 && Number(plan) !== 2,
          filteredNoResults: transFilters.imported === "N" && flags.transactionsFetched && counts.rowTotal === 0 && !flags.gridIsFiltered
        };

        if (wfhRedirect) {
          let currentYear = tax_years_atom.taxYears.filter(year => year.id == filters.dateFilter)[0].tax_year
          return (
            <Redirect
              to={{
                pathname: "/taxForms",
                state: { year: currentYear, form: "HW1" }
              }}
            />
          )
        }

        if (recoilFetched) {
          if (showSectionIf.addYourFirstAccount) {
            return (
              <IonGrid>
                <IonLoading
                  isOpen={show.loading}
                  onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
                  message={'Please wait...'}
                // duration={500}
                />

                <IonRow style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <IonCol style={{ fontSize: '16px' }}>
                    Welcome to untied, here is a getting started video...
                  </IonCol>
                </IonRow>
                <IonRow style={{ height: "50vh" }}>
                  <IonCol>
                    {/* <div className="connect-account-picture" title="add first account" /> */}
                    <div
                      style={{
                        height: "100%"
                        // padding:"67.5% 0 0 0", 
                        // position:"relative"
                      }}>
                      <iframe
                        src="https://player.vimeo.com/video/641733898?h=5f38a05eeb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;texttrack=en"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          // position:"absolute",
                          // top:0,
                          // left:0,
                          width: "100%",
                          height: "100%",
                        }}
                        title="Welcome to untied Pro in the browser">

                      </iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                  </IonCol>
                </IonRow>
                <IonRow style={{ textAlign: 'center', marginTop: '10px' }}>
                  <IonCol style={{ fontSize: '16px' }}>
                    Why not add your first account? To do so, click on "Connect an account" below or
                    <br />
                    see how to add an account step by step <a className="stay-blue-link" href="https://help.untied.io/article/72-adding-a-bank-or-card-account-to-untied" target='_blank'>here</a>.{" "}
                    Alternatively, you can add your own transactions manually! <a className="stay-blue-link" href="https://help.untied.io/article/27-adding-manual-transaction-to-untied" target="_blank">See how</a>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ alignContent: 'center' }}>
                    <IonButton
                      className="ion-button-inverse"
                      style={{ height: 30, paddingRight: 5 }}
                      onClick={() => {
                        setShow((show) => ({ ...show, loading: true }))
                        services.actionsService.addDemoBankAccount()
                      }}>
                      Try demo account</IonButton>
                    <IonButton
                      className="ion-button-money"
                      style={{ height: 30, paddingLeft: 5 }}
                      // onClick={() => {
                      //  window.location = '/accounts'
                      // }}>Connect an account</IonButton>
                      onClick={() => {
                        setAddAccount(true)
                      }}>Add Account</IonButton>
                  </IonCol>

                </IonRow>
              </IonGrid>)
          }
          else if (showSectionIf.connectToAccount) {
            return (<IonGrid>
              {/* <IonButton
                  className="ion-button-inverse"
                  style={{ height: 30, marginLeft: 'auto', display: "block", width: 'fit-content' }}
                  onClick={() => services.actionsService.addDemoBankAccount()}>
                  Try demo account</IonButton> */}
              <IonRow style={{ textAlign: 'center', marginBottom: '10px' }}>
                <IonCol style={{ fontSize: '16px' }}>
                  No connected accounts
                </IonCol>
              </IonRow>
              <IonRow style={{ textAlign: 'center', marginTop: '10px' }}>
                <IonCol style={{ fontSize: '16px' }}>
                  To connect an account please upgrade to the Pro plan
                </IonCol>

              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    className="ion-button-money"
                    style={{ height: 30, marginLeft: 'auto', marginRight: "auto", display: "block", width: 'fit-content' }}
                    onClick={() => {
                      //window.location = '/profileMain'
                      props.history.push('/plan', { state: "Manage your plan" })
                    }}
                  >
                    Upgrade to Pro
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>)
          }
          else if (showSectionIf.initialScreen) {
            return (<>
              <IonLoading
                isOpen={show.loading}
                onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
                message={'Please wait...'}
              // duration={500}
              />

              <IonAlert
                isOpen={similarMessage.show}
                onDidDismiss={() => setSimilarMessage({
                  show: false,
                  message: "",
                  longMessage: "",
                  buttonText: "OK",
                  buttonHandler: undefined,
                  cancelButton: false
                })}
                header={similarMessage.message}
                message={similarMessage.longMessage}
                buttons={similarMessage.cancelButton ?
                  [{
                    cssClass: 'pointer',
                    text: similarMessage.buttonText,
                    handler: similarMessage.buttonHandler
                  },
                  {
                    cssClass: 'pointer',
                    text: similarMessage.similarText,
                    handler: similarMessage.similarButtonHandler
                  },
                  {
                    cssClass: 'pointer',
                    text: 'Cancel',
                    handler: () => {
                      setSimilarTransactionData({})
                      setSelectSimilarRow(null)
                      setTaxYearsAtom({ ...tax_years_atom, dateFilter: tax_years_atom.taxYears[0].id, dateFilterStart: tax_years_atom.taxYears[0].start_date, dateFilterEnd: tax_years_atom.taxYears[0].end_date })
                      services.fetchService.getTransactions()
                    }
                  },
                  ] : [{
                    cssClass: 'pointer',
                    text: similarMessage.buttonText,
                    handler: similarMessage.buttonHandler
                  }]
                }
              />
              <IonAlert
                isOpen={taggedAlert}
                onDidDismiss={() => setTaggedAlert(false)}
                header={"You are tying to change already tagged items!"}
                message={"You have selected some items that have already been tagged. Are you sure you want to replace those tags with a new tag?"}
                buttons={
                  [
                    {
                      cssClass: 'pointer',
                      text: "Yes, please",
                      handler: () => services.actionsService.claimSelected()
                    },
                    {
                      cssClass: 'pointer',
                      text: "Oops, I made a mistake!",
                      handler: () => {
                        // alert("Don't worry, nothing has changed!");
                        setTaggedAlert(false)
                        gridApi.deselectAll();
                      }
                    }
                  ]

                }

              />

              <IonAlert
                isOpen={insightMessage.show}
                onDidDismiss={() => setInsightMessage({
                  show: false,
                  message: "",
                  longMessage: "",
                  buttonText: "OK",
                  buttonHandler: undefined,
                  cancelButton: false
                })}
                header={insightMessage.message}
                message={insightMessage.longMessage}
                buttons={insightMessage.cancelButton ?
                  [{
                    cssClass: 'pointer',
                    text: insightMessage.buttonText,
                    handler: insightMessage.buttonHandler
                  },
                  {
                    cssClass: 'pointer',
                    text: 'Cancel',
                    handler: undefined
                  },
                  ] : [{
                    cssClass: 'pointer',
                    text: insightMessage.buttonText,
                    handler: insightMessage.buttonHandler
                  }]
                }
              />

              <div
                className={`ag-theme-alpine grid-container-small`}

              >
                <AgGridReact
                  modules={AllCommunityModules}
                  defaultColDef={constants.defaultColDef}
                  rowData={transactionsRowData}
                  columnDefs={constants.columnDefs}
                  pagination={false}
                  animateRows
                  rowHeight={30}
                  isExternalFilterPresent={() => true}
                  doesExternalFilterPass={node => services.gridService.doesExternalFilterPass(node)}
                  rowSelection={"multiple"}
                  rowDeselection={true}
                  suppressRowClickSelection={true}
                  onFilterChanged={() => services.gridService.filterChanged()}
                  onSelectionChanged={() => services.gridService.onSelectionChanged()}
                  rowClassRules={constants.rowClassRules}
                  suppressDragLeaveHidesColumns={true}
                  onGridReady={services.gridService.onGridReady}
                />
              </div>
            </>)
          }
        }
        else {
          return (<>
            <IonLoading
              isOpen={show.loading}
              onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
              message={'Please wait...'}
              duration={2000}
            />
            <div>Loading...</div>
          </>
          )
        }
      },
      displayTransactionPopover: () => {
        if (recoilFetched) {
          return (<>

            <IonModal
              className="my-ion-trans-popover"
              id="popover-100"
              isOpen={show.addTrans}
              onDidDismiss={() => {
                setAttachment([]);
                // setAttachmentName("");
                setAttachments([])
                setAlloc("100%")
                setShow((show) => ({ ...show, addTrans: false }));
              }
              }

            >
              <div>
                <IonAlert
                  isOpen={show.errorBox}
                  onDidDismiss={() => setShow((show) => ({ ...show, errorBox: false }))}
                  header={'Oops'}
                  subHeader={'Information required'}
                  message={errorText}
                  buttons={['OK']}
                />
                <IonAlert
                  isOpen={attachmentError}
                  onDidDismiss={() => setAttachmentError(false)}
                  header={'File too large'}
                  message={'Only able to upload files less than 10MB in size'}
                  buttons={['OK']}
                />
                <IonAlert
                  isOpen={fileFormatError}
                  onDidDismiss={() => setFileFormatError(false)}
                  header={'File type incorrect'}
                  message={'Only able to upload jpg, png, jpeg or pdf files'}
                  buttons={['OK']}
                />


                {incomeExpenseModal()}



                <div style={{ backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "42px", marginBotton: "10px", display: "flex", flexDirection: "row" }}>
                  {/* <div style = {{width: "30%", paddingLeft: "10px", marginTop: "10px", height: "30px",}}> */}
                  <div style={{ width: "30%", paddingTop: "10px", color: "white", fontFamily: "Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px" }}>
                    Add transaction
                    {/* </div> */}

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContents: "center", paddingRight: "10px", marginLeft: "auto" }}>

                    <div style={{ alignSelf: "center" }}>

                      <CloseIcon
                        style={styles.filterButtons.closeWhite}
                        onClick={() => {
                          setAttachment([]);
                          // setAttachmentName("");
                          setShow((show) => ({ ...show, addTrans: false }));
                        }
                        }
                      /> </div>
                  </div>
                </div>



                <div style={{ height: 'fit-content', maxHeight: "70vh", overflowY: "scroll" }}>
                  <IonGrid fixed={true} style={{}}>
                    <div style={{ background: "#F4F6F6", borderRadius: 5, margin: "5px", marginBottom: 0, paddingBottom: 5, width: "98%" }}>


                      <IonRow>
                        <IonCol style={{ marginTop: "auto" }}>
                          <IonItem
                            // lines = "full"
                            className="grey-input"
                          // style = {{ "--padding": 0, marginRight: 5}}
                          >
                            <IonLabel position="floating" className="custom-tag-style"><h2>Transaction type</h2></IonLabel>
                            <IonInput
                              value={type ? taxTypes.filter(t => t.value == type)[0].type : "Choose a transaction type"} readonly
                              onClick={(e) => { setShowIncomeExpense(true) }}

                              // className="my-tax-selector-input left-indent"
                              className="custom-filter-payments"
                              style={{ paddingLeft: 0, }} >
                              <div className="my-caret-dropdown-icon" />
                            </IonInput>
                          </IonItem>
                        </IonCol>

                        <IonCol
                        // className="ion-align-self-right" style = {{paddingBottom: 0}} 
                        >
                          <IonItem className="grey-input" >
                            <IonLabel position="floating" className="custom-tag-style" style={{ paddingLeft: 2, }}><h2>Tag transaction as</h2></IonLabel>
                            <IonInput style={{ paddingLeft: 2, marginTop: 3 }} disabled={type.length === 0} value={taxCats.find(el => el.id === cat) ? taxCats.find(el => el.id === cat).title : "Choose a tax tag"} readonly onClick={() => {
                              setShowTagSelector({ show: true, id: "" })
                              setTagClickFunction("filterbar")
                            }}
                              className="my-tax-selector-input left-indent">
                              <div className="my-caret-dropdown-icon" />
                            </IonInput>
                          </IonItem>
                        </IonCol>

                      </IonRow>
                      <IonRow >


                        <IonCol className="ion-align-self-right" style={{ marginTop: "auto" }}>
                          <IonItem className="grey-input" lines="none">
                            <IonLabel position="stacked" style={{ marginBottom: 8, paddingLeft: 2 }}><h2>Amount</h2></IonLabel>
                            <IonItem
                              className="grey-input"
                              id="add-amount"
                            // style = {{height: "100%"}}
                            >
                              <IonInput
                                // className = "money-input"
                                // className="my-tax-selector-input left-indent"
                                value={transaction.amount ? `£${transaction.amount}` : null}
                                placeholder="Amount"
                                inputmode="text"
                                type="text"
                                // className = "left-indent"
                                className="my-tax-selector-input left-indent"
                                style={{ margin: "8px 0px, 0px" }}
                                onIonChange={e => {
                                  addTransactionAmount(e.detail.value)
                                }}
                                onIonBlur={e => {

                                  if (transaction.amount) {
                                    let amount = transaction.amount.replace(/[^0-9\.]/g, '');

                                    amount = transaction.currency_symbol + Math.abs(parseFloat(amount))
                                      .toFixed(2)
                                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

                                    setTransaction({ ...transaction, amount })
                                  }

                                }}
                              />
                            </IonItem>
                          </IonItem>
                        </IonCol>


                        {/* <IonCol style = {{"padding-inline-start": 5}}>
                     */}
                        <IonCol className="ion-align-self-right" >
                          <IonItem className="grey-input" lines="none">
                            <IonLabel position="stacked" style={{ marginBottom: 8, paddingLeft: 2 }}><h2>Transaction date</h2></IonLabel>
                            <IonItem className="grey-input">

                              <input
                                type="date"
                                value={transaction.date}
                                style={{ border: "none", width: "100%", height: "100%", font: "400 14px Arial", backgroundColor: "#F4F6F6", margin: "12px 0px 0px" }}
                                onChange={e => {
                                  setTransaction({
                                    type: transaction.type,
                                    description: transaction.description,
                                    cat: transaction.cat,
                                    date: e.target.value,
                                    fetched: transaction.fetched,
                                    amount: transaction.amount
                                  })
                                }}

                              />

                            </IonItem>
                          </IonItem>
                        </IonCol>


                      </IonRow>

                      <IonRow style={{ marginBottom: "5px" }}>

                        <IonCol>
                          <IonItem
                            className="grey-input "
                            id="add-description"
                            lines="none"
                          >
                            <IonLabel
                              position="stacked" style={{ paddingLeft: 2, paddingBottom: 8 }}
                            ><h2>Description</h2></IonLabel>

                            <IonItem className="grey-input" >
                              <IonInput
                                value={transaction.description}
                                placeholder="Description"
                                inputmode="text"
                                type="text"
                                style={{ margin: "8px 0px, 0px" }}
                                // position = "bottom"
                                className="left-indent"
                                onIonChange={e => {
                                  setTransaction({
                                    type: transaction.type,
                                    description: e.detail.value,
                                    cat: transaction.cat,
                                    date: transaction.date,
                                    fetched: transaction.fetched,
                                    amount: transaction.amount
                                  })
                                }}
                              />
                            </IonItem >
                          </IonItem >
                        </IonCol>
                      </IonRow>
                      <IonRow style={{ marginBottom: "5px" }}>
                        <IonCol>
                          <IonLabel style={{
                            display: "block",
                            // color: "rgba(0, 0, 0, 0.38)", 
                            color: addNote ? "rgba(255, 0, 0, 0.6)" : "rgba(0, 0, 255, 0.6)",
                            fontSize: 12,
                            width: "100%",
                            padding: 0,
                            textAlign: "left",
                            textDecoration: "underline",
                            cursor: "pointer"
                          }}
                            onClick={() => {
                              setAddNote(!addNote)
                              if (addNote) {
                                setNote({
                                  transId: "",
                                  text: ""
                                })
                              }

                            }}
                          > {addNote ? "Remove note" : "Add note"}</IonLabel>
                          {addNote &&
                            <IonCol>
                              <IonItem
                                className="grey-input "
                                id="add-description"
                                lines="none"
                              >
                                <IonItem className="grey-input" >
                                  <IonTextarea
                                    style={{ "marginLeft": "5px", "--padding-top": "0px" }}
                                    value={note.text}
                                    onIonChange={(e) => {
                                      setNote({
                                        ...note,
                                        text: e.target.value
                                      })
                                    }}
                                  />
                                </IonItem >
                              </IonItem >
                            </IonCol>
                          }
                        </IonCol>
                      </IonRow>

                    </div>

                    <IonRow >
                      <IonCol >
                        <p style={{ textAlign: "center", margin: 5, marginBottom: 10 }}>Add evidence here</p>
                        {show.addTrans && <DropFile
                          accept=".jpg, .png, .jpeg, .pdf"
                          label=""
                          multiple={true}
                          fileFormatError={fileFormatError}
                          handleUpload={handleUpload}
                          clearFiles={clearFiles}
                          setAttachments={setAttachments}
                          maxFileSizeInBytes={10485760}
                        />}
                        {/* <p style={{textAlign: "center", marginTop: 0, fontSize: 10}}>Files must be .jpg, .png, .jpeg or .pdf format. Max file size: 2MB</p> */}
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                </div>

                <IonGrid fixed={true} style={{ "--ion-grid-width-md": '700px', "--ion-grid-width-lg": '700px', "--ion-grid-width-xl": '700px' }}>

                  <IonRow>

                    <IonCol style={{ textAlign: 'right', paddingBottom: 0, paddingTop: 0 }}>
                      <IonButton
                        className="ion-button-inverse"
                        // className="reset-form-button" 
                        onClick={() => services.actionsService.resetAddTransactionsPopoverForm()}>
                        Reset form
                      </IonButton>
                    </IonCol>


                    <IonCol style={{ textAlign: 'center', paddingBottom: 0, paddingTop: 0 }}>
                      <IonButton className="ion-button-inverse"
                        style={{ "paddingRight": "5px", width: "140px" }}
                        onClick={() => setShow((show) => ({ ...show, addTrans: false }))}
                      >
                        Cancel
                      </IonButton>
                    </IonCol>



                    <IonCol style={{ textAlign: 'left', paddingBottom: 0, paddingTop: 0 }}>
                      <IonButton className="ion-button-money" id="add-transaction"
                        style={{ "paddingLeft": "5px", width: "140px" }}
                        onClick={() => {
                          setShow((show) => ({ ...show, loading: true }));
                          services.fetchService.addTransaction()
                        }}>
                        {/* Add {transaction.type === 'I' ? "Income" : "Expense"} */}
                        Save
                      </IonButton>
                    </IonCol>
                  </IonRow>


                  {/* <IonRow >
                    <IonCol style={{ paddingBottom: 0, paddingTop: 0}}>
                      <IonButton className="reset-form-button" onClick={() => services.actionsService.resetAddTransactionsPopoverForm()}>
                        Reset form
                      </IonButton>
                    </IonCol>
                  </IonRow> */}
                </IonGrid>

              </div>

              <IonLoading
                isOpen={show.loading}
                onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
                message={'Please wait...'}
                duration={5000}
              />

            </IonModal>



          </>)
        }
      },
      displayFooter: () => {
        return (
          <Footer />
        );
      },
      popUpRedesign: () => {
        let dataTotal = 0
        if (transactionData.data.length == 0) {
          return (null)
        }
        else {
          transactionData.data.forEach(d => {
            if (d.amount) {
              dataTotal += parseFloat(d.amount)
            }
            else {
              dataTotal += 0
            }
          })
        }
        return (<>
          <IonAlert
            isOpen={claimedError}
            onDidDismiss={() => setClaimedError(false)}
            header={"Claimed total too high"}
            message={"You cannot claim more than the transaction total."}
            buttons={["OK"]}
          />
          <IonModal
            className="my-ion-trans-popover"
            isOpen={transactionData.show}
            onDidDismiss={() => {

              setTransactionData({
                show: false,
                data: [],
                paramsData: {}
              });
              // setReceipt(null);
              setAttachment([]);
              setAttachments([]);
              setAttachmentNames([])
              // setAttachmentName("");
              initials = {
                description: [],
                amount: []
              };
              setNote({
                transId: "",
                text: ""
              })
              setToggleChecked(true)
              setAllocatedArray([])
              setToggleArray([])
              setAllocated("100%")
              setType("")
              setAddNote(false)
              setAlloc("100%")
              setShow((show) => ({ ...show, editDescription: false, editAmount: false }));
              const obj = document.getElementById("uploader")
              if (obj != null) obj.value = "";
            }
            }
          >

            <IonLoading
              isOpen={show.loading}
              onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
              message={'Please wait...'}
            // duration={2000}
            />
            <IonAlert
              isOpen={attachmentError}
              onDidDismiss={() => setAttachmentError(false)}
              header={'File too large'}
              message={'Only able to upload files less than 10MB in size'}
              buttons={['OK']}
            />
            <IonAlert
              isOpen={fileFormatError}
              onDidDismiss={() => setFileFormatError(false)}
              header={'File type incorrect'}
              message={'Only able to upload jpg or png files'}
              buttons={['OK']}
            />
            <IonAlert
              isOpen={show.amountError}
              onDidDismiss={() => setShow((show) => ({ ...show, amountError: false }))}
              header={'Oops!'}
              message={'Sum of amounts is greater than total.'}
              buttons={['OK']}
            />

            <div style={{ backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "42px", display: "flex", flexDirection: "row" }}>
              <div style={{ paddingTop: "10px", color: "white", fontFamily: "Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px" }}>
                {transactionData.paramsData.type == "DEBIT" ? "Expense transaction" : "Income transaction"}
              </div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContents: "center", paddingRight: "10px", marginLeft: "auto" }}>

                <div style={{ alignSelf: "flex-end" }}>
                  <CloseIcon
                    style={styles.filterButtons.closeWhite}
                    onClick={() => {

                      setTransactionData({
                        show: false,
                        data: [],
                        paramsData: {}
                      });
                      setAllocated("100%")
                      setAllocatedArray([])
                      setToggleArray([])
                      setToggleChecked(true)
                    }}
                  /> </div>
              </div>
            </div>
            <div style={{ height: 'fit-content', maxHeight: "70vh", overflowY: "scroll" }}>
              <IonGrid fixed={true} style={{ textAlign: "center", paddingBottom: 10 }}>

                <div style={{ background: "#F4F6F6", borderRadius: 5, marginBottom: 10 }}>

                  <IonRow>
                    <IonCol>
                      <IonLabel style={styles.labelLeft}>Description</IonLabel>

                      <IonRow style={{ display: "block", width: "100%", padding: 0, textAlign: "left" }}>
                        {transactionData.paramsData.description}
                      </IonRow>

                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonLabel style={{
                        display: "block",
                        // color: "rgba(0, 0, 0, 0.38)", 
                        color: addNote ? "rgba(255, 0, 0, 0.6)" : "rgba(0, 0, 255, 0.6)",
                        fontSize: 12,
                        width: "100%",
                        padding: 0,
                        textAlign: "left",
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                        onClick={() => {
                          setAddNote(!addNote)
                          if (addNote) {
                            setNote({
                              transId: transactionData.paramsData.id,
                              text: ""
                            })
                          }

                        }}
                      > {addNote ? "Remove note" : "Add note"}</IonLabel>
                      {addNote &&
                        <IonItem className="grey-input" style={{ "--padding-start": "0px", "--padding-end": "0px", width: "100%" }}>
                          <IonTextarea
                            style={{ "marginLeft": "5px", "--padding-top": "0px" }}

                            value={note.text}
                            onIonChange={(e) => {
                              setNote({
                                ...note,
                                text: e.target.value
                              })
                            }}
                          />
                        </IonItem>}
                    </IonCol>

                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonLabel style={styles.labelLeft}>Account</IonLabel>

                      <IonRow style={{ display: "block", width: "100%", padding: 0, textAlign: "left" }}>
                        {transactionData.paramsData.ac_name}
                      </IonRow>

                    </IonCol>
                    <IonCol>
                      <IonLabel style={styles.labelLeft}>Transaction Date</IonLabel>
                      <IonRow style={{ display: "block", width: "100%", padding: 0, textAlign: "left" }}>
                        {services.actionsService.prettyDateTime(transactionData.paramsData.datetime)}
                      </IonRow>

                    </IonCol>
                    <IonCol>
                      <IonLabel style={styles.labelLeft}>Amount</IonLabel>
                      <IonRow style={{ display: "block", width: "100%", padding: 0, textAlign: "left" }}>
                        {services.actionsService.formatValue(Math.abs(transactionData.paramsData.amount), false, false, transactionData.paramsData.currency_symbol)}
                      </IonRow>

                    </IonCol>
                  </IonRow>



                  <IonRow>
                    <IonCol>
                      <p style={{ marginTop: 0, marginBottom: 0, fontSize: 14 }}>Note: changing the description and amount here changes what is claimed on your tax return but does not affect how the transaction appears in your bank feed.</p>
                    </IonCol>
                  </IonRow>
                </div>

                {/* (Math.abs(transactionData.paramsData.claimed_amount) < Math.abs(transactionData.paramsData.amount) ||  ) */}
                {transactionData.data
                  && allocatedArray.length > 0
                  && (dataTotal < Math.abs(transactionData.paramsData.amount)) ? <IonRow style={{ height: 0 }}
                  >
                  <IonCol style={{ height: 0 }}>
                    <AddIcon
                      style={styles.filterButtons.addBlack}
                      onClick={() => {
                        splitTrans(transactionData.data)
                      }}
                    />
                  </IonCol>

                </IonRow> : null}

                {transactionData.data && transactionData.show && transactionData.data.map((trans, index) => {
                  let transId = trans.id
                  return (
                    <div key={index} style={{ background: "#F4F6F6", borderRadius: 5, marginBottom: 10 }}>
                      {/* <div key={index} style={{background: "#F4F6F6", borderRadius: 5, boxShadow: "2px 2px 3px #BBBBBB", marginBottom: 10}}> */}
                      {index > 0 && <IonRow
                        style={{ flexDirection: "row" }}>
                        <IonCol style={{ textAlign: "right" }}>
                          <DeleteIcon
                            style={styles.filterButtons.smallBlack}
                            onClick={() => {
                              removeTrans(trans, index)
                            }}
                          />
                        </IonCol>

                      </IonRow>}
                      <IonRow
                        style={{ width: "95%", }}>
                        <IonCol>
                          <IonLabel style={styles.labelLeftBlack}>Description</IonLabel>
                          <IonItem
                            // className = "white-input" 
                            className="grey-input"
                            style={{
                              width: "100%",
                              // "--border-width": "1px",
                            }}>
                            <IonInput
                              style={{ "marginLeft": "5px" }}
                              // className = "custom-ion-native"
                              value={trans.description}
                              onIonChange={(e) => {
                                clickedId = trans.id;
                                services.actionsService.updateTransactionDataInfo(e.target.value, clickedId, "description")
                              }}
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol>
                          <IonLabel style={styles.labelLeftBlack}>Tag transaction as</IonLabel>
                          <IonItem
                            // className="custom-ion-native" 
                            className="grey-input"
                            style={{
                              width: "100%",
                              // "--border-width": "1px",
                            }}>
                            <IonInput
                              style={{ "marginLeft": "5px", textOverflow: "ellipsis", "--padding-start": "5px !important", }}
                              placeholder="Choose a tax tag"
                              disabled={type.length === 0}
                              value={trans.category !== "0" ? taxCats.filter(cat => cat.id === trans.category)[0].title : null}
                              readonly
                              onClick={() => {
                                setTagClickFunction('editTransaction')
                                setShowTagSelector({ show: true, id: trans.id })
                                // setShowTagSelector(true)
                              }}>
                              <div className="my-caret-dropdown-icon" />
                            </IonInput>
                          </IonItem>
                        </IonCol>

                        <IonCol size="1">
                          <IonLabel style={styles.labelTop}>{toggleArray[index] == true ? transactionData.paramsData.currency_symbol : "%"}</IonLabel>

                          <IonToggle
                            style={{ "--background": "#C0C0C0", "--background-checked": "#C0C0C0", "--handle-background-checked": "white" }}
                            checked={toggleArray[index]}
                            onIonChange={(e) => {
                              let toggleClone = [...toggleArray]
                              toggleClone[index] = e.detail.checked
                              setToggleArray(toggleClone)
                            }}

                          />
                        </IonCol>
                        {!toggleArray[index] && allocatedArray[index] &&
                          <IonCol style={{ "--ion-width": "800px" }} >

                            <IonLabel style={styles.labelLeftBlack}>Partial claim by percentage</IonLabel>
                            <IonItem
                              className="grey-input"
                              style={{
                                "--padding-start": "5px !important",
                                //  "--border-width": "1px",
                                margin: 0,
                                width: "100%"
                              }}
                            >
                              <IonInput
                                className="custom-filter-payments"
                                // value={allocated}
                                value={allocatedArray[index].percent}
                                // placeholder="100%"
                                style={{ paddingLeft: 5, "--padding-start": "5px" }}
                                inputmode="text"
                                type="text"
                                onIonChange={(event) => {
                                  const value = event.target.value;
                                  let newVal = value;
                                  let lastChar = value[value.length - 1];
                                  if (value !== "") {
                                    let v = value.replace("%", "");
                                    if (!isNaN(Number.parseFloat(v)) && isFinite(Number.parseFloat(v))) {
                                      let num = (v / 100) * Math.abs(transactionData.paramsData.amount)
                                      setAllocAmount(num.toFixed(2))
                                      if (Number(v) < 101) {
                                        let newTotal = Math.abs(transactionData.paramsData.amount) * (v / 100)

                                        newTotal = newTotal.toString().replace(/[^0-9\.]/g, '');
                                        newTotal = transactionData.paramsData.currency_symbol + Math.abs(parseFloat(newTotal))
                                          .toFixed(2)
                                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

                                        services.actionsService.updateTransactionDataInfo(newTotal, trans.id, "amount", false)
                                        if (lastChar === "%") {
                                          newVal = v + "%";
                                        }
                                      } else {
                                        newVal = "100%";
                                      }
                                    } else {
                                      newVal = "100%";
                                    }
                                  }
                                  let allocate = [...allocatedArray]
                                  allocate[index].percent = newVal
                                  setAllocatedArray(allocate);
                                }}
                              />
                            </IonItem>
                          </IonCol>
                        }
                        {toggleArray[index] &&
                          <IonCol
                            style={{ "--ion-width": "800px" }}
                          >
                            <IonLabel style={styles.labelLeftBlack}>Partial claim by amount</IonLabel>
                            <IonItem
                              className="grey-input"
                              style={{
                                "--padding-start": "5px !important",
                                //  "--border-width": "1px",
                                margin: 0,
                                width: "100%"
                              }}
                            >

                              <IonInput
                                style={{ "marginLeft": "5px" }}
                                // value={services.actionsService.formatValue(trans.amount, true, index+1 !== clickedId, transactionData.paramsData.currency_symbol)}
                                value={`${transactionData.paramsData.currency_symbol}${trans.amount}`}
                                onIonChange={(e) => {
                                  clickedId = trans.id;
                                  services.actionsService.updateTransactionDataInfo(e.target.value, clickedId, "amount")
                                }}
                                onIonBlur={e => {
                                  let amount = trans.amount.replace(/[^0-9\.]/g, '');
                                  let p = parseFloat(amount) / Math.abs(transactionData.paramsData.amount)
                                  p = (p * 100).toFixed(2) + "%"
                                  let allocate = allocatedArray
                                  allocate[index].percent = p
                                  setAllocatedArray(allocate);
                                  amount = transactionData.paramsData.currency_symbol + Math.abs(parseFloat(amount))
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                  services.actionsService.updateTransactionDataInfo(amount, trans.id, "amount")
                                }}
                                id="amount_claim"
                              />
                            </IonItem>


                          </IonCol>
                        }
                      </IonRow>
                    </div>)
                })}
                <IonRow>
                  {receipts && receipts.map((receipt, index) => {

                    const friendly_name = receipt.friendly_name == null ? "attachment " + index : receipt.friendly_name
                    const url = `https://${global.api_url}/downloadReceipt.php?ts=${getTimestamp()}&name=${receipt.name}&request=${receipt.token}`;
                    let filetype = friendly_name.substr(friendly_name.length - 3).toLowerCase()
                    return (

                      <IonRow>
                        <IonCol>

                          <a href={url} target="_new"><img style={filetype == 'pdf' ? { height: "60px", width: "50px" } : { height: "60px", width: "60px" }} src={filetype == 'pdf' ? require("../images/PDF_file_icon.png").default : url} alt={friendly_name} title={friendly_name} /></a> <DeleteIcon style={{ cursor: "pointer" }} onClick={() => deleteReceipt(receipt.id, transactionData.paramsData.id)} />
                        </IonCol>
                      </IonRow>

                    )
                  })}
                </IonRow>
                <IonRow style={{ paddingBottom: 10 }}>
                  <IonCol
                    // size="9" 
                    style={{ "--ion-grid-column-padding": "0px" }}>
                    {transactionData.show && <DropFile
                      accept=".jpg, .png, .jpeg, .pdf, .gif"
                      label=""
                      multiple={true}
                      fileFormatError={fileFormatError}
                      handleUpload={handleUpload}
                      clearFiles={clearFiles}
                      setAttachments={setAttachments}
                      maxFileSizeInBytes={10485760}
                    />}


                    <input
                      id="uploader"
                      className="csv-input"
                      style={{
                        display: "none",
                        marginLeft: 90
                      }}
                      type="file"
                      name="file"
                      placeholder={null}
                      onChange={handleUpload}
                    />
                  </IonCol>

                </IonRow>
              </IonGrid>
            </div>
            <div style={{ textAlign: "center", height: 50 }}>
              <IonButton
                className="ion-button-money"
                id="add-transaction"
                style={{ paddingLeft: "5px", width: "140px" }}
                onClick={() => {
                  let total = 0
                  transactionData.data.forEach(d => total += parseFloat(d.amount))

                  if (total <= Math.abs(transactionData.paramsData.amount)) {
                    saveEditData()
                    transactionData.show = false
                    setToggleChecked(true)
                    if (attachmentsPresent) {
                      uploadReceipt(transactionData.paramsData.id)
                    }
                    saveTransactionInfo()
                    setAllocated("100%")
                  }
                  else {
                    setClaimedError(true)
                  }
                }}
              >
                Save
              </IonButton>

            </div>

          </IonModal>
        </>)
      },
      displayTransitionsModal: (props) => {
        return (
          <div
            style={{
              /* width: 120, */
              borderColor: "black",
              float: "right",
              textAlign: "right",
              paddingRight: 2
            }}
          >

            <IconButton
              style={{ padding: 6 }}
              // aria-owns={show.txMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                setShow((show) => ({ ...show, txMenuOpen: true, menuTarget: event.currentTarget }));
                Mixpanel.track("transaction menu clicked");
              }}
              color="inherit"
            >
              <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: '#00338d' }} height="32" viewBox="0 0 24 24" width="32"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
            </IconButton>

            <Menu
              anchorEl={show.menuTarget}
              open={Boolean(show.menuTarget)}
              onClose={() => setShow({ ...show, menuTarget: undefined })}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => {
                setType("E")
                setShow((show) => ({ ...show, addTrans: true, menuTarget: undefined }));
                services.actionsService.resetAddTransactionsPopoverForm();
              }}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: '#00338d' }}
                  height="24"
                  viewBox="0 0 24 24"
                  width="24">
                  <path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
                </svg>&nbsp;Add transaction
              </MenuItem>

              <MenuItem onClick={() => {
                setShow((show) => ({ ...show, showClassification: !show.showClassification, menuTarget: undefined }))
              }}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: '#00338d' }}
                  height="24"
                  viewBox="0 0 24 24"
                  width="24">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3.5 18.99l11 .01c.67 0 1.27-.33 1.63-.84L20.5 12l-4.37-6.16c-.36-.51-.96-.84-1.63-.84l-11 .01L8.34 12 3.5 18.99z" /></svg>
                </svg>&nbsp;{show.showClassification ? "Hide classification column" : "Show classification column"}
              </MenuItem>
              <MenuItem onClick={() => {
                setShow((show) => ({ ...show, showNotesColumn: !show.showNotesColumn, menuTarget: undefined }))
              }}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: '#00338d' }}
                  height="24"
                  viewBox="0 0 24 24"
                  width="24">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="none"><path d="M0 0h24v24H0z" fill="none" /><path fill="rgb(1, 33, 122)" d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z" /></svg>              </svg>
                &nbsp;{show.showNotesColumn ? "Hide notes column" : "Show notes column"}
              </MenuItem>


              <MenuItem

                onClick={() => {
                  setShow({ ...show, menuTarget: undefined })
                  props.onDownload();
                }}>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ fill: '#00338d' }}
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                </svg>&nbsp;Download filtered transactions
              </MenuItem>
              <MenuItem

                onClick={e => {
                  setShow({ ...show, menuTarget: undefined })
                  props.history.push("/ManualTransactionsImporter");
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  style={{ fill: '#00338d' }}
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                </svg>&nbsp;Import transactions
              </MenuItem>
            </Menu>


            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className="transactions-page-modal-container"
              open={show.open}
              onClose={() => setShow((show) => ({ ...show, open: false }))}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={show.open}>
                <div className="transactions-page-modal-content">
                  <h2 id="transition-modal-title" style={{ color: "#14A7CC" }}>
                    About this Web App
                  </h2>
                  <p>
                    This Web App is designed to accompany the untied mobile app which
                    offers more insights, easier wizards and
                    additional features such as journey logging.
                  </p>

                  <p>
                    Please, visit either the "Google Play Store" or the "App Store"
                    to download the Mobile App onto your device.
                  </p>

                  <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
                    <img
                      style={{
                        width: 200,
                        paddingLeft: -40,
                        paddingTop: 20
                      }}
                      alt="untied logo"
                      src={require("../images/play-300x116.png").default}
                      href="https://help.untied.io/"
                    />
                  </a>
                  <a href="https://apps.apple.com/gb/app/untied/id1458630284">
                    <img
                      style={{
                        width: 200,
                        paddingLeft: -40,
                        paddingTop: 20
                      }}
                      alt="untied logo"
                      src={require("../images/apple-app-store-badge-300x116.png").default}
                    />
                  </a>
                  <h3 style={{ color: "#14A7CC" }}>How to use this Web App</h3>
                  <p>
                    This App works better on Chrome, Firefox or Safari. Edge works in
                    a slightly different way and we cannot guarantee the user
                    experience, unless you upgrade to the new version of Edge.
                  </p>
                  <p>
                    You can change the width of the columns and re-arrange them (NB:
                    they will return to default when the page is reloaded).
                  </p>
                  <p>
                    You can search all your transactions within a set tax year in the
                    main toolbar, or you can search in the top of the column headers.
                  </p>
                  <a
                    style={{ color: "black" }}
                    href="https://help.untied.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get help
                  </a>
                </div>
              </Fade>
            </Modal>
          </div>
        );
      },
      displayAlerts: () => {
        return (<>
          <IonAlert
            isOpen={incomeInfo.required}

            onDidDismiss={() => setIncomeInfo((incomeInfo) => ({ ...incomeInfo, required: false }))}
            header={"Way to go!"}
            message={"You've just tagged your first " + incomeInfo.type + " transaction for " + incomeInfo.year + ". " +
              "To accurately calculate your tax we need a few details about your " + incomeInfo.type + ". Would you like to do this now?"}
            buttons={[
              {
                text: 'No, not yet',
              },
              {
                text: 'Yes, please',
                handler: () => {
                  //this.saveRule();
                  setFlags({
                    redirectForms: true,
                    transactionsFetched: flags.transactionsFetched
                  })
                }
              }
            ]}
          />


          <IonAlert
            isOpen={show.bulkRemoveTagAlert}
            onDidDismiss={() => setShow((show) => ({ ...show, bulkRemoveTagAlert: false }))}
            header={"You are about to untag"}
            message={"Are you sure you want to untag the selected items?"}
            buttons={[
              {
                text: 'No',
                handler: () => {
                  gridApi.deselectAll();
                }
              },
              {
                text: 'Yes, please',
                handler: () => {
                  //this.saveRule();
                  services.actionsService.bulkRemoveTag()
                }
              }
            ]}
          />



          <IonPopover

            isOpen={similarDataTrue}
            onDidDismiss={() => {
              // setSimilarTransactionData({})
              // setSelectSimilarRow(null)
              setSimilarDataTrue(false)
            }}
          >
            <h2 style={{ paddingLeft: "16px", marginBottom: "0px" }}>Similar transactions</h2>
            {similarData()}
            <div
              style={{
                color: "#008ae6",
                margin: "10px 16px 16px 16px",
                cursor: "pointer"
              }}
              onClick={() => {
                setSimilarMessage({
                  show: true,
                  message: "",
                  longMessage: "This transaction is similar to other tagged transactions. Do you want to select a tag?",
                  buttonText: "Select tag",
                  buttonHandler: () => {
                    Mixpanel.track("clicked_similar_action");
                    gridApi.forEachNode(node => {
                      if (node.data == selectSimilarRow[0]) {
                        if (node.data.datetime > tax_years_atom.dateFilterStart && node.data.datetime < tax_years_atom.dateFilterEnd) {
                          node.setSelected(true)
                        }
                      }
                    })
                  },
                  similarText: "View similar transactions",
                  similarButtonHandler: () => { Mixpanel.track("clicked_view_similar_action"); services.fetchService.getSimilar(selectSimilarRow[0].id); },
                  cancelButton: true
                })
                // setSimilarTransactionData({})
                // setSelectSimilarRow(null)
                setSimilarDataTrue(false)
              }}
            >CLOSE</div>
          </IonPopover>

          <IonAlert
            isOpen={show.discardAlert}
            onDidDismiss={() => setShow((show) => ({ ...show, discardAlert: false }))}
            header={"You are about to discard!"}
            message={"Only items that are not tagged will be discarded. At present the only way to restore discarded transactions is via the mobile app."}
            buttons={[
              {
                text: 'Cancel',
                handler: () => {
                  gridApi.deselectAll();
                }
              },
              {
                text: 'Continue',
                handler: () => {
                  //this.saveRule();
                  services.actionsService.bulkDiscard();
                }
              },

              {
                text: "Untag and discard",
                handler: () => {
                  untagAndDiscard()
                }
              },

            ]}
          />
{/* 
          <IonModal
            isOpen={agentAdded}
            backdropDismiss={false}
            onDidDismiss={() => {

              setAgentAdded(null)
            }
            }
            className="set-password-modal"
          >
            <div style={{ paddingLeft: 15, paddingRight: 15, height: 400, overflow: 'auto' }}>

              <h3 style={{ color: '#00338d', fontWeight: 400, fontSize: 21 }}>Welcome to untied</h3>

              <p>You have accepted your adviser’s invitation to manage your untied account for you.</p>

              <p>Please complete your <a href="" onClick={() => { localStorage.removeItem("agent_code"); props.history.push('/profileMain') }}>profile</a> on this page.</p>

              <p>Thank you</p>

              <IonGrid>
                <IonRow>
                  <IonButton
                    className="ion-button-money"
                    onClick={() => {
                      localStorage.removeItem("agent_code")
                      props.history.push('/profileMain')
                    }}>
                    Ok
                  </IonButton>
                </IonRow>
              </IonGrid>

            </div>
          </IonModal> */}

{/* 
          <IonModal
            // isOpen={true}
            isOpen={showSetPassword}
            onDidDismiss={() => {
              localStorage.setItem("has_password", "Y")
              setShowSetPassword(false)
            }
            }
            className="set-password-modal"
          >
            <div style={{ paddingLeft: 15, paddingRight: 15, height: 400, overflow: 'auto' }}>

              <div style={{ position: 'absolute', top: 6, right: 6 }}>
                <CloseIcon onClick={() => {
                  localStorage.setItem("has_password", "Y")
                  setShowSetPassword(false)
                }
                } style={{ cursor: 'pointer' }} />
              </div>

              <h3 style={{ color: '#00338d', fontWeight: 400, fontSize: 21 }}>Set a password</h3>

              <p>You can now set a password for your untied account!</p>

              <p>To do so, go to your <a href="" onClick={() => props.history.push('/profileMain', { state: "Security" })}>account settings</a> by clicking on your profile icon at top right and selecting Account settings, or click below.</p>

              <p>Don't worry if you prefer using the magic link to login, you aren't the only one, and you still have that option!</p>

              <IonGrid>
                <IonRow>

                  <IonCol>
                    <IonButton
                      className="ion-button-inverse"
                      onClick={() => {
                        setShowSetPassword(false)
                        localStorage.setItem("has_password", "Y")
                        services.fetchService.suppressPasswordPrompt()
                      }}>
                      Don't show this again
                    </IonButton>
                  </IonCol>

                  <IonCol>
                    <IonButton
                      className="ion-button-money"
                      onClick={() => {
                        props.history.push('/profileMain', { state: "Security" })
                      }}>
                      I want a password!
                    </IonButton>
                  </IonCol>
                </IonRow>

              </IonGrid>

            </div>


          </IonModal>
 */}


        </>)
      }
    }
  };


  const styles = {
    filterButtons: {
      tag: {
        height: "100%",
        borderRadius: 2,
        color: "white",
        backgroundColor: "#00338d",
        textTransform: "none"
      },
      unTag: {
        height: "100%",
        border: "1px solid #00338d",
        borderRadius: 2,
        color: "#00338d",
        backgroundColor: "white",
        textTransform: "none"
      },
      discard: {
        height: "100%",
        border: "1px solid red",
        borderRadius: 2,
        color: "red",
        backgroundColor: "white",
        textTransform: "none"
      },
      close: {
        height: 32,
        width: "auto",
        cursor: "pointer"
      },
      closeWhite: {
        height: 32,
        color: "white",
        width: "auto",
        cursor: "pointer"
      },
      addBlack: {
        position: "absolute",
        right: 5,
        top: 5,
        height: 32,
        color: "black",
        width: "auto",
        cursor: "pointer"
      },
      smallBlack: {
        position: "absolute",
        right: 5,
        top: 10,
        height: 24,
        color: "black",
        width: "auto",
        cursor: "pointer"
      },
    },
    labelLeft: {
      display: "block",
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: 12,
      width: "100%",
      padding: 0,
      textAlign: "left"
    },
    labelLeftBlack: {
      display: "block",
      color: "rgba(0, 0, 0, 1)",
      fontSize: 12,
      width: "100%",
      padding: 0,
      textAlign: "left"
    },
    labelTop: {
      display: "block",
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: 12,
      width: "100%",
      padding: 0,
      textAlign: "center"
    },
    tagSelect: {
      display: "flex",
      position: "relative",
      borderBottom: "2px solid #00338d",
      height: 40,
      minWidth: 710
    },
    filtersContainer: {
      display: "flex",
      position: "relative",
      height: 51,
      width: "100%"
    },
    toolBar: {
      maxWidth: "270px",
      flexDirection: "row",
      display: "flex",
      paddingRight: 15,
      alignItems: "stretch"
    },
    pasteZone: {
      border: "2px dashed #BABCBD"
    }
  };

  const similarData = () => {

    let numbers = []
    for (const [key, value] of Object.entries(similarTransactionData)) {
      let year_id = tax_years_atom.taxYears.filter(year => year.explain == key)[0].id
      numbers.push([year_id, `${key}: ${value.length}`])
    }

    return numbers.map(message => {
      return <div
        onMouseEnter={() => {
          services.externalFiltersService.setDateFilter(message[0], "similar_data")
          let year_data = tax_years_atom.taxYears.filter(year => year.id == message[0])[0]
          setTaxYearsAtom({
            ...tax_years_atom,
            dateFilterStart: year_data.start_date,
            dateFilterEnd: year_data.end_date,
            dateFilter: year_data.id
          })
        }}
        onClick={() => {
          // setSimilarTransactionData({})
          setSimilarDataTrue(false)
          gridApi.forEachNode(node => {
            if (node.data == selectSimilarRow[0]) {
              if (node.data.datetime > tax_years_atom.dateFilterStart && node.data.datetime < tax_years_atom.dateFilterEnd) {
                node.setSelected(true)
              }
            }
          })
        }}
        className="similar-years"
      >
        {message[1]}
      </div>
    })

  }

  const splitTrans = (data) => {
    let transDataClone = { ...transactionData }
    let currentAmount = 0
    data.forEach(t => currentAmount += parseFloat(t.amount))
    let newAmount = Math.abs(transactionData.paramsData.amount) - currentAmount
    newAmount = newAmount.toFixed(2)
    let num = data.length + 1
    let dataClone = data
    dataClone.push({
      id: num,
      amount: `${newAmount}`,
      description: "",
      category: dataClone[0].category,
      receipt: dataClone[0].receipt,
      token: dataClone[0].token,
      tags: [],
      tagText: ""
    })
    transDataClone.data = dataClone
    setToggleArray([...toggleArray, true])
    setAllocatedArray([...allocatedArray, { percent: "0%" }])
    setTransactionData(transDataClone)
  }

  const removeTrans = (trans, index) => {
    let transDataClone = { ...transactionData }
    let dataClone = transactionData.data.filter(d => { if (d !== trans) { return d } })
    transDataClone.data = dataClone
    let toggleClone = [...toggleArray]
    let allocClone = [...allocatedArray]
    toggleClone.splice(index, 1)
    allocatedArray.splice(index, 1)
    setToggleArray(toggleClone)
    setAllocatedArray(allocatedArray)
    setTransactionData(transDataClone)
  }

  const deleteReceipt = (receipt_id, transId) => {
    fetch(
      `https://${global.api_url}/deleteReceipt.php?id=${receipt_id}&ts=${getTimestamp()}`,
      {
        method: "GET",
        headers: { token: user_atom.token, agentId: user_atom.agent },
      }
    )
      .then(res => {
        res.json()
      })
      .then(json => {
        Mixpanel.track('delete_receipt');
      })
    const new_receipts = receipts.filter(receipt => receipt.id !== receipt_id);
    setReceipts(new_receipts)
    if (new_receipts.length == 0) {
      function updateNode(node) {
        node.has_receipt = "N"
        gridApi.updateRowData({ node });
      }

      gridApi.forEachNode(node => {
        if (transId === node.data.id) {
          updateNode(node.data)
        }
      })
    }
    setAttachments([])
    setAttachmentNames([])
  }


  const uploadReceipt = (transId) => {
    let data = new FormData()

    attachments.forEach(a => {
      data.append('receipts[]', a);
    })

    fetch(
      `https://${global.api_url}/uploadReceipts.php?trans_id=${transId}&ts=${getTimestamp()}`,
      {
        method: "POST",
        headers: { Accept: "application/json", token: user_atom.token, agentId: user_atom.agent },
        body: data
      }
    )
      .then(res => {

        res.json()
      })
      .then(json => {
        Mixpanel.track('upload_receipt');
      })
      .catch(err => {
        console.log(err)
      })
    if (JSON.stringify(transactionData.paramsData) !== "{}") {
      setShow({ ...show, loading: true })
      let len = 1

      attachments.forEach(a => {
        let s = (a.size / 1000000)
        if (s < 0.5) {
          s = 0.5
        }
        len += (1 * s)
      })

      setTimeout(() => {
        // getTaxTrans(transactionData.paramsData)
        function updateNode(node) {
          node.has_receipt = "Y"
          gridApi.updateRowData({ node });
        }

        gridApi.forEachNode(node => {
          if (transId === node.data.id) {
            updateNode(node.data)
          }
        })
        setShow((show) => ({ ...show, loading: false }))
        setTransactionData({ show: false, data: [], paramsData: {} })
        setClearFiles(true)


      }, (1500 * len))

    }

    else {
      setTransactionData({ show: false, data: [], paramsData: {} })
      setReceipts([])
      setAttachments([])
      setAttachmentNames([])
    }

    setTimeout(() => {
      setClearFiles(true)
    }, 1000)
  }

  const getTaxTrans = (params) => {
    fetch(`https://${global.api_url}/getTaxTransactions.php?trans_id=${params.id}&ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then(res => res.json())
      .then(json => {
        let jsonClone = [...json]
        jsonClone.forEach(d => {
          d.amount = sanitizeInputAmount(d.amount)
        })

        let claim = Math.abs(params.claimed_amount)
        let total = Math.abs(params.amount)
        let p = (claim / total) * 100
        let s = p.toString()
        if (s.includes(".")) {
          let array = s.split('.')
          if (array[1].length > 2) {
            p = p.toFixed(2)
          }
        }
        if (p < 0) {
          p = p * -1
        }

        let array = []
        let t_array = []
        let num = 0
        jsonClone.forEach(d => {
          claim = Math.abs(d.amount)
          let per = (claim / total) * 100
          s = per.toString()
          if (s.includes(".")) {
            let arr = s.split('.')
            if (arr[1].length > 2) {
              per = per.toFixed(2)
            }
          }
          if (per < 0) {
            per = per * -1
          }
          let percent = `${per}%`
          if (num < per) {
            num = per
          }
          array.push({ percent })
          t_array.push(true)
        })
        setAllocatedArray(array)
        setToggleArray(t_array)

        setTransactionData({
          data: [...jsonClone],
          show: false,
          paramsData: { ...params },
        });

        setClearFiles(false)
        if (array.length > 1) {
          setAllocated(`${num}%`)
        }
        else {
          setAllocated(`${p}%`)
        }

        getReceipts(params.id)
      })
  }

  const getReceipts = (id) => {
    fetch(`https://${global.api_url}/getReceipts.php?trans_id=${id}&ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then(res => res.json())
      .then(json => {
        setReceipts(json)
      });

  }

  const handleUpload = data => {
    if (data.length === 0) {
      data[0] = {
        size: 0,
        name: "",
        type: ""
      }
    }

    var error = false;

    data.forEach(file => {
      //const file = data[0];
      const size = file.size;
      const name = file.name;

      // setShow((show) => ({ ...show, loading: false }))
      if (size >= 10485760) {
        setAttachment("")
        // setAttachmentName("")
        setAttachmentError(true)
        return

      } else {

        if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === "image/png" || file.type === 'application/pdf' || file.type === 'image/gif') {

          let atts = attachments
          atts.push(file)
          let attNames = attachmentNames
          attNames.push(name)

          setAttachments(atts)
          setAttachmentNames(attNames)
          if (atts.length > 0) {
            setAttachmentPresent(true)
          }

        }
        else {
          if (file.type !== "") setFileFormatError(true)
          error = true;
        }
      }
    })

  }

  useEffect(() => {
    if (arrayReset) {
      setToggleArray([])
      setAllocatedArray([])
      setArrayReset(false)
    }
  }, [arrayReset])

  useEffect(() => {
    if (!transactionData.show && transactionData.data.length > 0 && allocatedArray.length > 0) {
      let transData = { ...transactionData, show: true }
      setTransactionData({ ...transData })
    }

  }, [transactionData])

  useEffect(() => {

    if (gridApi && JSON.stringify(filterChanged) !== "{}" && filterChanged !== "Tag filter" && filterChanged !== "Money filter" && filterChanged !== "") {
      setClearFilters(true)
    }
  },
    [filterChanged, gridApi])

  useEffect(() => {
    if (clearFiles) {
      setAttachments([])
      setAttachmentNames([])
    }

  }, [clearFiles])

  useEffect(() => {
    if (attachments.length == 0) {
      setAttachmentPresent(false)
    }
  }, [attachments])

  useEffect(() => {
    if (note.addNewNote) {
      saveTransactionInfo()
    }
  }, [note])



  useEffect(() => {
    if (!first) {
      if (!user_atom.token || user_atom.agent !== "") {
        let userState = getLocalStorage()
        setUserAtom({
          ...userState,
          agent: userState.agent
        })
        services.fetchService.getRecoilTaxYears(userState.token, userState.agent)
      }
      else if (JSON.stringify(tax_years_atom) === "{}") {
        services.fetchService.getRecoilTaxYears()
      }
      else {
        setFirst(true)
      }
    }
  }, [])

  useEffect(() => {

    if (
      transactionData.show == false &&
      JSON.stringify(transactionData.data) !== "[]" && allocatedArray.length == 0
    ) {
      setTransactionData({
        show: false,
        data: [],
        paramsData: {},
      });
      // setReceipt(null);
      setAttachment([]);
      // setAttachmentName("");
      initials = {
        description: [],
        amount: [],
      };
      setShow((show) => ({
        ...show,
        editDescription: false,
        editAmount: false,
      }));
      let uploaderObj = document.getElementById("uploader")
      if (uploaderObj != null) uploaderObj.value = "";
      ;
    }
  }, [transactionData]);


  useEffect(() => {
    if (JSON.stringify(similarTransactionData) !== "{}") {
      setSimilarDataTrue(true)
    }
  }, [similarTransactionData])

  useEffect(() => {
    if (first) {
      let currentDate = new Date();
      let newDate = new Date(Date.parse(filters.dateFilterEnd));
      let date;
      date = currentDate < newDate ? currentDate : newDate;
      setTransaction({
        type: transaction.type,
        description: transaction.description,
        cat: transaction.cat,
        date: date.toISOString(),
        fetched: transaction.fetched,
        amount: transaction.amount
      });
    }

  }, [filters.dateFilterEnd])

  useEffect(() => {
    if (first) {
      services.fetchService.getTransactions()
      services.fetchService.getTaxYears()
      if (props.location.state && props.location.state.filters) {
        setClearFilters(true)
      }
    }
  }, [first]);

  useEffect(() => {
    if (flags.clearTransIds) {
      if (props.location.state && props.location.state.fromCsvImporter) props.location.state.fromCsvImporter = "";
      services.actionsService.externalFilterChanged(filters.dateFilterStart, "dateFilterStart");
      services.actionsService.externalFilterChanged(filters.dateFilterEnd, "dateFilterEnd");
      services.actionsService.externalFilterChanged("N", "imported");
      gridApi.onFilterChanged();
      setFlags((flags) => ({ ...flags, clearTransIds: false }));
    }
  }, [flags.clearTransIds]);

  useEffect(() => {
    if (taxYears) {
      if (!firstTimeRender.current && gridApi) {
        gridApi.onFilterChanged();
        if (props.location.state && props.location.state.descriptionFilter !== undefined) {
          const event = new KeyboardEvent("keypress", {
            view: window,
            keyCode: 13,
            bubbles: true,
            cancelable: true
          });
          document.getElementsByClassName('ag-floating-filter-input')[2].value = props.location.state.descriptionFilter;
          document.getElementsByClassName('ag-floating-filter-input')[2].dispatchEvent(event)
        }
      }
    }
    firstTimeRender.current = false;
  }, [taxYears]);

  useEffect(() => {
    if (tax_years_atom.taxYears) {
      services.externalFiltersService.setDateFilter(tax_years_atom.dateFilter, "tax_years_atom use effect")
    }
  }, [tax_years_atom])


  useEffect(() => {
    if (landedFromBreakdown) {
      if (gridApi && props.location.state) {
        services.externalFiltersService.setDateFilter(props.location.state.yearData.id, "landed From breakdown use effect 1");
        gridApi.onFilterChanged();
        setLandedFromBreakdown(false);
        history.replace({ ...location, state: undefined });
      }
    }
    else {
      if (gridApi && !props.location.state && !parsedData) {
        services.externalFiltersService.setDateFilter(tax_years_atom.dateFilter, "landed From breakdown use effect 2")
      }
    }
  }, [gridApi]);


  useEffect(() => {

    if (gridApi && props.location.state && props.location.state.hubFilter) {
      services.externalFiltersService.setDateFilter(props.location.state.yearData.id);
      services.externalFiltersService.setTextFilter(props.location.state.hubFilter)
      setClearFilters(true)
    }

    if (gridApi && props.location.state && props.location.state.addManual) {
      services.externalFiltersService.setDateFilter(props.location.state.yearData.id);
      setShow({ ...show, addTrans: true })
    }
    if (gridApi && props.location.state && props.location.state.moneyFilter) {
      services.externalFiltersService.setDateFilter(props.location.state.yearData.id);
      services.externalFiltersService.setMoneyFilter(props.location.state.moneyFilter)
    }

  }, [gridApi]);

  useEffect(() => {
    if (gridColumnApi) {
      gridColumnApi.setColumnVisible('classification', show.showClassification)
      gridColumnApi.setColumnVisible('has_note', show.showNotesColumn)

    }

  }, [show, gridColumnApi]);

  useEffect(() => {
    if (!recoilFetched) {
      if (props.match && props.match.params) {
        let params = props.match.params
        if (params.data) {

          //setImportData({data: params.data})
          localStorage.setItem('import_trans_data', params.data)

          //do we also have a tokoen 
          if (params.token) {

            const decoded_jwt = jwt_decode(params.token);
            const user_email = decoded_jwt.user_email
            props.history.push(`/login?email=${user_email}&target=transactions`);

            //                        recoilAuth(params.token)                        
          } else {

            if (localStorage.getItem('token')) {
              // setDataReady(true)
              // setRecoilFetched(true)
            } else {
              props.history.push("/login?target=transactions");
            }
          }
        }

      }
    }
  }
    , [props])

  useEffect(() => {
    if (user_atom.token) {
      const idata = localStorage.getItem('import_trans_data')

      if (idata) {
        let p = Buffer.from(idata, 'base64')
        let parsed

        try {
          parsed = JSON.parse(p)
          if (!parsed) {
            setParsedError(true)
          }

          if (parsed.fromDate && parsed.toDate) {
            let parsed_start = new Date(parsed.fromDate)
            let parsed_end = new Date(parsed.toDate)
            setParsedData({ ...parsed, parsedStart: parsed_start, parsedEnd: parsed_end })
          }
          else if (parsed.fromDate) {
            let parsed_start = new Date(parsed.fromDate)
            setParsedData({ ...parsed, parsedStart: parsed_start })
          }
          else if (parsed.toDate) {
            let parsed_end = new Date(parsed.toDate)
            setParsedData({ ...parsed, parsedEnd: parsed_end })
          }
          else {
            setParsedData({ ...parsed })
          }
          setParsedError(false)

          localStorage.removeItem('import_trans_data')

        }
        catch (e) {
          setParsedError(true)
        }

      }
    }
  }, [user_atom])


  useEffect(() => {

    if (parsedData && transactionsRowData.length > 0 && !clearFilters && gridApi) {
      let final_transactions = [...transactionsRowData]

      if (parsedData.taxYear) {
        let tax_year

        if (parsedData.taxYear == "CURRENT_YEAR") {
          let date = new Date()
          tax_year = tax_years_atom.taxYears.find(y => { if (date >= new Date(y.start_date) && date <= new Date(y.end_date)) { return y } })
          setTaxYearsAtom({ ...tax_years_atom, dateFilter: tax_year.id, dateFilterStart: tax_year.start_date, dateFilterEnd: tax_year.end_date })
        }
        else if (parsedData.taxYear == "LAST_YEAR") {
          let date = new Date()
          let month = date.getMonth()
          date.setMonth(month - 12)
          tax_year = tax_years_atom.taxYears.find(y => { if (date >= new Date(y.start_date) && date <= new Date(y.end_date)) { return y } })
          setTaxYearsAtom({ ...tax_years_atom, dateFilter: tax_year.id, dateFilterStart: tax_year.start_date, dateFilterEnd: tax_year.end_date })
        }
        else {
          tax_year = tax_years_atom.taxYears.find(y => y.tax_year == parsedData.taxYear)
          setTaxYearsAtom({ ...tax_years_atom, dateFilter: tax_year.id, dateFilterStart: tax_year.start_date, dateFilterEnd: tax_year.end_date })
        }
        // services.externalFiltersService.setDateFilter(tax_year.id, "parsed Data use effect");

        if (parsedData.parsedStart && parsedData.parsedEnd) {
          final_transactions = final_transactions.filter(t => { if (new Date(t.datetime) >= parsedData.parsedStart && new Date(t.datetime) <= parsedData.parsedEnd) { return t } })
        }
        else if (parsedData.parsedStart && !parsedData.parsedEnd) {
          final_transactions = final_transactions.filter(t => { if (new Date(t.datetime) >= parsedData.parsedStart && new Date(t.datetime) <= new Date(tax_year.end_date)) { return t } })
        }
        else if (!parsedData.parsedStart && parsedData.parsedEnd) {
          final_transactions = final_transactions.filter(t => { if (new Date(t.datetime) >= new Date(tax_year.start_date) && new Date(t.datetime) <= parsedData.parsedEnd) { return t } })
        }
        else {
          final_transactions = final_transactions.filter(t => { if (new Date(t.datetime) >= new Date(tax_year.start_date) && new Date(t.datetime) <= new Date(tax_year.end_date)) { return t } })
        }
      }

      if (final_transactions.length > 0) {

        if (parsedData.type) {
          final_transactions = final_transactions.filter(t => {
            if (parsedData.type == "E") {
              return t.type == "DEBIT"
            }
            else if (parsedData.type == "I") {
              return t.type == "CREDIT"
            }
            else {
              return t
            }
          })
        }

        if (parsedData.tagged) {
          final_transactions = final_transactions.filter(t => t.claimed == parsedData.tagged)
        }

        if (parsedData.tagName) {
          final_transactions = final_transactions.filter(t => t.tagname == parsedData.tagName)
        }
        if (parsedData.description) {
          let descriptionArray = []
          final_transactions.forEach(t => {
            parsedData.description.forEach(d => {
              if (t.description.toLowerCase().includes(d.toLowerCase())) {
                descriptionArray.push(t)
              }
              else if (t.classification.toLowerCase().includes(d.toLowerCase())) {
                descriptionArray.push(t)
              }
            })
          })
          final_transactions = descriptionArray
        }
        if (parsedData.order) {
          let sort
          let colId = 'datetime'
          if (parsedData.orderBy) {
            colId = parsedData.orderBy
          }
          if (parsedData.order == "DESC") {
            sort = [
              {
                colId: colId,
                sort: "desc",
              },
            ];

          }
          if (parsedData.order == "ASC") {
            sort = [
              {
                colId: colId,
                sort: "asc",
              },
            ];

          }
          gridApi.setSortModel(sort);
        }

      }
      if (final_transactions.length > 0) {
        setClearFilters(true)
        setTransactionsRowData(final_transactions)
      }
      else {
        setNoDataAlert(true)
      }

    }
  }, [parsedData, transactionsRowData, clearFilters, gridApi])

  useEffect(() => {
    if (gridApi) {
      let totalIn = 0;
      let totalOut = 0;

      gridApi.getSelectedNodes().map(node => {
        if (node.data.type === "CREDIT") {
          totalIn = totalIn + Math.abs(node.data.amount);
        } else {
          totalOut = totalOut + Math.abs(node.data.amount);
        }
      });

      setCounts((counts) => ({
        ...counts,
        totalIn: parseFloat(totalIn.toString()).toFixed(2),
        totalOut: parseFloat(totalOut.toString()).toFixed(2)
      }));
    }
  }, [counts.rowCount]);


  return (<>
    <IonAlert
      isOpen={trialExpired}
      onDidDismiss={() => props.history.push("/logout/logout")}
      header={'Users subscription is no longer valid'}
      subHeader={''}
      message={"Please resubscribe to access"}
      buttons={['OK']}
    />
    <IonAlert
      isOpen={noDataAlert}
      onDidDismiss={() => setNoDataAlert(false)}
      header={'No found transactions'}
      subHeader={''}
      message={"There are no transactions that match the current search parameters"}
      buttons={['OK']}
    />
    {flags.redirectForms && <Redirect
      to={{
        pathname: "/taxForms",
        state: { year: incomeInfo.year, form: incomeInfo.form }
      }}
    />}

    {addAccount && <Redirect
      to={{
        pathname: "/accounts",
        state: { addAccount: true }
      }}
    />}

    <React.Fragment>
      <div className="transaction-page-container">
        <MiniDrawer location={props.location} />
        <div className="transaction-page-content">
          {tagModal()}
          {tagConfirmModal()}
          {SelectionBar(first, update)}
          {(counts.rowCount < 1 && first) ? services.renderService.displayFilterControls(props) : services.renderService.displayChangeTagControls()}
          {services.renderService.displayMainScreen()}
          {services.renderService.displayTransactionPopover()}
          {services.renderService.popUpRedesign()}

          {services.renderService.displayAlerts()}
        </div>
      </div>
    </React.Fragment>
  </>);
};

export default Transactions