
import React, { useState, useEffect } from "react";

import MiniDrawer from "./../MiniDrawer";
import { Container } from "@material-ui/core";

import Covid from '../components/Covid'
import './Page.css';
import Footer from "../components/Footer";


const Covid19Support = () => {

    const displayFooter = () => {
        return (
          <Footer />
        );
    }
    const divStyles = { 
        display: "flex",
        marginTop: "20px", 
        width: "100%", 
        backgroundColor: "#FFFFFF", 
        minWidth: "300px", 
        flexBasis: "300px", 
        flexShrink: "0", 
        marginBottom: "50px"
    };


       return (
        <React.Fragment>
            <div style={divStyles}>
            {/* <div className = "transaction-page-container" style ={{flexDirection: "column", bottom: "0px" }}> */}
      
            <MiniDrawer />
                <div className="transaction-page-content">
                {/* <Container style={{
                        width: "100%",
                        marginTop: 20,
                        height: "100%",
                        paddingBottom: "50px",
                        overflow: "auto",
                        paddingLeft: "200px"
                        
                    }}> */}
                    {Covid()}
                </div>
                {/* </Container> */}
              
                
            </div>
        </React.Fragment>
        );

}

 
 export default Covid19Support
 