import React, { useState, useEffect } from "react";

import { useRecoilState } from 'recoil'
import { userAtom,  taxYearsAtom } from "../atom.js"


import { IonButton } from '@ionic/react';
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import { withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import MiniDrawer from "./../MiniDrawer";
import Footer from "../components/Footer";
import "./../App.css";
import "./../tax_filing_modal.css";
import './Page.css';
import { getTimestamp } from '../components/timeStamp.js'
import { getLocalStorage } from '../components/localstorage.js'

const TaxReturns = (props) => {
  const [user_atom, setUserAtom] = useRecoilState(userAtom)
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
  const [recoilFetched, setRecoilFetched] = useState(false)
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const [returnsRowData, setReturnsRowData] = useState([]);
  const [flags, setFlags] = useState({
    infoOpen: false
  });
  const [repUrl, setRepUrl] = useState("");
  const constants = {
    defaultColDef: {
      resizable: true,
      editable: false,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      flex: 1
    },
    columnDefs: [
      {
        headerName: "Submitted date",
        field: "submitted",
        cellClass: "cell-style",
        maxWidth: 200,
        valueFormatter: params => params.value,
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const cellDate = new Date(cellValue.substring(0, 10));
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: "Tax year",
        field: "tax_year",
        cellClass: "cell-style",
        maxWidth: 120,
        //editable: true,
        resizable: false
      },
      {
        headerName: "SA302 summary",
        cellClass: "cell-style",
        field: "token",
        filter: false,
        resizable: false,
        sortable: false,
        cellRenderer: params => {
          const eDiv = document.createElement("div");
          eDiv.innerHTML = `<button class='gridButton btn-view'>View</button>`;
          const dButton = eDiv.querySelectorAll(".btn-view")[0];

          dButton.addEventListener("click", () => {
            const timeStamp = Math.floor(Date.now() / 1000);
            const url = `https://${global.api_url}/tax/viewSA302Report.php?t=${params.value}&ts=${timeStamp}`;
            setFlags((flags) => ({ ...flags, infoOpen: true }));
            setRepUrl(url);
          });
          return eDiv;
        },
        maxWidth: 140,
      },
      {
        headerName: "Tax return",
        cellClass: "cell-style",
        field: "token",
        filter: false,
        resizable: false,
        sortable: false,
        cellRenderer: params => {
          const eDiv = document.createElement("div");
          eDiv.innerHTML = `<button class='gridButton btn-view'>View</button>`;
          const dButton = eDiv.querySelectorAll(".btn-view")[0];

          dButton.addEventListener("click", () => {
            const timeStamp = Math.floor(Date.now() / 1000);
            const url = `https://${global.api_url}/tax/viewReturnReport.php?t=${params.value}&ts=${timeStamp}`;
            setFlags((flags) => ({ ...flags, infoOpen: true }));
            setRepUrl(url);
          });
          return eDiv;
        },
        maxWidth: 140,
      },      
      {
        headerName: "Status",
        cellClass: "cell-style",
        field: "success",
        resizable: false,
        maxWidth: 120,
        valueFormatter: params => params.value === "Y" ? "Success" : "Fail"
      },
      {
        headerName: "Test submission?",
        cellClass: "cell-style",
        field: "test",
        maxWidth: 150,
        resizable: false,
        valueFormatter: params => params.value === "Y" ? "Yes" : "No"
      },
      {
        headerName: "Ref (IRMark)",
        cellClass: "cell-style",
        field: "response",
        //maxWidth: 550
      }
    ]
  };

  useEffect(() => {
    if (!user_atom.token) {
      let userState = getLocalStorage()
      setUserAtom(userState)
    }
    else {
      setRecoilFetched(true)
    }
  }, [])

  useEffect(() => {
    if (user_atom.token) {
      setRecoilFetched(true)
    }
  }, [user_atom])

  const services = {
    actionsService: {
      prettyDateTime: (dateToProcess) => {
        let date1 = new Date(dateToProcess);
        let longDate = date1.toDateString();
        let timePart = date1.toLocaleTimeString();
        return longDate + " " + timePart;
      }
    },
    fetchService: {
      getReturns: () => {

        fetch(`https://${global.api_url}/getTaxSubmissions.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        })
          .then(res => res.json())
          .then(json => {

            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {
              setReturnsRowData(json.returns);
            }
          });
      }
    },
    renderService: {
      displayMainScreen: () => {
        const advisor = localStorage.getItem("agent") 

        return (<>

          {((user_atom.submissions_enabled==='Y' || advisor=='') || advisor!='') && (
          <div
            style={{
              //maxWidth: "300px",
              height: 48,
              flexDirection: "row",
              display: "flex",
              //paddingLeft: 15,
              paddingBottom: 7
            }}
          >
            <IonButton className="ion-button-money"
              onClick={() => {
                props.history.push('/taxReturn/form')
              }}>File New Return or Amendment</IonButton>
          </div>
          )}

          <div className={`ag-theme-alpine grid-container ${1 === 2 ? "hide-grid" : "show-grid"}`}>
            <AgGridReact
              modules={AllCommunityModules}
              defaultColDef={constants.defaultColDef}
              columnDefs={constants.columnDefs}
              animateRows
              rowHeight={30}
              rowSelection={"multiple"}
              rowData={returnsRowData}
              suppressRowClickSelection={false}
              suppressDragLeaveHidesColumns={true}
              rowClassRules={constants.rowClassRules}
              onFilterChanged={() => services.gridService.filterChanged()}
              onRowSelected={(node) => services.gridService.onRowSelected(node)}
              onGridReady={services.gridService.onGridReady}
              applyColumnDefOrder={true}
            />
          </div>
        </>)
      },
      displayFooter: () => {
        return (
          <Footer />
        );
      }
    },
    gridService: {
      onGridReady: (params) => {
        setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        services.fetchService.getReturns();
      },
      onRowSelected: (node) => {
        node.data.checkbox = node.node.selected;
        node.data.isSelected = node.node.selected ? "Y" : "N";
        gridApi.updateRowData({ node });
      },
      filterChanged() {
        let total = 0;
        gridApi.forEachNodeAfterFilter(node => {
          if (node.data.type === "CREDIT") {
            total = total + Math.abs(node.data.amount);
          } else {
            total = total - Math.abs(node.data.amount);
          }
        });
      }
    }
  };

  return (

    <React.Fragment>
      <div className="tax-returns-page-container">
        <MiniDrawer />
        <Popup
          open={flags.infoOpen}
          modal
          onClose={() => {
            setFlags((flags) => ({ ...flags, infoOpen: false }));
          }}
        >
          {close => (
            <div className="tr_modal">
              <span className="close" onClick={close} style={{ cursor: 'pointer' }}>
                &times;
                </span>
              <div className="header">
                {" "}
                    Tax Return{" "}
                <a href={repUrl} target="_NewWindow">
                  Open in New Tab
                    </a>
                    &nbsp;|&nbsp;
                    <a
                  href={`https://pdfe1.untied.io/render_pdf.php?u=${repUrl}`}
                >
                  Download PDF
                    </a>
              </div>
              <div className="content">
                <iframe
                  name="report_frame"
                  title="2"
                  src={repUrl}
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                />
              </div>
              <div className="actions">
                <button
                  className="button"
                  onClick={() => {
                    close();
                  }}
                >
                  cancel
                    </button>
              </div>
            </div>
          )}
        </Popup>
        <div className="tax-returns-page-content">


          {recoilFetched ? services.renderService.displayMainScreen() : <div>Loading...</div>}
        
        </div>
      </div>
    </React.Fragment>
  );
};

function scale(val) {
  return val;
}

const styles = {
  card: {
    minWidth: 675
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  buttonContainer: {
    paddingVertical: 12,
    width: "100%",
    marginBottom: 15,
    height: 36,
    borderRadius: 2,
    color: "#14A7CC",
    backgroundColor: "#c7ff00",
    textTransform: "none",
    paddingLeft: 5
  },
  buttonContainerSubtle: {
    paddingVertical: 5,
    marginBottom: 15,
    height: 36,
    borderRadius: 4,
    backgroundColor: "#FAFAFC"
  },
  buttonText: {
    color: "#000000",
    textAlign: "center",
    fontWeight: "700"
  },
  input: {
    width: "100%",
    borderRadius: scale(4),
    borderColor: "#14A7CC",
    borderStyle: "solid",
    borderBottomWidth: scale(2),
    backgroundColor: "#ffffff",
    _paddingLeft: scale(12),
    get paddingLeft() {
      return this._paddingLeft;
    },
    set paddingLeft(value) {
      this._paddingLeft = value;
    },
    fontSize: scale(14),
    height: 36
  },
  itIs: {
    color: "#c7ff00",
    fontSize: 18,
    fontWeight: "500",
    letterSpacing: 0.54,
    textAlign: "center",
    alignSelf: "center"
  },
  tagSelect: {
    borderRadius: scale(4),
    borderColor: "#14A7CC",
    borderBottomStyle: "solid",
    borderBottomWidth: scale(2),
    backgroundColor: "#ffffff",
    paddingBottom: 2,
    width: "100%"
  },
  footer: {
    alignSelf: "center",
    justifyContent: "center",
    paddingBottom: 5
  }
};

export default withRouter(TaxReturns)
