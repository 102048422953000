import React, { useState, useEffect } from "react";

import { withRouter, BrowserRouter, Route } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Mixpanel } from "./mixpanel";
import { isMobile } from 'react-device-detect';

const UntiedAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open_width = isMobile ? 240 : 245;
  const close_width = isMobile ? 62 : 60;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLogout = () => {
    // props.clearAuth()
    // this.props.clearTaxYears()
    // props.clearTrans()
    if (localStorage.getItem('agentEmail') == null) {
      localStorage.clear()
      // localStorage.removeItem('agentId');
      // localStorage.removeItem('isAgentEmail');
      // localStorage.removeItem('agentEmail');
      props.history.push("/logout/logout");
    } else {
      console.log('loging out to agent')
      localStorage.clear()
      // localStorage.removeItem('agentId');
      // localStorage.removeItem('isAgentEmail');
      // localStorage.removeItem('agentEmail');
      props.history.push("/agent")
    }

    // setLoggedInAs("");
    localStorage.setItem("logged_in_as", "");
  };

  const handleMenuProfile = () => {
    props.history.push("/profileMain");
  };


  const handleGS = () => {
    window.open(`https://www.untied.io/rp/${localStorage.username}`, 'referral_scheme')
  };


  const plan = localStorage.has_sub
  const isMenuOpen = Boolean(anchorEl);
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuProfile}>Account settings</MenuItem>
      <MenuItem onClick={handleGS}>Refer friends &amp; earn cash</MenuItem>
      {/* <MenuItem onClick={handleSubmit}>Manual submission</MenuItem> */}
      {/* <MenuItem onClick={() => props.history.push("/hmrcpay")}>Pay your tax bill</MenuItem>*/}
    </Menu>
  );

  return(
    
    <React.Fragment>
      <CssBaseline />
      <Container style={{ maxWidth: "100%" }}>
      </Container>
    </React.Fragment >
  )

  // return (

  //   <React.Fragment>
  //     <CssBaseline />
  //     <Container style={{ maxWidth: "100%" }}>
  //       <AppBar title="untied web" style={{ backgroundColor: "#00338d" }}>
  //         <Toolbar style={{ height: 80 }}>
  //           <div style={{ transform: `translateX(${props.open ? open_width : close_width}px)`, transition: "all .4s cubic-bezier(0.42, 0, 0.58, 1.0)" }}>
  //             {/* <h6 style={{ paddingLeft: 0, margin: 0, textAlign: "left" }}>
  //               untied V1.6
  //               </h6> */}
  //             <Typography variant="h6">
  //               <BrowserRouter>
  //                 <Route
  //                   path="/transactions"
  //                   render={() => <div>Transactions</div>}
  //                 />
  //                 <Route
  //                   path="/claimedTransactions"
  //                   render={() => <div>Claimed transactions</div>}
  //                 />
  //                 <Route path="/journeys" render={() => <div>Travel</div>} />
  //                 <Route
  //                   path="/accounts"
  //                   render={() => <div>Accounts</div>}
  //                 />
  //                 <Route
  //                   path="/taxBreakdown"
  //                   render={() => <div>Tax breakdown</div>}
  //                 />
  //                 <Route
  //                   path="/taxReturns"
  //                   render={() => <div>Tax returns</div>}
  //                 />
  //                 <Route
  //                   path="/taxReturn/form"
  //                   render={() => <div>Tax return form</div>}
  //                 />
  //                 <Route
  //                   path="/taxForms"
  //                   render={() => <div>Tax forms</div>}
  //                 />
  //                 <Route
  //                   path="/MTD"
  //                   render={() => <div>Digital tax (beta)</div>}
  //                 />
  //                 <Route
  //                   path="/cov19"
  //                   render={() => <div>COVID-19 support</div>}
  //                 />
  //                      <Route
  //                   exact path="/payment/form"
  //                   render={() => <div>Request payment</div>}
  //                 />
  //                      <Route
  //                   exact path="/payment"
  //                   render={() => <div>Request payment</div>}
  //                 />
  //                 <Route path="/profileMain" render={() => <div>Profile</div>} />
  //                 <Route path="/profile" render={() => <div>Account</div>} />
  //                 <Route
  //                   path="/rules"
  //                   render={() => <div>Rules</div>}
  //                 />

  //                 <Route
  //                   path="/insights"
  //                   render={() => <div>Insights (beta)</div>}
  //                 />
  //               </BrowserRouter>
  //             </Typography>
  //           </div>
  //           <div style={{ flexGrow: 1 }} />

  //           {/* {loggedInAs && <h4>Managing: {loggedInAs} </h4>} */}
  //         </Toolbar>
  //         {renderMenu}
  //       </AppBar>
  //     </Container>
  //   </React.Fragment >
  // );
};

export default withRouter(UntiedAppBar)