import mixpanel from "mixpanel-browser";
mixpanel.init("8d3e81efdac1efda725b4f9f0f4bc631", { track_pageview: true });

//let env_check = process.env.NODE_ENV === 'production';
let env_check = true;

let actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props);
    }
  }
  // createAlias: id => {
  //   if (env_check) mixpanel.alias(id);
  // }
};

export let Mixpanel = actions;
