import React, { useState, useRef, useEffect } from "react";

import { Mixpanel } from "../mixpanel";
import MiniDrawer from "../MiniDrawer";
import { Container } from "@material-ui/core";
import { withRouter, Link, Redirect } from "react-router-dom";
import { getTimestamp } from '../components/timeStamp.js'
import P60 from "../images/P60.png"
import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { SelectionBar } from '../components/selectionBar.js';

import {
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    IonLoading,
    IonText,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,


} from '@ionic/react';

import './Page.css';

const Partner_import_employed = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [redirectPension, setRedirectPension] = useState(false)
    const [redirectEmployment, setRedirectEmployment] = useState(false)
    const [update, setUpdate] = useState(false)
    const firstTimeRender = useRef(true);

    const [box_1, setBox1] = useState('£0')
    const [box_2, setBox2] = useState('£0')

    const [box_4, setBox4] = useState('')
    const [box_5, setBox5] = useState('')

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    // const [years, setYears] = useState(tax_years_atom.taxYears);

    const divStyles = {
        display: "flex",
        marginTop: "20px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        minWidth: "300px",
        flexBasis: "300px",
        flexShrink: "0",
        marginBottom: "50px"
    };

    const ionGridStyle = {
        backgroundColor: '#f5f7fa',
        fontSize: '14px',
        borderRadius: '5px',
        width: "70%",
        marginBottom: 5,
        marginTop: 5
    }


    useEffect(() => {
        if (!recoilFetched) {
            if (!user_atom.token) {
                let userState = getLocalStorage()
                setUserAtom(userState)
                getTaxYears(userState.token)
            }
            else {
                getTaxYears()
            }
        }
    }, [])

    useEffect(() => {
        if (JSON.stringify(tax_years_atom) !== '{}') {
            setRecoilFetched(true)
        }
    }, [tax_years_atom])

    const parseAmount = (value) => {
        if (value && value.length > 0) {
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if (value.indexOf(".") > -1) {
                const integers = value.split(".")[0] + ".";
                let digits = value.split(".")[1];
                if (digits.length > 2) digits = digits.substring(0, 2);
                if (digits.length < 2) digits = digits + "0"

                return "£" + integers + digits;
            } else {
                return "£" + value + ".00";
            }
        } else {
            return "";
        }
    };

    const cleanNumbers = (num) => {
        let newValue = num
        newValue = newValue.replace(/[^0-9\.]/g, '');
        newValue = parseFloat(newValue).toString()
        return newValue
    }

    const saveData = () => {
        let yearId = props.location.state.yearData.id
        let tax_column = "employment"
        let box1 = cleanNumbers(box_1)
        let box2 = cleanNumbers(box_2)

        let dataPackage = [{ "include": true, "box_1": box1, "box_2": box2, box_4, box_5 }];
        dataPackage = JSON.stringify(dataPackage)

        fetch(`https://${global.api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${yearId}&type=${tax_column}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: dataPackage
        })
            .then(res => res.json())
            .then(json => {             
                if (json.status === "success") {
                    Mixpanel.track("employment_data_saved");
                    setSuccess(true)
                } else {
                    setError(true)
                }

            });

    }


    const getTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {

        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    if (props.location && props.location.state && props.location.state.yearData) {
                        let year = props.location.state.yearData
                        setTaxYearsAtom(
                            {
                                taxYears: json.years,
                                dateFilterStart: year.state_date,
                                dateFilterEnd: year.end_date,
                                dateFilter: year.id
                            });
                    }
                    else {
                        let today = new Date();
                        let currentYearID = 0;
                        let dateFilterStart = "";
                        let dateFilterEnd = "";
                        json.years.forEach(element => {
                            let start = new Date(element.start_date);
                            start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0)

                            let end = new Date(element.end_date);
                            end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

                            if (today >= start && today <= end) {
                                currentYearID = element.id;
                                dateFilterStart = element.start_date;
                                dateFilterEnd = element.end_date;
                            }
                        });

                        setTaxYearsAtom(
                            {
                                taxYears: json.years,
                                dateFilterStart: dateFilterStart,
                                dateFilterEnd: dateFilterEnd,
                                dateFilter: currentYearID
                            });
                    }
                }
            })

    }



    const mainContent = () => {
        if (props.location) {
            console.log(props.location.state)
        }
        return (
            <IonGrid style={ionGridStyle}>
                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>
                        <h2 className="tax-forms-card-title" style = {{marginBottom: 5}}>Employment</h2>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>
                        <IonText>
                        We have determined from the import data that you are employed. Before submitting your tax return, you will need to provide a few details about your employment which you can find on the P60 that your employer should have provided to you.
                        We take the tax already deducted by your employer into account in our calculations for your tax returns. If you don’t have your P60 now, you can enter this information later under <span className="fake-anchor" onClick={() => setRedirectEmployment(true)}>Tax Forms – Employment</span>.
                            
                            {/* According to the data imported from {props.location.state.importedFrom} you are employed. Therefore, to submit your tax return you
                            will need to enter the following information from the p60 which you will have received from your employer. If you do not have this to hand
                            right now you can enter this information later in the <span className="fake-anchor" onClick={() => setRedirectEmployment(true)}>Employment tax form here</span>. */}
                        </IonText>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <img src={P60} style={{ height: 161, }} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>
                        <IonText>Information entered here will replace anything already set for employment for this period</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className="ion-no-padding">
                            <IonLabel position="floating">PAYE tax reference of your employer (on your P45/P60)</IonLabel>
                            <IonInput value={box_4}
                                onIonChange={e => setBox4(e.detail.value)}
                                placeholder=""
                                inputmode="text"
                                type="text" />
                        </IonItem >

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className="ion-no-padding">
                            <IonLabel position="floating">Employer's name (from P60)</IonLabel>
                            <IonInput value={box_5}
                                onIonChange={e => setBox5(e.detail.value)}
                                placeholder=""
                                inputmode="text"
                                type="text" />
                        </IonItem >

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className="ion-no-padding">
                            <IonLabel position="floating">Pay in this employment</IonLabel>
                            <IonInput
                                value={box_1}
                                onIonChange={e => {
                                    setBox1(e.detail.value)
                                }}
                                onFocus={e => {
                                    let newValue = cleanNumbers(box_1)
                                    setBox1(newValue)
                                }}
                                onBlur={e => {
                                    let newValue = cleanNumbers(box_1)
                                    setBox1(parseAmount(newValue))
                                }}

                                placeholder=""
                                inputmode="text"
                                type="text" />
                        </IonItem >

                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className="ion-no-padding">
                            <IonLabel position="floating">Tax in this employment</IonLabel>
                            <IonInput
                                value={box_2}
                                onIonChange={e => {
                                    setBox2(e.detail.value)
                                }}
                                onFocus={e => {
                                    let newValue = cleanNumbers(box_2)
                                    setBox2(newValue)
                                }}
                                onBlur={e => {
                                    let newValue = cleanNumbers(box_2)
                                    setBox2(parseAmount(newValue))
                                }}
                                placeholder=""
                                inputmode="text"
                                type="text" />
                        </IonItem >

                    </IonCol>
                </IonRow>


                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    className="ion-button-inverse"
                                    style={{ width: 150 }}
                                    onClick={() => {
                                        Mixpanel.track("Employment_page_skip_pressed");
                                        setRedirect(true)
                                    }}
                                >Skip</IonButton>

                            </IonCol>
                            <IonCol>
                                <IonButton
                                    className="ion-button-money"
                                    onClick={() => {
                                        if(box_1 === '£0' && box_2 === '£0' && box_4 === "" && box_5 === ""){
                                            Mixpanel.track("no_employment_data_entered");
                                            setRedirect(true)
                                        }
                                        else{
                                        saveData()
                                        }
                                    }}
                                >Save employment data</IonButton>

                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonText className="fake-anchor" onClick={() => setRedirectPension(true)}>For additional employment info eg tips &amp; P11D benefits use this form.</IonText>
                            </IonCol>
                        </IonRow>

                    </IonCol>


                </IonRow>
            </IonGrid>)
    }




    const getYear = () => {
        let year = tax_years_atom.taxYears.filter(y => { if (y.id == tax_years_atom.dateFilter) { return y } })
        let tax_year = year[0].tax_year
        return tax_year
    }

    const alerts = () => {
        return (<>
            <IonAlert
                isOpen={error}
                onDidDismiss={() => setError(false)}
                header={"Error saving employment information"}
                message={"Please double check the employment information and submit again"}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={success}
                onDidDismiss={() => {
                    setSuccess(false)
                    setRedirect(true)
                }
                }
                header={"Employment information Saved"}
                message={""}
                buttons={['OK']}
            />
        </>)
    }

    return (

        <React.Fragment>

            {redirect && <Redirect
                to={{
                    pathname: "/partner_import2",
                    state: { yearData: props.location.state.yearData, importedFrom: props.location.state.importedFrom }
                }}
            />}

            {redirectPension && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA100", year: getYear() }
                }}
            />}
            {redirectEmployment && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA102", year: getYear() }
                }}
            />}

            <div style={divStyles}>
                <MiniDrawer />
                {/* {recoilFetched && */}
                <Container className="tax-breakdown-page-content">
                    {SelectionBar(recoilFetched, update)}
                    <div style={{ width: "80%", margin: "auto" }}>
                        {alerts()}
                        {mainContent()}
                    </div>

                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={30000}
                    />
                </Container>
                {/* } */}
            </div>
        </React.Fragment>)
};


export default withRouter(Partner_import_employed)
