import {
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonIcon,
    IonItem,
    IonAlert, IonText,
    IonLoading, IonButton
} from '@ionic/react';
import React, { useEffect, useState } from "react";
import { CustomButton } from "./../../Common"
import MiniDrawer from "./../../MiniDrawer";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import { Container } from "@material-ui/core";
import './HMRCPayment.scss';
//import logo from '../images/fractal-untied-1536x130.png';


const HMRCPayment = () => {
    const [screen, setScreen] = useState("initial");
    const [banks, setBanks] = useState([]);
    const [showUTR, setShowUTR] = useState(false);
    const [formData, setFormData] = useState({ userProfile: {}, utr: "", amount: "", bankId: "" });
    const [picUrl, setPicUrl] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const target_account = ['HMRC Self Assessment Cumbernauld', '083210', '12001039'];
    //const target_account = ['HMRC Shipley', '083210', '12001020'];
    const getProfile = () => {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${global.api_url}/getUserProfile.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                setFormData({
                    userProfile: json,
                    utr: json.utr,
                    amount: formData.amount,
                    bankId: formData.bankId
                });
            })
    };
    const getBanks = () => {
        fetch(`https://${global.api_url}/truelayer/getPaymentBanks.php`)
            .then(res => res.json())
            .then(json => {
                setBanks(json);
            })
    };
    const getPicUrl = (id) => {
        const obj = banks.filter((bank) => (bank.id === id))[0];
        setPicUrl(obj.icon);
    };
    const saveUserUTR = () => {
        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        let payload = formData.userProfile;
        payload.utr = formData.utr;

        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${timeStamp}`, {
            method: "POST",
            headers: { token: token },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                getProfile();
            })
    };
    const pay = () => {
        const payload = {
            bank_id: formData.bankId,
            name: target_account[0],
            sort_code: target_account[1],
            account_number: target_account[2],
            reference: formData.utr + 'K',
            amount: formData.amount
        };
        setShowLoading(true);
        fetch(`https://${global.api_url}/truelayer/getPaymentAuthLink.php`,
            { method: "POST", body: JSON.stringify(payload) }
        )
            .then(res => res.json())
            .then(json => {
                if (json.status === 'success') {
                    const authUrl = json.authUrl;
                    window.location = authUrl
                }
            })
    };
    const checkScreen = (str) => {
        if (!str.includes("/form") && screen === "form") {
            setScreen("initial");
            setShowLoading(false);
        }
        if (str.includes("/form") && screen === "initial") {
            setScreen("form");
            setShowLoading(false);
            setError(false);
        }
    };
    const validForm = () => {
        return formData.utr !== "" && formData.amount !== "" && formData.bankId !== ""
    };
    const buildInitialScreen = () => (<>
        <IonRow>

            <IonCol className="text-center">
                <p><span className="strong-text">Pay HMRC. Safe, Secure, Trusted.</span></p>
                {/* <p><span className="strong-text">Safe. Secure. Trusted.</span></p> */}
                <p>
                    <img style={{ width: 50, marginTop: 10 }} src={require("../../images/trusted-icon.png").default} />
                </p>

            </IonCol>
        </IonRow>
        <IonRow>
            {/* <IonCol size-sm="3" /> */}
            <IonCol className="text-center">
                <IonButton
                    style={{ height: 30, marginTop: 0 }}
                    className="ion-button-money"
                    onClick={() => {
                        setScreen("form");
                        window.history.pushState(null, "HMRC Payment", "/hmrcpay/form")
                    }}>
                    Get started
                </IonButton>
            </IonCol>
        </IonRow>

        {/* <IonRow>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'center', marginTop: 20 }}>

                <IonText style={{ fontSize: "0.8rem", paddingBottom: 20 }}>Powered by SMART from</IonText> <br />

                <a href="https://www.untied.io/smart/" target="_new">
                    <img style={{ width: '95%', marginTop: 18, maxWidth: 350 }} src={require("../../images/fractal-untied-1.png").default} />
                </a>

            </IonCol>
        </IonRow> */}

    </>);
    const buildFormScreen = () => (<>
        <div style={{ width: '100%', margin: 'auto' }} >
            <IonRow>
                <IonCol>
                    <IonItem className="input-container" style = {{border: "1px solid #00338d", borderRadius: "3px" }} lines = "none">
                        <IonLabel style = {{marginLeft: "3px"}} position="floating">Unique Taxpayer Reference:</IonLabel>
                        <IonInput
                            type="text"
                            placeholder=""
                            value={formData.utr}
                            style = {{marginLeft: "3px"}} 
                            onIonChange={e => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: e.detail.value,
                                    amount: formData.amount,
                                    bankId: formData.bankId
                                })
                            }} />
                        <IonIcon color="primary" className="mr-0" slot="end" icon={InfoIcon}
                            onClick={() => {
                                setShowUTR(true)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem className="input-container" style = {{border: "1px solid #00338d", borderRadius: "3px" }} lines = "none">
                        <IonLabel style = {{marginLeft: "3px"}}  position="floating">Amount you are paying to HMRC</IonLabel>
                        <IonInput
                            type="number"
                            placeholder="0.00"
                            value={formData.amount}
                            style = {{marginLeft: "3px"}} 
                            onIonChange={(e) => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: formData.utr,
                                    amount: e.detail.value,
                                    bankId: formData.bankId
                                })
                            }}><IonText style={{ marginTop: 8, marginRight: 3, marginLeft: 3 }}>&pound;</IonText></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem className="input-container bank-selector-container" style = {{border: "1px solid #00338d", borderRadius: "3px" }} lines = "none" > 
                        <Autocomplete
                            {...bankSelectorProps}
                            className="bank-selector"
                            autoComplete
                            style = {{marginLeft: "3px"}} 
                            includeInputInList
                            onChange={(e, value) => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: formData.utr,
                                    amount: formData.amount,
                                    bankId: value ? value.id : ""
                                });
                                if (value) {
                                    getPicUrl(value.id);
                                }
                            }}
                            renderInput={(params) => {
                                return (<div className={`display-inline-flex ${formData.bankId ? "selected" : ""}`}>
                                    <div className={formData.bankId ? "display-flex" : "display-none"}>
                                        <div style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${picUrl})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            marginTop: "auto",
                                            marginBottom: "6px",
                                            marginRight: "10px"
                                        }} />
                                    </div>
                                    <TextField {...params} label="Choose your bank" placeholder="Search and select" margin="normal" />
                                </div>)
                            }}
                            renderOption={(option) => {
                                return (
                                    <div>
                                        <span style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${option.icon})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            display: "inline-block",
                                            verticalAlign: "middle"
                                        }} />
                                        <span style={{ marginLeft: "20px" }}>{option.displayable_name}</span>
                                    </div>
                                )
                            }}
                        />
                    </IonItem>

                </IonCol>
            </IonRow>
            <IonRow>
                {/* <IonCol size-sm="3" /> */}
                <IonCol className="text-center">
                    <IonButton
                        style={{ height: 30 }}
                        className="ion-button-money"
                        onClick={() => {
                            if (validForm()) {
                                saveUserUTR();
                                pay();
                            } else {
                                setError(true);
                            }
                        }}>
                        Next
                </IonButton>
                </IonCol>
                {/* <IonCol size-sm="3" /> */}
            </IonRow>
            <IonRow>
                <IonCol>
                    <p className="text-center">Pressing "Next" will safely take you to your bank, where you can authorise this payment.</p>
                    <p className="text-center">Payments are made to the default HMRC account for income tax.</p>
                </IonCol>
            </IonRow>
        </div>
    </>);

    const bankSelectorProps = {
        options: banks,
        getOptionLabel: (banks) => banks.displayable_name,
    };

    useEffect(() => {
        getProfile();
        getBanks();
    }, []);

    return (
        <React.Fragment>
            <div className="hmrc-payment">
                <MiniDrawer />
                <Container style = {{marginTop: "20vh", }}>
                    <IonGrid>
                        {checkScreen(window.location.pathname)}
                        {screen === "initial" && buildInitialScreen()}
                        {screen === "form" && buildFormScreen()}
                    </IonGrid>
                </Container>


                <IonAlert
                    isOpen={showUTR}
                    onDidDismiss={() => {
                        setShowUTR(false)
                    }}
                    header={"Unique Tax Reference"}
                    subHeader={""}
                    message={'UTR stands for Unique Taxpayer Reference.  Your UTR is a unique number that identifies you to HMRC. ' +
                        'Your UTR number is assigned automatically by HMRC when you register for self assessment tax returns. ' +
                        'If you are not yet registered for self assessment you will need to do so if you need to submit a tax return. ' +
                        'You can do that <a href="https://www.gov.uk/log-in-file-self-assessment-tax-return/register-if-youre-not-self-employed">here on the HMRC website</a>.'}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <IonAlert
                    isOpen={error}
                    onDidDismiss={() => {
                        setError(false)
                    }}
                    header={"Payment form has errors"}
                    subHeader={""}
                    message={'Please note all fields are mandatory.'}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={10000}
                />
            </div >
        </React.Fragment>
    );
};

export default HMRCPayment;
