import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    IonLoading,
    IonCard,
    IonText,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonPopover,
    IonTitle,
    IonItem,
    IonInput,
    IonLabel,
    IonTextarea,
    IonModal,
    IonIcon,

} from '@ionic/react';
import { Checkbox, FormControlLabel, Card, Button, CardContent, } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CachedIcon from '@material-ui/icons/Cached';

import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { helpCircleOutline } from 'ionicons/icons';
import { getTimestamp } from '../components/timeStamp.js';
import CountDwnr from '../components/CountDwnr';
import CustomFilter from "../components/CustomFilter";
import { PasswordInput, AuthCodeInput } from '../components/PasswordInput';
import MiniDrawer from "./../MiniDrawer";
import "./../App.css";
import { Mixpanel } from "../mixpanel";
import moment from "moment";

import { useRecoilState } from 'recoil'
import { userAtom, profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js';
import { checkUTR } from '../components/UtrChecker.js';
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { TagSelector } from "../components/TagSelector.js";

const getMinutes = (date) => {

    const expireTime = moment(date).add(1, 'hours')
    const now = moment(new Date())
    const duration = moment.duration(expireTime.diff(now))

    //if expire time in the past then set to zero (it has expired)
    return duration._milliseconds > 0 ? (duration._milliseconds / 1000) / 60 : 0

};

const Profile = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [profile_atom, setProfileAtom] = useRecoilState(profileAtom);

    const [first, setFirst] = useState(false)
    const [phone, setPhone] = useState("")
    const [phoneType, setPhoneType] = useState("")
    const [showPhoneVerify, setShowPhoneVerify] = useState(false)

    const [UTRError, setUTRError] = useState(false)

    const [showHelp, setShowHelp] = useState(false)
    const [smsCode, setSmsCode] = useState("")
    const [showLoading, setShowLoading] = useState(false)
    const [showSaved, setShowSaved] = useState(false)
    const [showConfirmVerification, setShowConfirmVerification] = useState(false)
    const [linebreaks, setLinebreaks] = useState(1)
    const firstTimeRender = useRef(true);
    const [subscription, setSubscription] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [authCodeValid, setAuthCodeValid] = useState(false);
    const [sendAuthCode, setSendAuthCode] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [selected, setSelected] = useState("Your profile")
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [postcode, setPostcode] = useState("");
    const [nin, setNin] = useState("");
    const [utr, setUtr] = useState("");
    const [dob, setDob] = useState(null);
    const [moved, setMoved] = useState(null);
    const [phoneVerified, setPhoneVerified] = useState("");
    const [originalNumber, setOriginalNumber] = useState("");
    const [selectedScheme, setSelectedScheme] = useState("");
    const [gatewayId, setGatewayId] = useState("");
    const [hasCustomTags, setHasCustomTags] = useState(false);
    const [help, setHelp] = useState({ helpMainTitle: "", helpTitle: "", helpText: "" });
    const [monthlyDiscount, setMonthlyDiscount] = useState(0)
    const [annualDiscount, setAnnualDiscount] = useState(0)
    const [planOnly, setPlanOnly] = useState(false)
    const [show, setShow] = useState({
        isChecked: profile_atom.send_monthly !== "N",
        loaded: false,
        showHelp: false,
        showLoading: false,
        validPhone: true,
        validCode: true,
        downgrade: false,
        upgrade: false
    });
    const [validPass, setValidPass] = useState({
        newPass: {
            hasCharactersLength: false,
            hasCapitalLetter: false,
            hasNumber: false
        },
        confirmPass: {
            hasCharactersLength: false,
            hasCapitalLetter: false,
            hasNumber: false
        }
    });
    const [changePhone, setChangePhone] = useState({
        phoneNumber: "",
        verificationCode: "",
        message: ""
    });
    const [agent, setAgent] = useState("");
    const [agents, setAgents] = useState([]);
    const [minutes, setMinutes] = useState(getMinutes(localStorage.getItem('support_token_start')) || 0);
    const [countingTime, setCountingTime] = useState(localStorage.getItem('support_token_start') !== "" ? getMinutes(localStorage.getItem('support_token_start')) > 0 : false);
    const [upgradeMessage, setUpgradeMessage] = useState("");

    //Invoice settings constants

    const [title, setTitle] = useState("");
    const [tagline, setTagline] = useState("");
    const [imageData, setImageData] = useState("");
    const [redirect, setRedirect] = useState(false)
    const [invoiceAddress, setInvoiceAddress] = useState("")
    const [invoiceLinebreaks, setInvoiceLinebreaks] = useState(3)
    const [savedData, setSavedData] = useState(false)
    const [saving, setSaving] = useState(false);
    const inputFile = useRef(null)
    const [file, setFile] = useState()
    const [upload, setUpload] = useState({
        show: false,
        error: false,
        message: ""
    })
    const [customTags, setCustomTags] = useState([]);
    const taxTypes = [{ type: "Expense", value: 'E' }, { type: "Income", value: "I" }]
    const [selectedTaxType, setSelectedTaxType] = useState('E')
    const [inputTagName, setInputTagName] = useState("")
    const [editingTagId, setEditingTag] = useState(0)
    const [inputTagTitle, setInputTagTitle] = useState("")

    const [inputTagDescription, setInputTagDescription] = useState("")
    const [showIncomeExpense, setShowIncomeExpense] = useState(false)
    const [showTagSelector, setShowTagSelector] = useState(false)
    const [taxCat, setTaxCat] = useState(null)
    const [taxCats, setTaxCats] = useState([])
    const [tagActionCaption, setTagActionCaption] = useState("Add")
    const [showDeleteTag, setShowDeleteTag] = useState({show:false, id: 0, tagName: null, tagType: null})

    const labelLeft = {
        display: "block",
        color: "rgba(0, 0, 0, 0.38)",
        fontSize: 12,
        width: "100%",
        paddingLeft: "16px",
        textAlign: "left"
    }

    const borderBox = {
        "border": "1px solid #00338d",
        borderRadius: "3px"
    }


    //end of invoice settings constants

    const constants = {
        columnDefs: [
            {
                headerName: "Adviser name",
                field: "name",
                minWidth: 100,
                filter: false,
                sortable: false,
                resizable: true,
                cellStyle: { textAlign: "left" }
            },
            {
                headerName: "Email",
                field: "email",
                minWidth: 150,
                filter: false,
                sortable: false,
                resizable: true,
                cellStyle: { textAlign: "left" }
            },
            {
                headerName: "Action",
                field: "action",
                filter: false,
                sortable: false,
                resizable: false,
                cellStyle: { textAlign: "left" },
                cellRendererFramework: params => {
                    return (
                        <button className="gridButton" onClick={() => {
                            fetchService.removeAgentAccess(params.data.id)
                        }}>
                            Remove access
                        </button>
                    );
                }
            }
        ],
        customTagsColumnDefs: [

            {
                headerName: "Title",
                field: "title",
                resizable: true,
                cellStyle: { textAlign: "left" },
                width:150
            },
            {
                headerName: "Description",
                field: "description",
                resizable: true,
                cellStyle: { textAlign: "left" }
            },
            {
                headerName: "Type",
                field: "type",
                resizable: false,
                cellStyle: { textAlign: "left" },
                width:120,
                valueGetter: params => params.data.type === "E" ? "Expense" : "Income"
            },
            {
                headerName: "Tax category",
                field: "tax_cat",
                resizable: true,
                cellStyle: { textAlign: "left" }
            },
            {
                headerName: "Tagname",
                field: "tagname",
                resizable: true,
                cellStyle: { textAlign: "left" },
                width:150
            },
            {
                headerName: "Action",
                filter: false,
                sortable: false,
                resizable: false,
                cellStyle: { textAlign: "left" },
                cellRendererFramework: params => {
                    return (
                        <div>
                        <button className="gridButton" onClick={() => {
                            setEditingTag(params.data.id)
                            setInputTagName(params.data.tagname)
                            setInputTagTitle(params.data.title)
                            setInputTagDescription(params.data.description)
                            setSelectedTaxType(params.data.type)
                            setTaxCat(params.data.tax_cat_id)
                            setTagActionCaption('Update')
                        }}>
                            Edit
                        </button>
                        <button style={{marginLeft:5}} className="gridButton" onClick={() => {
                           // fetchService.delCustomTag(params.data.tagname, params.data.type)
                            setShowDeleteTag({show:true, id: params.data.id, title: params.data.title, tax_cat_id: params.data.tax_cat_id})
                        }}>
                            Delete
                        </button>
                        </div>

                    );
                }
            }

        ]
    };
    const minPhoneLength = 11;
    const styles = {
        cardStyle: {
            marginTop: 10,
            //minWidth: 800,
            padding: "none",
            position: "relative"
        },
        divCardStyle: {
            marginTop: 10,
            //minWidth: 800,
            padding: "none",
            position: "relative",
            display: "flex"
        },
        addAdvisorButton: {
            paddingVertical: 12,
            width: 150,
            height: 48,
            borderRadius: 4,
            backgroundColor: "#00338d",
            color: "#ffffff",
            position: "absolute",
            margin: "auto",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            marginRight: 10
        },
        buttonContainer: {
            paddingVertical: 12,
            width: 150,
            height: 48,
            borderRadius: 4,
            backgroundColor: "#00338d",
            color: "#ffffff"
        },
        smallButtonContainer: {
            width: 150,
            borderRadius: 4,
            backgroundColor: "#00338d",
            color: "#ffffff"
        },
        disabledButton: {
            width: 150,
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.26)"
        },
        infoIcon: {
            height: 15,
            width: 15,
            color: "#00338d",
            cursor: "pointer"
        },
        planCardContainer: {
            width: "50%",
            height: 100,
            padding: 20,
            textAlign: "center"
        },
        planCardContent: {
            height: "100%",
            maxWidth: 200,
            margin: "auto",
            cursor: "pointer"
        }
    };

    const handleKeyDownAddress = (e) => {
        let lbreaks = address ? address.split("\n").length : 0
        setLinebreaks(lbreaks)
    }
    const handleKeyDownInvoiceAddress = (e) => {
        let lbreaks = invoiceAddress ? invoiceAddress.split("\n").length : 3
        if (lbreaks < 3) { lbreaks = 3 }
        setInvoiceLinebreaks(lbreaks)
    }

    const handlers = {
        setUnsubscribe: () => {
            setShow({ ...show, isChecked: !show.isChecked });
        },
        setAgent: (value) => {
            setAgent(value)
        },
        parseDate: (input) => {
            let output;
            if (input.length > 0) {
                output = new Date(input);
                output = output.toLocaleString('default', { month: 'long' }) + " " + output.getFullYear();
                return output;
            } else return "unknown date";

        },
        openCheckout: (sku) => {
            Mixpanel.track("opened_checkout", { sku: sku });
            const guid = localStorage.getItem("guid");
            const email = localStorage.getItem("username");
            const coupon = subscription.offercode;
            window.Paddle.Checkout.open({
                product: sku,
                passthrough: guid,
                email: email,
                coupon: coupon,
                successCallback: () => { fetchService.doPurchase(sku) },
                closeCallback: () => { }
            });
        },
        buyPlan: (plan) => {
            if (plan === "1") handlers.openCheckout(620338);
            if (plan === "2") handlers.openCheckout(627400);
        },
        prettyDate: (date) => {
            let ds = "";
            if (date !== "") {
                let theDate = moment(date, 'YYYY-MM-DD HH:mm').toDate();
                ds = theDate.toDateString();
            }
            return ds;
        },
        getStore: () => {
            if (subscription.store === "untied") return "untied";
            if (subscription.store === "PLAY_STORE") return "Google Play";
            if (subscription.store === "APP_STORE") return "iTunes";
        },
        getUrlToStore: () => {
            if (subscription.store.toUpperCase() === "PLAY_STORE") {
                return (<a className="stay-blue-link" href="https://play.google.com/store/account/orderhistory" target="_blank">Google Play</a>);
            }
            if (subscription.store.toUpperCase() === "APP_STORE") {
                return (<a className="stay-blue-link" href="https://apps.apple.com/us/genre/ios/id36" target="_blank">iTunes</a>);
            }
        },
        getTheDate: () => {
            function twoDigit(n) {
                return (n < 10 ? "0" : "") + n;
            }
            let now = new Date();
            return (
                "" +
                now.getFullYear() +
                "-" +
                twoDigit(now.getMonth() + 1) +
                "-" +
                twoDigit(now.getDate())
            );
        }
    };

    const setPasswordService = {
        newPass: (value) => {
            setValidPass({
                ...validPass, ["newPass"]: {
                    hasCharactersLength: value.length > 9,
                    hasCapitalLetter: /(?=.*[A-Z])/.test(value),
                    hasNumber: /\d/.test(value)
                }
            });
            setPassword(value);
        },
        confirmPass: (value) => {
            setValidPass({
                ...validPass, ["confirmPass"]: {
                    hasCharactersLength: value.length > 9,
                    hasCapitalLetter: /(?=.*[A-Z])/.test(value),
                    hasNumber: /\d/.test(value)
                }
            });
            setNewPassword(value);
        },
        validPasswords: () => {
            return (authCode != "" && password === newPassword && password !== "" && newPassword !== "") &&
                (Object.keys(validPass.newPass).filter(item => !validPass.newPass[item]).length === 0) &&
                (Object.keys(validPass.confirmPass).filter(item => !validPass.confirmPass[item]).length === 0)
        }
    };

    const setPhoneNumberService = {
        handleProvideInput: (input, field) => {
            const exp = {
                phoneNumber: /^[0-9+\b]+$/, // allow only numbers and +
                verificationCode: /^[0-9\b]+$/ // allow only numbers and
            };

            if (input) {
                input = input.trim();
                if (input.length > 1) {
                    let substring = input.substring(1, input.length);
                    if (substring.indexOf("+") > -1) {
                        substring = substring.replace("+", "");
                        input = input.substring(0, 1) + substring;
                    }
                }
            }

            if (exp[field].test(input)) {
                setChangePhone({ ...changePhone, [field]: input })
            } else {
                setChangePhone({ ...changePhone, [field]: input.length > 0 ? changePhone[field] : "" })
            }
        },
        validateNumber: (number, length) => {
            const data = {
                phoneNumber: changePhone.phoneNumber.length >= minPhoneLength,
                verificationCode: changePhone.verificationCode.length >= 4
            };

            if (number && length) {
                return number.length >= length
            } else {
                return data.phoneNumber && data.verificationCode
            }
        }
    };

    const fetchService = {
        getProfile: (passed_token = user_atom.token, passed_agent = user_atom.agentId) => {
            fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
                method: "GET",
                headers: { token: passed_token, agentId: passed_agent }
            })
                .then(res => res.json())
                .then(json => {
                    if (json.error) {
                        if (json.error === "unauthorized") {
                            //alert('redirect to login page');
                            props.history.push("/logout/logout");
                        }
                    } else {
                        localStorage.setItem('support_token_start', json.support_token_start);
                        setProfileAtom(json);
                        setName(json.name);
                        let lbreaks = json.address ? json.address.split("\n").length : 3
                        setLinebreaks(lbreaks)

                        setAddress(json.address);
                        setPostcode(json.postcode);

                        console.log(json)
                        setPhoneVerified(json.phone_verified);

                        setPhone(json.phone)

                        setHasCustomTags(json.custom_tags === 'Y' || user_atom.agentId !== "")

                        var date
                        if (json.dob == null) {
                            date = moment(new Date()).subtract(18, "years").format('YYYY-MM-DD')
                        } else {
                            date = json.dob
                            if (date.includes('/')) {
                                date = date.split("/").reverse().join("-");
                            }
                            date = moment(new Date(date)).format('YYYY-MM-DD')
                        }
                        console.log('dob', date)

                        setDob(date);
                        setNin(json.nin);
                        setUtr(json.utr);
                        setMoved(json.moved)
                        setOriginalNumber(json.phone_verified === 'Y' ? json.phone : "");
                        setGatewayId(json.gateway_id);
                        setMinutes(getMinutes(json.support_token_start));
                        setShow({ ...show, isChecked: json.send_monthly !== "N", loaded: true });
                    }
                    setRecoilFetched(true)
                });

        },
        getSubscription: (token, agentId) => {
            fetch(`https://${global.api_url}/getUserSubscription.php?ts=${getTimestamp()}`, {
                method: "GET",
                headers: { token, agentId }
            })
                .then(res => res.json())
                .then(json => {
                    if (json.error) {
                        if (json.error === "unauthorized") {
                            props.history.push("/logout/logout");
                        }
                    } else {
                        console.log('sub', json)
                        setSubscription(json);
                        const has_sub = !json.in_trial && json.plan !== 3 ? 'Y' : 'N';
                        localStorage.setItem("has_sub", has_sub)

                        if (json.offercode && json.offercode != "") {
                            //get coupon info 
                            fetch(`https://${global.api_url}/paddle/getCouponInfo.php?ts=${getTimestamp()}&plan=620338&coupon=${json.offercode}`, {
                                method: "GET",
                                headers: { token, agentId }
                            })
                                .then(res => res.json())
                                .then(json => {
                                    setMonthlyDiscount(json.discount)
                                })
                            //get coupon info 
                            fetch(`https://${global.api_url}/paddle/getCouponInfo.php?ts=${getTimestamp()}&plan=627400&coupon=${json.offercode}`, {
                                method: "GET",
                                headers: { token, agentId }
                            })
                                .then(res => res.json())
                                .then(json => {
                                    setAnnualDiscount(json.discount)
                                })

                        }

                    }
                });
        },
        getAgents: () => {
            fetch(`https://${global.api_url}/agent/getUserAgents.php?ts=${getTimestamp()}`, {
                method: "GET",
                headers: { token: user_atom.token, agentId: user_atom.agent }
            })
                .then(res => res.json())
                .then(json => {
                    setAgents(json)
                })
        },
        getVerificationCode: () => {
            setChangePhone({ ...changePhone, message: "Please check your phone for an SMS/phone call..." })
            fetch(
                `https://${global.api_url}/twilio/sendVerify.php`,
                {
                    method: "POST",
                    body: JSON.stringify({ phone_number: changePhone.phoneNumber }),
                    headers: { token: user_atom.token, agentId: user_atom.agent }
                }
            )
                .then(res => res.json())
                .then(json => {
                    if (!json.number) {
                        setShow({ ...show, validPhone: false });
                    } else {

                    }
                })

        },
        setNewPhoneNumber: () => {
            fetch(
                `https://${global.api_url}/twilio/sendVerify.php`,
                {
                    method: "POST",
                    body: JSON.stringify({ phone_number: changePhone.phoneNumber, code: changePhone.verificationCode }),
                    headers: { token: user_atom.token, agentId: user_atom.agent }
                }
            )
                .then(res => res.json())
                .then(json => {

                    if (!json.approved) {
                        setShow({ ...show, validCode: false });
                    } else {
                        const payload = {
                            name,
                            address,
                            postcode,
                            nin,
                            utr: utr ? utr.replace(" ", "") : "",
                            dob: dob ? moment(dob).format("DD/MM/YYYY") : "",
                            phone: changePhone.phoneNumber,
                            phone_verified: changePhone.phoneNumber === originalNumber ? "Y" : "N",
                            moved: moved
                        };

                        setChangePhone({ phoneNumber: "", verificationCode: "", message: "" })

                        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${getTimestamp()}`, {
                            method: "POST",
                            headers: { token: user_atom.token, agentId: user_atom.agent },
                            body: JSON.stringify(payload)
                        })
                            .then(res => res.json())
                            .then(json => {

                                console.log(json)
                                fetchService.getProfile();
                                Mixpanel.track("saved_new_phone_number");



                            })
                    }
                })
        },
        addAgent: () => {
            // codes to use for testing: TT1L1QPWJV, TT1L1QPF7V
            if (agent === "" || agent == null) {
                setHelp({
                    helpTitle: 'We’ve not been able to find a match for your adviser code',
                    helpText: `Please check you have entered the code correctly. \n Any problems, <a target="_blank" href="https://help.untied.io/article/111-adding-an-advisor-or-accountant-to-your-untied-account">contact us</a>`
                });
                setShow({ ...show, showHelp: true, showLoading: false });
                return;
            }

            let payload = {
                "code": agent
            };

            fetch(`https://${global.api_url}/agent/addAgent.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(json => {
                    fetchService.getAgents();
                    setShow({ ...show, showLoading: false });
                    setAgent("")
                })
        },
        removeAgentAccess: (id) => {
            let payload = {
                "id": id
            };

            fetch(`https://${global.api_url}/agent/removeUserAgent.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then(json => {
                    fetchService.getAgents();
                    setShow({ ...show, showLoading: false })
                })

        },
        saveProfile: () => {
            fetch(`https://${global.api_url}/saveUserProfile.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify({
                    nin: nin,
                    utr: utr,
                    gateway_id: gatewayId,
                    send_monthly: show.isChecked ? "Y" : "N"
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {
                        alert("Your changes have been saved!");
                        Mixpanel.track("updated profile");
                    } else {
                        alert(json.message);
                    }
                });
        },
        sendAuthCode: () => {
            fetch(`https://${global.api_url}/setUserPassword.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {

                    } else {
                        alert(json.message);
                    }
                });
        },
        changePassword: () => {
            fetch(`https://${global.api_url}/setUserPassword.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify({
                    auth_code: authCode,
                    password,
                    pass_confirm: newPassword
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {
                        alert("Your password has been updated!");
                        props.history.push("/logout/logout");
                    } else {
                        alert(json.message);
                    }
                });
        },
        grantSupportAccess: () => {
            fetch(`https://${global.api_url}/authSupportAccess.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent }
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {
                        alert(
                            "You have given untied Customer support temporary access to your account. Access will expire in 1 hour."
                        )

                        fetchService.getProfile(user_atom.token, user_atom.agent)
                        setCountingTime(true);

                    } else {
                        alert("Unauthorised")
                    }
                });
        },
        upgradeDowngrade: () => {
            fetch(`https://${global.api_url}/paddle/upgradeDowngrade.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent }
            }).then(res => res.json())
                .then(json => {
                    setUpgradeMessage(json.message)
                });
        },
        doPurchase: (iap_item) => {
            const body = JSON.stringify({
                guid: localStorage.getItem("guid"),
                email: localStorage.getItem("username"),
                start_date: handlers.getTheDate(),
                plan: "2",
                sub_sku: iap_item === 627400 ? 'untiedy1' : 'untiedm1',
                store: "untied",
                app: 'web'
            });

            let timeStamp = Math.floor(Date.now() / 1000);
            fetch(
                `https://${global.api_url}/addUserSubscription.php?ts=${timeStamp}&duration=year`,
                {
                    method: "POST",
                    body: body,
                    headers: { partnerId: global.partner_id }
                }
            )
                .then(res => res.json())
                .then(json => {
                    //all good, now we can send user back to login page ....
                    window.location.reload(false);
                });

        },
        getCustomTags: () => {
            fetch(`https://${global.api_url}/getCustomTags.php?ts=${getTimestamp()}`,
            {
                method: "GET",
                headers: { token: user_atom.token },
            })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                setCustomTags(json)

                fetch(
                    `https://${global.api_url}/getTaxCategories.php?ts=${getTimestamp()}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            // remove items from json.tax_categories where tax_cat_id starts with 'c'
                            let filtered = json.tax_categories.filter(item => item.id.startsWith('c') === false);
                            setTaxCats(filtered)
                        }
                    });

            });
        },
        
        addCustomTag: () => {
            fetch(`https://${global.api_url}/addCustomTag.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify({
                    id: editingTagId, tagname: inputTagName, title: inputTagTitle, description: inputTagDescription, tax_cat_id: taxCat, type: selectedTaxType
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {
                        fetchService.getCustomTags()
                        setEditingTag(0)
                        setTagActionCaption('Add')
                    } else {
                        alert(json.message);
                    }
                });
        },
        delCustomTag: (id, title, tax_cat_id) => {
            fetch(`https://${global.api_url}/addCustomTag.php?ts=${getTimestamp()}`, {
                method: "POST",
                headers: { token: user_atom.token, agentId: user_atom.agent },
                body: JSON.stringify({
                    id, title, tax_cat_id, delete: true
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success") {
                        fetchService.getCustomTags()                      
                    } else {
                        alert(json.message);
                    }
                });
        }
    };

    const topStyles = {
        selectedBarButton: {
            marginLeft: "5px",
            marginRight: "5px",
            height: "25px",
            border: " 1px solid #00338d",
            borderBottom: "none",
            color: "white",
            fontWeight: "bold",
            backgroundColor: "#00338d",
            backgroundHover: "#a00338",
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: "5px 5px 0 0",
            paddingTop: "10px"
        },
        unselectedBarButton: {
            marginLeft: "5px",
            marginRight: "5px",
            height: "25px",
            border: " 1px solid #00338d",
            borderBottom: "none",
            fontWeight: "bold",
            color: "#00338d",
            backgroundColor: "white",
            backgroundHover: "grey",
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: "5px 5px 0 0",
            paddingTop: "10px"
        }
    }

    // Invoice settings functions

    useEffect(() => {
        if (file) {
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === "image/png") {
                if (file.size < 512000) {
                    getBase64()
                }
                else {
                    setUpload({
                        show: true,
                        error: true,
                        message: "File too large, upload limit 500kb"
                    })

                }
            }
            else {
                setUpload({
                    show: true,
                    error: true,
                    message: "File wrong format, file types: jpg, jpeg, or png"
                })
            }
        }
    }, [file])



    const getBase64 = () => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //   console.log(reader.result);
            let data = reader.result.split(",")[1]
            setImageData(data)
            setSaving(true)
            //   setUpload({
            //     show:true,
            //     error:false,
            //     message: ""
            //   })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            setUpload({
                show: true,
                error: true,
                message: "Error with uploading"
            })
        };
    }



    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.target.files[0];
        // console.log(file);
        setFile(file)
        // this.setState({file}); /// if you want to upload latter
    }


    //end of invoice settings functions



    const optionBar = () => {

        if (planOnly) return <div />

        return (
            <div style={{
                borderBottom: "2px solid #00338d"
            }}>
                <IonGrid style={{ "padding-bottom": 0 }} >
                    <IonRow>
                        <IonCol
                            style={selected == "Your profile" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}

                            onClick={() => {
                                setSelected("Your profile")
                            }}>
                            <IonText>Your profile</IonText>
                        </IonCol>
                        <IonCol
                            style={selected == "Security" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}
                            onClick={() => {
                                setSelected("Security")
                            }}>
                            <IonText>Security</IonText>
                        </IonCol>

                        {hasCustomTags && (
                            <IonCol
                                style={selected == "Custom tags" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}
                                onClick={() => {
                                    setSelected("Custom tags")
                                }}>
                                <IonText>Custom tags</IonText>
                            </IonCol>
                        )}

                        <IonCol
                            style={selected == "Invoice settings" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}
                            onClick={() => {
                                setSelected("Invoice settings")
                            }}>
                            <IonText>Invoice settings</IonText>
                        </IonCol>
                        <IonCol
                            style={selected == "Adviser management" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}
                            onClick={() => {
                                setSelected("Adviser management")
                            }}>
                            <IonText>Adviser management</IonText>
                        </IonCol>
                        {/* <IonCol 
                    style = {{
                    cursor: "pointer", 
                    borderBottom: selected == "Documents" ? "3px solid #00338d" : ""
                    }} 
                    onClick = {()=>{
                        setSelected("Documents")
                    }}>
                    <IonText>Documents</IonText>
                </IonCol> */}
                        <IonCol
                            style={selected == "Manage your plan" ? topStyles.selectedBarButton : topStyles.unselectedBarButton}
                            onClick={() => {
                                setSelected("Manage your plan")
                            }}>
                            <IonText>Manage your plan</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        )
    }

    const incomeExpenseModal = () => {
        return (
          <IonModal
            isOpen={showIncomeExpense}
            onDidDismiss={() => setShowIncomeExpense(false)}
            className="income-expense-selector-modal"
          >
    
            <div style={{ overflow: 'auto', borderRadius: 5, paddingBottom: 5 }}>
              <h4 style={{ paddingLeft: 10, color: "#00338d" }}>Transaction type</h4>
              {taxTypes !== [{}] && taxTypes.map((type, index) => {
    
                return <div className="income-expense-modal-item" key={index} onClick={() => {
                  setSelectedTaxType(type.value)
                  setShowIncomeExpense(false)
                }}>
                  <div className="income-expense-tag-item">{type.type}</div>
                </div>
              })}
            </div>
          </IonModal>
    
        )
      }

    const renderService = {
        EmailCard: () => {
            return (
                <div style={{ paddingRight: 10 }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ textAlign: "left" }}>
                                        <span style={{ textAlign: "left", fontWeight: 700, fontSize: 20 }}>
                                            {profile_atom.name}
                                        </span>
                                        <span style={{ marginLeft: 15, color: "#ABB2B9", fontStyle: "italic", lineHeight: 2 }}>untied user since {handlers.parseDate(profile_atom.joined || "")}</span>
                                        <p>Email: <span style={{ fontWeight: 700 }}>{profile_atom.email}</span></p>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },
        AccessCard: () => {
            return (
                <div style={{ paddingRight: 10, }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <h3 style={{ textAlign: "left" }}>
                                            Get customer support access
                                        </h3>
                                    </IonCol>
                                    <IonCol>
                                        {minutes > 0 && <CountDwnr hours={0} minutes={minutes} initial={profile_atom.support_token_start} />}
                                    </IonCol>
                                    <IonCol>
                                        {!countingTime && <Button
                                            onClick={() => {
                                                fetchService.grantSupportAccess();

                                            }}
                                            style={styles.addAdvisorButton}
                                        >
                                            Grant access
                                        </Button>}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },
        InvoiceCard: () => {
            return (
                <div style={{ paddingRight: 10, }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel style={labelLeft}>Business name</IonLabel>
                                        <IonItem>
                                            <CustomFilter
                                                // label="Send payment request to"
                                                placeholder="Title"
                                                value={title}
                                                className="custom-filter-payments"
                                                onChange={e => setTitle(e.target.value)}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel style={labelLeft}>Tag line</IonLabel>
                                        <IonItem >
                                            <CustomFilter
                                                // label="Send payment request to"
                                                placeholder="Displayed under business name on your invoices"
                                                value={tagline}
                                                className="custom-filter-payments"
                                                onChange={e => setTagline(e.target.value)}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <IonLabel style={labelLeft}>Address</IonLabel>
                                        <IonItem lines="inset" style={{ "--highlight-color-focused": "none" }} >

                                            <IonTextarea

                                                onKeyPress={handleKeyDownInvoiceAddress}
                                                placeholder=""
                                                value={invoiceAddress}
                                                onIonChange={e => setInvoiceAddress(e.detail.value)}
                                                rows={invoiceLinebreaks}
                                            ></IonTextarea>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <IonLabel style={labelLeft}>Postcode</IonLabel>
                                        <IonItem >
                                            <CustomFilter
                                                // label="Send payment request to"
                                                placeholder="Postcode"
                                                value={postcode}
                                                className="custom-filter-payments"
                                                onChange={e => setPostcode(e.target.value)}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <IonLabel style={labelLeft}>Phone number</IonLabel>
                                        <IonItem >
                                            <CustomFilter
                                                // label="Send payment request to"
                                                placeholder="Phone number"
                                                value={phone}
                                                className="custom-filter-payments"
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    {imageData !== "" && <>
                                        <IonCol style={{ textAlign: "right", marginLeft: 16 }}>
                                            <img
                                                style={{
                                                    height: 80,
                                                    // width: 80,
                                                    alignSelf: "center",
                                                    // border: "1px solid #00338d",
                                                    margin: "auto",
                                                }}
                                                src={`data:image/gif;base64,${imageData}`}

                                            />
                                        </IonCol>

                                        <IonCol style={{ textAlign: "left" }}>
                                            <Button
                                                style={{
                                                    height: "100%",
                                                    verticalAlign: "middle",
                                                    color: "#00338d"
                                                }}
                                                onClick={() => {
                                                    inputFile.current.click()

                                                }}
                                            >
                                                <input
                                                    type='file'
                                                    id='file'
                                                    ref={inputFile}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onChangeFile(e)} />
                                                <CachedIcon /> Replace
                                            </Button>

                                        </IonCol>
                                        <IonCol style={{ textAlign: "left" }}>
                                            <Button
                                                style={{
                                                    height: "100%",
                                                    verticalAlign: "middle",
                                                    color: "#a00338"
                                                }}
                                                onClick={() => {
                                                    setImageData("")
                                                    setFile(null)
                                                    inputFile.current = null
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon /> Remove
                                            </Button>
                                        </IonCol>

                                    </>}

                                    {!imageData &&
                                        <IonCol>
                                            <IonButton
                                                className="ion-button-money"
                                                onClick={() => {
                                                    inputFile.current.click()
                                                }}>
                                                <input
                                                    type='file'
                                                    id='file'
                                                    ref={inputFile}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onChangeFile(e)} />
                                                Add logo
                                            </IonButton>
                                        </IonCol>
                                    }

                                    <IonCol style={{ display: "flex" }}>
                                        <IonButton
                                            style={{ "--box-shadow": "none" }}
                                            className="ion-button-money"
                                            style={{ margin: "auto", marginTop: "auto", marginBottom: "auto" }}
                                            onClick={() => {
                                                setSaving(true)
                                            }}
                                        >
                                            Save
                                        </IonButton>
                                    </IonCol>
                                </IonRow>

                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },

        CustomTagsCard: () => {

            return (
                <div style={{ paddingRight: 10 }}>
                    {incomeExpenseModal()}

                    <TagSelector 
                        taxCats={taxCats}
                        type={selectedTaxType}
                        show={showTagSelector.show} 
                        onDidDismiss={() => setShowTagSelector(false)} 
                        onClick={(item) => {
                            setTaxCat(item.id);
                            setShowTagSelector(false)
                        }} />

                    <Card style={styles.cardStyle}>
                        <CardContent style={{ minHeight: 300 }}>
                            <IonGrid>
                                <IonRow style={{ height: '100%' }}>

                                    <IonCol style={{ textAlign: "left" }}>
                                        <h3>
                                            My tags
                                        </h3>
                                        <div className={`ag-theme-alpine grid-container`} style={{ height: 300 }}>
                                            <AgGridReact

                                                modules={AllCommunityModules}
                                                rowData={customTags}
                                                columnDefs={constants.customTagsColumnDefs}
                                                pagination={false}
                                                rowHeight={30}
                                                onGridReady={onGridReady}
                                                suppressNoRowsOverlay={true}
                                            />
                                        </div>

                                        <div>
                                            <IonRow style={{ height: 400, minWidth: 780 }}>

                                            <IonCol>


                                                <IonItem
                                                    // lines = "full"
                                                    className="custom-tags-input"
                                                // style = {{ "--padding": 0, marginRight: 5}}
                                                >
                                                    <IonLabel position="stacked" className="custom-tag-style"><h2>Title</h2></IonLabel>
                                                    <IonInput
                                                        value={inputTagTitle}
                                                        placeholder="Title"
                                                        onIonChange={(e) => { setInputTagTitle(e.target.value) }}
                                                        readonly={tagActionCaption === "Update"}
                                                        // className="my-tax-selector-input left-indent"
                                                        className="custom-filter-payments"
                                                        style={{ paddingLeft: 0, }} >
                                                    
                                                    </IonInput>
                                                </IonItem>

                                                </IonCol>


                                                <IonCol>


                                                    <IonItem
                                                        className="custom-tags-input"
                                                        style={{minWidth:190}}                      
                                                    >
                                                        <IonLabel position="stacked" className="custom-tag-style"><h2>Transaction type</h2></IonLabel>
                                                        <IonInput
                                                            value={selectedTaxType ? taxTypes.filter(t => t.value == selectedTaxType)[0].type : "Choose a transaction type"} readonly
                                                            onClick={(e) => { setShowIncomeExpense(true) }}

                                                            // className="my-tax-selector-input left-indent"
                                                            className="custom-filter-payments"
                                                            style={{ paddingLeft: 0, }} >
                                                            <div className="my-caret-dropdown-icon" />
                                                        </IonInput>
                                                    </IonItem>

                                                </IonCol>


                                                <IonCol>

                                                <IonItem className="custom-tags-input" style={{minWidth:190}}  >
                                                    <IonLabel position="stacked" className="custom-tag-style" style={{ paddingLeft: 2, }}><h2>Main tax tag</h2></IonLabel>
                                                    <IonInput disabled={selectedTaxType.length === 0} value={taxCats.find(el => el.id === taxCat) ? taxCats.find(el => el.id === taxCat).title : "Select"} readonly onClick={() => {
                                                            setShowTagSelector({ show: true, id: "" })
                                                            //setTagClickFunction("filterbar")
                                                        }}
                                                        className="my-tax-selector-input left-indent">
                                                        <div className="my-caret-dropdown-icon" />
                                                    </IonInput>
                                                </IonItem>

                                                </IonCol>
                                                <IonCol>


                                                    <IonItem
                                                        className="custom-tags-input"
                                               
                                                    >
                                                        <IonLabel position="stacked"><h2>Description</h2></IonLabel>
                                                        <IonInput
                                                            value={inputTagDescription}
                                                            onIonChange={(e) => { setInputTagDescription(e.target.value) }}
                                                            placeholder="Description"
                                                            // className="my-tax-selector-input left-indent"
                                                            className="left-indent"
                                                            style={{ margin: "8px 0px, 0px" }} >
                                                          
                                                        </IonInput>
                                                    </IonItem>

                                                </IonCol>

                                                <IonCol>


                                                <IonItem
                                                    // lines = "full"
                                                    className="custom-tags-input"
                                                // style = {{ "--padding": 0, marginRight: 5}}
                                                >
                                                    <IonLabel position="stacked" className="custom-tag-style"><h2>Tagname</h2></IonLabel>
                                                    <IonInput
                                                        value={inputTagName}
                                                        placeholder="Tagname"
                                                        onIonChange={(e) => { setInputTagName(e.target.value) }}
                                                        
                                                        // className="my-tax-selector-input left-indent"
                                                        className="custom-filter-payments"
                                                        style={{ paddingLeft: 0, }} >
                                                    
                                                    </IonInput>
                                                </IonItem>

                                                </IonCol>


                                                <IonCol>
                                                
                                                <IonButton
                                                    className="ion-button-money"
                                                    style={{paddingTop:20}}
                                                    onClick={() => {
                                                       fetchService.addCustomTag()
                                                    }}>
                                                    {tagActionCaption}
                                                </IonButton>

                                                {tagActionCaption === "Update" &&
                                                <IonButton
                                                    className="ion-button-money"
                                                    style={{paddingTop:20}}
                                                    onClick={() => {
                                                        setEditingTag(0)
                                                        setInputTagName('')
                                                        setInputTagTitle('')
                                                        setInputTagDescription("")
                                                        setSelectedTaxType("E")
                                                        setTaxCat(0)
                                                        setTagActionCaption('Add')
                                                    }}>
                                                    Cancel
                                                </IonButton>}

                                                </IonCol>


                                            </IonRow>


                                            <IonRow>
                                                    <IonCol>
                                                        <p style={{fontWeight:'700'}}>Keep the tagname short as this is what shows up in the transaction feed - max length 25 characters but 12 best for mobile. Title and description are used in the tag selector. </p>
                                                    </IonCol>
                                            </IonRow>        

                                        </div>


                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>

                    <IonAlert
                        isOpen={showDeleteTag.show}
                        onDidDismiss={() => setShowDeleteTag({...showDeleteTag, show: false})}
                        header={'Delete tag'}
                   
                        message={'Are you sure you want to delete this tag?'}
                        buttons={[
                            {
                              text: 'No',
                              handler: () => {
                                setShowDeleteTag({...showDeleteTag, show: false})
                              }
                            },
                            {
                              text: 'Yes, please',
                              handler: () => {
                                //this.saveRule();
                                fetchService.delCustomTag(showDeleteTag.id, showDeleteTag.title, showDeleteTag.tax_cat_id)
                                setShowDeleteTag({...showDeleteTag, show: false})
                              }
                            }
                          ]}
                    />

                </div>
            )
        },
        AgentCard: () => {
            return (
                <div style={{ paddingRight: 10 }}>
                    <Card style={styles.cardStyle}>
                        <CardContent style={{ minHeight: 300 }}>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ textAlign: "left", minWidth: 250 }} size="3">
                                        <h3>
                                            Adviser management
                                        </h3>
                                        <div>
                                            <p>Enter adviser code <a href="https://help.untied.io/article/111-adding-an-advisor-or-accountant-to-your-untied-account" target="_blank" style={{ position: "relative" }}><InfoIcon style={styles.infoIcon} /></a></p>
                                            <div style={{ display: "inline-block", width: "40%" }}>
                                                <CustomFilter
                                                    label=" "
                                                    value={agent}
                                                    style={{ marginTop: 0 }}
                                                    onChange={(e) => handlers.setAgent(e.target.value)}
                                                />
                                            </div>
                                            <p>Any problems, go to <a href="https://help.untied.io/" target="_blank" style={{ color: "#00338d" }}>help</a> to contact us.</p>
                                            <Button
                                                style={styles.smallButtonContainer}
                                                onClick={() => {
                                                    setShow({ ...show, showLoading: true });
                                                    fetchService.addAgent()
                                                }}
                                            >
                                                Add adviser
                                            </Button>
                                        </div>
                                    </IonCol>
                                    <IonCol style={{ textAlign: "left" }}>
                                        <h3>
                                            My advisers
                                        </h3>
                                        <div className={`ag-theme-alpine grid-container`}>
                                            <AgGridReact
                                                modules={AllCommunityModules}
                                                rowData={agents}
                                                columnDefs={constants.columnDefs}
                                                pagination={false}
                                                rowHeight={30}
                                                onGridReady={onGridReady}
                                                suppressNoRowsOverlay={true}
                                            />
                                        </div>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },
        OnTrialCard: () => {
            return (
                <div style={{ paddingRight: 10 }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ textAlign: "left" }}>

                                        <p>Current plan: <span style={{ fontWeight: 700 }}>{Number(subscription.plan) === 2 ? "untied Pro Trial" : "untied Lite Trial"}</span></p>
                                        <p>Trial expires: <span style={{ fontWeight: 700 }}>{subscription.trial_expires}</span></p>
                                        <p>Upgrade to Pro and get access to powerful features that will help you stay on top of your taxes!</p>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <ul>
                                                    <li>Link unlimited bank accounts</li>
                                                    <li>Intelligent tagging</li>
                                                    <li>All supported income streams*</li>
                                                    <li>Automatic journey logging</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li>Tax optimisation</li>
                                                    <li>Submit tax return to HMRC</li>
                                                    <li>File without an HMRC login</li>
                                                    <li>Priority support</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p style={{ fontSize: 10 }}>* Supported income streams: Self-employment income &#60; £85k; Property income &#60; £85k; PAYE salary income; Dividend income; Bank interest; Capital gains</p>

                                        <div style={{ display: "flex" }}>
                                            <div style={styles.planCardContainer}>
                                                <IonCard
                                                    style={styles.planCardContent}
                                                    onClick={() => {
                                                        if (selectedScheme !== "1") setSelectedScheme("1");
                                                        else setSelectedScheme("")
                                                    }}
                                                    className={selectedScheme === "1" ? "plan-card-content-selected" : ""}
                                                >
                                                    <IonCardHeader style={{ padding: 0, height: 30 }}>
                                                    </IonCardHeader>

                                                    <IonCardContent style={{ color: "#000000", marginTop: 10 }}>
                                                        Pro monthly
                                                        <p style={{ color: "#00338d", padding: 0 }}>£ 9.99</p>
                                                    </IonCardContent>
                                                </IonCard>
                                            </div>
                                            <div style={styles.planCardContainer}>
                                                <IonCard
                                                    style={styles.planCardContent}
                                                    onClick={() => {
                                                        if (selectedScheme !== "2") setSelectedScheme("2");
                                                        else setSelectedScheme("")
                                                    }}
                                                    className={selectedScheme === "2" ? "plan-card-content-selected" : ""}
                                                >
                                                    <IonCardHeader style={{ backgroundColor: "#00338d", padding: 0, height: 30 }}>
                                                        <span style={{ color: "#ffffff", lineHeight: 2 }}>Best value</span>
                                                    </IonCardHeader>

                                                    <IonCardContent style={{ color: "#000000", marginTop: 10 }}>
                                                        Pro yearly
                                                        <p style={{ color: "#00338d", padding: 0 }}>£ 99.99</p>
                                                    </IonCardContent>
                                                </IonCard>
                                            </div>

                                        </div>

                                        {subscription.offercode && subscription.offercode !== "" && annualDiscount != 0 && annualDiscount != null && <div>
                                            <p style={{ textAlign: 'center', paddingLeft: 50, paddingRight: 50 }}>Your discount code (<strong>{subscription.offercode}</strong>)
                                                will automatically be applied during checkout. This gives you <strong>{annualDiscount} off the yearly plan price shown above</strong>.</p>
                                        </div>}

                                        <div style={{ display: "flex", height: 70 }}>
                                            <div style={{ display: "block", margin: "auto" }}>
                                                <Button style={selectedScheme !== "" ? styles.smallButtonContainer : styles.disabledButton}
                                                    disabled={selectedScheme === ""}
                                                    onClick={() => handlers.buyPlan(selectedScheme)}
                                                >
                                                    Upgrade now
                                                </Button>
                                            </div>
                                        </div>

                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },
        OnPlanCard: () => {
            return (
                <div style={{ paddingRight: 10 }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ textAlign: "left" }}>
                                        <h3>Plan management</h3>
                                        <p>Current plan: <span style={{ fontWeight: 700 }}>{Number(subscription.plan) === 2 ? "untied Pro" : "untied Lite"} {subscription.sku === 'untiedy1' ? 'annual' : 'monthly'}</span></p>
                                        {subscription.cancels_on === "" && <p>Next billing date: <span style={{ fontWeight: 700 }}>{handlers.prettyDate(subscription.next_instalment)}</span></p>}
                                        {subscription.cancels_on !== "" && <p>Your subscription cancels on <span style={{ fontWeight: 700 }}>{handlers.prettyDate(subscription.cancels_on)}</span></p>}

                                        {subscription.store === "untied" && subscription.cancels_on === "" &&
                                            <>
                                                <p>Subscribed through: <span style={{ fontWeight: 700 }}>{handlers.getStore()}</span></p>
                                                <p style={{ textAlign: 'left' }}>
                                                    <IonButton
                                                        className="ion-button-inverse"
                                                        style={{ height: 30, paddingRight: 5 }}
                                                        href={subscription.update_url}
                                                    >
                                                        Update payment details
                                                    </IonButton>
                                                    <IonButton
                                                        className="ion-button-inverse"
                                                        style={{ height: 30, paddingRight: 5 }}
                                                        href={subscription.cancel_url}
                                                    >
                                                        Cancel subscription
                                                    </IonButton>
                                                    {Number(subscription.plan) === 3 && <IonButton
                                                        className="ion-button-inverse"
                                                        style={{ height: 30, paddingRight: 5 }}
                                                        onClick={() => {
                                                            setShow({ ...show, upgrade: true });
                                                        }}>
                                                        Upgrade to Pro
                                                    </IonButton>}
                                                    {Number(subscription.plan) === 2 && <IonButton
                                                        className="ion-button-inverse"
                                                        style={{ height: 30, paddingRight: 5 }}
                                                        onClick={() => {
                                                            setShow({ ...show, downgrade: true });
                                                        }}>
                                                        Downgrade to Lite
                                                    </IonButton>}
                                                </p>
                                            </>}
                                        {subscription.store !== "untied" && <p>Cancel by visiting {handlers.getUrlToStore()}</p>}
                                        <p>If you need any help with your subscription, please <a className="stay-blue-link" href="https://www.untied.io/contact" target="_new">contact us</a>.</p>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            )
        },
        PasswordCard: () => {
            return (
                <Card style={{ marginRight: 0, paddingBottom: 0, minWidth: 600 }} id="password_card">
                    <CardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol style={{ textAlign: "left" }}>
                                    <h3>
                                        Set new password
                                    </h3>

                                    {!authCodeValid && (
                                        <div>
                                            <p>To change your password press the button below to get an auth code sent to your registered email address:</p>

                                            <IonButton className="ion-button-money"
                                                onClick={() => {
                                                    fetchService.sendAuthCode()
                                                    setSendAuthCode(true);
                                                }}
                                            >Send auth code</IonButton>

                                            <div style={{ marginLeft: "10px", minWidth: 250 }}>


                                                <AuthCodeInput
                                                    id="passwordAuthCode"
                                                    label="Enter auth code"
                                                    value={authCode}
                                                    onChange={setAuthCode}
                                                />

                                            </div>


                                        </div>
                                    )}

                                    <>
                                        <div style={{ display: "flex" }}>

                                            <div style={{ marginLeft: "10px", minWidth: 250 }}>
                                                <PasswordInput
                                                    id="newPassword"
                                                    label="Enter new password"
                                                    value={password}
                                                    onChange={setPasswordService.newPass}
                                                />
                                                <div style={{ display: "flex", marginLeft: 10, }}>
                                                    <div>{validPass.newPass.hasCharactersLength ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>10 characters long</div>
                                                </div>

                                                <div style={{ display: "flex", marginLeft: 10 }}>
                                                    <div>{validPass.newPass.hasCapitalLetter ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>1 capital letter</div>
                                                </div>

                                                <div style={{ display: "flex", marginLeft: 10 }}>
                                                    <div>{validPass.newPass.hasNumber ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>1 number</div>
                                                </div>
                                            </div>
                                            <div style={{ marginLeft: "10px", minWidth: 250 }}>
                                                <PasswordInput
                                                    id="confirmNewPassword"
                                                    label="Confirm new password"
                                                    value={newPassword}
                                                    onChange={setPasswordService.confirmPass}
                                                />
                                                <div style={{ display: "flex", marginLeft: 10 }}>
                                                    <div>{validPass.confirmPass.hasCharactersLength ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>10 characters long</div>
                                                </div>

                                                <div style={{ display: "flex", marginLeft: 10 }}>
                                                    <div>{validPass.confirmPass.hasCapitalLetter ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>1 capital letter</div>
                                                </div>

                                                <div style={{ display: "flex", marginLeft: 10 }}>
                                                    <div>{validPass.confirmPass.hasNumber ? <CheckIcon style={{ fontSize: "medium", marginTop: 2, color: "#00338d" }} /> : <ClearIcon style={{ fontSize: "medium", marginTop: 2 }} />}</div>
                                                    <div>1 number</div>
                                                </div>
                                            </div>
                                        </div>


                                        <div style={{ marginTop: 20 }}>
                                            <Button
                                                style={setPasswordService.validPasswords() ? styles.smallButtonContainer : styles.disabledButton}
                                                disabled={!setPasswordService.validPasswords()}
                                                onClick={() => {
                                                    setShow({ ...show, showLoading: true });
                                                    fetchService.changePassword()
                                                }}
                                            >
                                                Set password
                                            </Button>
                                        </div>
                                    </>



                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>

            );
        },
        TelephoneCard: () => {
            return (
                <Card style={{ height: "100%", paddingBottom: 0 }}>
                    <CardContent>
                        <IonGrid>
                            <IonRow>
                                <IonCol style={{ textAlign: "left" }}>
                                    <h3>
                                        Change my mobile
                                    </h3>

                                    <p>Current number: <span style={{ fontWeight: 700 }}>{profile_atom.phone}</span></p>

                                    {changePhone.message != "" &&
                                        (<p>{changePhone.message}</p>)
                                    }

                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: 270, position: "relative" }}>
                                            <CustomFilter
                                                label="Enter new number"
                                                value={changePhone.phoneNumber}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 15) e.target.value = e.target.value.substring(0, 15);
                                                    setPhoneNumberService.handleProvideInput(e.target.value, "phoneNumber")
                                                }}
                                            />
                                            {changePhone.phoneNumber.length > 0 && <>
                                                {
                                                    setPhoneNumberService.validateNumber(changePhone.phoneNumber, minPhoneLength) ?
                                                        <span
                                                            className="stay-blue-link"
                                                            style={{ position: "absolute", right: 75, bottom: 5, cursor: "pointer", textDecoration: "underline" }}
                                                            onClick={fetchService.getVerificationCode}
                                                        >Verify
                                                            <CheckCircleIcon style={{ fontSize: 24, position: "absolute", bottom: 0, color: "#229954", opacity: 0.5, cursor: "default", pointerEvents: "none" }} /></span> :
                                                        <ErrorRoundedIcon style={{ fontSize: 24, position: "absolute", bottom: 0, color: "#C0392B", opacity: 0.5 }} />
                                                }
                                            </>}
                                        </div>
                                        <div style={{ marginLeft: 10, width: 270, position: "relative" }}>
                                            <CustomFilter
                                                label="Verification code"
                                                value={changePhone.verificationCode}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 8) e.target.value = e.target.value.substring(0, 8);
                                                    setPhoneNumberService.handleProvideInput(e.target.value, "verificationCode")
                                                }}
                                            />
                                            {changePhone.verificationCode.length > 0 && <>
                                                {
                                                    setPhoneNumberService.validateNumber(changePhone.verificationCode, 4) ?
                                                        <CheckCircleIcon style={{ fontSize: 24, position: "absolute", bottom: 0, color: "#229954", opacity: 0.5 }} /> :
                                                        <ErrorRoundedIcon style={{ fontSize: 24, position: "absolute", bottom: 0, color: "#C0392B", opacity: 0.5 }} />
                                                }
                                            </>}
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 20 }}>
                                        <Button style={setPhoneNumberService.validateNumber() ? styles.smallButtonContainer : styles.disabledButton}
                                            disabled={!setPhoneNumberService.validateNumber()}
                                            onClick={fetchService.setNewPhoneNumber}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>
            )
        },
        PreferenceCard: () => {
            return (
                <div style={{ paddingRight: 10, paddingBottom: 80 }}>
                    <Card style={styles.cardStyle}>
                        <CardContent>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ textAlign: "left" }}>
                                        <h3>
                                            Communication preferences
                                        </h3>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={show.isChecked}
                                                    onChange={value => handlers.setUnsubscribe(value)}
                                                    name="Subscribe"
                                                />}
                                            label="Receive untied monthly summary email"
                                        />
                                        <p>
                                            If you wish to unsubscribe from our regular newsletter that contains updates, tips and guidance <a href="https://untied.us18.list-manage.com/unsubscribe?u=680bce75d2db1530b6abf02cd&id=e0b6c06316" target="_blank">click here</a>
                                        </p>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </CardContent>
                    </Card>
                </div>
            );
        },
        Alerts: () => {
            return (<>
                <IonAlert
                    isOpen={show.showHelp}
                    onDidDismiss={() => setShow({ ...show, showHelp: false })}
                    header={help.helpMainTitle || 'Help'}
                    subHeader={help.helpTitle}
                    message={help.helpText}

                    buttons={[
                        {
                            text: 'Translate',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                const text = document.body.innerText;
                                const url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');
                            }
                        },
                        'OK'
                    ]}
                />

                <IonAlert
                    isOpen={!show.validPhone}
                    onDidDismiss={() => setShow({ ...show, validPhone: true })}
                    header={'Error'}
                    subHeader={'Invalid phone number'}
                    message={'Please check your phone number is valid. If it is not a UK number please start the number with + followed by the country code. E.g. +34XXXXXXXXXX'}
                    buttons={[
                        {
                            text: 'Translate',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                const text = document.body.innerText;
                                const url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');
                            }
                        },
                        'OK'
                    ]}
                />

                <IonAlert
                    isOpen={!show.validCode}
                    onDidDismiss={() => setShow({ ...show, validCode: true })}
                    header={'Error'}
                    subHeader={'Invalid code'}
                    message={'Sorry that code is not valid. Please try again or cancel and resend.'}
                    buttons={[
                        {
                            text: 'Translate',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                const text = document.body.innerText;
                                const url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');
                            }
                        },
                        'OK'
                    ]}
                />

                <IonAlert
                    isOpen={show.upgrade}
                    onDidDismiss={() => setShow({ ...show, upgrade: false })}
                    header={"Upgrade to untied Pro"}
                    message={`This will upgrade your plan to untied ${subscription.paddle_sku === '630445' ? 'Pro for deliveroo' : 'Pro'}. Your ${subscription.sku === 'untiedy1' ? 'annual' : 'monthly'} amount will change to ${subscription.sku === 'untiedy1' ? (subscription.paddle_sku === '630445' ? '£49.99' : '£99.99') : (subscription.paddle_sku === '630445' ? '£4.99' : '£9.99')} and your payment method will be charged a pro-rated amount for the current period. Do you wish to proceed?`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                //
                            }
                        },
                        {
                            text: 'Upgrade',
                            handler: () => {
                                setSubscription({ ...subscription, plan: 2 });
                                fetchService.upgradeDowngrade()
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={show.downgrade}
                    onDidDismiss={() => setShow({ ...show, downgrade: false })}
                    header={"Downgrade to untied lite"}
                    message={`This will downgrade your plan to untied lite. You will lose access to open banking and journey logging. Your ${subscription.sku === 'untiedy1' ? 'annual' : 'monthly'} amount will change to ${subscription.sku === 'untiedy1' ? '£49.99' : '£4.99'} minus any credit remaining on balance for the current period. Do you wish to proceed?`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                //
                            }
                        },
                        {
                            text: 'Downgrade',
                            handler: () => {
                                setSubscription({ ...subscription, plan: 3 });
                                fetchService.upgradeDowngrade()
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={show.showUpgraded}
                    onDidDismiss={() => setShow({ ...show, showUpgraded: false })}
                    header={'Upgrade'}
                    subHeader={''}
                    message={upgradeMessage}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            setUpgradeMessage("")
                        }
                    }]}
                />

                <IonAlert
                    isOpen={sendAuthCode}
                    onDidDismiss={() => setShow({ ...show, showUpgraded: false })}
                    header={'Sent auth code'}
                    subHeader={''}
                    message={"Please check your email for your auth code."}
                    buttons={[{
                        text: 'OK',
                        handler: () => {
                            setUpgradeMessage("")
                        }
                    }]}
                />

            </>)
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    // useEffect(()=>{
    //     if(showConfirmVerification){
    //         window.scrollTo({top: 0, behavior: "smooth"})
    //         disableScroll()
    //     }
    // }
    // ,[showConfirmVerification])

    useEffect(() => {
        if (selected == "Invoice settings" && user_atom.token) {
            fetch(`https://${global.api_url}/invoiceSettings.php?ts=${getTimestamp()}`,
                {
                    method: "GET",
                    headers: {
                        token: user_atom.token,
                        // "Content-Type": "application/json" 
                    },
                })
                .then(res => res.json())
                .then(json => {
                    if (json.status == "success" || json.status == "queued") {
                      
                        if (json.settings) {
                            setTitle(json.settings.title);
                            setTagline(json.settings.tagline);
                            setImageData(json.settings.logo);

                            if (json.settings.address) {
                                let lbreaks = json.settings.address.split("\n").length
                                if (lbreaks && lbreaks < 3) lbreaks = 3
                                setInvoiceLinebreaks(lbreaks)
                                setInvoiceAddress(json.settings.address)
                            }
                            if (json.settings.postcode) setPostcode(json.settings.postcode);
                            if (json.settings.phone) setPhone(json.settings.phone);

                            if (!json.settings.address || !json.settings.postcode || !json.settings.phone) {
                                getProfileForInvoice(json.settings.address, json.settings.postcode, json.settings.phone)
                            }
                        }
                    } else {
                        //   Alert.alert("Error retrieving invoice settings. ");
                    }
                }
                );
        }

        if (selected == "Custom tags" && user_atom.token) {
            fetchService.getCustomTags()
        }

    }, [selected]);

    useEffect(() => {
        if (selected == "Invoice settings" && saving) {
            console.log("saving");
            fetch(`https://${global.api_url}/invoiceSettings.php?ts=${getTimestamp()}`,
                {
                    method: "POST",
                    headers: {
                        token: user_atom.token,
                        // "Content-Type": "application/json" 
                    },
                    body: JSON.stringify({ title, tagline, imageData, phone, address: invoiceAddress, postcode }),
                })
                // .then(res => res.text())
                // .then(t => console.log(t))
                .then(res => res.json())
                .then(json => {
                    console.log(json);
                    if (json.status == "success" || json.status == "queued") {
                        setSaving(false);
                        Mixpanel.track("saved_invoice_settings");
                        setSavedData(true)
                        // props.navigation.goBack(null);
                    } else {
                        // Alert.alert("Error saving invoice settings", json.message);
                        setSaving(false);
                    }
                }
                );
        }
    }, [saving]);

    useEffect(() => {

        if (!recoilFetched) {
            if (!user_atom.token) {
                let userState = getLocalStorage();
                setUserAtom(userState);
                fetchService.getProfile(userState.token, userState.agent);
                fetchService.getSubscription(userState.token, userState.agent)
            } else if (JSON.stringify(profile_atom) === "{}") {
                fetchService.getProfile();
                fetchService.getSubscription(user_atom.token, user_atom.agent)
            } else {
                getProfile()
                // setName(profile_atom.name);
                // setAddress(profile_atom.address);
                // setPostcode(profile_atom.postcode);
                // setPhoneVerified(profile_atom.phone_verified);
                // setDob(profile_atom.dob);
                // setNin(profile_atom.nin);
                // setUtr(profile_atom.utr);
                // setOriginalNumber(profile_atom.phone_verified === 'Y' ? profile_atom.phone : "");
                // setGatewayId(profile_atom.gateway_id);
                setShow({ ...show, isChecked: profile_atom.send_monthly !== "N", loaded: true });
                setRecoilFetched(true);
            }
        }
    }, []);

    // useEffect(() => {
    //     if (recoilFetched && profile_atom){
    //     firstTimeRender.current = false;
    //         setName(profile_atom.name)
    //         setAddress(profile_atom.address)
    //         setPostcode(profile_atom.postcode)
    //         setPhone(profile_atom.phone)
    //         setPhoneVerified(profile_atom.phone_verified)
    //         setDob(profile_atom.dob)
    //         setNin(profile_atom.nin)
    //         setUtr(profile_atom.utr)
    //         setOriginalNumber(profile_atom.phone_verified == 'Y' ? profile_atom.phone : "")
    //     }
    //   }, [recoilFetched, profile_atom]);



    // useEffect(() => {
    //     if (show.loaded && recoilFetched){
    //     fetchService.saveProfile()
    //     }
    // }, [show.isChecked]);

    useEffect(() => {
        if (recoilFetched) {
            firstTimeRender.current = false;
            fetchService.getAgents();
            fetchService.getSubscription(user_atom.token, user_atom.agent);
        }
    }, [recoilFetched]);

    useEffect(() => {
        if (show.loaded && recoilFetched) {
            setShow({ ...show, showUpgraded: true });
        }
    }, [upgradeMessage]);

    useEffect(() => {
        if (recoilFetched && subscription) {
            if (props.location.state && props.location.state.state == "Security") {
                setSelected("Security")
            }
            if (props.location.state && props.location.state.state == "Manage your plan") {
                setSelected("Manage your plan")
            }
            if (props.location.pathname == '/plan') {
                setPlanOnly(true)
                setSelected("Manage your plan")
            }
        }
    }, [subscription]);


    useEffect(() => {
        if (phone && (phone == originalNumber) && first) {
            saveProfile()
        }
        if (recoilFetched) {
            setFirst(true)
        }
    }, [originalNumber])
    //  TOP LINE OF BROUGHT OVER PROFILE STUFF


    const getProfile = (passed_token = user_atom.token, passed_agent = user_atom.agentId) => {
        fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agent }
        })
            .then(res => res.json())
            .then(json => {
                let lbreaks = json.address ? json.address.split("\n").length : 0
                setLinebreaks(lbreaks)
                localStorage.setItem('support_token_start', json.support_token_start);
                setProfileAtom(json);
                setName(json.name);
                setAddress(json.address);
                setPostcode(json.postcode);
                setPhoneVerified(json.phone_verified);

                var date
                if (json.dob == null) {
                    date = moment(new Date()).subtract(18, "years").format('YYYY-MM-DD')
                } else {
                    date = json.dob
                    if (date.includes('/')) {
                        date = date.split("/").reverse().join("-");
                    }
                    date = moment(new Date(date)).format('YYYY-MM-DD')
                }
                const advisor = localStorage.getItem("agent") 

                setHasCustomTags(json.custom_tags === 'Y' || advisor!=="");

                setDob(date)
                setNin(json.nin);
                setUtr(json.utr);
                setPhone(json.phone)
                setMoved(json.moved)
                setOriginalNumber(json.phone_verified === 'Y' ? json.phone : "");
                setGatewayId(json.gateway_id);
                setMinutes(getMinutes(json.support_token_start));
                setShow({ ...show, isChecked: json.send_monthly !== "N", loaded: true });
            })
    }

    const getProfileForInvoice = (passed_address, passed_postcode, passed_phone) => {
        fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agentId }
        })
            .then(res => res.json())
            .then(json => {
                if (!passed_address) {
                    let lbreaks = json.address ? json.address.split("\n").length : 0
                    setInvoiceLinebreaks(lbreaks)
                    setInvoiceAddress(json.address);
                }
                if (!passed_postcode) {
                    setPostcode(json.postcode);
                }
                if (!passed_phone) {
                    setPhone(json.phone)
                }
            })
    }


    const verifySMSCode = () => {
        fetch(
            `https://${global.api_url}/twilio/sendVerify.php`,
            {
                method: "POST",
                body: JSON.stringify({ phone_number: phone, code: smsCode }),
                headers: { token: user_atom.token, agentId: user_atom.agent }
            }
        )
            .then(res => res.json())
            .then(json => {
                console.log(json)

                if (json.approved) {
                    setShowPhoneVerify(false)
                    setShowConfirmVerification(false)
                    setOriginalNumber(phone)
                }
                if (json.approved == false) {
                    setShowHelp(true)
                    setHelp({
                        setHelphelpTitle: 'Invalid code',
                        helpMainTitle: 'Error',
                        helpText: "Sorry that code is not valid. Please try again or cancel and resend.",
                    })
                    setShowLoading(false)
                } else {
                    setShowPhoneVerify(false)
                    setOriginalNumber(phone)
                    setHelp({
                        ...help,
                        helpMainTitle: 'Help'
                    })
                }
            })
    }

    const sendSMSVerification = () => {
        fetch(
            `https://${global.api_url}/twilio/sendVerify.php`,
            {
                method: "POST",
                body: JSON.stringify({ phone_number: phone }),
                headers: { token: user_atom.token, agentId: user_atom.agent }
            }
        )
            .then(res => res.json())
            .then(json => {

                if (json.number == false) {
                    //pop up error about number ... 
                    setShowPhoneVerify(false)
                    setShowHelp(true)
                    setHelp({
                        helpTitle: 'Invalid phone number',
                        helpMainTitle: 'Error',
                        helpText: 'Please check your phone number is valid. If it is not a UK number please start the number with + followed by the country code. E.g. +34XXXXXXXXXX',
                    })
                    setShowLoading(false)
                } else {
                    setShowPhoneVerify(true)
                    setPhone(json.number)
                    setPhoneType(json.type)
                    setHelp({
                        ...help,
                        helpMainTitle: 'Help'
                    })
                }
            })

    }

    const saveProfile = () => {

        if (name === "" || name == null) {
            setShowHelp(true)
            setHelp({
                ...help,
                helpTitle: 'Name missing',
                helpText: 'Please enter your name.',
            })
            setShowLoading(false)
            return;
        }

        if (postcode === "" || postcode == null) {
            setShowHelp(true)
            setShowLoading(false)
            setHelp({
                ...help,
                helpTitle: 'Postcode missing',
                helpText: 'Please enter your postcode.',
            })
            return;
        }

        if (phone != null) {
            const regex = /^(\+{0,1})(\d*)$/;
            if (!regex.test(phone.replace(/\s+/g, ''))) {
                setShowHelp(true)
                setShowLoading(false)
                setHelp({
                    ...help,
                    helpTitle: 'Phone number invalid',
                    helpText: 'Please check your phone number.',
                })
                return;
            }
        }

        var payload = {
            name,
            address,
            postcode,
            nin,
            utr: utr ? utr.replace(" ", "") : "",
            // dob: dob ? moment(dob).format("DD/MM/YYYY") : "",
            dob: dob ? dob : "",
            phone,
            phone_verified: phone === originalNumber ? "Y" : "N"
        }

        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${getTimestamp()}`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                Mixpanel.track("saved_profile");
                setShowLoading(false)
                getProfile();

                //has phone number changed .... (and not empty or null)
                if ((phone != "") && (phone != null)) {
                    if ((phone != originalNumber)) {
                        //this.setState({ showPhoneVerify: true, showLoading: false })

                        //ask ... 
                        setShowLoading(false)
                        setShowConfirmVerification(true)
                    } else {
                        setShowSaved(true)
                    }
                } else {
                    setShowSaved(true)
                }

            })
    }


    const renderPhoneVerifyModal = () => {

        const message = phoneType == 'mobile'
            ? "We have sent you a text message with a verification code. Please wait for the text and then enter the verification code below to confirm your phone number:"
            : "We are calling your number with a verification code. Please stand by for a phone call and then enter the verification code provided below to confirm your phone number:";

        return (

            <IonPopover
                className="my-ion-popover"
                isOpen={showPhoneVerify}
                backdropDismiss={"false"}
                onDidDismiss={e => {

                    setShowPhoneVerify(false)
                }}
            >
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonTitle style={{ paddingLeft: 0 }}>We need to verify your number</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonText>{message}</IonText>
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem className="money-input">
                                <IonInput value={smsCode}
                                    onIonChange={e => setSmsCode(e.detail.value)}
                                    placeholder="Code" inputmode="numeric" type="number" />
                            </IonItem>
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                            <IonButton
                                className="ion-button-inverse"
                                onClick={() => {

                                    setShowPhoneVerify(false);
                                }}>Cancel</IonButton>
                        </IonCol>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                            <IonButton className="ion-button-money"
                                onClick={() => {

                                    verifySMSCode();
                                }}>Verify</IonButton>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonPopover>

        )
    }


    const profileDetails = () => {
        const verified_message = (phone === "") || (phone == null) ? "" : (phone === originalNumber) ? " (verified)" : " (unverified)";
        return (<>
            <div style={{ paddingRight: 10, minWidth: "400px" }}>
                <Card style={styles.cardStyle}>
                    <CardContent style={{ minHeight: 300, minWidth: 400 }}>
                        {renderPhoneVerifyModal()}
                        <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                            <h3 style={{ textAlign: "left", paddingLeft: "16px" }}>
                                Your profile
                            </h3>

                            <IonRow>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating">Name</IonLabel>
                                        <IonInput value={name} data-tip="Enter your name here"
                                            onIonChange={e => setName(e.detail.value)}
                                            placeholder="" inputmode="text" type="text" />
                                    </IonItem >
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="floating">Address</IonLabel>

                                        <IonTextarea
                                            onKeyPress={handleKeyDownAddress}
                                            placeholder=""
                                            value={address}
                                            onIonChange={e => setAddress(e.detail.value)}

                                            rows={linebreaks}
                                        ></IonTextarea>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating">Postcode</IonLabel>
                                        <IonInput value={postcode} data-tip="Your postcode"
                                            onIonChange={e => setPostcode(e.detail.value)}
                                            placeholder="" inputmode="text" type="text" />
                                    </IonItem >
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating">Phone number{verified_message}</IonLabel>
                                        <IonInput value={phone}
                                            onIonChange={e => setPhone(e.detail.value)}
                                            placeholder="" inputmode="tel" type="tel" />
                                    </IonItem >
                                </IonCol>
                            </IonRow>
                            <IonRow >
                                <IonCol >
                                    <IonItem lines="full">
                                        <IonLabel position="stacked" style={{ marginBottom: 15 }} >Date of birth</IonLabel>
                                        <input
                                            type="date"
                                            value={dob}
                                            style={{ border: "none", width: "100%", height: "fit-content", font: "400 14px Arial", }}
                                            onChange={e => {
                                                console.log(e.target.value)
                                                setDob(e.target.value)
                                            }}

                                        />
                                        {/* <IonDatetime displayFormat="DD MMM YYYY" placeholder="Select your date of birth" value={dob}
                                        style={{ "--padding-start": 0 }}
                                        onIonChange={e => setDob(e.detail.value )}></IonDatetime> */}
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="floating">National Insurance Number</IonLabel>
                                        <IonInput value={nin}
                                            onIonChange={e => setNin(e.detail.value)}
                                            placeholder="" inputmode="text" type="text" />
                                    </IonItem>
                                </IonCol>
                                <IonCol size="auto">

                                    <IonIcon icon={helpCircleOutline} size="large"
                                        onClick={() => {
                                            setShowHelp(true)
                                            setHelp({
                                                helpTitle: 'National Insurance Number',
                                                helpText: 'Your National Insurance Number or NINO is made up of letters and numbers and never changes. You can find it on your payslip; ' +
                                                    'on your P60; on letters about your tax, pension or benefits; or ' +
                                                    'in the National Insurance section of your <a href="https://www.gov.uk/personal-tax-account">personal tax account</a>.'
                                            })
                                        }} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating">Unique Taxpayer Reference</IonLabel>
                                        <IonInput value={utr}
                                            onIonChange={e => setUtr(e.detail.value)}
                                            placeholder="" inputmode="text" type="text" />
                                    </IonItem >
                                </IonCol>
                                <IonCol size="auto">
                                    <IonIcon icon={helpCircleOutline} size="large"
                                        onClick={() => {
                                            setShowHelp(true)
                                            setHelp({
                                                ...help,
                                                helpTitle: 'Unique Tax Reference',
                                                helpText: 'UTR stands for Unique Taxpayer Reference. It is assigned automatically by HMRC when you register for self assessment. ' +
                                                    'If you are not yet registered for self assessment you will need to do so if you need to submit a tax return. ' +
                                                    'You can do that <a href="https://www.gov.uk/log-in-file-self-assessment-tax-return/register-if-youre-not-self-employed">here on the HMRC website</a>.'
                                            })
                                        }} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                    <IonButton className="ion-button-money"
                                        onClick={() => {
                                            setShowLoading(true)
                                            let utrError = true
                                            if (utr) {
                                                utrError = checkUTR(utr)
                                            }
                                            if (!utrError) {
                                                setUTRError(true)
                                            }

                                            else {
                                                saveProfile();
                                            }
                                        }}>Save Profile</IonButton>
                                </IonCol>
                            </IonRow>


                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                    If you don't have any of this information, check your <a href="https://www.gov.uk/personal-tax-account" target="_new">personal tax account</a>.
                                    Any problems, <a href="https://help.untied.io/" target="_new">go to help to contact us</a>.
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonAlert
                            isOpen={showHelp}
                            onDidDismiss={() => setShowHelp(false)}
                            header={help.helpMainTitle || 'Help'}
                            subHeader={help.helpTitle}
                            message={help.helpText}
                            buttons={[
                                {
                                    text: 'Translate',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {
                                        var text = help.helpText;
                                        var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                        window.open(url, '_untiedTranslater');
                                    }
                                },
                                'OK'
                            ]}
                        />
                        <IonAlert
                            isOpen={UTRError}
                            onDidDismiss={() => setUTRError(false)}
                            header={'UTR error'}

                            message={"The unique tax reference you have provided does not match HMRC's format, please cancel and double check or save profile anyway"}
                            buttons={[
                                'Cancel',

                                {
                                    text: 'Save anyway',
                                    handler: blah => {
                                        saveProfile()
                                    }
                                },

                            ]}
                        />



                        {/* <IonAlert
                        isOpen={showConfirmVerification}
                        backdropDismiss = {"false"}
                        onDidDismiss={() => {
                            enableScroll()
                            setShowConfirmVerification(false)}}
                        header={'Verify phone number'}
                        message={"Your profile has been saved.<br>We noticed your phone number has changed, do you want to verify it now?<br>If you want, you can come back to this later."}
                        buttons={[
                            {
                                text: 'No',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: e => {
                                    enableScroll()
                                    setShowConfirmVerification(false)
                                }

                            },
                            {
                                text: 'Verify now',
                                handler: blah => {
                                    setShowConfirmVerification(false)
                                    sendSMSVerification()
                                }
                            },
                        ]}
                    /> */}
                        {/* <IonAlert
                        isOpen={showSaved}
                        onDidDismiss={() => setShowSaved(false)}
                        header={'Saved'}
                        subHeader={''}
                        message={'Your profile has been saved'}
                        buttons={['OK']}
                    /> */}
                        <IonPopover
                            isOpen={showSaved}
                            backdropDismiss={"false"}
                            style={{ width: "200px" }}
                            onDidDismiss={() => { setShowSaved(false) }}
                        >
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <h3 style={{ paddingBottom: "0px", paddingTop: "5px", margin: 0 }}>Saved</h3>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        Your profile has been saved.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton
                                            className="ion-button-inverse"
                                            onClick={() => {
                                                setShowSaved(false)
                                            }}>
                                            OK
                                        </IonButton>
                                    </IonCol>


                                </IonRow>

                            </IonGrid>
                        </IonPopover>

                        <IonPopover
                            isOpen={showConfirmVerification}
                            backdropDismiss={"false"}
                            style={{ width: "200px" }}
                            onDidDismiss={() => {

                                setShowConfirmVerification(false)
                            }}
                        >
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <h3 style={{ paddingBottom: "0px", paddingTop: "5px", margin: 0 }}>Verify phone number</h3>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        Your profile has been saved. <br /> We noticed your phone number has changed, do you want to verify it now?<br />If you want, you can come back to this later.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton
                                            className="ion-button-inverse"
                                            onClick={() => {

                                                setShowConfirmVerification(false)

                                            }}>
                                            No
                                        </IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton
                                            className="ion-button-money"
                                            onClick={() => {
                                                setShowConfirmVerification(false)
                                                sendSMSVerification()
                                            }}
                                        >
                                            Verify now
                                        </IonButton>
                                    </IonCol>


                                </IonRow>

                            </IonGrid>
                        </IonPopover>

                        <IonLoading
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                            duration={5000}
                        />

                    </CardContent>
                </Card >
            </div >
        </>

        );
    }
    //  BOTTOM LINE OF BROUGHT OVER PROFILE STUFF


    return (<React.Fragment>
        <IonAlert
            isOpen={savedData}
            header="Data saved"
            onDidDismiss={() => {
                setSavedData(false)
                setRedirect(true)
            }}
            buttons={["OK"]}
        />
        <IonAlert
            isOpen={upload.show}
            header={upload.error ? "Error uploading" : "Logo uploaded - still need to save to confirm"}
            message={upload.message}
            onDidDismiss={() => {
                setUpload({
                    show: false,
                    error: false
                })
            }}
            buttons={["OK"]}

        />

        <div className="profile-page-container">
            <MiniDrawer />

            {recoilFetched && subscription &&
                <div className="profile-page-content">
                    {optionBar()}
                    {renderService.EmailCard()}

                    {selected == "Your profile" && profileDetails()}


                    {subscription.in_trial && selected == "Manage your plan" && renderService.OnTrialCard()}

                    {selected == "Security" &&
                        <IonGrid style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginBottom: 0 }}>
                            <IonRow>
                                <IonCol size="6" style={{ paddingLeft: 0, paddingRight: 10 }}>

                                    {renderService.PasswordCard()}

                                </IonCol>

                                {/* <IonCol style={{paddingLeft:0, paddingRight:10}}>
        
                {renderService.TelephoneCard()}
      
            </IonCol> */}
                            </IonRow>
                        </IonGrid>
                    }

                    {!subscription.in_trial && selected == "Manage your plan" && renderService.OnPlanCard()}
                    {selected == "Adviser management" && renderService.AccessCard()}
                    {selected == "Adviser management" && renderService.AgentCard()}
                    {selected == "Custom tags" && renderService.CustomTagsCard()}
                    {selected == "Invoice settings" && renderService.InvoiceCard()}
                    {selected == "Manage your plan" && renderService.PreferenceCard()}
                    {renderService.Alerts()}
                </div>}
            <IonLoading
                isOpen={show.showLoading}
                onDidDismiss={() => setShow({ ...show, showLoading: false })}
                message={'Please wait...'}
                duration={5000}
            />
        </div>
    </React.Fragment>);

};


export default withRouter(Profile)
