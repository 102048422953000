import React, { useState, useRef, useEffect } from "react";
import { getTaxCalc, TaxBrkdown } from "../Common"

import { Mixpanel } from "../mixpanel";
import MiniDrawer from "../MiniDrawer";
import { Container } from "@material-ui/core";
import { withRouter, Link, Redirect } from "react-router-dom";
import { getTimestamp } from '../components/timeStamp.js'
import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { SelectionBar } from '../components/selectionBar.js';


import {
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    IonLoading,
    IonText,
    IonButton,


} from '@ionic/react';


import './Page.css';

const Partner_import2 = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [redirectForms, setRedirectForms] = useState(false)
    const [redirectEmployment, setRedirectEmployment] = useState(false)
    const [redirectCapital, setRedirectCapital] = useState(false)
    const [redirectPension, setRedirectPension] = useState(false)
    const [redirectResidence, setRedirectResidence] = useState(false)
    const [redirectTutorials, setRedirectTutorials] = useState(false)
    const [redirectReturn, setRedirectReturn] = useState(false)
    const [update, setUpdate] = useState(false)
    const firstTimeRender = useRef(true);

    const [box_1, setBox1] = useState('£0')
    const [box_2, setBox2] = useState('£0')

    const [box_4, setBox4] = useState('')
    const [box_5, setBox5] = useState('')

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [selectedYear, setSelectedYear] = useState(tax_years_atom.dateFilter);
    const [selectedYearData, setSelectedYearData] = useState({});
    const [taxCalc, setTaxCalc] = useState({});

    // const [years, setYears] = useState(tax_years_atom.taxYears);

    const divStyles = {
        display: "flex",
        marginTop: "20px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        minWidth: "300px",
        flexBasis: "300px",
        flexShrink: "0",
        marginBottom: "50px"
    };

    const ionGridStyle = {
        backgroundColor: '#f5f7fa',
        fontSize: '14px',
        borderRadius: '5px',
        width: "70%",
        marginBottom: 5,
        marginTop: 5
    }

    useEffect(() => {
        if (recoilFetched) {
            getTaxCalc(selectedYear, (json) => { setTaxCalc({ ...json }) })
        }
    }, [selectedYearData])


    useEffect(() => {
        if (!recoilFetched) {
            if (!user_atom.token) {
                let userState = getLocalStorage()
                setUserAtom(userState)
                getTaxYears(userState.token)
            }
            else {
                getTaxYears()



            }
        }
    }, [])

    useEffect(() => {
        if (JSON.stringify(tax_years_atom) !== '{}') {

            const yearElement = tax_years_atom.taxYears.find(element => {
                return element.id === tax_years_atom.dateFilter
            })

            setSelectedYear(tax_years_atom.dateFilter)
            setSelectedYearData({ ...yearElement })

            setRecoilFetched(true)
        }
    }, [tax_years_atom])

    const parseAmount = (value) => {
        if (value && value.length > 0) {
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if (value.indexOf(".") > -1) {
                const integers = value.split(".")[0] + ".";
                let digits = value.split(".")[1];
                if (digits.length > 2) digits = digits.substring(0, 2);
                if (digits.length < 2) digits = digits + "0"

                return "£" + integers + digits;
            } else {
                return "£" + value + ".00";
            }
        } else {
            return "";
        }
    };
   
    const getTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {

        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                console.log("recoil tax years success")
                console.log(json)
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    if (props.location && props.location.state && props.location.state.yearData) {
                        let year = props.location.state.yearData

                        setTaxYearsAtom(
                            {
                                taxYears: json.years,
                                dateFilterStart: year.state_date,
                                dateFilterEnd: year.end_date,
                                dateFilter: year.id
                            });

                    }
                    else {
                        let today = new Date();
                        let currentYearID = 0;
                        let dateFilterStart = "";
                        let dateFilterEnd = "";
                        json.years.forEach(element => {
                            let start = new Date(element.start_date);
                            start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0)

                            let end = new Date(element.end_date);
                            end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

                            if (today >= start && today <= end) {
                                currentYearID = element.id;
                                dateFilterStart = element.start_date;
                                dateFilterEnd = element.end_date;
                            }
                        });

                        setTaxYearsAtom(
                            {
                                taxYears: json.years,
                                dateFilterStart: dateFilterStart,
                                dateFilterEnd: dateFilterEnd,
                                dateFilter: currentYearID
                            });
                    }
                }
            })

    }



    const mainContent = () => {
        if (redirectReturn) {
            // window.location = "/taxReturn/form"
            return (
                <Redirect
                    to={{
                        pathname: "/taxReturn/form"
                    }}
                />
            )
        }
        return (<>
            <IonGrid style={ionGridStyle}>


                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <h3 className="tax-forms-card-title" style={{ margin: 0 }}>Data imported</h3>

                    </IonCol>
                </IonRow>

                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText>Your data has now been imported from {props.location.state.importedFrom}. If that is everything you can go ahead and submit your tax return. If you prefer you can come back later - head to 'Tax filings' in the menu whenever you are ready.
                            See below if you have other things you need to declare.</IonText>


                    </IonCol>
                </IonRow>


                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <h3 className="tax-forms-card-title" >Additional forms of income:</h3>

                    </IonCol>
                </IonRow>


                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText>If you have income from self-employment or wish to report charitable giving, COVID support payments or other forms of income/expenses please{" "}
                            <span className="fake-anchor" 
                            onClick={() => {
                            Mixpanel.track("tutorial_redirect_from_partner_import");
                            setRedirectTutorials(true)
                            }
                            }>see our tutorials</span> to find out what you need to do. You can also:</IonText>


                    </IonCol>
                </IonRow>

                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText> - </IonText><IonText className="fake-anchor" 
                        onClick={() => {
                        Mixpanel.track("pension_from_partner_import");
                        setRedirectPension(true)
                        }}>Add pensions, benefits and other tax reliefs</IonText>


                    </IonCol>
                </IonRow>


                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText> - </IonText><IonText className="fake-anchor" 
                        onClick={() => {
                        Mixpanel.track("capital_gains_redirect_from_partner_import");
                        setRedirectCapital(true)
                        }}>Add capital gains info eg from shares and securities</IonText>


                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText> - </IonText><IonText className="fake-anchor" 
                        onClick={() => {
                            Mixpanel.track("residence_redirect_from_partner_import");
                            setRedirectResidence(true)}}>Are you non-UK resident or domiciled outside of the UK, or eligible for Overseas Workday Relief</IonText>


                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}>

                        <IonText> - </IonText><IonText className="fake-anchor" 
                        onClick={() => {
                            Mixpanel.track("tax_forms_redirect_from_partner_import");
                            setRedirectForms(true)}}>Go here or click on 'Tax forms' in menu for more</IonText>

                    </IonCol>
                </IonRow>

                <IonRow>

                    <IonCol style={{ textAlign: 'left' }}>

                        <h3 className="tax-forms-card-title" >Submit your return</h3>

                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol style={{ textAlign: 'left', paddingTop: 10, fontWeight: 700 }}>

                        <IonText>If you are satisfied all the information you have imported is correct and wish to submit your return now click "submit return". Or you can submit your return later from the "Tax filings" page.</IonText>

                    </IonCol>
                </IonRow>


                <IonRow>
                    <IonCol>
                        <IonButton
                            className="ion-button-money"
                            onClick={() => {
                                Mixpanel.track("partner_import_return_submitted");
                                setRedirectReturn(true)
                            }}
                        >
                            Submit return
                        </IonButton>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <h3 className="tax-forms-card-title">Tax breakdown</h3>
                        <TaxBrkdown taxCalc={taxCalc} yearData={selectedYearData} />
                    </IonCol>
                </IonRow>

            </IonGrid>
        </>)
    }


    const getYear = () => {
        let year = tax_years_atom.taxYears.filter(y => { if (y.id == tax_years_atom.dateFilter) { return y } })
        let tax_year = year[0].tax_year
        return tax_year
    }

    const alerts = () => {
        return (<>
            <IonAlert
                isOpen={error}
                onDidDismiss={() => setError(false)}
                header={"Error saving emloyment information"}
                message={"Please double check the employment information and submit again"}
                buttons={['OK']}
            />
            <IonAlert
                isOpen={success}
                onDidDismiss={() => setSuccess(false)}
                header={"Emloyment information Saved"}
                message={""}
                buttons={['OK']}
            />
        </>)
    }

    return (

        <React.Fragment>

            {redirectTutorials && <Redirect
                to={{
                    pathname: "/tutorials"
                }}
            />}

            {redirectForms && <Redirect
                to={{
                    pathname: "/taxForms/menu",

                }}
            />}

            {redirectResidence && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA109", year: getYear() }
                }}
            />}

            {redirectPension && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA100", year: getYear() }
                }}
            />}

            {redirectCapital && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA108", year: getYear() }
                }}
            />}

            {redirectEmployment && <Redirect
                to={{
                    pathname: "/taxForms",
                    state: { form: "SA102", year: getYear() }
                }}
            />}

            <div style={divStyles}>
                <MiniDrawer />
                {/* {recoilFetched && */}
                <Container className="tax-breakdown-page-content">
                    {SelectionBar(recoilFetched, update)}
                    <div style={{ width: "80%", margin: "auto" }}>
                        {alerts()}
                        {mainContent()}
                    </div>

                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Please wait...'}
                        duration={30000}
                    />
                </Container>
                {/* } */}
            </div>
        </React.Fragment>)
};


export default withRouter(Partner_import2)
