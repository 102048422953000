import moment from "moment";

export const verifyTaxForm = (tax_column, data, tax_year) => {

    if (tax_column == 'self_employment') {

        let box_5_Date = moment(data[0].box_5, 'DD/MM/YYYY', true);
        let box_5_Valid = box_5_Date.isValid();

        let box_6_Date = moment(data[0].box_6, 'DD/MM/YYYY', true);
        let box_6_Valid = box_6_Date.isValid();

        let box_7_Date = moment(data[0].box_7, 'DD/MM/YYYY', true);
        let box_7_Valid = box_7_Date.isValid();

        let box_5_test = {
            "test": false,
            'messsage': ''
        }
        let box_6_test = {
            "test": false,
            'messsage': ''
        }
        let box_7_test = {
            "test": false,
            'messsage': ''
        }

        let box_5_date
        if (data[0].box_5) {
            box_5_date = data[0].box_5.split('/')
            box_5_date = `${box_5_date[2]}-${box_5_date[1]}-${box_5_date[0]}`
        }

        if (box_5_Valid) {
            box_5_test = taxFieldVerify(
                "date",
                data[0].box_5,
                {
                    "param1": tax_year.dateFilterStart,
                    "param2": tax_year.dateFilterEnd,
                },
                'box_5')
        }

        if (box_6_Valid) {
            box_6_test = taxFieldVerify(
                "date",
                data[0].box_6,
                {
                    "param1": tax_year.dateFilterStart,
                    "param2": tax_year.dateFilterEnd,
                    "param3": box_5_date
                },
                'box_6')
        }

        if (box_7_Valid) {
            box_7_test = taxFieldVerify(
                "date",
                data[0].box_7,
                {
                    "param1": tax_year.dateFilterStart,
                    "param2": tax_year.dateFilterEnd
                },
                'box_7')
        }
        if (box_5_test.test) {
            return { pass: false, open: true, alertTitle: "Invalid date", alertMessage: box_5_test.message };
        }
        if (box_6_test.test) {
            return { pass: false, open: true, alertTitle: "Invalid date", alertMessage: box_6_test.message };
        }
        if (box_7_test.test) {
            return { pass: false, open: true, alertTitle: "Invalid date", alertMessage: box_7_test.message };
        }

    }

    return { pass: true }

}

export const taxFieldVerify = (verificationType, data, params, box) => {
    if (verificationType == 'date' && data) {

        let date = data.split('/')
        date = `${date[2]}-${date[1]}-${date[0]}`
        let test = new Date(date)
        let testParam1 = new Date(params.param1)
        let testParam2 = new Date(params.param2)

        let testingStart = test > testParam1
        let testingEnd = test <= testParam2
        let testingCeased = true
        if (params.param3 && box == "box_6") {
            let testParam3 = new Date(params.param3)
            testingCeased = test >= testParam3
        }

        if (box == 'box_5') {
            if (!testingStart) {
                return {
                    "test": true,
                    "message": "Please check that the date in 'Date business started' is equal to or after the start of the year - 06/04/" + params.param1.substring(0, 4) + ". If your business started before this tax year you do not need to enter a date here."
                }
            }
            if (!testingEnd) {
                return {
                    "test": true,
                    "message": "The date entered for 'Date business started' is after the end of the tax year. If this is correct then you do not need to provide details for this year. Otherwise please check"
                }
            }
            return {
                "test": false,
                "message": ""
            }
        }

        if (box == 'box_6') {
            if (!testingStart) {
                return {
                    "test": true,
                    "message": "Please check that the date your business ceased is equal to or after the start of the year - 06/04/" + params.param1.substring(0, 4) + ". If your business did not stop trading in the year then remove the date."
                }
            }
            if (!testingEnd) {
                return {
                    "test": true,
                    "message": "The date entered for when your business ceased is after the end of the tax year. Please check. If your business did not stop trading in the year then remove the date."
                }
            }
            if (!testingCeased) {
                return {
                    "test": true,
                    "message": "The date you have entered for when your business started is after the date entered for when your business ceased. Please check."
                }
            }
            return {
                "test": false,
                "message": ""
            }
        }
        if (box == 'box_7') {
            if (!testingStart) {
                return {
                    "test": true,
                    "message": "Please check that the date in 'Date books made up to' is equal to or after the start of the year - 06/04/" + params.param1.substring(0, 4) + ". For most people this date should be set to 05/04/" + params.param2.substring(0, 4)
                }
            }
            if (!testingEnd) {
                return {
                    "test": true,
                    "message": "The date entered for 'Date books made up to' is after the end of the tax year. Please check. For most people this date should be set to 05/04/" + params.param2.substring(0, 4)
                }
            }
            return {
                "test": false,
                "message": ""
            }
        }
    }
}

