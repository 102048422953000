export const getLocalStorage = () => {
    let data = {
    guid: localStorage.getItem('guid'),
    token: localStorage.getItem("token"),
    admin_token: localStorage.getItem("admin_token"),
    admin: localStorage.getItem("admin"),
    username: localStorage.getItem("username"),
    mtd: localStorage.getItem("mtd"),
    has_sub: localStorage.getItem("has_sub"),
    in_trial: localStorage.getItem('in_trial'),
    plan: localStorage.getItem('plan'),
    sub_active: localStorage.getItem('subscription_active'),
    agent: localStorage.getItem('agent'),
    logged_in: localStorage.getItem('logged_in'),
    support_token_start: localStorage.getItem('support_token_start')
    }
    return data
}
