import React, { useState, useRef, useEffect } from "react";
import { Button } from "@material-ui/core";

import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const MAX_FILE_SIZE = 2097152;
const BYTES_RATIO = 1000;


const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / BYTES_RATIO);
const DropFile = ({label, handleUpload, fileFormatError, maxFileSizeInBytes = MAX_FILE_SIZE, clearFiles, setAttachments, ...otherProps}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles, fromPaste) => {
        for (let file of newFiles) {
            if(fromPaste) file = file.fileObject;
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = Object.keys(files).length > 0 ? convertNestedObjectToArray(files) : [];
        handleUpload(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (filename) => {
        let backup = {}
        let backupArray =[]
        let filenames = Object.keys(files)
        filenames.forEach(f=> {if(f!== filename){ 
            backup[f]= files[f]
            backupArray.push(files[f])
        }})
        setFiles(backup);
        setAttachments(backupArray)
       
    };

    const pasteCaption = (e) => {
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];
            const updatedFiles = addNewFiles([{fileObject}], true);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        } else {
            alert('There is no data in your clipboard. Please copy an image first or take a screenshot.');
        }
    };

    useEffect(() => {
        setFiles({})
    }, [fileFormatError]);

    useEffect(() => {
        if (clearFiles){
        setFiles({})
        }
    }, [clearFiles]);


    return (<>
    <div className="drop-file-container" 
    style ={{
        display: "flex",
        flexDirection: "row",
    }}>

        {<div style={styles.fileUploadContainer}>
            <h2 style={styles.infoText}>
                <BackupOutlinedIcon style={styles.iconStyle}/>
                {/* Drop files here or */}
                Drop files or
                <button type="button" style={styles.uploadFileButton} onClick={handleUploadBtnClick}>
                    browse
                </button>
                {/* to select */}
              
            </h2>
            <p style={{textAlign: "center", marginTop: 0, fontSize: 12}}>Files must be .jpg, .png, .jpeg or .pdf format. Max file size 2MB</p>

            <input style={styles.formField}
                   type="file"
                   ref={fileInputField}
                   onChange={handleNewFileUpload}
                   title=""
                   value=""
                   {...otherProps}
            />

            {/* {Object.keys(files).length > 0 && <>
                {Object.keys(files).map((fileName) => {
                    let file = files[fileName];
                    return(
                        <div key={fileName} style={styles.uploadedFileInfo}>
                            <div style={{width: "33%", textAlign: "left", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                {file.name}
                            </div>
                            <div style={{width: "33%", paddingLeft: 10, textAlign: "left"}}>
                                {convertBytesToKB(file.size)}KB
                            </div>
                            <div style={{width: "33%", textAlign: "right", position: "relative"}}>
                                <Button
                                    style={{marginRight: -20, padding: 0}}
                                    onClick={() => removeFile(fileName)}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </>} */}
        </div>}
        <div style={styles.pasteZone} onPaste={pasteCaption}>
            <h2 style={styles.pastedInfoText}>
                <FileCopyIcon style={styles.iconStyle}/>
                {/* Or just paste your screen capture here */}
                Paste an image
            </h2>
            <p style={{textAlign: "center", marginTop: 0, fontSize: 12}}>Files must be .jpg, .png or .jpeg. Max file size 2MB</p>
        </div>

    </div>
      {Object.keys(files).length > 0 && <>
        <div
        style ={styles.filesContainer}>
        {Object.keys(files).map((fileName) => {
            let file = files[fileName];
            return(
                <div key={fileName} style={styles.uploadedFileInfo}>
                    <div style={{width: "33%", textAlign: "left", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {file.name}
                    </div>
                    <div style={{width: "33%", paddingLeft: 10, textAlign: "left"}}>
                        {convertBytesToKB(file.size)}KB
                    </div>
                    <div style={{width: "33%", textAlign: "right", position: "relative"}}>
                        <Button
                            style={{marginRight: -20, padding: 0}}
                            onClick={() => removeFile(fileName)}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </Button>
                    </div>
                </div>
            )
        })}
    </div>
    </>}
    </>)
};

const styles = {
    fileUploadContainer: {
        position: "relative",
        margin: "0px 0 0",
        border: "2px dashed #BABCBD",
        display: "flex",
        width: "50%",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        
    },
    filesContainer: {
        position: "relative",
        margin: "0px 0 0",
        border: "2px dashed #BABCBD",
        display: "flex",
        width: "99.5%",
        borderTop: "none",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        
    },
    pasteZone: {
        position: "relative",
        margin: "0px 0 0",
        width: "50%",
        border: "2px dashed #BABCBD",
        borderLeft: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    infoText: {
        fontWeight: 100,
        lineHeight: 1.5,
        fontSize: 18,
        width: "100%",
        textAlign: "center"
    },
    pastedInfoText: {
        fontWeight: 100,
        fontSize: 18,
        width: "100%",
        textAlign: "center",
        lineHeight: 1.5,
   
    },
    formField: {
        fontSize: 18,
        display: "block",
        width: "100%",
        border: "none",
        textTransform: "none",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        cursor: "pointer"
    },
    iconStyle:{
        fontSize: 22,
        marginRight: 10,
        marginBottom: -5
    },
    uploadedFileInfo: {
        width: "90%",
        backgroundColor: "#f9f9f9",
        borderTop: "1px solid #dddddd",
        display: "flex",
        zIndex: 999,
        cursor: "auto",
        lineHeight: 1.5
    },
    uploadFileButton: {
        backgroundColor: "transparent",
        border: "none",
        fontSize: 18,
        color: "#00338d",
      
    },
    dragDropText: {
        marginTop: 0,
        textAlign: "center"
    },

};

export default DropFile;

