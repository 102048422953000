import React, {useEffect, useState} from "react";
import { IonButton, IonPopover, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonItem, IonSelect, IonSelectOption, IonInput, IonLoading, IonLabel, IonDatetime, IonModal, IonText, IonTextarea } from '@ionic/react';
import { Redirect, useLocation, useHistory  } from "react-router-dom";

import MiniDrawer from "../MiniDrawer";
import { Mixpanel } from "../mixpanel";
import Footer from "../components/Footer";

import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
// import  from "@material-ui/core/Menu";
import {Container, Menu , MenuItem} from "@material-ui/core";

import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { getTimestamp } from '../components/timeStamp.js';
import CustomFilter from "../components/CustomFilter";
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import Link from '@material-ui/icons/Link';
import invoiceSettings from "./invoiceSettings"
import { TagSelector } from "../components/TagSelector.js";
import ReactHtmlParser from 'react-html-parser'; 

import "./../App.css";
import './Page.css';


const Payment = (props) => {

    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [first, setFirst] = useState(false)
    const [accountsFetched, setAccountsFetched] = useState(false)
    const [alert, setAlert] = useState({
        open: false,
        title: "",
        text: ""
    })
    const [reconnectAlert, setReconnectAlert] = useState({
        open: false,
        title:"Oops", 
        text:"We do not have the full account name for this account. Before you can request a payment into this account we need you to re-authenticate it so that we can update the account details."
    })

    const [noAccount, setNoAccount] = useState({
      open: false,
      title:"No accounts", 
      text:"You need to have an account connected to use this feature that is not a demo account, please add on in the accounts page before continuing"
    })
    const [linebreaks, setLinebreaks] = useState(1)
    const [linebreaksNotes, setLinebreaksNotes] = useState(1)
    
    const [showPop, setShowPop] = useState(false)
    const [accountsData, setAccountsData] = useState([])
    const [addAccount, setAddAccount] = useState(false);
    const [requests, setRequests] = useState([])
    const [accounts, setAccounts] = useState([])
    const [taxCats, setTaxCats] = useState([])
    const [taxCatsOpen, setTaxCatsOpen] = useState(false)
    const [reconnectAccount, setReconnectAccount] = useState(false)
    const [accountsComplete, setAccountsComplete] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [mCopied, setMCopied] = useState(false)
    const [lCopied, setLCopied] = useState(false)
    const [hidePayment, setHidePayment] = useState(false)
    const [sendInvoice, setSendInvoice] = useState(false) 
    const [showInvoiceSettings, setShowInvoiceSettings] = useState(false)
    const [customerDetails, setCustomerDetails] = useState("")
    const [invoiceNotes, setInvoiceNotes] = useState("")
    const [dueDate, setDueDate] = useState()
    const [sendCopy, setSendCopy] = useState(false)

    const [toEmail, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [amount, setAmount] = useState(0)

    const [autoClaim, setAutoClaim] = useState(false)
    const [category, setCategory] = useState(5)
    const [cat_name, setCatName] = useState("Business Income")
    const [saving, setSaving] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [pay_info, setPayInfo] = useState({})
    const [viewRequests, setViewRequests] = useState(false)
    const [loading, setLoading] = useState(true)
    const [accountData, setAccountData] = useState({
        id: 0,
        name: "",
        bankName: ""
    });

    const [customers, setCustomers] = useState([]);
    const [emailList, setEmailList] = useState([])
    const [customerShow, setCustomerShow] = useState(undefined)

    const [showTagSelector, setShowTagSelector] = useState(false)
    //const [taxCat, setTaxCat] = useState(null)

    const labelLeft = {
      display: "block", 
      color: "rgba(0, 0, 0, 0.38)", 
      fontSize: 12, 
      width: "100%", 
      paddingLeft: "16px", 
      textAlign: "left"
    }
    const borderBox = {"border": "1px solid #00338d", borderRadius: "3px"}
  

    const close = {
      color: "white",
      height: "40px",
      width: "auto",
      cursor: "pointer"
    }

    const testAccounts = [
      {
          "name": "Cash (un)",
          "id": "6924",
          "user_id": "2216",
          "institution": "Untied",
          "type": "CASH",
          "source": "untied",
          "enabled": "Y",
          "logo_url": null,
          "sort_code": null,
          "ac_number": null,
          "tl_iscard": "N",
          "manual": "Y",
          "demo": "N",
          "currency": "GBP",
          "currency_symbol": "£",
          "simple_name": "Cash",
          "balance": "0",
          "tl_prov_id": null,
          "token": null,
          "num_trans": "4",
          "full_name": null,
          "orig_source": null,
          "connected": "2020-12-17 16:09:36"
      },
      {
          "name": "FAKEBANK (tr)",
          "id": "8025",
          "user_id": "2216",
          "institution": "FAKEBANK",
          "type": "TRANSACTION",
          "source": "truelayer",
          "enabled": "Y",
          "logo_url": "https://api.untied.io/bank_icons/ob-nationwide.png",
          "sort_code": "01-02-03",
          "ac_number": "98765432",
          "tl_iscard": "N",
          "manual": "N",
          "demo": "N",
          "currency": "GBP",
          "currency_symbol": "£",
          "simple_name": "FAKEBANK",
          "balance": "39.58",
          "tl_prov_id": "ob-nationwide",
          "token": "1521979A36D6E1EC9083A453D0BC3A6D9509FB23C58C18A26476B7D892C4943C",
          "num_trans": "38",
          "full_name": "Jane Doe",
          "orig_source": "truelayer",
          "connected": "2021-12-16 09:50:15"
      },
      {
          "name": "INGOGNITOBANK (tr)",
          "id": "8027",
          "user_id": "2216",
          "institution": "INGOGNITOBANK",
          "type": "TRANSACTION",
          "source": "truelayer",
          "enabled": "Y",
          "logo_url": "https://api.untied.io/bank_icons/ob-INGOGNITOBANK.png",
          "sort_code": "12-11-10",
          "ac_number": "12345678",
          "tl_iscard": "N",
          "manual": "N",
          "demo": "N",
          "currency": "GBP",
          "currency_symbol": "£",
          "simple_name": "INGOGNITOBANK",
          "balance": "12237.61",
          "tl_prov_id": "ob-INGOGNITOBANK",
          "token": "DBD34EBA7EE994CAE31F5290FC35414C273983D551AA7A908938426A79C3DF61",
          "num_trans": "29",
          "full_name": "Mx Jane Doe",
          "orig_source": null,
          "connected": "2021-10-22 15:19:44"
      }
  ]

    //trigger send when saving is set to true ... 
    useEffect(() => {
        if (saving) {
            doSend()
        }
    }, [saving])


    useEffect(() => {
      if(!dueDate){
        const date = new Date(moment(new Date()).add(30, "days"))

        let longDate = moment.utc(date).format('YYYY-MM-DD')
        setDueDate(longDate)
      }
        console.log(dueDate)
    }, [dueDate])

    useEffect(() => {
        if (!first) {
          
          if (!user_atom.token || user_atom.agent !== "") {
            let userState = getLocalStorage()
            setUserAtom({
              ...userState,
              agent: user_atom.agent
            })
          }
          else {
            setFirst(true)
          }
        }
      }, [])

    useEffect(() => {
        if (first){
            getTaxCats()
            fetchRequests();
            getAccounts()
            fetchCustomers()
        }
    }, [first])

    useEffect (()=> {
      if(props.location.state && props.location.state.paymentData){
        console.log(props.location.state.paymentData)
        let data = props.location.state.paymentData
        setEmail(data.toEmail)
        setDescription(data.description)
        setAccountData(data.accountData)
        setAmount(data.amount)
        setInvoiceNotes(data.invoiceNotes)
        setCustomerDetails(data.customerDetails)
        setDueDate(data.dueDate)
        setSendInvoice(data.sendInvoice)
        setSendCopy(data.sendCopy)

      }
    },[props])

    useEffect (()=> {
      if(user_atom.token){
        setFirst(true)
      }

    }, [user_atom])

    useEffect(()=> {
      handleKeyDownAddress()
    }, [customerDetails])

    useEffect(()=> {
      handleKeyDownNotes()
    }, [invoiceNotes])


    useEffect(()=> {
      if(sendInvoice){
        let customer = customers.find(e => e.customer_email == toEmail);
        if (customer) {
          setCustomerDetails(customer.customer_address);
          }
      }
    }, [sendInvoice])



    useEffect(()=> {
      if(JSON.stringify(accounts) !== "[]"){
       
      }
    }, [accounts])


    useEffect(()=> {
      if (accountsFetched){
          fetchAccounts()
      }
  }, [accountsFetched])

    useEffect(()=> {
      if(accountsData && accountsFetched){
            setLoading(false)
        }
    }, [accountsData])


    const fetchRequests = () => {
        fetch(
            `https://${global.api_url}/getPaymentRequests.php?ts=${getTimestamp()}`,
            {
              method: "GET",
              headers: { token: user_atom.token, agentId: user_atom.agent }
            }
          )
          .then(res => res.json())
          .then(json => { 
              setRequests(json)
            });
    }

    const fetchCustomers = () => {
      console.log("fetchCustomers");
  
      fetch(`https://${global.api_url}/getInvoiceCustomers.php`,
        { 
          method: "GET",
         headers: { token: user_atom.token }
        }
        )
        .then(resp => resp.json())
        .then(json => {
          setCustomers(json);
          console.log("customers:",json);
          let emailListNew = [];
          json.forEach((e) => {
            console.log(e);
            emailListNew.push({ key: e.id, label: e.customer_email });
          });
  
          setEmailList(emailListNew);
        }
      );
    }

    const getAccounts =  () => {
        fetch(
          `https://${global.api_url}/getAccounts.php?ts=${getTimestamp()}`,
          {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
          }
        )
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {
              // setAccounts(testAccounts)
              // console.log(json.accounts)
 
              setAccounts(json.accounts);
              setAccountsFetched(true)
            }
          });
      }

    const fetchAccounts = () => {
        const new_accounts = []
        const accounts_data = []
        accounts.forEach( element => {
            if (element.enabled === 'Y' && element.demo === 'N' && element.manual === 'N') {
                const ac_number = element.ac_number != null ? " " + element.ac_number : ""
                const sort_code = element.sort_code != null ? " " + element.sort_code : ""
                new_accounts.push({ key: element.id, label: element.simple_name + ac_number + sort_code, name: element.full_name ? element.full_name : "" });
                accounts_data.push(element)
            }
        });

        // const new_account_name = new_accounts[0].label
        const new_account_name = new_accounts.length == 0 ? "" : new_accounts[0].label
        const new_account_id = new_accounts.length > 0 ? new_accounts[0].key : -1
        // const new_account_id = new_accounts[0].key 

        let new_accounts_user = new_accounts.length > 0 ? new_accounts[0].name : ""
        
        setAccounts(new_accounts)
        setAccountsData(accounts_data)

        setAccountData({
            id: new_account_id,
            name: new_accounts_user,
            bankName: new_account_name
        });
    };

    const accountsMenu = () => {
      return(<>
      <IconButton
            style={{ marginTop: 15}}
            aria-haspopup="true"
            onClick={(event) => {
              setCustomerShow(event.currentTarget);
              Mixpanel.track("payment menu selection");
            }}
            color="inherit"
          >
            <svg xmlns="http://www.w3.org/2000/svg"  style={{fill: '#00338d'}}  height="32" viewBox="0 0 24 24" width="32"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
          </IconButton>

          <Menu
            anchorEl={customerShow}
            open={Boolean(customerShow)}
            onClose={()=>setCustomerShow(undefined)}
            disableScrollLock={ true }
          >

          {customers.map(e => { 
            console.log(e)
            return(
            <MenuItem onClick={() => {
               setCustomerDetails(e.customer_address)
               setEmail(e.customer_email)
               setCustomerShow(undefined)
              }}>              
              {e.customer_email}
          </MenuItem>
          )
        })}
        </Menu>

      </>)
    }


    const validateEmail = (email) => {
        if (email == ""){ 
          return false}
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const doSend = () => {
        // scrollView.scrollTo(0)
        //validate - we need an account, an amount, an email and a description
        if (validateEmail(toEmail) == false) {
            setAlert({open: true, title: "Oops", text:"Please enter a valid email address for the recipient"});
            setSaving(false)
            return;
        }


        if (description === "") {
            setAlert({open: true, title: "Oops", text: "Please describe what you are wanting payment for"});
            setSaving(false)
            return;
        }
        
        if (isNaN(parseFloat(amount)) || parseFloat(amount) < 0 ) {
            setAlert({open: true, title: "Oops", text:"Please enter a valid amount greater than 0"});
            setSaving(false)
            return;
        }


        if (amount === 0) {
            setAlert({open: true, title:"Oops", text:"Please enter a valid amount"});
            setSaving(false)
            return;
        }

        if (accountData.bankName === "") {
          setAlert({
            open: true, 
            title:"Oops", 
            text:"Please make sure you have selected an account."
          });
          setSaving(false)
          return;
      }

        if (accountData.name === "") {
            setReconnectAlert({
              open: true, 
              title:"Oops", 
              text:"We do not have the full account name for this account. Before you can request a payment into this account we need you to re-authenticate it so that we can update the account details."
            });
            setSaving(false)
            return;
        }

        Mixpanel.track('sending_payment_request')

        const payload = {
            "name": accountData.name,
            "email": toEmail.toLowerCase(),
            "description": description,
            "amount": parseFloat(amount),
            "account_id": accountData.id,
            "auto_claim": taxCatsOpen,
            "claim_as": category,
            send_invoice: sendInvoice,
            due_date: dueDate,
            send_copy: sendCopy,
            
            notes: invoiceNotes,
            customer: customerDetails,
        }
       
        fetch(
            `https://${global.api_url}/sendPaymentRequest.php`,
        {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
          })
          .then(res => res.json())
          .then(json => {

            if (json != null) {
                Mixpanel.track('sent_payment_request')
                setSaving(false)
                setShowConfirm(true)
                setHidePayment(true)
                setPayInfo({show: true, data: json, pay_guid: json.g, pay_id: json.i })
                fetchRequests();
            } else {
                Mixpanel.track('error_sending_payment_request')
                setAlert({open: true, title: "Error", text:"Error sending request."});
            }
        })

    }


    
    // const _addBankAccount = provider => {
    //     Mixpanel.trackWithProperties('tapped_add_account', { provider: provider, screen: 'PayRequest' });
    //     props.navigation.navigate("Home", { screen: "AddAccountList" });
    // };

    const formatAmount = (amount) => {
        return parseFloat(Math.abs(amount))
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }

    const displayMain = () => {
        if (accounts.length == 0) {
            return(<>
     
                <div style ={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  width: "98%",
                  height: "100%",
                }}>
                     <div style = {{marginBottom: "10px"}}>You do not have any connected bank accounts to receive the payment. To use this feature, please add an account.</div>
                    
                     <IonButton
                      className="ion-button-money"
                      onClick={() => {
                        setAddAccount(true)
                      }}>Add Account</IonButton>
                </div>
                     
             </>
            )
        }
        // else if (showConfirm){
        //   return(
        //     <div className="payment-form-confirmation-container">
        //       {confirmationMessage()}
        //     </div>
        //   )
        // }
        else if (JSON.stringify(accountsData !== "[]")){
          // if(showInvoiceSettings){
          //   return(
          //   <div>
          //     {invoiceSettings()}
          //   </div>
          //   )
          // }
          // else{
          return(
            <div>
                    <TagSelector 
                      taxCats={taxCats}
                      type="I"
                      show={showTagSelector.show} 
                      onDidDismiss={() => setShowTagSelector(false)} 
                      onClick={(item) => {
                          setCategory(item.id);
                          setShowTagSelector(false)
                      }}                     
                    />

              {paymentTable()}
            </div>
          )
          // }
        }
        else{
            return(
                <>
                {loadingMain()}
                </>
            )
        }
    }

    const loadingMain = () => {
                return (<div
                    style={{
                        alignItems: "center",
                        width: "100%", flex: 1,
                        paddingTop: 20
                    }}
                >
        
                    <div
                        style={{
                            width: '100%',
                            textAlign: "center",
                            alignItems: "center",
                            paddingTop: 40
                        }}
                    >
                        <div></div>
                    </div>
                </div>)

    }

    const getTaxCats = () => {
        fetch(`https://${global.api_url}/getTransactions.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        })
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {
                let tax_cats = json.tax_categories
                // let income_categories = []
                // tax_cats.forEach(element => {
                //     if (element.type === 'I' && element.title !== "Ask untied") {
                //             income_categories.push({ key: element.id, label: element.title });
                //     }
                // })
                // setTaxCats(income_categories);
                // filter tax_cats by type == "I"
                setTaxCats(tax_cats);
            }
          });
      }

      const previewInvoice = () => {
        const payload = {
          email: toEmail,
          description: description,
          amount: parseFloat(amount),
          due_date: dueDate,
          customer: customerDetails,
          notes: invoiceNotes,
        };
    
        fetch(`https://${global.api_url}/draftInvoice.php`, {
          method: "POST",
          headers: { token: user_atom.token, 
            // "Content-Type": "application/application/json" 
          },
          body: JSON.stringify(payload)
          })
          .then(res => res.json())
          .then(json => {
            if (json != null) {
              console.log(json);
              window.open(
                `https://${global.api_url}/public/PDFViewer.php?id=${json.guid}&draft=Y`
              );
            }
          }
        );
      };

    const handleKeyDownAddress = (e) => {        
        let lbreaks = customerDetails ? customerDetails.split("\n").length : 1
        setLinebreaks(lbreaks)
      }
    const handleKeyDownNotes = (e) => {        
        let lbreaks = invoiceNotes ? invoiceNotes.split("\n").length : 1
        setLinebreaksNotes(lbreaks)
      }

    const parseValue = (value) => {
        if(value && value.length > 0){
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if(value.indexOf(".")>-1){
                const integers = value.split(".")[0]+".";
                let digits = value.split(".")[1];
                if(digits.length > 2) digits = digits.substring(0,2);
                

                return "£" + integers + digits;
            }else{
                return "£" + value 
            }
        }else{
            return "";
        }
    };
    const parseAmount = (value) => {
        if(value && value.length > 0){
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if(value.indexOf(".")>-1){
                const integers = value.split(".")[0]+".";
                let digits = value.split(".")[1];
                if(digits.length > 2) digits = digits.substring(0,2);
                if(digits.length < 2) digits = digits + "0"

                return "£" + integers + digits;
            }else{
                return "£" + value + ".00";
            }
        }else{
            return "";
        }
    };

    const setFormData = {
        setFormEmail: (val) => {
            setEmail(val)
        },
        setFormDescription: (val) => {
            setDescription(val)
        },
        setFormAccount: (val) => {
            const obj = accounts.filter(item => item.key === val)[0];
            setAccountData({
                id: obj.key,
                name: obj.name,
                bankName: obj.label
            });
        },
        setFormCatName: (val) => {
            setCatName(val)
        }
    };

    const paymentTable = () => {
        return(
            <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "#FFFFFF", "--ion-grid-padding": "0px", borderRadius: "5px 5px 0px 0px", visibility: hidePayment ? "hidden" : "" }}>
              <IonRow style = {{backgroundColor : "#00338d", borderRadius: "5px 5px 0px 0px", }} >
              <div style ={{width: "100%", display: "flex", flexDirection: "row", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}> 
                <h2 style = {{marginLeft: "10px", color: "white"}}>Request payment</h2> 
                <div style={{alignSelf:"center", alignItem: "flex-end", paddingRight: "10px", marginLeft: "auto"}}>  
                <CloseIcon
                      style={close}
                      onClick={() => {
                        setRedirect(true)
                      }}
                  />
                </div>
              </div>
              
              </IonRow>
                <IonRow>
                    <IonCol>
                            <IonLabel style = {labelLeft}>Account holder</IonLabel>
                        <IonItem 
                        // lines="none" style = {borderBox}
                        >
                            <CustomFilter
                                // label="Account holder"
                                disabled={true}
                                value={accountData.name || "Not provided"}
                                className="custom-filter-payments"

                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol 
                    size = {customers.length> 0 ? "10" : ""}
                    >
                      <IonLabel style = {labelLeft}>Send payment request to</IonLabel>
                      <IonItem 
                      // lines="none" style = {borderBox}
                      >
                            <CustomFilter
                                // label="Send payment request to"
                                placeholder="Email"
                                value={toEmail}
                                className="custom-filter-payments"
                                onChange={e => setFormData.setFormEmail(e.target.value)}
                            />
                        </IonItem>
                    </IonCol>
                    {customers.length>0 &&
                    <IonCol style = {{width: "fit-content"}}>
                      {accountsMenu()}
                    </IonCol>
                    }
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonLabel style = {labelLeft}>Describe what the payment is for</IonLabel>
                        <IonItem 
                        // lines="none" style = {borderBox}
                        >
                            <CustomFilter
                                // label="Describe what the payment is for"
                                placeholder="Description"
                                value={description}
                                className="custom-filter-payments"
                                onChange={e => setFormData.setFormDescription(e.target.value)}
                                multiline={true}
                                rows={3}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Amount</IonLabel>
                      <IonItem 
                      // lines="none" style = {borderBox}
                      >
                            <CustomFilter
                                // label="Amount"
                                placeholder="£1.00"
                                value={parseValue(amount)}
                                className="custom-filter-payments"
                                onChange={e => {
                                  let newValue = e.target.value
                                  newValue = newValue.replace(/[^0-9\.]/g,'');
                 
                                  setAmount(newValue)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Account</IonLabel>
                      <IonItem 
                      // lines="none" style = {borderBox}
                      >
                            <CustomFilter
                                select
                                // label=""
                                placeholder={accountData.bankName}
                                value={accountData.id}
                                className="custom-filter-payments"
                                onChange={e => setFormData.setFormAccount(e.target.value)}
                            >
                                {accounts.map((account, index) => (
                                    <MenuItem key={index} value={account.key}>
                                        {account.label}
                                    </MenuItem>
                                ))}
                            </CustomFilter>
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                    <IonItem 
                    // lines="none" style = {borderBox}
                    >
                            <CustomFilter
                                label=""
                                value="Send invoice"
                                disabled={true}
                                className="custom-filter-payments"
                            />
                            <IonCheckbox
                                checked={sendInvoice}
                                onIonChange={(e) => { 
                                  setSendInvoice(e.detail.checked)
                                  }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                {sendInvoice && 
                <div style= {{backgroundColor: "#F3F9FB", marginLeft: 10, marginRight: 10, borderRadius: 5}}>
                <IonRow style= {{"--background": "#F3F9FB"}}>
                <IonCol  style= {{"--background": "#F3F9FB"}}>
                    <IonItem lines ="none"
                    style ={{
                      width: "100%",
                      "--inner-padding-end": 0,
                      "--padding-start": 0,
                      "--background": "#F3F9FB"
                    }}>
                        <IonLabel position= "stacked" 
                        style = {{
                          display: "block", 
                          color: "rgba(0, 0, 0, 0.38)",
                          backgroundColor: "#F3F9FB", 
                          width: "100%", 
                          paddingLeft: "16px", 
                          textAlign: "left",
                          marginBottom: 4
                          }}>Due date</IonLabel>
                        <IonItem
                         style ={{
                          width: "100%",
                          "--inner-padding-end": 0,
                          "--background": "#F3F9FB"
                           }}>
                     
                        <input 
                        type= "date" 
                        value = {dueDate}
                        style = {{
                          border: "none", 
                          width: "100%", 
                          height: "100%", 
                          font: "400 14px Arial", 
                          backgroundColor: "#F3F9FB",
                          margin: "12px 0px 0px"}}
                        onChange={e => setDueDate(e.target.value)} 

                        />
         
                      </IonItem>
                      </IonItem>
                    </IonCol>
                  <IonCol style = {{textAlign: "right", "--background": "#F3F9FB"}}>
                    <IonButton
                    className = "ion-button-inverse"
                    onClick = {() => {setShowInvoiceSettings(true)}}>
                    Invoice setup
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>

                <IonCol style = {{"--background": "#F3F9FB"}}>
                        <IonLabel style = {labelLeft}>Customer contact details</IonLabel>
                                <IonItem 
                                lines="inset" 
                                style = {{"--background": "#F3F9FB"}}>
                                    <IonTextarea
                                        style ={{"--background": "#F3F9FB"}}
                                        placeholder="optional" 
                                        value={customerDetails} 
                                        onIonChange={e => setCustomerDetails(e.detail.value)}
                                        rows={linebreaks}
                                        ></IonTextarea>
                                </IonItem>
                            </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol style = {{"--background": "#F3F9FB"}}>
                  <IonLabel style = {labelLeft}>Invoice notes</IonLabel>
                        <IonItem lines = "inset" style = {{"--background": "#F3F9FB"}}>
                            <IonTextarea 
                                        // onKeyPress={handleKeyDownNotes}
                                        placeholder="optional"
                                        style ={{"--background": "#F3F9FB"}} 
                                        value={invoiceNotes} 
                                        onIonChange={e => setInvoiceNotes(e.detail.value)}
                                        rows={linebreaksNotes}
                                        ></IonTextarea>
                        </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style = {{"--background": "#F3F9FB"}}>
                  <IonItem lines="none" style = {{"--background": "#F3F9FB"}}>
                            <CustomFilter
                                label=""
                                value="Send me a copy"
                                disabled={true}
                                className="custom-filter-payments"
                            />
                            <IonCheckbox
                                checked={sendCopy}
                                onIonChange={(e) => { setSendCopy(e.detail.checked)}}
                            />
                        </IonItem>
                  
                  </IonCol>
                  <IonCol style = {{textAlign: "right"}}>
                      <IonButton
                        className = "ion-button-money"
                        onClick = {() => {previewInvoice()}}>
                        View Invoice
                        </IonButton>
                    </IonCol>
                </IonRow>
                </div>
                }

                <IonRow>
                    <IonCol>
                    <IonItem 
                    // lines="none" style = {borderBox}
                    >
                            <CustomFilter
                                label=""
                                value="Tag payment when it arrives in untied"
                                disabled={true}
                                className="custom-filter-payments"
                            />
                            <IonCheckbox
                                checked={taxCatsOpen}
                                onIonChange={(e) => { setTaxCatsOpen(e.detail.checked)}}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>


                {taxCatsOpen && <IonRow>
                      <IonCol>
                      <IonItem
                      // lines="none" style = {borderBox}
                      >
                              <CustomFilter

                                  label=""
                                  value={taxCats.find(el => el.id === category) ? taxCats.find(el => el.id === category).title : "Select"}
                                  className="custom-filter-payments custom-filter-payments-fake-select"
                                  readonly
                                  //onChange={e => setFormData.setFormCatName(e.target.value)}
                                  onClick={() => {
                                    setShowTagSelector({ show: true, id: "" })
                                    
                                }}
                                style={{cursor:'pointer'}}
                              >
                                  {/* {taxCats.map((item) => (
                                      <MenuItem key={item.key} value={item.label}>
                                          {item.label}
                                      </MenuItem>
                                  ))} */}
                    
                              </CustomFilter>

                                  {/* <IonInput value={taxCats.find(el => el.id === taxCat) ? taxCats.find(el => el.id === taxCat).title : "Select"} readonly onClick={() => {
                                        setShowTagSelector({ show: true, id: "" })
                           
                                    }}
                                    className="custom-filter-payments">
                                    <div className="my-caret-dropdown-icon" />
                                  </IonInput> */}
                              <div className="my-caret-dropdown-icon" style={{right:21}} />
                          </IonItem>
                      </IonCol>
                  </IonRow>}

                <IonRow>
                    <IonCol>
                        <IonButton
                            style = {{"--box-shadow": "none"}}
                            className= "ion-button-inverse"
                            onClick = {()=> {
                                setRedirect(true)
                            }
                            }>
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton
                            style = {{"--box-shadow": "none"}}
                            className="ion-button-money"
                            onClick = {() => {
                                setSaving(true)
                            }}
                        >
                            Send
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    };

    const displayBottomInfo = () => {
      return(
        <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "#FFFFFF", "--ion-grid-padding": "5px", borderRadius: "0px 0px 5px 5px", visibility: hidePayment ? "hidden" : "" }}>
        <IonRow>
          Payment request is sent via untied and their partners to make payments safe and secure.
        </IonRow>
        <IonRow>
          {/* <IonCol>
          <img
              style={{width: "100px",}}
              resizeMode="contain"
              src={require("../images/tplogo.png").default} />
          </IonCol> */}
          <IonCol>
            <img
              style={{width: "300px",}}
              resizeMode="contain"
              src={require("../images/untied-logo-text.png").default} />
            </IonCol>
        </IonRow>
        
        <IonRow style = {{fontSize: "8px"}}>
          untied is regulated by the Financial Conduct Authority (FRN 9101069), supervised by the Chartered Institute of Taxation and recognised by HMRC.
        </IonRow>
      </IonGrid>
      )
    }

    const displayFooter = () => {
      return (
        <Footer />
      );
    }

    const displayPopover = () => {
      if(pay_info.data){

        const payUrl = `https://pay.untied.io/?g=${pay_info.pay_guid}|${pay_info.pay_id}`
        const payMessage = 'Please pay me ' + parseAmount(amount) + ' for ' + description + ' ' + payUrl

      // replace line breaks in description with <br/>
      const descriptionWithBreaks = description.replace(/(?:\r\n|\r|\n)/g, '<br />');
      

      return(
          <IonPopover
          cssClass = {"my-payment-popover-wide"}
          isOpen={pay_info.show}
          onDidDismiss={() => {
            setPayInfo({})
            setHidePayment(false)
            }} 
          >
                  <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "rgba(132, 200, 217, 0.1)", "--ion-grid-padding": "0px", borderRadius: "5px 5px 0px 0px"}}>
                    <IonRow style = {{backgroundColor : "#00338d", borderRadius: "5px 5px 0px 0px", }}>
                    <div style ={{width: "100%", display: "flex", flexDirection: "row", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}> 
                      <h2 style = {{marginLeft: "10px", color: "white"}}>Your payment request has been sent</h2> 
                      <div style={{alignSelf:"center", alignItem: "flex-end", paddingRight: "10px", marginLeft: "auto"}}>  
                      <CloseIcon
                            style={close}
                            onClick={() => {
                              setPayInfo({})
                              setPayInfo({})
                              setShowConfirm(false)
                              setAmount(0)
                              setDescription("")
                              setEmail("")
                              setTaxCatsOpen(false)
                              setCategory(5)
                              setCatName("Business Income")
                            }}
                        />
                      </div>
                    </div>
                    
                    </IonRow>
                    <IonRow><div style ={{ padding: "5px"}} >The following message and payment link has been sent to <strong>{toEmail.toLowerCase()}</strong></div></IonRow>
                    <IonRow >
                      <div style ={{margin: "10px", padding: "5px",  backgroundColor: "rgb(255,255,255)", borderRadius:"3px", width: "100%", paddingBottom: "10px"}}>
                        <p>Hi</p>
                        <p>I'm using untied to manage my payment requests.</p>
                        <p>This email is to ask you to pay me £{parseFloat(amount).toFixed(2)} for:</p>
                        <p>{ReactHtmlParser(descriptionWithBreaks)}</p>
                        <p>When you click the link below you'll be taken to a secure open banking payment portal where you can pay directly from your bank account. You will be able to edit the amount and view my account name for verification. And for your records, the transaction will carry the reference {pay_info.pay_guid}.</p>
                    
                    <div style ={{margin: "auto", backgroundColor: "white", width: "120px"}}>
                    <img style= {{height: "120px", width: "120px"}} src ={`https://quickchart-pdfv22ipta-nw.a.run.app/qr?text=${payUrl}`} alt = {"QR code"} title = {payUrl}/>
                    </div>
                    </div>
                    </IonRow>
        
                    <IonRow style = {{ padding: "10px", paddingTop: "0px"}}>
                      <div>
                        You can also copy the following message, just the payment link or resend the email using the buttons below 
                      </div>
                      <div style ={{padding: "3px", backgroundColor: "rgb(255,255,255)", borderRadius:"3px", width: "100%"}}>
                      <em>{payMessage}</em>
                      </div>
                    </IonRow>

                    <IonRow style = {{ paddingBottom: "10px"}}>
                          <IonCol size ="3"> 
                          <IonButton
                            className="ion-button-inverse"
                            onClick = {()=>{ Mixpanel.track("clicked_copy_message_action"); navigator.clipboard.writeText(payMessage); setMCopied(true) }}
                            style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            size = "small">

                            <FileCopy/>
                                Copy message
                              </IonButton>
                          </IonCol>
                          <IonCol size ="4"> 
                          <IonButton
                            className="ion-button-inverse"
                            onClick = {()=>{ Mixpanel.track("clicked_copy_link_action"); navigator.clipboard.writeText(payUrl); setLCopied(true) }}
                            style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            size = "small">
                            <Link/>
                              Copy payment link
                            </IonButton>
                          </IonCol>
                          {/* <IonCol size ="4"> 
                            <IonButton
                            className="ion-button-inverse"
                            onClick={()=>{
                              Mixpanel.track("clicked_resend_payment_email_action")
                              doSend(pay_info.id)
                            }}
                            style = {{width: "100%", height: "95%", fontSize: "12px"}}
                            size = "small">
                            <Refresh/>
                              Resend payment email
                            </IonButton>
                          </IonCol> */}
                    </IonRow>
                    <IonRow>
                    <IonCol>
                      <IonButton
                        className="ion-button-inverse"
                            onClick = {() => {
                            setPayInfo({})
                            setHidePayment(false)
                            Mixpanel.track('tapped_send_another')
                            setShowConfirm(false)
                            setAmount(0)
                            setDescription("")
                            setEmail("")
                            setTaxCatsOpen(false)
                            setCategory(5)
                            setCatName("Business Income")
                            }} 
                              // props.history.push('/payment/form')
                             
                            >
                              Send a new payment request
                            </IonButton>
                      </IonCol>

                      <IonCol>
                      <IonButton
                      className="ion-button-money"
                         
                            style ={{float: "right", marginRight: "16px"}}
                            onClick={() => setRedirect(true)}
                            >
                              Done
                            </IonButton>
                      </IonCol>
                 
                     
                    </IonRow>
                  </IonGrid>
      
          </IonPopover>
      )
                          }
    }

    // const confirmationMessage = () => {
    // const payUrl = `https://pay.untied.io/?g=${pay_info.pay_guid}|${pay_info.pay_id}`
    //   return(
    //     <IonGrid fixed={true} style={{ position: "absolute", margin: "auto", top: 0, right: 0, bottom: 0, left: 0, height: 280, width: 500, backgroundColor: "white", borderRadius: "10px"}}>
    //       <IonRow>
    //         <IonCol>
    //           A payment link has been sent to {toEmail}
    //         </IonCol>
    //       </IonRow>
    //       <IonRow >
    //         <IonCol
    //          style = {{
    //           backgroundColor: "#f2f2f2",
    //           borderRadius: "5px",

    //           alignContent: "center"
    //         }}>
    //         <div style = {{display: "flex"}}>
    //         <div style = {{margin: "10px auto", display: "flex", alignItems:"center" }}>
    //         {'Please pay me ' + parseValue(amount) + ' for ' + description + ' ' + payUrl}
    //         </div>
    //         </div>
    //         <div style = {{display: "flex"}}>

    //         <div style = {{margin: " 10px 10px 10px auto", display: "flex", alignItems:"center" }}>Copy message to clipboard</div>
    //           <div
    //           className = "paperclip"
    //           onClick = {()=>{
    //           Mixpanel.track('tapped_copy_payment_message')
    //           navigator.clipboard.writeText('Please pay me ' + parseValue(amount) + ' for ' + description + ' ' + payUrl)
    //         }}/>
    //        </div>

    //        <div style = {{display: "flex"}}>
    //        <div style = {{margin: " 10px 10px 10px auto", display: "flex", alignItems:"center" }}>Copy link to clipboard</div>
    //           <div
    //           className = "paperclip"
    //           onClick = {()=>{
    //           Mixpanel.track('tapped_copy_payment_link')
    //           navigator.clipboard.writeText(payUrl)
    //         }}/>
    //         </div>
    //         </IonCol>
    //       </IonRow>
    //       {/* <IonRow>
    //         <IonRow>
    //           <IonCol>
    //             If you are with the person you are requesting payment from you can also show them this QR code:
    //           </IonCol>
    //           <IonCol>
    //             <img style={{ width: 200, height: 200 }} src={{ uri: `https://quickchart-pdfv22ipta-nw.a.run.app/qr?text=${payUrl}` }} />
    //           </IonCol>
    //         </IonRow>
    //         <IonRow>
    //           <IonCol>Powered by SMART from</IonCol>
    //           <IonCol><img
    //                 style={{
    //                     marginTop: -40,
    //                     alignSelf: "center",
    //                     width: "70%"
    //                 }}
    //                 resizeMode="contain"

    //                 src={require("../images/fractal-untied-2.png")} /></IonCol>
    //         </IonRow>
    //       </IonRow> */}
    //       <IonRow>
    //           <IonCol>
    //               <IonButton
    //                   className = "ion-button-money"
    //                   style={{width: 200}}
    //                   onClick = {()=>{
    //                       Mixpanel.track('tapped_send_another')
    //                       setShowConfirm(false)
    //                       setAmount(0)
    //                       setDescription("")
    //                       setEmail("")
    //                       setTaxCatsOpen(false)
    //                       setCategory(5)
    //                       setCatName("Business Income")
    //                   }}>
    //                   Send another
    //               </IonButton>
    //           </IonCol>

    //           <IonCol>
    //               <IonButton
    //                   className = "ion-button-money"
    //                   style={{width: 200}}
    //                   onClick = {()=>{
    //                       setRedirect(true)
    //                   }}>
    //                   Done
    //               </IonButton>
    //           </IonCol>
    //       </IonRow>

    //     </IonGrid>
    //   )
    // }

       return (<>
         {addAccount && <Redirect
            to={{
                pathname: "/accounts",
                state: { addAccount: true }
            }}
            />}
         {showInvoiceSettings && <Redirect
            to={{
                pathname: "/invoiceSettings",
                state: {description, amount, toEmail, accountData, customerDetails, invoiceNotes, dueDate, sendInvoice, sendCopy}
            }}
            />}

          {reconnectAccount && <Redirect
            to={{
                pathname: "/accounts",
                state: { "reconnectAccount":  {value: accountData.id, data: accountsData.filter( account => {return account.id = accountData.id})[0]} }
            }}
            />}
          {redirect &&  <Redirect
            to={{pathname: "/payment" }}
            />}
               <IonAlert
                isOpen={mCopied}
                header = "Message copied to clipboard"
                onDidDismiss={() => {setMCopied(false)}}
                buttons = {["OK"]}
              />
            <IonAlert
              isOpen={lCopied}
              header = "Payment link copied to clipboard"
              onDidDismiss={() => {setLCopied(false)}}
              buttons = {["OK"]}
            />

          <IonAlert
            cssClass = "payment-popup"
            isOpen={alert.open}
            onDidDismiss={() => setAlert({open: false})}
            header={alert.title}
            message={alert.text}
            buttons={[
              {
                text: 'close',
                handler: () => {setAlert({open: false})} 
              }
            ]}
          />

            <IonAlert
            cssClass = "payment-popup"
            isOpen={reconnectAlert.open}
            onDidDismiss={() => setReconnectAlert({open: false})}
            header={reconnectAlert.title}
            message={reconnectAlert.text}
            buttons={[
              { text: 'Reconnect Account',
              handler: () => {setReconnectAccount(true)}
              },
              {
                text: 'close',
                handler: () => {setReconnectAlert({open: false})}
              }
            ]}
          />

        <IonAlert
            cssClass = "payment-popup"
            isOpen={noAccount.open}
            onDidDismiss={() => setNoAccount({open: false})}
            header={noAccount.title}
            message={noAccount.text}
            buttons={[
              { text: 'Add Account',
              handler: () => {setAddAccount(true)}
              },
              {
                text: 'close',
                handler: () => {setNoAccount({open: false})}
              }
            ]}
          />
           
           
        <React.Fragment>
            <div className="payments-page-container" style={{backgroundColor: "lightGrey", top: 0, height: sendInvoice ? "125vh": "100vh"}}>
                <MiniDrawer />
                <div className="payments-page-content" style = {{marginTop: "10px"}}>
                    {loading ? loadingMain() : displayMain()}
                    {displayPopover()}
                    {loading ? null : displayBottomInfo()}
                  
                </div>
            </div>
        </React.Fragment>
        
          <IonLoading
              isOpen={loading}
              message={'Please wait...'}
              // duration={500}
          />
        </>
        )
};


 
 export default Payment
