export const checkUTR = (number) => {
    if (number === '1000000239') { return true }
    let digits = number.replace(/[^0-9]/g, '')
    if (digits.length !== 10){
        return false
    }
    let first = parseInt(digits[0])
    let second = parseInt(digits[1])*6
    let third = parseInt(digits[2])*7
    let fourth = parseInt(digits[3])*8
    let fifth = parseInt(digits[4])*9
    let sixth = parseInt(digits[5])*10
    let seventh = parseInt(digits[6])*5
    let eighth = parseInt(digits[7])*4
    let ninth = parseInt(digits[8])*3
    let tenth = parseInt(digits[9])*2
    let total = second + third + fourth + fifth + sixth +seventh + eighth + ninth + tenth
    let division = total/11    
    let integer = Math.trunc(division)    
    let remainder = total - (integer * 11)
    let elevenMinus = 11 -remainder 
    if (elevenMinus>9) elevenMinus-=9
    return elevenMinus == first
}