import React, { useState, useEffect } from "react";
import { IonInput, IonSelect, IonSelectOption } from '@ionic/react';
import classnames from 'classnames'

export const TagSelector = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [type, setType] = useState('E')

    useEffect(() => {
        if (props.type) {
            setType(props.type)
        }
    }, [props])

    const TaxCatItem = (props) => {
        return (
            <div className="tax-tag-item">
                {props.item.title}<br /><div className="tax-tag-item-description">{props.item.description}</div>
            </div>)
    }


    const handleDismiss = () => {
        if (props.onDidDismiss) {
            props.onDidDismiss();
        }
        setSearchTerm('')
    }

    const handleTagClick = (tag) => {
        if (props.onClick) {
            props.onClick(tag);
        }
        setSearchTerm('')
    }

    const classes = classnames("tag-selector-modal", {
        //  'toolbar-tag-selector-modal': props.toolbar === true
    })

    // close the modal when the user hits the escape key
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleDismiss();
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // when modal is shown set height to 600
    useEffect(() => {
        if (props.show === true) {
            document.getElementById('tag-selector-modal').style.height = '600px';
            document.getElementById('tag-selector-modal').style.width = '430px';
        }
    }, [props.show]);


    if (!props.show) return <div />

    let left = 0
    let top = 0
    let position = 'relative'

    if (props.dockWith) {
        const el = document.getElementById(props.dockWith)
        console.log(el.getBoundingClientRect())
        left = el.getBoundingClientRect().left
        top = el.getBoundingClientRect().top + el.getBoundingClientRect().height + 10
        position = 'absolute'
    }

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 99999999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        modal: {
            position: position,
            top: top,
            left: left,
            backgroundColor: 'white',
            textAlign: 'left',
            boxShadow: "0 5px 10px rgb(0 0 0 / 0.5)",
            transition: "all .15s ease-in-out" /*make it smooth*/,
            height: 0,
            width: 0
        }
    }

    return (

        <div style={styles.overlay}
            onClick={handleDismiss}
        >
            <div
                id="tag-selector-modal"
                isOpen={props.show}
                onDidDismiss={handleDismiss}
                style={styles.modal}
                className={classes}
                onClick={(e) => { e.stopPropagation() }}
            >

                <div style={{ width: '100%', borderBottomColor: '#00338d', borderBottomWidth: 2, borderBottomStyle: 'solid', height: 40 }}>
                    <IonInput style={{ float: 'left', width: '70%' }} placeholder="Search" onIonChange={(e) => {
                        setSearchTerm(e.detail.value)
                    }} />
                    {props.type !== 'M' && props.enableToggle === true && (
                        <IonSelect value={type} style={{ float: 'right', width: '30%', textAlign: 'right' }}
                            onIonChange={e => setType(e.target.value)}>
                            <IonSelectOption value="I">Income</IonSelectOption>
                            <IonSelectOption value="E">Expense</IonSelectOption>
                        </IonSelect>
                    )}
                </div>

                <div style={{ paddingTop: 5, overflowY: 'auto', overflowX: 'hidden', width: '100%', height: 'calc(100% - 40px)' }}>
                    {props.taxCats !== [{}] && props.taxCats.map((item, index) => {
                        if (item.type === type && (searchTerm === '' || item.description.toLowerCase().includes(searchTerm.toLowerCase()) || item.title.toLowerCase().includes(searchTerm.toLowerCase()))) {
                            return <div className="tax-tag-modal-item" key={index} onClick={() => handleTagClick(item)}>
                                <TaxCatItem item={item} /></div>
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>

        </div>

    )


}

