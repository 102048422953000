

class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    if (localStorage.getItem("agent")!== ""){
      return true
    }
    else{
    return localStorage.getItem("token");
    }
    // }
  }

  // isAdmin() {
  //   return localStorage.getItem("admin") === "Y";
  // }

  // isAdminAndVPN() {
  //   return localStorage.getItem("admin") === "Y" && localStorage.getItem("IP_ADDRESS") === global.vpn_ip;
  // }
}

export default new Auth();



// const mapStateToProps = (state) => {
//   return { ...state, 
//       user_auth: state.auth,
//   }
// }


// export default connect(mapStateToProps)(Auth)
