import React, { useState, useRef, useEffect } from "react";
import { userAtom, userCheckoutAtom, taxYearsAtom, profileAtom } from './atom.js'
import { useRecoilState } from 'recoil'
import "./App.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Mixpanel } from "./mixpanel";
import { isMobile } from 'react-device-detect';
import steps from './images/steps.png'
import moment from "moment";
import {
  IonAlert, IonGrid, IonRow, IonCol, IonPopover, IonButton
} from '@ionic/react';
import { getTimestamp } from './components/timeStamp.js'
import { Redirect } from "react-router-dom";

import { useToasts } from 'react-toast-notifications'
import { DriveEtaRounded } from "@material-ui/icons";
import { styles } from './LoginStyles'

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

const Login = (props) => {
  // recoil state
  const [user, setUser] = useRecoilState(userAtom)
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
  const [profile_atom, setProfileAtom] = useRecoilState(profileAtom)
  // end of recoil state

  const firstTimeRender = useRef(true)
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState()
  const [agent, setAgent] = useState()
  const [userCheckout, setUserCheckout] = useState(userCheckoutAtom)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [usePassword, setUsePassword] = useState(false)
  const [doingMagiclink, setDoingMagiclink] = useState(false)
  const [secretcode, setSecretcode] = useState("")
  const [loggedin, setLoggedin] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [goBack, setGoBack] = useState(false)

  const [wrongPlanRedirect, setWrongPlanRedirect] = useState(false)
  const [trialExpired, setTrialExpired] = useState(false)

  const [headingText, setHeadingText] = useState("untied - the UK's personal tax app")
  const [placeholder, setPlaceholder] = useState("Have a secret code? Paste it here.")
  const [secureEntry, setSecureEntry] = useState(true)
  const [ignoreDeviceCheck, setIgnoreDeviceCheck] = useState(false)
  const [target, setTarget] = useState('home')
  const [agentCode, setAgentCode] = useState(null)
  const [showCheckout, setShowCheckout] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showExtendTrialMessage, setShowExtendedTrialMessage] = useState(false)
  //const [usingMagicLink, setUsingMagicLink] = useState(false)
  const [error, setError] = useState({
    errorTitle: "",
    errorSubTitle: "",
    errorText: ""
  })
  const [showExpLinkError, setShowExpLinkError] = useState(false)

  useEffect(() => {
    if (userId && users.length > 0 && agent) {
      connectUser()
    }
  },
    [userId, users, agent])

  useEffect(() => {
    if (!pageLoaded) {

      let old_token = localStorage.getItem('token');
      if (old_token) {
        console.log('have old token - invalidate it')
        fetch(`https://${global.api_url}/invalidateToken.php?ts=${getTimestamp()}`, {
          method: "POST",
          headers: { token: old_token }
        })
      }

      firstTimeRender.current = false
      if (props.location && props.location.search) {
        let qs = new URLSearchParams(window.location.search)
        let data = qs.get("data")
        if (data) {
          data = JSON.parse(atob(data))

          agentPasswordAuth(data.email, data.password, data.userId)

        }


      } else if (props.match != null) {

        if (props.match.params.user) {
          let token = props.match.params.user;
          recoilAuth('', '', false, '', false, token)
        }

        if (props.match.params.token) {
          let token = props.match.params.token;
          let email = props.match.params.email;          
          recoilAuth(email, token, false, '', true)
          setUsername(email)
          setDoingMagiclink(true)
          setSecretcode(token)
        }

        //clear local storage apart from agent_code ...
        const agent_code = localStorage.getItem('agent_code');

        console.log('check for imported data')
        const import_data = localStorage.getItem('import_data');
        const import_trans_data = localStorage.getItem('import_trans_data')
        localStorage.clear()
        if (import_data) {         
          console.log('have data')
          localStorage.setItem('import_data', import_data)
          setTarget('partner_import')
        }
        if (import_trans_data) {         
          console.log('have transactions data')
          localStorage.setItem('import_trans_data', import_trans_data)
          setTarget('transactions')
        }

        localStorage.setItem('agent_code', agent_code)

      } else {
        //do we have imported data?
        console.log('check for imported data')
        const import_data = localStorage.getItem('import_data');
        const import_trans_data = localStorage.getItem('import_trans_data')
        localStorage.clear()
        if (import_data) {         
          console.log('have data')
          localStorage.setItem('import_data', import_data)          
        }
        if (import_trans_data) {         
          console.log('have transactions data')
          localStorage.setItem('import_trans_data', import_trans_data)          
        }
      }


      let urlParams = new URLSearchParams(window.location.search);
      const url_target = urlParams.get('target')
      if (url_target != null) {
        localStorage.setItem("target", target);
        setTarget(url_target)
        setIgnoreDeviceCheck(true)
      }
      const passed_email = urlParams.get('email');
      if (passed_email != null) {
        setUsername(passed_email)
      }

      const agent_code = urlParams.get('agentCode');
      if (agent_code != null) {
        localStorage.setItem('agent_code', agent_code)
        setAgentCode(agent_code);
      }

      setPageLoaded(true)
    }
  }, [])
  
  useEffect(() => {

    if (username!="") {
      let urlParams = new URLSearchParams(window.location.search);
      const ml = urlParams.get('ml');
      if (ml != null) {
        doLogin(true, false)
      }
    }

  }, [username])


  // useEffect(() => {
  //   if (pageLoaded && loggedin) {
  //     props.history.push(`/${target}`)
  //   }
  // }, [loggedin])


  const yyyymmdd = () => {
    function twoDigit(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var now = new Date();
    return (
      "" +
      now.getFullYear() +
      "-" +
      twoDigit(now.getMonth() + 1) +
      "-" +
      twoDigit(now.getDate())
    );
  }

  const doPurchase = (iap_item) => {
    const body = JSON.stringify({
      email: userCheckout.checkoutEmail,
      guid: userCheckout.checkoutGuid,
      start_date: yyyymmdd(),
      plan: "2",
      sub_sku: iap_item === 627400 ? 'untiedy1' : 'untiedm1',
      store: "untied",
      app: 'web'
    });


    let timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${global.api_url}/addUserSubscription.php?ts=${timeStamp}&duration=year`,
      {
        method: "POST",
        body: body,
        headers: { partnerId: global.partner_id }
      }
    )
      .then(res => res.json())
      .then(json => {
        //all good, now we can send user back to login page ....
        //alert('Thanks for subscribing. You can now log in.')
        //window.location.reload(false);

        if (doingMagiclink) {
          recoilAuth(username,secretcode)
        } else {
          recoilAuth(username, password, true)
        }

      });

  }


  const openCheckout = (sku) => {
    Mixpanel.track("opened_checkout", { sku: sku });
    const guid = userCheckout.checkoutGuid
    const email = userCheckout.checkoutEmail
    const coupon = userCheckout.coupon
    window.Paddle.Checkout.open({
      product: sku, passthrough: guid, email: email, coupon: coupon,
      successCallback: () => { doPurchase(sku) },
    });
  }

  const withToast = (Component) => {
    return WrappedComponent(props, Component)
  }

  const WrappedComponent = (props, Component) => {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }

  const EyeIcon = (props) => {
    if (props.open === true) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
      )
    } else {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" /><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" /></svg>
      )
    }
  }

  const isEdgeOrIE = () => {
    const IE = (window.navigator.userAgent.indexOf('MSIE') > 0 || !!window.navigator.userAgent.match(/Trident.*rv:11\./));
    const Edge = (/Edge\/\d./i.test(window.navigator.userAgent))

    return IE || Edge;
  }


  const updateUsername = (event) => {
    setUsername(event.target.value)
  };

  const updatePassword = (event) => {
    setPassword(event.target.value)
  };


  const validateEmail = (email) => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const doSecretCode = () => {
    recoilAuth(username, secretcode);

    props.removeAllToasts()
  }

  const getProfile = (token, agentId) => {
    fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token, agentId }
    })
      .then(res => res.json())
      .then(json => {
        json.dob = moment(json.dob, 'DD/MM/YYYY').toISOString()
        localStorage.setItem("support_token_start", json.support_token_start || "");
        setProfileAtom({ ...json })

        if (json.show_hub_page==="N") {
          setTarget("transactions")
        }

        console.log('got profile, now set recoil tax year ... ')
        getRecoilTaxYears(token)


      })
  }

  // const getData = (token, agentId) => {
  //   fetch(`https://${global.api_url}/getObjectData.php?type=hubpage&ts=${getTimestamp()}`, {
  //       method: "GET",
  //       headers: { token, agentId}
  //   })
  //   .then(res => res.json())
  //   .then(json => {

  //       if (JSON.stringify(json.data)!== "[]"){
  //         console.log("fetched hub data", json.data)
  //           if(!json.data.show){
  //             setTarget("transactions")
  //             setDataFetched(true)
  //           }
  //           else{
  //             setDataFetched(true)
  //           }
  //       }
  //       else{
  //         setDataFetched(true)
  //       }
  //   })
  // }

  const getRecoilTaxYears = (token, agentId) => {
    console.log('Login getTaxYears')
    fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token, agentId }
    })
      .then(response => response.json())
      .then(json => {
        console.log(json)
        if (json.error) {
          if (json.error === "unauthorized") {
            //alert('redirect to login page');
            props.history.push("/logout/logout");
          }
        } else {
          let today = new Date();
          let currentYearID = 0;
          let dateFilterStart = "";
          let dateFilterEnd = "";
          json.years.forEach(element => {
            const start = new Date(element.start_date);
            const end = new Date(element.end_date);

            if (today >= start && today <= end) {
              currentYearID = element.id;
              dateFilterStart = element.start_date;
              dateFilterEnd = element.end_date;
            }
          });
          console.log(json.years)
          setTaxYearsAtom(
            {
              taxYears: json.years,
              dateFilterStart: dateFilterStart,
              dateFilterEnd: dateFilterEnd,
              dateFilter: currentYearID
            });
          console.log('have now set recoil tax years')
        }
      });
  }

  useEffect(() => {
    console.log(JSON.stringify(tax_years_atom))
    if (JSON.stringify(tax_years_atom) !== "{}") {
      console.log('got all data now log in ... ')
      setDataFetched(true);
    }
  }, [tax_years_atom])

  const agentPasswordAuth = (email, password, userId) => {

    let body = JSON.stringify({ email, token: password, user_id: userId });

    // props.agentAuth(email, token, usePassword)

    fetch(`https://${global.api_url}/agent/agentLogin.php?ts=${getTimestamp()}`, {
      method: "POST",
      body: body,
      headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
    })
      .then(res => res.json())
      .then(json => {
   
        if (json.status === "success") {
          //now we need to list the users ....
          setUsers(json.users)
          setUserId(userId)
          setAgent({ id: json.id, email: json.email })
        }
      });
  }
  const connectUser = () => {
    const user_info = users.find((user) => {
      return user.id == userId
    })
    setUser({
      ...user_info,
      agent: agent.id
    })
    setTarget("transactions")
    setDataFetched(true)
    localStorage.setItem('agent', agent.id)
    localStorage.setItem('agentEmail', agent.email)
    localStorage.setItem('guid', user_info.id);
    //localStorage.setItem('userid', json.id);
    localStorage.setItem("token", user_info.token);
    localStorage.setItem("username", user_info.email);
    localStorage.setItem("agentEmail", username)
    localStorage.setItem("mtd", user_info.mtd);

    Mixpanel.identify(user_info.id);
    Mixpanel.track("log_in");
    Mixpanel.people.set({
      $email: user.email,
      dev: process.env.NODE_ENV != "production"
    });

    props.removeAllToasts()
    setLoggedin(true)

  }

  // const agentAuth = (user, advisor) => {
  //   // getRecoilTaxYears(user.token)
  //   getProfile(user.token)
  //   //getData(user.token)
  //   setUser(user)
  //   localStorage.setItem('logged_in', true)
  //   localStorage.setItem('agent', advisor.agent)
  //   localStorage.setItem('guid', user.id);
  //   localStorage.setItem("token", user.token);
  //   localStorage.setItem("username", user.email);
  //   localStorage.setItem("logged_in_as", "");
  //   localStorage.setItem("mtd", user.mtd);
  //   Mixpanel.identify(user.id);
  //   Mixpanel.track("log_in");
  //   Mixpanel.people.set({
  //     $email: user.email,
  //     dev: process.env.NODE_ENV != "production"
  //   });

  //   props.removeAllToasts()
  //   setLoggedin(true)
  // }

  const recoilAuth = (email, token, usePassword = false, agent = '', withMagicLink = false, session_token = '') => {

    const agent_code = localStorage.getItem('agent_code');

    let body = JSON.stringify({ email: email, token: token, agentCode: agent_code });

    if (usePassword) {
      body = JSON.stringify({ email: email, password: token, agentCode: agent_code });
    }

    if (session_token !== '') {
      body = JSON.stringify({ session_token })
    }

    // console.log(agent_code, body)

    fetch(`https://${global.api_url}/getToken.php?ts=${getTimestamp()}`, {
      method: "POST",
      body: body,
      headers: { partnerId: global.partner_id }
    })
      .then(res => res.json())
      .then(json => {
        console.log(json)

   
        if (json.status === "success") {

          const has_sub = (!json.in_trial && json.plan != 3) ? "Y" : "N"
          let userForStorage = {
            ...json,
            has_sub,
            logged_in: true
          }
          getProfile(json.token)
    
          setUser(userForStorage)
          //getData(json.token)
          localStorage.setItem('in_trial', json.in_trial)
          localStorage.setItem('logged_in', true)
          localStorage.setItem('plan', json.plan)
          localStorage.setItem('subscription_active', json.subscription_active)
          localStorage.setItem('agent', agent)
          localStorage.setItem('guid', json.guid);
          localStorage.setItem("token", json.token);
          localStorage.setItem("admin_token", json.token);
          localStorage.setItem("admin", json.admin);
          localStorage.setItem("username", json.email);
          localStorage.setItem("logged_in_as", "");
          localStorage.setItem("mtd", json.mtd);
          localStorage.setItem("has_sub", !json.in_trial && json.plan != 3 ? "Y" : "N")
          localStorage.setItem("has_password", json.has_password ? "Y" : "N")
          localStorage.setItem('web_login_timeout', json.web_login_timeout)
          Mixpanel.identify(json.guid);
          Mixpanel.track("log_in");

          Mixpanel.people.set({
            $email: email,
            dev: process.env.NODE_ENV != "production"
          });

          props.removeAllToasts()
          setLoggedin(true)
        } else {
          if (json.info && (json.info === 'trial_expired' || json.info === 'subscription_invalid')) {
            Mixpanel.identify(json.guid);
            // this.setState({ showError: true, errorButtons: 'all', errorTitle: "Trial Expired", errorSubTitle: "", errorText: "Your trial has expired, if you wish to continue you will need to purchase a subscription." })
            setUserCheckout({
              showCheckout: true,
              checkoutGuid: json.guid,
              checkoutEmail: email,
              showMessage: false,
              coupon: json.offercode,
              messageTitle: json.info === 'trial_expired' ? 'Your trial has expired' : 'Your subscription has expired',
              heading_text: json.info === 'trial_expired' ? "Your trial has expired" : 'Your subscription has expired',
              messageSubTitle: '',
              messageText: json.info === 'trial_expired' ? "Sorry, your 30 day trial has expired. Please purchase a plan." : "Sorry, your subscription has expired. Please purchase a plan.",
              offerExtension: json.offer_extension
            })
            setHeadingText(json.info === 'trial_expired' ? 'Your trial has expired' : 'Your subscription has expired')
            setShowCheckout(true)
            Mixpanel.track("Expired trial");
            return;
          }

          // if (!usePassword) {
          //   recoilAuth(email, token, true);
          //   return;
          // }

          if (json.info === "too_many_attempts") {
            setError({
              showError: true,
              errorTitle: "Unauthorised",
              errorSubTitle: "",
              errorText: "Too many failed attempts. Please wait 5 minutes and try again.",
              buttons: ''
            })
          } else {

            if (withMagicLink) {
              //do this to show the magic link option
              setShowPassword(true)
              setShowExpLinkError(true)


            } else {

              setError({
                showError: true,
                errorTitle: "Unauthorised",
                errorSubTitle: "",
                errorText: usePassword
                  ? "There was a problem logging you in. Please check you have entered the correct email and password.<br><br>If you don't have a password set up yet, click 'Email a magic link'."
                  : "There was a problem logging you in, please check you have entered the correct secret code.",
                buttons: ''
              })
            }
          }

          Mixpanel.track("Unsuccessful login");

        }
      });

  }

  const handleKeyDownSecret = (e) => {
    if (e.key === 'Enter') {
      doSecretCode();
      //recoilAuth(username, password, true);
      //props.removeAllToasts()
    }
  }

  const handleKeyDownEmail = (e) => {
    if (e.key === 'Enter') {
      //doLogin(true, false);
      setShowPassword(true)
    }
  }

  const handleKeyDownPassword = (e) => {
    if (e.key === 'Enter') {
      recoilAuth(username, password, true);
      props.removeAllToasts()
    }
  }

  const doLogin = (usemagiclink = true, register = true) => {
    if (username === "") {
      if (isEdgeOrIE()) { alert("Please enter your email address"); return };

      setError({
        showError: true,
        errorTitle: "Missing Email",
        errorSubTitle: "",
        errorText: "Please enter your email address.",
        buttons: ''
      })
      return;
    }

    if (!validateEmail(username)) {
      console.log(isEdgeOrIE());
      if (isEdgeOrIE()) { alert("Please enter a valid email address"); return };

      setError({
        showError: true,
        errorTitle: "Invalid Email",
        errorSubTitle: "",
        errorText: "Please enter a valid email address.",
        buttons: ''
      })
      return;
    }

    if (usemagiclink) {

      Mixpanel.track("pressed_get_started");
      setDoingMagiclink(true);

      const encoded_username = encodeURIComponent(username)

      //useToasts().addToast({ heading_text: register ? "Welcome!\r\nCheck your email to continue" : "Welcome back!\r\nCheck your email to continue" }, { appearance: 'success' });
      setHeadingText(register ? "Welcome!\r\nCheck your email to continue" : "Welcome back!\r\nCheck your email to continue");
      fetch(
        `https://${global.api_url}/userFromEmail.php?email=${encoded_username}&ts=${getTimestamp()}&app=web&agentCode=${agentCode}`,
        {
          method: "GET",
          headers: { partnerId: global.partner_id }
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json.status === "success") {
            Mixpanel.alias(json.guid);

            let toast_message = "We've sent you a special link and a secret code in your email so that you can log in. Click the link in the email to continue, or copy and paste the secret code into this screen.";
            if (!register) {
              toast_message += " If you have a password set up you can use that instead of the secret code."
            }
            props.addToast(toast_message, { appearance: 'success' })

          } else {
            setError({
              showError: true,
              errorTitle: "Unauthorised",
              errorSubTitle: "",
              errorText: "There was a problem setting up your account.",
              buttons: ''
            })
          }
        });
    } else {
      // want to use password ...
      setUsePassword(true)
      setGoBack(true);
      Mixpanel.track("pressed_use_password");
    }
  }

  const extendTrial = () => {
    
    let timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${global.api_url}/extendTrial.php?ts=${timeStamp}&duration=year`,
      {
        method: "POST",
        body: JSON.stringify({guid:userCheckout.checkoutGuid})       
      }
    )
      .then(res => res.json())
      .then(json => {
        
        Mixpanel.track("extended_trial");
        //re-run auth - this should now log us in if all successful ... 
        if (doingMagiclink) {
          recoilAuth(username,secretcode)
        } else {
          recoilAuth(username, password, true)
        }

      });
  }

  const doBottomView = () => {

    if (!ignoreDeviceCheck) {
      if (isMobile) {
        return (
          <div>
            <p style={{ fontSize: 14, paddingTop: 0, color: "white" }}>
              This web app is not optimised for mobile devices. Please download our native Android or iPhone app from the links below.
            </p>

            <p>
              <a href="https://apps.apple.com/gb/app/untied/id1458630284">
                <img
                  className="alignnone wp-image-3516"
                  src="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png"
                  alt="Download untied from the Apple App Store"
                  width="176"
                  height="68"
                  srcSet="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png 646w, https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge-300x116.png 300w"
                  sizes="(max-width: 176px) 100vw, 176px"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
                <img
                  className="alignnone wp-image-3515"
                  src="https://www.untied.io/wp-content/uploads/2019/11/play.png"
                  alt="Download untied from Google Play"
                  width="176"
                  height="68"
                  srcSet="https://www.untied.io/wp-content/uploads/2019/11/play.png 646w, https://www.untied.io/wp-content/uploads/2019/11/play-300x116.png 300w"
                  sizes="(max-width: 176px) 100vw, 176px"
                />
              </a>
            </p>

            <a href="#" onClick={() => setIgnoreDeviceCheck(true)}>It's ok, let me in!</a>

            <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
              © 2019-2021 untied | All rights reserved |{" "}
              <a
                style={{ color: "#FFF" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.untied.io/untied-privacy-policy/"
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <a
                style={{ color: "#FFF" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.untied.io/untied-app-terms-conditions/"
              >
                Terms of Service
              </a>
            </p>
          </div>
        )
      }
    }
    if (showCheckout) {
      return (
        <IonGrid style={{ width: '100%', marginTop: 0, paddingTop: 0, paddingBottom:0, textAlign: 'center' }}>
          <IonRow>
            {/* <IonCol><h2 style={{ marginTop: 0, paddingTop: 0, paddingBottom:0, marginBottom:0, color: "#c7ff00" }}>Purchase a plan</h2></IonCol> */}
            <IonCol><h2 style={{ marginTop: 0, paddingTop: 0, paddingBottom:0, marginBottom:0, color: "#c7ff00" }}>Your trial has expired</h2></IonCol>
          </IonRow>

          <IonRow>
            {/* <IonCol><p style={{color:'white'}}>To continue using untied and get your tax return done please purchase a plan. Your data is safe and will be restored when you start your plan so that you can continue where you left off.</p></IonCol> */}
            <IonCol><p style={{color:'white'}}>To keep using untied Pro, you need to choose a subscription. Any data you've already entered is still in untied so you can continue where you left off.</p></IonCol>
          </IonRow>

          <IonRow style = {{marginRight: 8}}>
            <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

              <div
                onClick={() => {
                  openCheckout(627400)
                }}
                style={styles.paddleButton}
              >
                <p style={{ fontSize: 12, backgroundColor: 'yellow', marginLeft: '20%', marginRight: '20%', marginTop: 2 }}>Best value</p>

                <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£99.99/year</p>
                <p style={{ fontSize: 12, paddingBottom: 5, paddingTop: 0, marginTop: 0 }}>Yearly</p>
              </div>


            </IonCol>
            <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

              <div
                onClick={() => {
                  openCheckout(620338)
                }}
                //style={{ ...styles.buttonContainer, borderColor: this.state.btn2Selected ? "#ff015a" : 'white' }}
                style={styles.paddleButton}
              >
                <p style={{ fontSize: 11, backgroundColor: 'white', marginLeft: '20%', marginRight: '20%', marginTop: 2 }}>&nbsp;</p>
                <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£9.99/month</p>
                <p style={{ fontSize: 12, paddingBottom: 5, paddingTop: 0, marginTop: 0 }}>Monthly</p>
              </div>


            </IonCol>
          </IonRow>

          {userCheckout.coupon && userCheckout.coupon !== "" &&
            <p style={{ textAlign: 'center', paddingLeft: 50, paddingRight: 50, color: 'white', fontSize: 14 }}>Your discount code (<strong>{userCheckout.coupon}</strong>)
              will be applied during checkout if applicable.</p>
          }
          {/* <IonRow style = {{marginRight: 8}}>
            <IonCol style = {{backgroundColor: "white", borderRadius: 10, paddingTop: 10}}><img src ={steps} style = {{width : "100%"}}/></IonCol>
          </IonRow> */}

          <IonRow>
            <IonCol>
            <p style = {{color: "white",}} >
              <ul style = {{ listStyleType: "none", paddingLeft: 0}}>
                <li>Add your transactions</li>
                <li>Tag income and expenses</li>
                <li>Automatically log journeys</li>
                <li>Submit your tax return to HMRC</li>
              </ul>
            </p>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <p><a style = {{color: "white"}} href="http://www.untied.io/#pricing" target="_new">Compare plans</a></p>
            </IonCol>
          </IonRow>

          {userCheckout.offerExtension && (
            <IonRow>
                <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
                  <p style={{textDecoration:'underline', cursor:'pointer', color:'white'}} onClick={()=>setShowExtendedTrialMessage(true)}>Get another 7 days' free trial</p>
                </IonCol>

                <IonAlert
                  isOpen={showExtendTrialMessage}
                  onDidDismiss={() => setShowExtendedTrialMessage(false)}
                header={'Extend your trial'}
                //subHeader={this.state.helpTitle}
                subHeader={'Do you want to try untied free for another 7 days?'}
                message={"As a special one time offer, we'll restore all your data and you can continue right where you left off. A paid subscription will still be required to file your tax return"}
                buttons={[
                  {
                    text: 'Cancel',
                    role: 'cancel' ,
                    cssClass: 'text-center col-5 alert-cancel-button',                
                  },
                  {
                    text: 'Yes please',
                    cssClass: 'text-center col-5',
                    handler: blah => {
                        extendTrial()
                    }
                  },
                ]}
              />

            </IonRow>
            

          )}


        </IonGrid>
      )
    }


    if (!doingMagiclink && !usePassword) {
      return (
        <div>
          <TextField
            id="inputFocus"
            autoFocus
            style={styles.input}
            onKeyPress={handleKeyDownEmail}
            autoCapitalize="none"
            value={username}
            onChange={event => updateUsername(event)}
            returnkeytype="next"
            placeholder="Email address"
            margin="none"
            InputProps={{ disableUnderline: true }}
          />

          {showPassword &&
            <div style={{ paddingTop: 5 }}>
              <TextField
                id="inputFocus"
                autoFocus
                style={styles.input}
                onKeyPress={handleKeyDownPassword}
                autoCapitalize="none"
                type="password"
                value={password}
                onChange={event => updatePassword(event)}
                returnkeytype="next"
                placeholder="Password"
                margin="none"
                InputProps={{ disableUnderline: true }}
              />

              <p style={{ fontSize: 16, paddingTop: 5, color: "white", textDecorationLine: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  Mixpanel.track("requested_magic_link")
                  
                  doLogin(true, false)
                }
                }
              >
                No password? Email a magic link.

              </p>

            </div>
          }

          <div
            style={{
              flexDirection: "row",
              flex: 1,
              paddingBottom: 10,
              paddingLeft: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          />

          <IonGrid style={{ padding: 0 }}>

            
            {agentCode==null && (
              <IonRow>

              <IonCol style={{ paddingLeft: 0 }}>

                <Button
                  style={styles.buttonContainer}
                  size="small"
                  onClick={() => {

                    if (showPassword) {
                      Mixpanel.track("password_login_attempt")
                      recoilAuth(username, password, true);
                      props.removeAllToasts()
                    } else {
                      setShowPassword(true)
                    }

                    //doLogin(true, false)
                  }}
                >
                  Login
                </Button>
              </IonCol>

              <IonCol style={{ paddingRight: 0 }}>
                <Button
                  style={styles.buttonContainer2}
                  size="small"
                  onClick={() => doLogin(true, true)}
                >
                  Sign up
                </Button>
              </IonCol>
              </IonRow>
            )}
            
            {agentCode != null && agentCode!=="" && (
              <IonRow>
                <IonCol style={{ paddingLeft: 0 }}>
                  <Button
                    style={styles.buttonContainer}
                    size="small"
                    onClick={() => doLogin(true, true)}
                    >
                      Sign in
                    </Button>
                  </IonCol>
                </IonRow>
            )}
            

           
          </IonGrid>

          <p style={{ fontSize: 14, paddingTop: 0, color: "white" }}>
            The untied web app is a companion for the untied mobile app. Use the mobile app to track your mileage and
            keep on top of your taxes while you are on the go.
          </p>

          <p>
            <a href="https://apps.apple.com/gb/app/untied/id1458630284">
              <img
                className="alignnone wp-image-3516"
                src="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png"
                alt="Download untied from the Apple App Store"
                width="176"
                height="68"
                srcSet="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png 646w, https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge-300x116.png 300w"
                sizes="(max-width: 176px) 100vw, 176px"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
              <img
                className="alignnone wp-image-3515"
                src="https://www.untied.io/wp-content/uploads/2019/11/play.png"
                alt="Download untied from Google Play"
                width="176"
                height="68"
                srcSet="https://www.untied.io/wp-content/uploads/2019/11/play.png 646w, https://www.untied.io/wp-content/uploads/2019/11/play-300x116.png 300w"
                sizes="(max-width: 176px) 100vw, 176px"
              />
            </a>
          </p>

          <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
            © 2019-2021 untied | All rights reserved |{" "}
            <a
              style={{ color: "#FFF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.untied.io/untied-privacy-policy/"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              style={{ color: "#FFF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.untied.io/untied-app-terms-conditions/"
            >
              Terms of Service
            </a>
          </p>
        </div>
      );
    } else {

      if (usePassword) {
        setPlaceholder("Password");
      }
      return (

        <div>
          <div
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <div
              style={{
                paddingLeft: 0,
                flex: 1,
                flexItems: 3,
                borderWidth: 0,
                flexDirection: 'row',
                marginBottom: 30,

              }}
            >
              <div style={{
                float: 'left', width: '95%', borderRadius: scale(4),
                borderColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: scale(1),
                backgroundColor: "#ACEAFF",
                color: "#ffffff",
                paddingLeft: 16,
                placeholderTextColor: "#ffffff",
                textAlign: "center",
                fontSize: scale(14),
                marginLeft: 0
              }}>
                <TextField
                  autoFocus

                  onKeyPress={handleKeyDownSecret}
                  style={{ width: '90%' }}
                  placeholder={placeholder}
                  type={secureEntry ? 'password' : 'text'}
                  //color="white"
                  value={secretcode}
                  onChange={event =>
                    setSecretcode(event.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                    className: styler.multilineColor
                  }}
                />

                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 0,
                    borderWidth: 2,
                    paddingRight: scale(8),
                    paddingTop: scale(5),
                    float: 'right'
                  }}
                  onClick={() => setSecureEntry(!secureEntry)}>

                  <EyeIcon open={secureEntry} style={{ paddingTop: 5 }} />
                </div>
              </div>
            </div>

            <div style={{ paddingTop: 50 }}>
              <Button
                onClick={() => doSecretCode()}
                style={styles.buttonContainer}
              >
                {" "}
                Login{" "}
              </Button>

              <Button
                style={styles.buttonContainer2}
                size="small"
                onClick={() => {
                  setDoingMagiclink(!doingMagiclink)
                  setHeadingText("untied - the UK's personal tax app")
                }
                }
              >
                Back
              </Button>


              <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
                © 2019-2021 untied | All rights reserved |{" "}
                <a
                  style={{ color: "#FFF" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.untied.io/untied-privacy-policy/"
                >
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a
                  style={{ color: "#FFF" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.untied.io/untied-app-terms-conditions/"
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
        </div >
      );
    }
  }

  function scale(val) {
    return val;
  }

  const styler = theme => ({
    multilineColor: {
      color: "red"
    }
  });

  console.log({loggedin})
  console.log({pageLoaded})
  console.log({dataFetched})

  if (loggedin && pageLoaded && dataFetched) {
    return <Redirect
      to={{
        pathname: "/" + target
      }}
    />
  }

  return (
    <div
      style={{
        backgroundColor: "#FAFAFC",
        width: "100%",
        height: "120vh",
        paddingTop: 30
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
      >
        <Card
          style={{ backgroundColor: "#00338d", width: 400, borderRadius: 5 }}
        >
          <CardContent>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                flex: 0.6,
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              <img
                style={{
                  width: "100%",
                  paddingLeft: -40,
                  paddingTop: 20
                }}
                alt=""
                src={require("./images/kpmg_hero_2.jpg").default}
              />
            </div>
            <p
              style={{
                fontSize: 18,
                color: "#ffffff",
                paddingBottom: showCheckout ? 0 : 30,
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
                fontWeight: 700
              }}
            >
              {headingText}
            </p>
            {doBottomView()}
          </CardContent>
        </Card>
      </div>

      <IonAlert
        isOpen={error.showError}
        onDidDismiss={() => setError({ showError: false })}
        header={error.errorTitle}
        subHeader={error.errorSubTitle}
        message={error.errorText}
        buttons={
          error.buttons != 'all'
            ? ['OK']
            : [
              {
                text: 'View Plans',
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  window.location.href = "https://www.untied.io/#pricing"
                }
              },
              'OK'
            ]
        }
      />

    <IonPopover
              className="my-ion-submit-type-popover"
                  isOpen={wrongPlanRedirect}
                  backdropDismiss ={false}
                  onDidDismiss={() => setWrongPlanRedirect(false)}
              >
              <IonGrid>
                <IonRow>
                  <IonCol style ={{margin: "0px 15px"}} >
                    <h3 style = {{fontWeight: "800", color: "#00338d", marginBottom: 5}}>Welcome to untied Pro</h3>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style ={{margin: "0px 15px", }} >
                    <p>You're trying to log in to untied Pro. Your email address is currently associated with an untied lite account.</p>
                    <p>untied Pro has more features compared to untied lite. With this plan you can link bank accounts, log mileage, have more income sources and use the untied mobile app. You can upgrade to Pro and enjoy all this power.</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style ={{textAlign: "center"}} >
                    <IonButton
                      className = "ion-button-inverse"
                      href="https://lite.untied.io/"
                    >
                      Take me to lite
                    </IonButton>
                  </IonCol>

                  <IonCol style ={{textAlign: "center"}} >
                    <IonButton
                      className = "ion-button-money"
                      onClick = {()=> {
                        setWrongPlanRedirect(false)
                        setTarget('plan')
                        setLoggedin(true)
                      }}
                    >
                      Upgrade to Pro
                    </IonButton>

                  </IonCol>
                </IonRow>
              </IonGrid>

              </IonPopover>

      <IonAlert
        isOpen={showExpLinkError}
        onDidDismiss={() => setShowExpLinkError({ showExpLinkError: false })}
        header={"Unauthorised"}
        subHeader={""}
        message={"It looks like your magic link has expired. Shall we email you a new one?"}
        buttons={
          [{
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => { }
          },
          {
            text: 'Yes please',
            cssClass: 'secondary',
            handler: blah => {
              doLogin(true, false)
              setShowExpLinkError(false)
            }
          }

          ]
        }
      />

    </div>
  );

}

export default withToast(Login)
