

export const styles = {
    root: {
      background: "black"
    },
    card: {
      minWidth: 675
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    buttonContainer: {
      paddingVertical: 12,
      width: "100%",
      marginBottom: 15,
      height: 36,
      borderRadius: 4,
      backgroundColor: "#c7ff00",
      color: "#00338d",
      textTransform: "none",
      fontSize: 14,
      fontWeight: 700
    },
    buttonContainer2: {
      paddingVertical: 12,
      width: "100%",
      marginBottom: 15,
      height: 36,
      borderRadius: 4,
      color: "#ffffff",
      textTransform: "none",
      fontSize: 14,
      fontWeight: 700,
      borderColor: "#FFFFFF",
      borderStyle: "solid",
      borderWidth: 1
    },
    buttonText: {
      color: "#00338d",
      textAlign: "center",
      fontWeight: "700"
    },
    input: {
      width: "95%",
      borderRadius: 4,
      borderColor: "#ffffff",
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: "#ACEAFF",
      color: "#ffffff",
      paddingLeft: 16,
      placeholderTextColor: "#ffffff",
      textAlign: "center",
      fontSize: 14,
      marginLeft: 0
    },
    input: {
      width: "95%",
      borderRadius: 4,
      borderColor: "#ffffff",
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: "#ACEAFF",
      color: "#ffffff",
      paddingLeft: 16,
      placeholderTextColor: "#ffffff",
      textAlign: "center",
      fontSize: 14,
      marginLeft: 0
    },
    inputBottomMargin: {
      width: "95%",
      borderRadius: 4,
      borderColor: "#ffffff",
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: "#ACEAFF",
      color: "#ffffff",
      paddingLeft: 16,
      placeholderTextColor: "#ffffff",
      textAlign: "center",
      fontSize: 14,
      marginLeft: 0,
      marginBottom: 10
    },
    itIs: {
      color: "#c7ff00",
      fontSize: 18,
      fontWeight: "500",
      letterSpacing: 0.54,
      textAlign: "center",
      alignSelf: "center"
    },
    paddleButton: {
      paddingVertical: 12,
      width: 150,
      marginBottom: 15,
      borderRadius: 4,
      borderWidth: 2,
      backgroundColor: 'white',
      fontSize: '16px',
      color: 'black',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: 5,
      textTransform: "none",
      cursor: 'pointer',
    },
    paddleText: {
      color: "#000000",
      textAlign: "center",
      fontWeight: "700"
    }
  }