import React, { useState,useEffect } from "react";

import { Container } from "@material-ui/core";
import { withRouter, Redirect } from "react-router-dom";
import MiniDrawer from "../MiniDrawer";
import {IonCard, IonCardHeader, IonCardTitle, IonCardContent} from '@ionic/react';


import { useRecoilState } from 'recoil'
import { userAtom} from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'


import "./../App.css";

const TutorialsMenu = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [chosenTutorial, setChosenTutorial] = useState("")
    const [goToTutorial, setGoToTutorial] = useState(false)
    const tutorials = [
        {
            title:"Start here",
            content: "How to set up untied and link your first account"
        }, 
        {
            title:"Transactions",
            content: "How to tag income and expenses, search, filter, edit, add transactions & receipts"
        },
        {
            title:"Journeys",
            content: "How to claim, edit and discard journeys"
        },
        {
            title:"Payments and invoicing",
            content: "How to use our payments and invoicing feature"
        },
        {
            title:"Doing your tax return",
            content: "How to send your tax return"
        },
    ]
   
    useEffect(() => {
            if(!user_atom.token){
                let userState = getLocalStorage();
                setUserAtom(userState);
            }
    }, []);

    const displayMenuCards = () => {
        return(<div className="tax-forms-menu-container">
            {tutorials.map((item, index) => (<div key={index} className="tax-forms-menu-content">
                <IonCard className="tax-forms-menu-card"
                         onClick={() => {
                            setChosenTutorial(item.title);
                            setGoToTutorial(true)
                            }}>
                    <IonCardHeader style={{height: 40}}>
                        <IonCardTitle className="tax-forms-card-title">{item.title}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="tax-forms-card-content">
                        {item.content}
                    </IonCardContent>
                </IonCard>
            </div>))}

            <div className="tax-forms-menu-content">
                <IonCard className="tax-forms-menu-card-help">
                    <IonCardHeader style={{height: 40}}>
                        <IonCardTitle className="tax-forms-card-title">Did we miss a Tutorial?</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent className="tax-forms-card-content">
                        If you can't find the tutorial you need, please <a href="https://help.untied.io/contact">contact us</a>.
                    </IonCardContent>
                </IonCard>
            </div>

        </div>)
    };





    return (<React.Fragment>
        <div className="tax-forms-page-container">
            <MiniDrawer />
            <Container>
                <div style={{width: "70%", minWidth: 700, margin: "auto"}}>
                    {displayMenuCards()}
                    {goToTutorial && <Redirect
                        to={{
                            pathname: "/tutorials",
                            state: {
                                tutorial: chosenTutorial
                            }
                        }}
                    />}
                </div>
            </Container>
        </div>
    </React.Fragment>)
};

export default withRouter(TutorialsMenu)
