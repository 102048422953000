import React from "react";

const Footer = () => {
    let date  = new Date
    date = date.getFullYear()
    const styles = {
        footer: {
            alignSelf: "center",
            justifyContent: "center",
            paddingBottom: 5,
            fontSize: 12,
            color: "#000000",
        },
        hyperLink: {
            color: "#000000"
        }
    };
    return(
        <div>
            <div style={styles.footer}>
                <p>
                    © 2019-{date} untied | All rights reserved |{" "}
                    <a
                        style={styles.hyperLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.untied.io/untied-privacy-policy/"
                    >
                        Privacy Policy
                    </a>{" "}
                    |{" "}
                    <a
                        style={styles.hyperLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.untied.io/untied-app-terms-conditions/"
                    >
                        Terms of Service
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer
